import React from "react";
import { Navigate, useLocation, useNavigate } from "react-router";
import { Box } from "@mui/material";


import { useAppSelector, useAppDispatch } from "../Redux/Hook";


interface Props {
  children: JSX.Element;
  getData?: string;
}


const AdminProtectedRoute = ({ children, getData }: Props) => {
  const navigate = useNavigate();
  const location=useLocation().pathname.split("/")[1]

  const dispatch = useAppDispatch();
  const userID = useAppSelector((state) => state.providerAuth.login.userID);
  const authUser = useAppSelector((state) => state.adminAuth);
  const logout = useAppSelector((state) => state.patientAuth.patientLogoutButton);

  const [data, setData] = React.useState<any>([]);
  const [value, setValue] = React.useState(0);
  const path=location===""?"patient":location
  


  let isAuth =
    path==="admin" &&
    authUser.adminLogin.userType==="ADMIN"&&
    (authUser.adminLogin !== null || undefined || "") &&
    authUser.adminLogoutButton;

  return isAuth ? (

<Box> 

              {children}</Box>
    
  ) : (
    <Navigate to="/admin/adminlogin" replace />
  );
};

export default AdminProtectedRoute;
