
import { Button } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useState, useEffect } from "react";
import * as Yup from "yup";
import {
  TextField,
  Box,
  Typography,
  Grid,
  Paper,
  Autocomplete,
  AutocompleteRenderInputParams,
  createFilterOptions,
} from "@mui/material";

import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import FormTextField from "../../Components/Textfield";
import { Buttoncomponent } from "../../Components/Buttoncomp";

import {  useAppSelector } from "../../Redux/Hook";
import { axiosPrivate,  } from "../../axios/axios";

import { useNavigate } from "react-router-dom";

import ErrorProps from "../../Components/Errorprops";
import CreatemanuallyTableview from "./CreatemanuallyTableview";
import {
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  DialogContent,
  DialogActions,

} from "@mui/material";

import useStyles from "../../styles";
interface InitialValues {
  Organizationid: string;
  ServiceCode: string;
  DiagnosisTestorServiceName: string;
  OrganizationPrices?: string;
  FacilityName: string;
  FacilityNPI?: string;
  FacilityPrices: string;
}
interface optiontype {
  CODE : string;
  CODE_DESC_CONSUMER : string;
  facilityNPI : any;
  facilityName : any;

}
type CloseReason = "backdropClick" | "escapeKeyDown" | "closeButtonClick";
interface DialogProps extends MuiDialogProps {
  onClose: (reason: CloseReason) => void;
}

const Dialog = ({ title, open, onClose, children, ...props }: DialogProps) => {
  return (
    <MuiDialog
      onClose={(_, reason) => onClose(reason)}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={props.maxWidth}
      slotProps={{
        backdrop: {
          style: { backgroundColor: "transparent", boxShadow: "none" },
        },
      }}
    >
      <DialogActions>
        <Button onClick={() => onClose("closeButtonClick")} color="primary">
          <CloseOutlinedIcon />
        </Button>
      </DialogActions>

      <DialogContent>{children}</DialogContent>
    </MuiDialog>
  );
};

const CreateService = ({
  handleCancel,
  getData,
  handleCloseAll,
  handleClosethrfacilitypage,
  serviceLandFlag,
  facilityStateRefresh,
}: any) => {
  const [info, setInfo] = useState<any>([""]);
  //const [serviceCodeError, setServiceCodeError] = useState("");
  const [npi, setNpi] = useState<any>([""]);
  
  const navigate = useNavigate();

  const Organizationid = useAppSelector(
    (state) => state.providerOrganization.orgEditData
  );
 
  const classes=useStyles()


  const initialValues: InitialValues = {
    Organizationid: Organizationid[0].organizationID,

    ServiceCode: "",
    DiagnosisTestorServiceName: "",
    OrganizationPrices: "",
    FacilityNPI: "",
    FacilityName: "",
    FacilityPrices: "",
  };

 
  const CustomPaper = (props: any) => {
    return (
      <Paper
        elevation={8}
        sx={{
        
          color: "black",
        }}
        {...props}
      />
    );
  };
  const OPTIONS_LIMIT = 20;
  const defaultFilterOptions = createFilterOptions();
  const [open, setOpen] = useState<boolean>(false);
  const filterOptions = (options: any, state: any) => {
    return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  };
  const orgid = useAppSelector(
    (state) => state.providerOrganization.orgEditData
  );
  useEffect(() => {
    const fetchUsers = async () => {
      await axiosPrivate.get(`/service/findServiceCode`).then((res) => {
        setInfo(res.data.data);
      });
    };
    const fetchFacilityNPI = async () => {
      await axiosPrivate
        .get(
          `/facility/getFacilityNPIByOrganization?organizationID=${orgid[0].organizationID}`
        )
        .then((res) => {
          setNpi(res.data.data);
        });
    };
    fetchUsers();
    fetchFacilityNPI();
  }, []);

  const [tableData, setTableData] = useState({});
  const handleClickOpen = (values: InitialValues, actions: any) => {
    const servicedata = {
      Organizationid: Organizationid[0].organizationID,
      ServiceCode: values.ServiceCode,
      DiagnosisTestorServiceName: values.DiagnosisTestorServiceName,
      OrganizationPrices: values.OrganizationPrices,
      FacilityNPI: values.FacilityNPI,
      FacilityName: values.FacilityName,
      FacilityPrices: values.FacilityPrices,
      serviceStatus: "Available",
    };
  
    const servicedata1 = Object.values(servicedata);
 
    setTableData(servicedata);
    setOpen(true);
  };

  const handleClose = (value: CloseReason) => {
    setOpen(false);
  };
  const handleSave = () => {
    handleCloseAll(); //  all dialog close
  };
  const handleClose1 = () => {
    navigate("/provider/facility/viewfacility");
  };
  const serviceStateRefresh = () => {
    getData();
  };
  const validationSchema = Yup.object().shape({
    // ServiceCode: Yup.string().required("Service Code is required"),
    // DiagnosisTestorServiceName: Yup.string().required(
    //   "Service Name is required"
    // ),
    FacilityPrices: Yup.string()
      .required("Facility price is required")
      .matches(
        /^(?:0|[1-9]\d*)(?:\.\d+)?$/,
        "only numbers"
      ),
    // FacilityNPI: Yup.string()
    //   .required("FacilityNPI is required")
    //   .matches(
    //     /^[1-9]\d{9}$/,
    //     "only numbers"
    //   )
    //   .test("len", " Invalid NPI", (val: any) => val && val.length === 10),
    // FacilityName: Yup.string().required("Facility Name is required"),
  });

  return (
    <Box sx={{ padding: "1vh" }}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleClickOpen}
        validationSchema={validationSchema}
      >
        {({ handleChange, setFieldValue,         values,
        touched,
        setFieldTouched,  }) => {
        const handleButtonClick = () => {
          setFieldTouched("ServiceCode", true); // Mark ServiceCode field as touched
          setFieldTouched("DiagnosisTestorServiceName", true); // Mark DiagnosisTestorServiceName field as touched
          setFieldTouched("FacilityNPI", true); // Mark ServiceCode field as touched
          setFieldTouched("FacilityName", true); // Mark DiagnosisTestorServiceName field as touched
          if (!values.ServiceCode || !values.DiagnosisTestorServiceName || !values.FacilityNPI || !values.FacilityName) {
            return; // Exit if any required field is empty
          }
      
        
          // If all fields are valid, continue with the form submission
          // setOpen(true);
        };
        // const handleServiceCodeChange = (value: string) => {
        //   setServiceCodeError("");
        //   if (!info.some((option:optiontype) => option.CODE === value)) {
        //     setServiceCodeError("Please select a service Code from the suggestions");
            
        //   } else {

        //     setServiceCodeError("");
       
        //   }
        //   setFieldValue("ServiceCode", value);
          
         
        // };
        return (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography className={classes.formHead}
                  sx={{
                    
                    fontWeight: 600,
               
                  }}
                >
                  Enter Service Data
                </Typography>
              </Grid>
              <Grid container spacing={2} sx={{ padding: "1rem", mt: "3vh" }}>
                <Grid item xs={12} md={6}>
                  <Typography className={classes.formType}
                    sx={{
                   
                      mb: "0.5rem",
                    }}
                  >
                    Service Code{" "}
                    <Typography component="span"display="inline" sx={{ color: "red" }}>
                      *
                    </Typography>
                  </Typography>
                  <Box sx={{ position: "relative" }}>
                  <Field className={classes.manualText}
                    name="ServiceCode"
                    component={Autocomplete}
                    options={info}
                    loading={info.length === 0}
                    PaperComponent={CustomPaper}
                    filterOptions={filterOptions}
                    getOptionLabel={(option: any) => option.CODE || option}
                    isOptionEqualToValue={(option: any, value: any) => 
                      option.value === value.value}
                    // freeSolo
                    fullWidth={true}
                    value={values.ServiceCode}
                    onChange={(event: any, value: any) => {
                  

                      setFieldValue("ServiceCode", value?.CODE || "");
                      setFieldValue(
                        "DiagnosisTestorServiceName",
                        value?.CODE_DESC_CONSUMER || ""
                      );

                      if (!value) {
                        setFieldValue("DiagnosisTestorServiceName", "");
                      }
                    }}
                    renderInput={(params: AutocompleteRenderInputParams) => (
                      <TextField
                        {...params}
                        name="ServiceCode"
                        placeholder="Search Service Code"
                       onChange={handleChange}
                       // onChange={(event) => handleServiceCodeChange(event.target.value)}
                        variant="outlined"
                        helperText={
                          (touched.ServiceCode && !values.ServiceCode) ? (
                            <ErrorMessage name="ServiceCode">
                              {(error) => <ErrorProps>{error}</ErrorProps>}
                            </ErrorMessage>
                          ) : (
                            <ErrorMessage name="ServiceCode">
                              {(error) => (
                                <ErrorProps>
                                  {values.ServiceCode ? (
                                    "Please select a service Code from the suggestions"
                                  ) : (
                                    "Service Code is required"
                                  )}
                                </ErrorProps>
                              )}
                            </ErrorMessage>
                          )
                        }
                        
                      />
                      
                    )}
                  />
                          {/* {serviceCodeError && (
            <Typography sx={{ color: "red" }}>{serviceCodeError}</Typography>
          )} */}

                                     {values.ServiceCode && !info.some((option: optiontype) => option.CODE === values.ServiceCode ) ? (
                    <Box
                      sx={{
                        color: "red",
                        position: "absolute",
                        top: { xs: "45px", md: "calc(100% + 5px)" },
                        left: 10,
                        fontSize: 14,
                        fontFamily: "Inder",
                        fontWeight: 400,
                      }}
                    >
                      { "Please select a service Code from the suggestions" }  
                    </Box>
                  ) : null}
                  {values.ServiceCode && values.ServiceCode.length === 3 && (
  <Box
    sx={{
      color: "red",
      position: "absolute",
      top: { xs: "45px", md: "calc(100% + 5px)" },
      left: 10,
      fontSize: 14,
      fontFamily: "Inder",
      fontWeight: 400,
    }}
  >
                { "Please select a service Code from the suggestions" } 
  </Box>
)}
                  {touched.ServiceCode && !values.ServiceCode ? (
                    <Box
                      sx={{
                        color: "red",
                        position: "absolute",
                        top: { xs: "45px", md: "calc(100% + 5px)" },
                        left: 10,
                        fontSize: 14,
                        fontFamily: "Inder",
                        fontWeight: 400,
                      }}
                    >
                      Service Code is required
                    </Box>
                  ) : null}
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography className={classes.formType}
                    sx={{
                      
                      mb: "0.5rem",
                    }}
                  >
                    Diagnosis Test/Service Name{" "}
                    <Typography  component="span"display="inline" sx={{ color: "red" }}>
                      *
                    </Typography>
                  </Typography>
                  <Box sx={{ position: "relative" }}>
                  <Field className={classes.manualText}
                    name="DiagnosisTestorServiceName"
                    component={Autocomplete}
                    options={info}
                    loading={info.length === 0}
                    PaperComponent={CustomPaper}
                    filterOptions={filterOptions}
                    getOptionLabel={(option: any) =>
                      option.CODE_DESC_CONSUMER || option
                    }
                    isOptionEqualToValue={(option: any, value: any) => 
                      option.value === value.value}
                    // freeSolo
                    onChange={(event: any, value: any) => {
                      setFieldValue("ServiceCode", value?.CODE || "");
                      setFieldValue(
                        "DiagnosisTestorServiceName",
                        value?.CODE_DESC_CONSUMER || ""
                      );

                      if (!value) {
                        setFieldValue("ServiceCode", "");
                      }
                    }}
                    value={values.DiagnosisTestorServiceName}
                    renderInput={(params: AutocompleteRenderInputParams) => (
                      <TextField
                        {...params}
                        name="DiagnosisTestorServiceName"
                        placeholder="Search Service Name"
                        onChange={handleChange}
                        variant="outlined"
                        helperText={
                          (touched.DiagnosisTestorServiceName && !values.DiagnosisTestorServiceName) ? (
                            <ErrorMessage name="DiagnosisTestorServiceName">
                              {(error) => <ErrorProps>{error}</ErrorProps>}
                            </ErrorMessage>
                          ) : (
                            <ErrorMessage name="DiagnosisTestorServiceName">
                              {(error) => (
                                <ErrorProps>
                                  {values.ServiceCode ? (
                                    "Please select a service Name from the suggestions"
                                  ) : (
                                    "Service Name is required"
                                  )}
                                </ErrorProps>
                              )}
                            </ErrorMessage>
                          )
                        }
                        
                      />
                    )}
                  />
                       {values.DiagnosisTestorServiceName && !info.some((option: optiontype) => option.CODE_DESC_CONSUMER === values.DiagnosisTestorServiceName) ? (
                    <Box
                      sx={{
                        color: "red",
                        position: "absolute",
                        top: { xs: "45px", md: "calc(100% + 5px)" },
                        left: 10,
                        
                        fontSize: 14,
                        fontFamily: "Inder",
                        fontWeight: 400,
                      }}
                    >
                      { "Please select a service from the suggestions" }  
                    </Box>
                  ) : null}
                  {touched.DiagnosisTestorServiceName && !values.DiagnosisTestorServiceName ? (
                    <Box
                      sx={{
                        color: "red",
                        position: "absolute",
                        top: { xs: "45px", md: "calc(100% + 5px)" },
                        left: 10,
                        fontSize: 14,
                        fontFamily: "Inder",
                        fontWeight: 400,
                      }}
                    >
                      Servicename is required
                    </Box>
                  ) : null}
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography className={classes.formType}
                   
                    sx={{
                      mt: "0.5rem",
                      mb: "0.5rem",
                    }}
                  >
                    Facility NPI{" "}
                    <Typography component="span" display="inline" sx={{ color: "red" }}>
                      *
                    </Typography>
                  </Typography>
                  <Box sx={{ position: "relative" }}>
                  <Field className={classes.manualText}
                    name="FacilityNPI"
                    component={Autocomplete}
                    options={npi}
                    loading={npi.length === 0}
                    PaperComponent={CustomPaper}
                    filterOptions={filterOptions}
                    getOptionLabel={(option: any) =>
                      option.facilityNPI || option
                    }
                    isOptionEqualToValue={(option: any, value: any) => 
                      option.value === value.value}
                    // freeSolo
                    fullWidth={true}
                    value={values.FacilityNPI}
                    onChange={(e: any, value: any) => {
                    

                      setFieldValue(
                        "FacilityNPI",
                        value && value.facilityNPI ? value.facilityNPI : ""
                      );
                      setFieldValue(
                        "FacilityName",
                        value && value.facilityName ? value.facilityName : ""
                      );
                      if (!value || (value.facilityNPI === null && value.facilityName === null)) {
                        setFieldValue("FacilityName", "");
                      }
                    }}
                   
                    renderInput={(params: AutocompleteRenderInputParams) => (
                      <TextField
                        {...params}
                        name="FacilityNPI"
                        placeholder="Search Facility NPI"
                        onChange={handleChange}
                        variant="outlined"
                
                        helperText={
                          (touched.FacilityNPI && !values.FacilityNPI) ? (
                            <ErrorMessage name="FacilityNPI">
                              {(error) => <ErrorProps>{error}</ErrorProps>}
                            </ErrorMessage>
                          ) : (
                            <ErrorMessage name="FacilityNPI">
                              {(error) => (
                                <ErrorProps>
                                  {values.FacilityNPI ? (
                                    "Please select a Facility NPI from the suggestions"
                                  ) : (
                                    "Facility NPI is required"
                                  )}
                                </ErrorProps>
                              )}
                            </ErrorMessage>
                          )
                        }
                      />
                    )}
                  />
                        {values.FacilityNPI && !npi.some((option: optiontype) => option.facilityNPI === values.FacilityNPI &&npi.length > 0) ? (
                    <Box
                      sx={{
                        color: "red",
                        position: "absolute",
                        top: { xs: "45px", md: "calc(100% + 5px)" },
                        left: 10,
                        
                        fontSize: 14,
                        fontFamily: "Inder",
                        fontWeight: 400,
                      }}
                    >
                      { "Please select a Facility NPI from the suggestions" }  
                    </Box>
                  ) : null}
                  {touched.FacilityNPI && !values.FacilityNPI ? (
                    <Box
                      sx={{
                        color: "red",
                        position: "absolute",
                        top: { xs: "45px", md: "calc(100% + 5px)" },
                        left: 10,
                        fontSize: 14,
                        fontFamily: "Inder",
                        fontWeight: 400,
                      }}
                    >
                      FacilityNPI is required
                    </Box>
                  ) : null}
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography className={classes.formType}
                 
                    sx={{
                      mt: "0.5rem",
                      mb: "0.5rem",
                  
                    }}
                  >
                    Facility Name{" "}
                    <Typography component="span" display="inline" sx={{ color: "red" }}>
                      *
                    </Typography>
                  </Typography>
                  <Box sx={{ position: "relative" }}>
                  <Field className={classes.manualText}
                
                    name="FacilityName"
                    component={Autocomplete}
                    options={npi}
                    loading={npi.length === 0}
                    PaperComponent={CustomPaper}
                    filterOptions={filterOptions}
                    getOptionLabel={(option: any) =>
                      option.facilityName || option
                    }
                    isOptionEqualToValue={(option: any, value: any) => 
                      option.value === value.value}
                    // freeSolo
                    onChange={(e: any, value: any) => {
                      setFieldValue(
                        "FacilityNPI",
                        value && value.facilityNPI ? value.facilityNPI : ""
                      );
                      setFieldValue(
                        "FacilityName",
                        value && value.facilityName ? value.facilityName : ""
                      );
                      if (!value || (value.facilityNPI === null && value.facilityName === null)) {
                        setFieldValue("FacilityNPI", "");
                      }
                    }}
                   
                    value={values.FacilityName}
                    renderInput={(params: AutocompleteRenderInputParams) => (
                      <TextField
                        {...params}
                        name="FacilityName"
                        placeholder="Search Facility Name"
                        onChange={handleChange}
                        variant="outlined"
                        helperText={
                          (touched.FacilityName && !values.FacilityName) ? (
                            <ErrorMessage name="FacilityName">
                              {(error) => <ErrorProps>{error}</ErrorProps>}
                            </ErrorMessage>
                          ) : (
                            <ErrorMessage name="FacilityName">
                              {(error) => (
                                <ErrorProps>
                                  {values.FacilityName ? (
                                    "Please select a Facility Name from the suggestions"
                                  ) : (
                                    "Facility Name is required"
                                  )}
                                </ErrorProps>
                              )}
                            </ErrorMessage>
                          )
                        }
                     
                      />
                    )}
                  />
                        {values.FacilityName && !npi.some((option: optiontype) => option.facilityName === values.FacilityName&&npi.length > 0) ? (
                    <Box
                      sx={{
                        color: "red",
                        position: "absolute",
                        top: { xs: "45px", md: "calc(100% + 5px)" },
                        left: 10,
                        
                        fontSize: 14,
                        fontFamily: "Inder",
                        fontWeight: 400,
                      }}
                    >
                      { "Please select a Facility Name from the suggestions" }  
                    </Box>
                  ) : null}
                  {touched.FacilityName && !values.FacilityName ? (
                    <Box
                      sx={{
                        color: "red",
                        position: "absolute",
                        top: { xs: "45px", md: "calc(100% + 5px)" },
                        left: 10,
                        fontSize: 14,
                        fontFamily: "Inder",
                        fontWeight: 400,
                      }}
                    >
                      Facility Name is required
                    </Box>
                  ) : null}
                  </Box>
                </Grid>
                

                <Grid item xs={12}>
                  <Typography className={classes.formType}
                    sx={{
                      mt: "0.5rem",
                      mb: "0.5rem",
                  
                    }}
                  >
                    Facility Prices{" "}
                    <Typography component="span" display="inline" sx={{ color: "red" }}>
                      *
                    </Typography>
                  </Typography>
                  <FormTextField className={classes.manualText}
                    container={TextField}
                    placeholder="Facility Prices"
                    name="FacilityPrices"
                    autoComplete="text"
                    type="text"
                    fullWidth={true}
                   
                  />
                </Grid>
              </Grid>
              <Grid container item xs={10} columnGap={1} justifyContent="left">
                <Buttoncomponent className={classes.noButton}
                  type="button"
                  onClick={handleCancel}
                  fullWidth={false}
                  variant="contained"
                  sx={{
                    mt: "2vh",
                   
                    width: "15vw",
                    
                    fontWeight: 400,
                    textTransform: "capitalize",
                    borderRadius: "0",
                  
                  }}
                >
                  Cancel
                </Buttoncomponent>
                <Buttoncomponent className={classes.yesButton}
                  type="submit"
                  onClick={handleButtonClick}
                  disable={
                    values.ServiceCode.length === 3 ||
                    touched.DiagnosisTestorServiceName &&
                    touched.ServiceCode &&
                    touched.FacilityPrices&&
                    touched.FacilityNPI&&
                    touched.FacilityName&&
                                       
                    (
                      !(
                        values.DiagnosisTestorServiceName &&
                    values.ServiceCode &&
                    values.FacilityPrices &&
                    values.FacilityNPI &&
                    values.FacilityName&&
                 
                    info.some(
                      (option: optiontype) =>
                        option.CODE_DESC_CONSUMER === values.DiagnosisTestorServiceName
                    ) &&
                    info.some((option: optiontype) => option.CODE === values.ServiceCode)&&   
                    npi.some((option: optiontype) => option.facilityNPI === values.FacilityNPI)&&
                    npi.some((option: optiontype) => option.facilityName === values.FacilityName)
                  )) ||
                  (
                    values.DiagnosisTestorServiceName &&
                    !info.some(
                      (option: optiontype) =>
                        option.CODE_DESC_CONSUMER === values.DiagnosisTestorServiceName
                    )
                  ) ||
                  (
                    values.ServiceCode && 
                    !info.some((option: optiontype) => option.CODE === values.ServiceCode)
                  )||
                  (
                    values.FacilityNPI &&
                    !npi.some((option: optiontype) => option.facilityNPI === values.FacilityNPI)
                  )||
                  (
                    values.FacilityName &&
                    !npi.some((option: optiontype) => option.facilityName === values.FacilityName)
                  )
                ? true : false }
                
                  fullWidth={false}
                  variant="contained"
                  sx={{
                    mt: "2vh",
               
                    textTransform: "capitalize",
                    width: "15vw",
                   
                    borderRadius: "0",
                   
                  }}
                >
                  Next
                </Buttoncomponent>
                <Dialog
                  maxWidth={"xl"}
                  open={open}
                  onClose={handleClose}
                  children={
                    <div>
                      <CreatemanuallyTableview
                        data={tableData}
                        handleCancel={handleClose}
                        onPostSuccess={handleSave}
                        getData={serviceStateRefresh}
                        handleClosethrfacilityPage={handleClosethrfacilitypage}
                        serviceLandFlag={serviceLandFlag}
                        facilityStateRefresh={facilityStateRefresh}
                      />
                    </div>
                  }
                />
              </Grid>
            </Grid>
          </Form>
   ) }}
      </Formik>
    </Box>
  );
};

export default CreateService;
