import {
  Box,
  Typography,
  Link,
 
  Menu,
  IconButton,
  MenuItem,
} from "@mui/material";
import * as Yup from "yup";
import MenuIcon from "@mui/icons-material/Menu";
import React, { useEffect } from "react";
import { useLocation, NavLink, useNavigate } from "react-router-dom";

import CircleIcon from "@mui/icons-material/Circle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { toast } from "react-toastify";

import { useAppDispatch, useAppSelector } from "../../Redux/Hook";

import IdleTimerContainer from "../../IdleTimerContainer"

const CreateOrganizationTab = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [isActive, setIsActive] = React.useState(false);
  const location = useLocation().pathname.split("/")[2];

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  React.useState(false);
  const [unsavedChanges, setUnsavedChanges] = React.useState(false);

  const orgselection = useAppSelector(
    (state: { providerOrganization: { tabstatus: any } }) =>
      state.providerOrganization.tabstatus
  );

  const contactfill = useAppSelector(
    (state: { providerOrganization: { contactstatus: any } }) =>
      state.providerOrganization.contactstatus
  );

  const orgInput = useAppSelector(
    (state: { providerOrganization: { orgData: any } }) =>
      state.providerOrganization.orgData
  );

  useEffect(() => {
    
  }, [orgselection]);

  useEffect(() => {
    if (unsavedChanges) {
      toast.warning("You have unsaved changes. Please save before navigating.");
      setUnsavedChanges(false);
    }
  }, [unsavedChanges]);

  const handleTab = async (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    targetPath: string
  ) => {
    event.preventDefault();

    try {
      if (location === "organizationCreate" && !orgselection) {
        setUnsavedChanges(true);
      } else if (location === "contactPersonInformation" && !contactfill) {
        setUnsavedChanges(true);
      } else {
        setUnsavedChanges(false);
        navigate(targetPath);
      }
    } catch (error) {
      alert("Validation failed. Please handle the error.");
    }
    setAnchorElNav(null);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleOpenNavMenu = (event: any) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const logout = useAppSelector(
    (state) => state.providerAuth.providerLogoutButton
  );
  const userID = useAppSelector((state) => state.providerAuth.login.userID);
  const userName = useAppSelector((state) => state.providerAuth.login.userName);

  return (
    <Box sx={{ padding: "1rem 0 0 0" }}>
      <Box sx={{ display: { xs: "flex", md: "none" } }}>
        <IconButton size="large" onClick={handleOpenNavMenu} color="inherit">
          <MenuIcon />
        </IconButton>

        <Menu
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
          sx={{
            display: { xs: "block", md: "none" },
          }}
        >
          <Link
            to="/provider/organizationCreate"
            component={NavLink}
            underline="none"
          >
            <MenuItem
              onClick={(e: any) => handleTab(e, "/provider/organizationCreate")}
              sx={{ width: 250 }}
            >
              <Typography 
                sx={{
                  
                  fontFamily: "Inder",
                  fontSize: "16px",
                  color: "#000000",
                 
                  padding: "0.3rem",
                
  
    
                  cursor: "pointer",
                }}
              >
                Organization Information
              </Typography>
            </MenuItem>
          </Link>
          <Link
            to="/provider/contactPersonInformation"
            component={NavLink}
            underline="none"
          >
            <MenuItem
              onClick={(e: any) =>
                handleTab(e, "/provider/contactPersonInformation")
              }
              sx={{ width: 250 }}
            >
              <Typography
                sx={{
                  fontFamily: "Inder",
                  fontSize: "16px",
                  color: "#000000",
                  padding: "0.3rem",
                  cursor: "pointer",
                }}
              >
                Contact Person Information
              </Typography>
            </MenuItem>
          </Link>

          {/* </Box> */}
        </Menu>
      </Box>

      <Box
        sx={{
          display: { xs: "none", md: "flex" },
          marginTop: "2rem",
          marginLeft: "2rem",
          backgroundColor: "#F9F9F9",
        }}
      >
        <Box display={"flex"}>
          <Link
            sx={{
              backgroundColor:
                location === "organizationCreate" ? "quaternary.dark" : "default",

              height: "4rem",
              width: "20rem",
            }}
            to="/provider/organizationCreate"
            component={NavLink}
            underline="none"
            onClick={(e: any) => handleTab(e, "/provider/organizationCreate")}
          >
            {location === "contactPersonInformation" ? (
              <CheckCircleIcon
                sx={{
                  color: "#D9D9D9",
                  fontSize: "2.5rem",
                  mt: "10px",
                  ml: "10px",
                }}
              />
            ) : (
              <CircleIcon
                sx={{
                  color: "#10C624",
                  fontSize: "2.5rem",
                  mt: "10px",
                  ml: "10px",
                }}
              />
            )}
            <Typography
              sx={{
                color: location === "organizationCreate" ? "white" : "black",

                cursor: "pointer",
                fontSize: "18px",
                fontWeight: "Bold",

                fontFamily: "Inter,sans-serif",
                marginLeft: "55px",
                marginTop: "-2.5rem",
              }}
            >
              Organization Information
            </Typography>
          </Link>

          <Link
            sx={{
              backgroundColor:
                location === "contactPersonInformation" ? "quaternary.dark" : "default",

              height: "4rem",
              width: "25rem",
            }}
            to="/provider/contactPersonInformation"
            onClick={(e: any) =>
              handleTab(e, "/provider/contactPersonInformation")
            }
            component={NavLink}
            underline="none"
          >
            <CircleIcon
              sx={{
                color:
                  location === "contactPersonInformation"
                    ? "#10C624"
                    : "#D9D9D9",

                fontSize: "2.5rem",
                mt: "10px",
                ml: "10px",
              }}
            />
            <Typography
              sx={{
                color:
                  location === "contactPersonInformation" ? "white" : "black",
                fontFamily: "Inter, sans-serif",
                fontWeight: "Bold",
                fontSize: "18px",
                letterSpacing: "0.2px",
                lineHeight: "28px",

                cursor: "pointer",

                marginLeft: "55px",
                marginTop: "-2.5rem",
              }}
            >
              Contact Person Information
            </Typography>
          </Link>
        </Box>
      </Box>
   <IdleTimerContainer/> 
    </Box>
  );
};

export default CreateOrganizationTab;
