import { useEffect, useState, useReducer } from "react";
import {

  Box,
   Button,
  Typography,
  TablePagination,
  IconButton,
  CircularProgress,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  DialogActions,
  DialogContent,

} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";

import {
  
  GridColumns,
  GridRow,
  GridColTypeDef,
  GridValueFormatterParams,
  GridPreProcessEditCellProps,
} from "@mui/x-data-grid";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

import {useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Redux/Hook";

import AddIcon from "@mui/icons-material/Add";
import clsx from "clsx";
import { axiosPrivate } from "../../axios/axios";

import Pricelist from "./pricelistthrofacilitytabview";
import PricelistEditpage from "./pricelisteditpage";
import React from "react";
import useStyles from "../../styles";
import { updateData } from "../../Redux/ProviderRedux/serviceSlice";
interface forminitialValues {
  _id: string;
  SNo: string;
  ServiceCode: string;
  DiagnosisTestorServiceName: string;
  Organizationid?: string;
  OrganizationPrices: string;
  FacilityNPI?: string;
  FacilityPrices: string;
  GridAlignment: "left" | "right" | "center";
}

type CloseReason = "backdropClick" | "escapeKeyDown" | "closeButtonClick";
interface DialogProps extends MuiDialogProps {
  onClose: (reason: CloseReason) => void;
}
const Dialog = ({ title, open, onClose, children, ...props }: DialogProps) => {
  return (
    <MuiDialog
      onClose={(_, reason) => {console.log('oncloseaction'); onClose(reason)}}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={props.maxWidth}
      slotProps={{
        backdrop: {
          style: { backgroundColor: "transparent", boxShadow: "none" },
        },
      }}
    >
      <DialogActions>
        <Button onClick={() => {onClose("closeButtonClick")}} color="primary">
          <CloseOutlinedIcon />
        </Button>
      </DialogActions>
      <DialogContent>{children}</DialogContent>
    </MuiDialog>
  );
};

export default function Pricelistlandingpage({ handleCloseService }: any) {
  const [data, setData] = useState([] as forminitialValues[]);
   const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [dataCheck, setDataCheck] = useState(false);
  const [dataFlag, setDataFlag] = useState(data.length === 0);
 
  const dispatch = useAppDispatch();
  const [, forceUpdate] = useReducer((x: any) => x + 1, 0);
  const addRow = (newRow: any) => {
  
    setData((prevData) => [...prevData, newRow]);
    forceUpdate();
    dispatch(updateData([...pricelistData, newRow]));
  };
  
   
  //Add Services Dialog Actions
  const [openAddService, setOpenAddService] = useState(false);
  const handleOpenAddService = () => {
    setOpenAddService(true);
  };
  const classes=useStyles()
  const handleCloseAddService = () => {
    setOpenAddService(false);
  };
  const [openEditService, setOpenEditService] = useState(false);
  const handleOpenEdit = () => {
    setOpenEditService(true);
  };
  const handleCloseEditService = () => {
    
    setOpenEditService(false);
  };
  const pricelistData = useAppSelector((state) => state.providerService.data);



  // Function to update the pricelist data in the parent component
  const handleUpdateData = (updatedData:any) => {
    dispatch(updateData(updatedData));
  };

  const orgid = useAppSelector(
    (state) => state.providerOrganization.orgEditData
  );
  
  const facilityinput = useAppSelector(
    (state) => state.providerService.serviceData
  );
  
  const navigate = useNavigate();
  useEffect(() => {
  
    getData();
  }, []);
  const getData = async () => {
    setDataCheck(true);
    const pricelistdetails = await axiosPrivate.get(
      `/service/getPriceListbyFacility?facilityNPI=${facilityinput.facilityNPI}&Organizationid=${orgid[0].organizationID}`
    );
    const data = pricelistdetails.data.data;
   
    if (data.length == 0) {
      setDataCheck(false);
     
    } else {
      setDataCheck(false);
      dispatch(updateData(pricelistdetails.data.data))
      setData(pricelistdetails.data.data);

    }


  };

  const usdPrice: GridColTypeDef = {
    type: "number",
  
    valueFormatter: (params: GridValueFormatterParams<number>) => {
      if (params.value == null) {
        return "";
      }

      const valueFormatted = Number(params.value).toLocaleString();
      return `$ ${valueFormatted} `;
    },
    cellClassName: "font-tabular-nums",
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    setPage(page);
  
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);

  };

  const columns: GridColumns = [
    // {
    //   field: "SNo",
    //   headerName: "S.No",
    //   headerClassName: "super-app-theme--header",
    //   width: 90,
    // },
    {
      field: "ServiceCode",
      headerName: "Service Code",
      headerClassName: "super-app-theme--header",
      minWidth: 200,
    },
    {
      field: "DiagnosisTestorServiceName",
      headerName: "Diagnosis Test/Service Name",
      headerClassName: "super-app-theme--header",
      minWidth: 405,
      // flex: 2,
    },

    {
      field: "OrganizationPrices",
      headerName: "Organization Prices",
      headerClassName: "super-app-theme--header",
      minWidth: 200,
      // flex: 1,
      type: "number",
      align: "right",
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const invalid = !Number(params.props.value);
        return { ...params.props, error: invalid };
      },
      ...usdPrice,
    },
    {
      field: "FacilityNPI",
      headerName: "FacilityNPI",
      headerClassName: "super-app-theme--header",
      minWidth: 200,
      //flex: 0.5,
    },
    {
      field: "FacilityPrices",
      headerName: "Facility Prices",
      headerClassName: "super-app-theme--header",
      minWidth: 200,
      // flex: 0.5,
      type: "number",
      align: "right",
      ...usdPrice,
    },
  ];

  function CustomRow(props: any) {
    const { className, index, ...other } = props;

    return (
      <GridRow
        index={index}
        className={clsx(className, index % 2 === 0 ? "oddodd" : "even")}
        {...other}
      />
    );
  }

  const handleClose = () => {
    handleCloseService()
  }
  const Pointer = { cursor: "hand" };

  return (
    <>
      {data.length === 0 && !dataCheck ? (
       <Pricelist handlePricelistUpdate= {getData} handleCloseService={handleClose} handleCancel={handleCloseAddService} addRow={addRow} dataCheck={dataFlag} />
      ) : (
        <>
          {!dataCheck ? (
            <>
              <Typography className={classes.priceList}
                mb={"0.5rem"}
                sx={{
                  backgroundColor:{ lg:"#E3ECF6", xs:"none"},
               
                  whiteSpace:"nowrap"
                }}
              >
                {facilityinput.facilityName} PRICELIST
              </Typography>

              <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="flex-end"
                sx={{
                  gap: "2rem",
                  mb: 2,
                  fontFamily: "Montserrat",
                }}
              >
                <IconButton
                  sx={{
                    backgroundColor: "custom.customColor14",
                
                    color: "custom.customColor15",
                    "&:hover": {
                      color: "black",
                   
                      letterSpacing: "0.2rem",
                      fontSize: "1rem",
                    },
                  }}
                  onClick={handleOpenAddService}
                // onClick={navigateToAdd}
                >
                  <AddIcon style={Pointer} />
                </IconButton>
                <Dialog
                  fullScreen={true}
                  fullWidth={true}
                  maxWidth={"xl"}
                  sx={{
                    width: "100%",
                  }}
                  open={openAddService}
                  onClose={handleCloseAddService}
                >
                  <DialogContent>
                    <DialogActions>
                      <Box sx={{ width: "100%" }}>
                      <Pricelist key={data.length} handlePricelistUpdate= {getData} handleCancel={handleCloseAddService}  addRow={addRow} />
                      </Box>
                    </DialogActions>
                  </DialogContent>
                </Dialog>
                <IconButton
                  sx={{
                    backgroundColor: "custom.customColor14",
                    
                    color: "custom.customColor15",
                    "&:hover": {
                      color: "black",
                     
                      letterSpacing: "0.2rem",
                      fontSize: "1rem",
                    },
                  }}
                  onClick={handleOpenEdit}
         
                >
                  <EditIcon style={Pointer} />
                </IconButton>
                <Dialog
                  fullScreen={true}
                  fullWidth={true}
                  maxWidth={"xl"}
                  sx={{
                    width: "100%",
                  }}
                  open={openEditService}
                  onClose={handleCloseEditService}
                >
                  <DialogContent>
                    <DialogActions>
                      <Box sx={{ width: "100%" }}>
                      <PricelistEditpage onUpdateData={handleUpdateData} handleCloseEditService = {handleCloseEditService} />
                      </Box>
                    </DialogActions>
                  </DialogContent>
                </Dialog>
               
              </Box>
              <Table
                sx={{ maxWidth: "100%", display: {  xs: "table" } }}
              >
                <TableHead
                  sx={{
                    backgroundColor: "custom.customColor12",
                    border: "1px solid custom.customColor14",
                  }}
                >
                  <TableRow>
                    <TableCell className={classes.landingtableCell}
                      sx={{
                        
                        textAlign: "left",
                      
                      }}
                    >
                      Service Code
                    </TableCell>
                    <TableCell className={classes.landingtableCell}
                      sx={{
                        
                        textAlign: "left",
                    
                      }}
                    >
                      Diagnosis Test/Service Name
                    </TableCell>
                    <TableCell className={classes.landingtableCell}
                      sx={{
                      
                        textAlign: "left",
                      
                      }}
                    >
                      Facility NPI
                    </TableCell>
                    <TableCell className={classes.landingtableCell}
                      sx={{
                       
                        textAlign: "center",
                       
                      }}
                    >
                      Organization Prices
                    </TableCell>
                    <TableCell className={classes.landingtableCell}
                      sx={{
                       
                        textAlign: "center",
                     
                      }}
                    >
                      Facility Prices
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {(rowsPerPage > 0
                    ? pricelistData.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    : pricelistData
                  ).map((dataPath: any, i: any) => (
                    <TableRow
                      key={i}
                      
                      sx={{
                        backgroundColor: (i + 1) % 2 === 0 ? "#EDEDED" : "white",
                        border: "1px solid #CCCCCC"
                      }}
                    >
                      <TableCell className={classes.landingtableCelldetails}
                        sx={{
                         
                          textAlign: "left",
                         
                        }}
                      >
                        {/* {dataPath.filePath.split("/")[2]} */}
                        {dataPath.ServiceCode}
                      </TableCell>
                      <TableCell className={classes.landingtableCelldetails}
                        sx={{
                        
                          textAlign: "left",
                         
                        }}
                      >
                      
                        {dataPath.DiagnosisTestorServiceName}
                      </TableCell>
                      <TableCell className={classes.landingtableCelldetails}
                        sx={{
                          
                          textAlign: "left",
                         
                        }}
                      >
                     
                        {dataPath.FacilityNPI}
                      </TableCell>
                      <TableCell className={classes.landingtableCelldetails}
                        sx={{
                     
                          textAlign: "right",
                          
                        }}
                      >
                      
                        {dataPath.OrganizationPrices === null
                          ? ""
                          : "$ " +
                          Number(
                            dataPath.OrganizationPrices
                          ).toLocaleString()}
                      </TableCell>
                      <TableCell className={classes.landingtableCelldetails}
                        sx={{
                       
                          textAlign: "right",
                        
                        }}
                      >
                    
                        {dataPath.FacilityPrices === null
                          ? ""
                          : "$ " +
                          Number(dataPath.FacilityPrices).toLocaleString()}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      count={pricelistData.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      labelDisplayedRows={({ from, to, count }) =>
                        `${from}-${to} of ${count !== -1 ? count : ` ${to}}`}`
                      }
                      backIconButtonProps={{
                        color: "secondary",
                      }}
                      nextIconButtonProps={{ color: "secondary" }}
                      showFirstButton={true}
                      showLastButton={true}
                      labelRowsPerPage={<span>Rows:</span>}
                      sx={{
                        ".MuiTablePagination-toolbar": {
                          backgroundColor: "#E3ECF6",
                          // "rgba(100,100,100,0.5)"
                        },
                        ".MuiTablePagination-selectLabel, .MuiTablePagination-input":
                        {
                          fontWeight: "bold",
                          color: "primary",
                        },
                      }}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
             
            </>
          ) : (
            <Box
              sx={{
                backgroundColor: "primary.light",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "84vh",
              }}
            >
              <Box>
                <CircularProgress color="inherit" size={50} />
                <Typography>Loading</Typography>
              </Box>
            </Box>
          )}
        </>
      )}
     
    </>
  );
}
