import React from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import {
  providerRoutespages as appRoutes,
  commonHome,
  providerLogin as appLogin,
  patientLogin,
  homePage,
  admin,
  adminRoute,
  searchPage,
} from "./routes";

import Layout from "./component/Layout";

import ProtectedRouteNew from "./ProtectedRoutes/ProtectedRouteNew";
import ProtectedLogin from "./ProtectedRoutes/ProtectedLogin";
import Contact from "./Pages/Contact/Contact";

import { useAppDispatch, useAppSelector } from "./Redux/Hook";
import PatientLogin from "./ProtectedRoutes/PatientLogin";
import PatientRoute from "./ProtectedRoutes/PatientRoute";
import HomePage from "./ProtectedRoutes/HomePage";
import AdminProtectedLogin from "./ProtectedRoutes/AdminLogin";
import AdminProtectedRoute from "./ProtectedRoutes/AdminRoute";
import useStyles from "./styles";

import { ThemeOptions } from "@mui/material/styles";

import { PaletteColorOptions, createTheme } from "@mui/material/styles";

type CustomColorOptions = PaletteColorOptions & {
  customColor1: string;
  customColor2?: string;
  customColor3?: string;
  customColor4?: string;
  customColor5?: string;
  customColor6?: string;
  customColor7?: string;
  customColor8?: string;
  customColor9?: string;
  customColor10?: string;
  customColor11?:string;
  customColor12?:string;
  customColor13?:string;
  customColor14?:string;
  customColor15?:string;
};

declare module "@mui/material/styles" {
  interface Palette {
    tertiary?: CustomColorOptions & {
      main: string;
      light: string;
      dark: string;
      contrastText: string;
    };
    quaternary?: CustomColorOptions & {
      main: string;
      light: string;
      dark: string;
      contrastText: string;
    };
    custom?: CustomColorOptions;
  }
  interface PaletteOptions {
    custom?: CustomColorOptions;
    tertiary?: {
      main: string;
      light: string;
      dark: string;
      contrastText: string;
    };
    quaternary?: {
      main: string;
      light: string;
      dark: string;
      contrastText: string;
    };
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      light: "white", // page background
      main: "#687B9E", //dark
      // dark:"#2898FF",//button backgroundcolor
      // contrastText:"#4D77FF"//hover text
      dark: "#E4ECF7", 
      contrastText: "#000",
    },
    secondary: {
      main: "#4db6ac",
    
      light: "#B4C8FC", 
      dark: "#4D77FF", 
      
    },
    tertiary: {
      main: "#30CDFF", // carecadetlogo
      light: "#173A5E", 
      dark: "#4F514F",
      contrastText: "#686868", 
    },
    quaternary: {
      main: "#687B9E", 
      
      contrastText: "#3AB8FF",
      dark: "#3A3D3E", 
      light: "#565151", 
    },
    custom: {
      customColor1: "#737373",
      customColor2: "#252B42",
  customColor3: "#F6F6F6",
  customColor4: "#3D86CA",
  customColor5: "#00334F",
  customColor6: "#2898FF",
  customColor7: "#4D77FF",
  customColor8: "#B9EA9B",
  customColor9: "#252B42",
  customColor10:"#F5F5F5",
  customColor11:"#E3ECF6",//pagination
  customColor12:"#EDEDED",
  customColor13:"#8A8A8A",
  customColor14:"#CCCCCC",
  customColor15:"#696969"
    },

    text: {
      primary: "#173A5E",
      secondary: "#46505A",
  
    },
    common: {
      black: "#000000",
      white: "#fff",
    },
  },

  
  typography: {
    h1: {
      fontFamily: "Inder",
    },
    h2: {
      fontFamily: "Inter",
    },

    h3: {
      fontFamily: "Montserrat",
    },
    h4: {
      fontFamily: "Poppins",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <>
        <CssBaseline />

        <Router>
          <Layout>
            <Routes>
              {searchPage.map((search) => (
                <Route
                  key={search.key}
                  path={search.path}
                  element={<search.component />}
                />
              ))}
       
              {admin.map((ad) => (
                <Route
                  key={ad.key}
                  path={ad.path}
                  element={
                    <AdminProtectedLogin>
                      <ad.component />
                    </AdminProtectedLogin>
                  }
                />
              ))}
              {adminRoute.map((ad) => (
                <Route
                  key={ad.key}
                  path={ad.path}
                  element={
                    <AdminProtectedRoute>
                      <ad.component />
                    </AdminProtectedRoute>
                  }
                />
              ))}

              {homePage.map((page) => (
                <Route
                  key={page.key}
                  path={page.path}
                  element={
                    <HomePage getData={page.key}>
                      <page.component />
                    </HomePage>
                  }
                />
              ))}
              {patientLogin.map((route) => (
                <Route
                  key={route.key}
                  path={route.path}
                  element={
                    <PatientLogin>
                      <route.component />
                    </PatientLogin>
                  }
                />
              ))}

          

              {appLogin.map((route) => (
                <Route
                  key={route.key}
                  path={route.path}
                  element={
                    <ProtectedLogin>
                      <route.component />
                    </ProtectedLogin>
                  }
                />
              ))}
              {appRoutes.map((route) => (
                <Route
                  key={route.key}
                  path={route.path}
                  element={
                    <ProtectedRouteNew getData={route.key}>
                      <route.component />
                    </ProtectedRouteNew>
                  }
                />
              ))}

           

              {commonHome.map((route) => (
                <Route
                  key={route.key}
                  path={route.path}
                  element={<route.component />}
                />
              ))}
            </Routes>
          </Layout>
        </Router>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </>
    </ThemeProvider>
  );
}

export default App;
