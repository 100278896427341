import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Ifacility {
  serviceData: any;
  facilityData:any;
  data:any

}
const initialState: Ifacility = {
  serviceData: {},
  facilityData:[],
  data: [],
};

export const serviceSlice = createSlice({
  name: "servicedata",
  initialState,
  reducers: {
    serviceInfo: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        serviceData: action.payload,
      };
    },
    facilitynameInfo: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        facilityData: action.payload,
      };
    },
    updateData: (state, action:PayloadAction<any>) => {
      return {
      ...state,
      data: action.payload,
    };
  }
  },
});

export const {  serviceInfo,facilitynameInfo ,updateData} = serviceSlice.actions;
export const serviceReducer = serviceSlice.reducer;
