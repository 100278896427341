import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  DialogActions,
  DialogContent,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Grid } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import FileUploadIcon from "@mui/icons-material/FileUpload";

import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
//comp
import { Buttoncomponent } from "../../Components/Buttoncomp";
//redux store
import { useAppSelector, useAppDispatch } from "../../Redux/Hook";
import { facilityInfo } from "../../Redux/ProviderRedux/facilitySlice";
import {
  serviceInfo,
  facilitynameInfo,
} from "../../Redux/ProviderRedux/serviceSlice";
import { axiosPrivate, baseURL } from "../../axios/axios";

import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import CreateFacility from "./CreateFacility";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Pricelist from "../Services/BulkuploadTabview";
import UpdateFacility from "../Facility/EditFacility";
import Pricelistlandingpage from "../Services/pricelistlandingpagewithoutactions";

import PricelistLandingforActions from "../Services/PricelistLandingforActions";

import useStyles from "../../styles";

interface serviceValue {
  ServiceCode: string;
  DiagnosisTestorServiceName: string;
  Organizationid: string;
  OrganizationPrices: string;
  FacilityNPI: string;
  FacilityName: string;
  FacilityPrices: string;
  serviceStatus: string;
}
interface forminitialValues {
  providerID: string;
  organizationID: string;
  facilityID: string;
  facilityNPI: string;
  facilityName: string;
  facilityType: {
    MainfacilityType: string;
    OthersfacilityType: string;
  };
  address: {
    addressLine1: string;
    addressLine2: string;
    city: string;
    state_code: string;
    zipCode: string;
    country: string;
  };
  contact: string;
  email: string;
  actions: string;
  serviceStatus: serviceValue[] | string;
}

interface formeditinitialValues {
  providerID: string;
  facilityNPI?: string | number;
  facilityName: string;
  // facilityType: string;
  MainfacilityType: string;
  OthersfacilityType: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  latitude: string;
  longitude: string;
  contact: string;
  email: string;
}

export default function ViewFacility() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [page1, setPage1] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [open, setOpen] = useState(-1);
  const [view, setView] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<any>("");
  const [selected, setSelected] = useState<any>([]);

  const [data, setData] = useState([] as forminitialValues[]);

  const [editId, setEditId] = useState(null);
  const [buttondisabled, setButtondisabled] = useState<any>(
    editId !== null || selected.length > 0
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const itemsPerPage = 5;
  const [noOfPages] = useState(Math.ceil(data.length / itemsPerPage));
  const dispatch = useAppDispatch();
  const getid = useAppSelector(
    (state: { providerAuth: { login: any } }) => state.providerAuth.login
  );

  const classes = useStyles();
  const facilityinput = useAppSelector(
    (state: { providerFacility: { fData: any } }) =>
      state.providerFacility.fData
  );

  const serviceinput = useAppSelector(
    (state: { providerService: { serviceData: any } }) =>
      state.providerService.serviceData
  );
  const orgid = useAppSelector(
    (state) => state.providerOrganization.orgEditData
  );

  useEffect(() => {
    getData();
  }, [orgid]);
  const getData = async () => {
    if (orgid && orgid.length > 0) {
      try {
        const facilityDetails = await axiosPrivate.get(
          `/facility/getFacilityByOrganization?organizationID=${orgid[0].organizationID}`
        );
        setData(facilityDetails.data.data);
        setOriginalData(facilityDetails.data.data);
        dispatch(facilitynameInfo(facilityDetails.data.data));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  const Pointer = { cursor: "hand" };

  function getFacilityType(id: any) {
    switch (id) {
      case "FACT-1":
        return "Primary Care";
      case "FACT-2":
        return "Urgent Care";
      case "FACT-3":
        return "Dentist Office";
      case "FACT-4":
        return "Imaging and Laboratory";
      case "FACT-5":
        return "Hospital";
      case "FACT-6":
        return "Others";
    }
  }
  type CloseReason = "backdropClick" | "escapeKeyDown" | "closeButtonClick";
  interface DialogProps extends MuiDialogProps {
    onClose: (reason: CloseReason) => void;
  }

  const Dialog = ({
    title,
    open,
    onClose,
    children,
    ...props
  }: DialogProps) => {
    return (
      <MuiDialog
        onClose={(_, reason) => onClose(reason)}
        aria-labelledby="simple-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={props.maxWidth}
        slotProps={{
          backdrop: {
            style: { backgroundColor: "transparent", boxShadow: "none" },
          },
        }}
      >
        <DialogActions>
          <Button onClick={() => onClose("closeButtonClick")} color="primary">
            <CloseOutlinedIcon />
          </Button>
        </DialogActions>

        <DialogContent>{children}</DialogContent>
      </MuiDialog>
    );
  };
  const [originalData, setOriginalData] = useState([] as forminitialValues[]);
  //Edit / update facility Dialog Actions //
  const [openedit, setOpenedit] = useState(false);

  const handleOpenedit = () => setOpenedit(true);

  const handleCloseedit = () => {
    setOpenedit(false);
    getData();
    setButtondisabled(editId == null);
    // setData (newData)
  };

  const onSubmit = (values: formeditinitialValues, actions: any) => {
    setIsLoading(true);
    const facilitydata = {
      facilityID: facilityinput.facilityID,
      providerID: values.providerID,
      facilityNPI: values.facilityNPI,
      facilityName: values.facilityName,
      facilityType: {
        MainfacilityType: values.MainfacilityType,
        OthersfacilityType: values.OthersfacilityType,
      },
      address: {
        addressLine1: values.addressLine1,
        addressLine2: values.addressLine2,
        city: values.city,
        state_code: values.state,
        zipCode: values.zipCode,
        country: values.country,
      },
      latitude: values.latitude,
      longitude: values.longitude,
      email: values.email,
      contact: values.contact,
    };

    axiosPrivate
      .put(`/facility/updateFacility`, facilitydata)
      .then((res) => {
        toast.success(res.data.message);
        getData();

        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.warning(err.message);
      });
    setOpenedit(false);
  };

  const handleEditClick = (facilityNPI: any) => {
    setEditId(facilityNPI);
  };

  const [open1, setOpen1] = useState(false);
  const handleClickOpen1 = () => {
    setOpen1(true);
  };
  const handleClose1 = (value: CloseReason) => {
    setOpen1(false);
    getData();
  };

  const [openService, setOpenService] = useState(false);
  const handleOpenService = () => {
    setOpenService(true);
  };
  const handleCloseService = (value: CloseReason) => {
    setOpenService(false);
    getData();
    //  reload();
  };
  //Dialog actions for serviceicon
  const [openServiceIcon, setOpenServiceIcon] = useState(false);
  const handleOpenServiceIcon = () => {
    setOpenServiceIcon(true);
  };
  const handleCloseServiceIcon = (value: CloseReason) => {
    setOpenServiceIcon(false);
    getData();
    //  reload();
  };

  const [openpricelistupload, setOpenpricelistupload] = useState(false);
  const handleClickOpenpricelistupload = () => {
    setOpenpricelistupload(true);
  };

  const handleClosepricelistupload = (value: CloseReason) => {
    setOpenpricelistupload(false);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage1(value);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    if (searchTerm === "") {
      setPage(0);
      setRowsPerPage(5);
      setData(originalData);
      return;
    }

    const filteredData = data.filter((item) =>
      item.facilityName.toLowerCase().includes(searchTerm)
    );
    setPage(0);
    setRowsPerPage(5);
    const newNoOfPages = Math.ceil(filteredData.length / itemsPerPage);
    setPage1(1);
    setRowsPerPage(5);
    setData(filteredData);
  };

  //ordering the table data//
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const [sortColumn, setSortColumn] =
    useState<keyof forminitialValues>("facilityID");
  const handleSort = (columnId: keyof forminitialValues) => {
    if (columnId === sortColumn) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(columnId);
      setSortDirection("asc");
    }
    const sortedData = [...data].sort((a, b) => {
      const isAsc = sortDirection === "asc" ? 1 : -1;
      return a[sortColumn] > b[sortColumn] ? isAsc : -isAsc;
    });

    setData(sortedData);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelected(event.target.checked ? data.map((row) => row.facilityNPI) : []);
  };

  const handleClick = (facilityNPI: string) => {
    const selectedIndex = selected.indexOf(facilityNPI);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, facilityNPI);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  interface EnhancedTableToolbarProps {
    numSelected: number;
  }
  function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
    const { numSelected } = props;

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",

          width: "100%",
          fontFamily: "Montserrat",
          fontSize: 14,
          backgroundColor: "#9F9F9",
          ...(numSelected > 0 && {
            backgroundColor: "custom.customColor4",
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            // sx={{ width: "90vw" }}
            // color="inherit"
            variant="subtitle1"
            component="div"
            sx={{
              fontFamily: "Montserrat",
              fontSize: 14,
              color: "#FFFFFF",
              p: "20px",
            }}
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Grid container width={"100%"} display="flex" columnSpacing={0.5}>
            <Grid item xs={12} md={7} mt={"1.1vh"} ml={0}>
              <TextField
                className={classes.manualText}
                id="search"
                type="search"
                placeholder="Search"
                value={searchTerm}
                onInput={handleChange}
                fullWidth
                sx={{
                  ".MuiInputBase-root": {
                    backgroundColor: "#F9F9F9",
                    color: "#252B42",
                    height: 60,
                  },
                  width: "100%",

                  "&:hover": {
                    border: 0,
                  },
                }}
                autoFocus
                InputProps={{
                  style: {
                    borderRadius: 0,
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton type="submit" aria-label="search">
                        <SearchIcon style={{ fill: "#737373" }} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={6} md={3} paddingTop={"1vh"} paddingBottom={"1vh"}>
              <Button
                className={classes.viewButton}
                fullWidth
                variant="contained"
                type="button"
                // size="large"
                sx={{
                  backgroundColor: "#FFFFFF",
                  // width: "100%",
                  // height: "100%",
                  color: "#000000",

                  boxShadow: 0,

                  borderRadius: 0,

                  fontSize: { xs: "0.9rem", md: 14 },
                }}
                onClick={handleClickOpenpricelistupload}
              >
                ADD CASH PRICE
                <CloudUploadOutlinedIcon
                  sx={{ fontSize: "3rem", padding: "1vh" }}
                />
              </Button>

              <Dialog
                open={openpricelistupload}
                onClose={handleClosepricelistupload}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth={"lg"}
                sx={{
                  "& .MuiDialog-container": {
                    fullWidth: "true",
                    width: "100%",
                    maxWidth: "lg",
                  },
                }}
                PaperProps={{
                  elevation: 0,

                  style: {
                    width: "90vh",
                  },
                }}
              >
                <DialogContent>
                  {/* <DialogContentText id="alert-dialog-description"> */}
                  <Pricelist
                    handleClosethrfacilitypage={handleClosepricelistupload}
                    facilityStateRefresh={getData}
                  />
                  {/* </DialogContentText> */}
                </DialogContent>
                <DialogActions></DialogActions>
              </Dialog>
            </Grid>

            <Grid item xs={6} md={2} paddingTop={"1vh"} paddingBottom={"1vh"}>
              <Buttoncomponent
                className={classes.yesButton}
                fullWidth
                variant="contained"
                type="button"
                sx={{
                  width: "100%",
                  height: "100%",

                  borderRadius: 0,

                  fontSize: { xs: "0.9rem", md: 14 },

                  fontWeight: "bold",
                }}
                onClick={() => {
                  handleClickOpen1();
                }}
              >
                Add Facility{" "}
                <AddCircleOutlineRoundedIcon
                  sx={{ fontSize: "3rem", padding: "1vh" }}
                />
              </Buttoncomponent>
              <Dialog
                fullScreen={true}
                fullWidth={true}
                maxWidth={"xl"}
                sx={{
                  width: "100%",
                }}
                open={open1}
                onClose={handleClose1}
              >
                <DialogContent>
                  <DialogActions>
                    <CreateFacility handleClose1={handleClose1} />
                  </DialogActions>
                </DialogContent>
              </Dialog>
            </Grid>
          </Grid>
        )}
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton
              style={Pointer}
              onClick={() => {
                const dataDelete = {
                  data: {
                    dataDel: selected,
                    organizationID: orgid[0].organizationID,
                  },
                };

                axiosPrivate
                  .delete(`/facility/multipleDeleteFacility`, dataDelete)
                  .then(() => {
                    toast.success("Successfully deleted");
                    getData();
                    dispatch(facilityInfo(data));
                    setSelected([]);
                  });
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontSize: 14,
                  color: "#FFFFFF",
                }}
              >
                Delete
              </Typography>
              <DeleteIcon style={Pointer} sx={{ color: "#FFFFFF" }} />
            </IconButton>
          </Tooltip>
        ) : null}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <EnhancedTableToolbar numSelected={selected.length} />
      <TableContainer
        component={Box}
        sx={{
          width: "100%",
        }}
      >
        <Grid item sx={{ justifyContent: "center" }}>
          <Table
            className={classes.table}
            sx={{
              maxWidth: "100%",
            }}
          >
            <TableHead
              sx={{
                width: "100%",
              }}
            >
              <TableRow>
                <TableCell padding="checkbox" sx={{ width: "2%" }}>
                  <Checkbox
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                    color="primary"
                    checked={data.length > 0 && selected.length === data.length}
                    onChange={handleSelectAllClick}
                  />
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  sx={{
                    textAlign: "left",
                  }}
                >
                  Facility NPI
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  sx={{
                    textAlign: "left",
                  }}
                >
                  Facility Name
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  sx={{
                    textAlign: "left",
                  }}
                >
                  Facility Type
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  sx={{
                    textAlign: "left",
                  }}
                >
                  Services Data
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  sx={{
                    textAlign: "left",
                  }}
                >
                  Address
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  sx={{
                    textAlign: "left",
                  }}
                >
                  Contact
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  sx={{
                    textAlign: "left",
                  }}
                >
                  Email
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  sx={{
                    textAlign: "center",
                  }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {(rowsPerPage > 0
                ? data.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : data
              ).map((facility, i) => {
                return (
                  <TableRow
                    key={facility.facilityNPI}
                    sx={{
                      cursor: "pointer",
                      width: "100%",
                      backgroundColor:
                        (i + 1) % 2 === 0 ? "#FFFFFF" : "custom.customColor3",
                    }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                        color="primary"
                        checked={selected.includes(facility.facilityNPI)}
                        onChange={() => handleClick(facility.facilityNPI)}
                      />
                    </TableCell>
                    <TableCell
                      className={classes.cellDetails}
                      sx={{
                        textAlign: "left",

                        fontWeight: 500,
                      }}
                    >
                      {facility.facilityNPI}
                    </TableCell>
                    <TableCell
                      className={classes.cellDetails}
                      sx={{
                        textTransform: "capitalize",

                        fontWeight: 500,
                      }}
                    >
                      {facility.facilityName.toLocaleLowerCase()}
                    </TableCell>
                    <TableCell
                      className={classes.cellDetails}
                      sx={{
                        textAlign: "left",

                        fontWeight: 500,
                      }}
                    >
                      {facility.facilityType.MainfacilityType !== null
                        ? getFacilityType(
                            facility.facilityType.MainfacilityType
                          )
                        : getFacilityType(
                            facility.facilityType.OthersfacilityType
                          )}
                    </TableCell>
                    <TableCell
                      className={classes.cellDetails}
                      sx={{
                        textAlign: "left",

                        fontWeight: 500,
                      }}
                    >
                      {facility.serviceStatus === null ? (
                        "No service data"
                      ) : (
                        <Button
                          variant="text"
                          onClick={() => {
                            handleOpenService();
                            dispatch(serviceInfo(facility));
                          }}
                          sx={{
                            color: "secondary.dark",
                            textTransform: "capitalize",
                            textAlign: "left",
                            fontFamily: "Montserrat",
                            fontSize: 14,
                          }}
                        >
                          Available
                        </Button>
                      )}

                      <Dialog
                        maxWidth={"xl"}
                        sx={{
                          width: "100%",
                        }}
                        open={openService}
                        onClose={handleCloseService}
                      >
                        <DialogContent>
                          <DialogActions>
                            <Box sx={{ width: "100%" }}>
                              {" "}
                              <Pricelistlandingpage />
                            </Box>
                          </DialogActions>
                        </DialogContent>
                      </Dialog>
                    </TableCell>
                    <TableCell>
                      <Typography
                        className={classes.cellDetails}
                        sx={{
                          textAlign: "left",
                          // width: "17%",
                          textTransform: "capitalize",

                          fontWeight: 500,
                        }}
                      >
                        {facility.address.addressLine1.toLocaleLowerCase()}
                      </Typography>
                      <Typography
                        className={classes.cellDetails}
                        sx={{
                          textAlign: "left",

                          textTransform: "capitalize",

                          fontWeight: 500,
                        }}
                      >
                        {facility.address.addressLine2 === "undefined" ||
                        facility.address.addressLine2 === "null" ||
                        facility.address.addressLine2 === null
                          ? null
                          : facility.address.addressLine2}
                      </Typography>
                      <Typography
                        className={classes.cellDetails}
                        sx={{
                          textAlign: "left",

                          textTransform: "capitalize",

                          fontWeight: 500,
                        }}
                      >
                        {facility.address.city.toLocaleLowerCase()},{" "}
                        {facility.address.state_code} {facility.address.zipCode}
                      </Typography>
                    </TableCell>
                    <TableCell
                      className={classes.cellDetails}
                      sx={{
                        textAlign: "left",

                        fontWeight: 500,
                      }}
                    >
                      {facility.contact}
                    </TableCell>
                    <TableCell
                      className={classes.cellDetails}
                      sx={{
                        textAlign: "left",

                        fontWeight: 500,
                      }}
                    >
                      {facility.email}
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "10%",
                      }}
                    >
                      <IconButton
                        style={Pointer}
                        disabled={buttondisabled}
                        onClick={() => {
                          handleEditClick(facility.facilityNPI);
                          handleOpenedit();
                          dispatch(facilityInfo(facility));
                        }}
                      >
                        <EditIcon
                          style={Pointer}
                          sx={{ fontSize: 18, color: "#252B42" }}
                        />
                      </IconButton>
                      <Dialog
                        fullScreen={true}
                        fullWidth={true}
                        maxWidth={"xl"}
                        sx={{
                          width: "100%",
                        }}
                        open={openedit}
                        onClose={handleCloseedit}
                      >
                        <DialogContent
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <DialogActions>
                            <UpdateFacility
                              onSubmit={onSubmit}
                              handleClose={handleCloseedit}
                            />
                          </DialogActions>
                        </DialogContent>
                      </Dialog>
                      <IconButton
                        onClick={() => {
                          dispatch(serviceInfo(facility));
                          handleOpenServiceIcon();
                        }}
                      >
                        <FileUploadIcon
                          sx={{ fontSize: 18, color: "#252B42" }}
                          style={Pointer}
                        />
                      </IconButton>
                      <Dialog
                        maxWidth={"xl"}
                        sx={{
                          width: "100%",
                        }}
                        open={openServiceIcon}
                        onClose={handleCloseServiceIcon}
                      >
                        <DialogContent>
                          <DialogActions>
                            <Box sx={{ width: "100%" }}>
                              {" "}
                              <PricelistLandingforActions
                                key={data.length}
                                handleCloseService={handleCloseServiceIcon}
                              />
                            </Box>
                          </DialogActions>
                        </DialogContent>
                      </Dialog>
                      <IconButton
                        style={Pointer}
                        onClick={() => {
                          setView(true);
                          handleEditClick(facility.facilityNPI);
                        }}
                        disabled={selected.length > 0}
                      >
                        <DeleteIcon
                          style={Pointer}
                          sx={{
                            fontSize: 18,
                            color: selected.length > 0 ? "gray" : "#252B42",
                          }}
                        />
                      </IconButton>

                      <Dialog
                        open={view}
                        maxWidth={"sm"}
                        sx={{
                          "& .MuiDialog-container": {
                            justifyContent: "flex-center",
                            alignItems: "flex-start",
                          },
                        }}
                        PaperProps={{
                          elevation: 0,
                          style: {
                            width: "90vh",
                          },
                          sx: {
                            border: "solid 1px gray",

                            m: 0,
                            top: 20,
                            left: 30,
                          },
                        }}
                        onClose={() => setView(false)}
                      >
                        <DialogContent
                          sx={{
                            fontSize: 14,
                            fontFamily: "Montserrat",
                            color: "black",
                          }}
                        >
                          Do you wish to confirm delete?
                        </DialogContent>

                        <DialogActions>
                          <Button
                            className={classes.yesButton}
                            onClick={() => {
                              dispatch(facilityInfo({ facility }));
                              axiosPrivate
                                .delete(
                                  `/facility/deleteFacility?facilityNPI=${editId}&organizationID=${facility.organizationID}`,
                                  facilityinput
                                )
                                .then(() => {
                                  toast.success("Successfully deleted");
                                  getData();
                                });
                              setView(false);
                            }}
                          >
                            Yes
                          </Button>
                          <Button
                            className={classes.noButton}
                            onClick={() => setView(false)}
                            autoFocus
                          >
                            Cancel
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} of ${count !== -1 ? count : ` ${to}}`}`
                  }
                  backIconButtonProps={{
                    color: "secondary",
                  }}
                  nextIconButtonProps={{ color: "secondary" }}
                  showFirstButton={true}
                  showLastButton={true}
                  labelRowsPerPage={<span>Rows:</span>}
                  sx={{
                    ".MuiTablePagination-toolbar": {
                      backgroundColor: "custom.customColor11",
                    },
                    ".MuiTablePagination-selectLabel, .MuiTablePagination-input":
                      {
                        fontWeight: "bold",
                        color: "primary",
                      },
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </Grid>
      </TableContainer>
    </Box>
  );
}
