import React, { useEffect } from "react";
import { useState, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Box,
  Typography,
  Grid,
  
  InputAdornment,
  ButtonBase,
  IconButton,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";

import { toast } from "react-toastify";


import { Buttoncomponent } from "../../Components/Buttoncomp";
import ClearIcon from "@mui/icons-material/Clear";
import { useAppDispatch, useAppSelector } from "../../Redux/Hook";
import { axiosPrivate } from "../../axios/axios";
import OrganizationLandingView from "./OrganizationLandingView";
import { useNavigate, NavLink } from "react-router-dom";
import ErrorProps from "../../Components/Errorprops";
import { storeLoginInfoupdate } from "../../Redux/ProviderRedux/LoginSlice";
import DoneIcon from "@mui/icons-material/Done";

import {
  contactInfor,
  contactimageInfor,
  delegateimageInfor,
  tabstatuss,
  orgInfo,
  orgfileName,
  contactstatuss,
  orgcreateImage,
} from "../../Redux/ProviderRedux/orgSlice";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { useParams } from "react-router-dom";

import useStyles from "../../styles";


interface InitialValues {
  firstName: string;
  lastName: string;
  role: string;
  contactno: string;
  email: string;
  profilefile: any;
  delegatefirstName: string;
  delegatelastName: string;
  delegaterole: string;
  delegatecontactno: string;
  delegateemail: string;
  delegatefile: any;
}

const ContactPersonInformation = () => {
  const dispatch = useAppDispatch();
  const dataLogin = useAppSelector((state) => state.providerAuth.login);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [attachment, setAttachment] = useState<any>(null);
  const [currentFile1, setCurrentFile1] = useState<any>();
  const [fileName, setFileName] = useState<any>("");
  const [fileName1, setFileName1] = useState<any>("");
  const [text, setText] = useState<any>(
    "Maximum file size 2 MB.Image size 2:1 ratio. Supported file format JPG,JPEG,PNG"
  );
  const [text1, setText1] = useState<any>(
    "Maximum file size 2 MB. Image size 2:1 ratio. Supported file format JPG,JPEG, PNG"
  );

  const [prev, setPrev] = useState<boolean>(false);
  const [orgData, setOrgData] = useState<any>({});

  const [imageData, setImageData] = useState<any>("");
  const [imageData1, setImageData1] = useState<any>("");

  const [save, setSave] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessage1, setErrorMessage1] = useState("");
  const [zipDisable, setZipDisable] = useState(false);
  const fileInput = useRef<any>();
  const fileInput1 = useRef<any>();
  const [open, setOpen] = React.useState<boolean>(false);

  const classes = useStyles();

  const [unsavedChanges1, setUnsavedChanges1] = useState<boolean>(false);

  const orgInput = useAppSelector(
    (state: { providerOrganization: { orgData: any } }) =>
      state.providerOrganization.orgData
  );

  const contactInput = useAppSelector(
    (state: { providerOrganization: { contactInfo: any } }) =>
      state.providerOrganization.contactInfo
  );

  const contactimgInput = useAppSelector(
    (state: { providerOrganization: { contactimageInfo: any } }) =>
      state.providerOrganization.contactimageInfo
  );

  const delegateimgInput = useAppSelector(
    (state: { providerOrganization: { delegateimageInfo: any } }) =>
      state.providerOrganization.delegateimageInfo
  );

  useEffect(() => {
    
  }, [currentFile1, fileName, attachment, fileName1]);

  const formatPhoneNumber = (input: any) => {
    const cleaned = input.replace(/\D/g, "");
    const formatted = cleaned.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    return formatted;
  };

  const CustomPhoneInput = ({ field, form, errorMessage, ...props }: any) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    const handleChange = (e: any) => {
      const formattedNumber = formatPhoneNumber(e.target.value);
      setFieldValue(name, formattedNumber);
      dispatch(contactstatuss(false));
    };

    return (
      <TextField
        className={classes.signuptext}
        {...props}
        {...field}
        onChange={handleChange}
        value={value}
        placeholder="(XXX) XXX-XXXX"
        fullWidth
        helperText={
          <ErrorMessage name={name}>
            {(error) => <ErrorProps>{error}</ErrorProps>}
          </ErrorMessage>
        }
        autoComplete="new-country-area"
        
      />
    );
  };

  const initialValues: InitialValues = {
    firstName: dataLogin?.firstName || contactInput?.firstName,
    lastName: dataLogin?.lastName || contactInput?.lastName,
    role: dataLogin?.userType,
    contactno: contactInput?.contact || "",
    email: dataLogin?.email,
    profilefile:  ""|| contactimgInput ,
    delegatefirstName: contactInput?.delegatefirstName || "",
    delegatelastName:  contactInput?.delegatelastName || "",
    delegaterole: contactInput?.delegaterole || "",
    delegatecontactno:  contactInput?.delegatecontactno || "",
    delegateemail: contactInput?.delegateemail || "",
    delegatefile:  ""||delegateimgInput ,
  };

  const SingleFileChange = () => {
    const selectedFile =fileInput.current.files[0];
    if (selectedFile.size > 2 * 1024 * 1024) { // 2MB limit
      dispatch(contactimageInfor(""))
      setErrorMessage("File size exceeds the limit (2MB)");
      return;
  }
    if (/\.(jpe?g|png|gif)$/i.test(fileInput.current.files[0].name) === false) {
      dispatch(contactimageInfor(""))
      setErrorMessage("Unsupported File Format (Allowed[.png,.jpeg,.jpg,.gif])");
      return;
    } else {
      setErrorMessage("");
      setCurrentFile1(selectedFile)
    
    }
    
    setFileName(selectedFile ? selectedFile.name : "");
    dispatch(contactimageInfor(selectedFile ? selectedFile.name : ""));

    let formData2 = new FormData();
    formData2.append("file", selectedFile);
    try {
      axiosPrivate
        .post("/organization/image", formData2, {
          headers: {
            "Content-type": "multipart/form-data",
          },
        })
        .then((res) => {
          toast.success(res.data.message);
          const image = res.data.data.filename;
          setImageData(image);
        })
        .catch((err) => {
          setIsLoading(false);

          toast.error(err.message);
        });
    } catch (err) {
      setIsLoading(false);
      throw err;
    }
    
  };

  const delegateFileChange = () => {
    const selectedFile1 = fileInput1.current.files[0];
    if (selectedFile1.size > 2 * 1024 * 1024) { // 2MB limit
      dispatch(delegateimageInfor(""))
      setErrorMessage1("File size exceeds the limit (2MB)");
      return;
  }
    if (/\.(jpe?g|png|gif)$/i.test(fileInput1.current.files[0].name) === false) {
      dispatch(delegateimageInfor(""))
      setErrorMessage1("Unsupported File Format (Allowed[.png,.jpeg,.jpg,.gif])");
      return;} 
    else {
      setErrorMessage1("");
      setAttachment(selectedFile1)
    }
    setFileName1(selectedFile1?selectedFile1.name : "");
    dispatch(delegateimageInfor(selectedFile1?selectedFile1.name : ""));

    let formData1 = new FormData();
    formData1.append("file", selectedFile1);
    try {
      axiosPrivate
        .post("/organization/image", formData1, {
          headers: {
            "Content-type": "multipart/form-data",
          },
        })
        .then((res) => {
          toast.success(res.data.message);
          const image1 = res.data.data.filename;
          setImageData1(image1);
        })
        .catch((err) => {
          setIsLoading(false);

          toast.error(err.message);
        });
    } catch (err) {
      setIsLoading(false);
      throw err;
    }

  };

  const handleCloseFile = () => {
    setErrorMessage("");
    setFileName("");
    dispatch(contactimageInfor(""));
    setText(
      "Maximum file size 2 MB. Image size 2:1 ratio. Supported file format JPG,JPEG,PNG"
    );
  };

  const handleCloseFile1 = () => {
    setErrorMessage1("");
    setFileName1("");
    dispatch(delegateimageInfor(""));
    setText(
      "Maximum file size 2 MB. Image size 2:1 ratio. Supported file format JPG,JPEG, PNG"
    );
  };

  const Previous = () => {
    setPrev(true);
  };

  const Save = () => {
    setSave(true);
  };

  const onSubmit = async (values: InitialValues, actions: any) => {
  
    const contactPersonData = {
      providerID: dataLogin.userID,
      firstName: values.firstName,
      lastName: values.lastName,
      role: values.role,
      contact: values.contactno,
      email: dataLogin.email,
      contactimg: contactimgInput ? contactimgInput : imageData,
      delegatefirstName: values.delegatefirstName,
      delegatelastName: values.delegatelastName,
      delegaterole: values.delegaterole,
      delegatecontactno: values.delegatecontactno,
      delegateemail: values.delegateemail,
      delegatefile: delegateimgInput ? delegateimgInput : imageData1,
    };

    setOrgData(contactPersonData);
    dispatch(contactInfor(contactPersonData));
    
 
    if (prev === true) {
      setOpen(true);
      dispatch(contactstatuss(true));
      toast.success("Organization information is saved successfully");
    
    }
    if (save === true) {
      try {
        axiosPrivate
          .put("provider/updateProvider", contactPersonData)
          .then((res) => {
            const updatelogininfo = {
              firstName: values.firstName,
              lastName: values.lastName,
            };

            dispatch(storeLoginInfoupdate(updatelogininfo));
            dispatch(contactInfor(initialValues));
            dispatch(orgInfo(initialValues));
            dispatch(orgfileName(""));
            dispatch(contactimageInfor(""));
            dispatch(delegateimageInfor(""));
         
            actions.resetForm({
              values: initialValues,
            });

      
          });

        const orgdata = {
          providerID: dataLogin.userID,
          organizationName: orgInput.organizationName,
          orgImg: orgInput.orgImg,
          address: {
            addressLine1: orgInput.address?.addressLine1,
            addressLine2: orgInput.address?.addressLine2,
            city: orgInput.address?.city,
            state_code: orgInput.address?.state,
            zipCode: orgInput.address?.zipCode,
            country: orgInput.address?.country,
          },
          email: orgInput.email,
          contact: orgInput.contact,
          contactPerson: {
            firstName: values.firstName || contactInput.firstName,
            lastName: values.lastName || contactInput.lastName,
            role: values.role || contactInput.role,
            contact: values.contactno || contactInput.contact,
            email: dataLogin.email,
            contactImg: imageData || contactInput.contactimg,
          },
          delegateInfo: {
            delegatefirstName:
              values.delegatefirstName || contactInput.delegatefirstName,
            delegatelastName:
              values.delegatelastName || contactInput.delegatelastName,
            delegaterole: values.delegaterole || contactInput.delegaterole,
            delegatecontactno:
              values.delegatecontactno || contactInput.delegatecontactno,
            delegateemail: values.delegateemail || contactInput.delegateemail,
            delegatefile: imageData1 || contactInput.delegatefile,
          },
        };

        axiosPrivate
          .post("/organization/createOrganization", orgdata)
          .then((res) => {
            actions.resetForm({
              values: initialValues,
            });
            setIsLoading(false);

            navigate("/provider/facility/viewFacility");
          })
          .catch((err) => {
            setIsLoading(false);

            toast.error(err.message);
          })
          .catch((err) => {
            setIsLoading(false);

            toast.error(err.message);
          });
      } catch (err) {}
    }
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required("First Name is a required field")
      .matches(/^[A-Za-z -]+$/, "First Name can only contain alphabets."),
    lastName: Yup.string()
      .required("Last Name is required")
      .matches(/^[A-Za-z -]+$/, "Last Name can only contain alphabets."),

    contactno: Yup.string()
      .required("Phone number is required")

      .matches(/^\((?!0\d{2}\))\d{3}\) \d{3}-\d{4}$/, "Invalid Phone Number"),
    email: Yup.string()
      .required("Email is a required field")
      .matches(
        /^[a-zA-Z0-9._%+-]{1,64}@[a-zA-Z0-9.-]{1,255}\.[a-zA-Z]{2,}$/, 
        'Invalid email address format.'
      )
      .email("invalid email"),
    delegatefirstName: Yup.string()
      .required("First Name is a required field")
      .matches(/^[A-Za-z -]+$/, "First Name can only contain alphabets."),
    delegatelastName: Yup.string()
      .required("Last Name is a required field")
      .matches(/^[A-Za-z -]+$/, "First Name can only contain alphabets."),
    delegaterole: Yup.string()
      .required("Role is a required field")
      .matches(/^[A-Za-z -]+$/, "Role can only contain alphabets "),
    delegatecontactno: Yup.string()
      .required("Phone number is required")

      .matches(/^\((?!0\d{2}\))\d{3}\) \d{3}-\d{4}$/, "Invalid Phone Number"),

    delegateemail: Yup.string()
      .required("Email is a required field")
      .matches(
        /^[a-zA-Z0-9._%+-]{1,64}@[a-zA-Z0-9.-]{1,255}\.[a-zA-Z]{2,}$/, 
        'Invalid email address format.'
      )
      .email("invalid email"),
  });

  const contactPersonData = [
    {
      xs: 12,
      md: 6,
      label: "First Name ",
      name: "firstName",
      placeholder: "First Name",
      type: "text",
    },
    {
      xs: 12,
      md: 6,
      label: "Last Name ",
      name: "lastName",
      placeholder: "Last Name",
      type: "text",
    },
  ];

  const delegateData = [
    {
      xs: 12,
      md: 6,
      label: "First Name ",
      name: "delegatefirstName",
      placeholder: "First Name",
      type: "text",
    },
    {
      xs: 12,
      md: 6,
      label: "Last Name ",
      name: "delegatelastName",
      placeholder: "Last Name",
      type: "text",
    },
  ];

  const roleOptions = [
    { roleId: "owner", item: "Owner" },
    { roleId: "admin", item: "Administrator" },
    { roleId: "director", item: "Medical Director" },
    { roleId: "Physicians", item: "Physicians" },
  { roleId: "Assistants", item: "Nurse Practitioners and Physician Assistants" },
  { roleId: "Nurses", item: "Nurses (RN, LPN)" },
  { roleId: "Medical Assistants", item: "Medical Assistants" },
  { roleId: "Receptionists", item: "Front Desk Staff/Receptionists" },
  { roleId: "Specialist", item: "Billing Specialist" },
  ];

  return (
    <Box sx={{ mt: "0.5rem" }}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ handleChange, setFieldValue, values }) => (
          <Form>
            <Grid container spacing={2} sx={{ p: "2rem" }}>
              {contactPersonData.map((person, i) => (
                <Grid item xs={person.xs} md={person.md} key={i}>
                  <Typography className={classes.h6Org}>
                    {person.label}{" "}
                    <Typography component="span"display="inline" className={classes.inlineOrg}>
                      *
                    </Typography>
                  </Typography>

                  <Field
                    className={classes.signuptext}
                    as={TextField}
                    name={person.name}
                    onChange={(e: any) => {
                      handleChange(e);
                      setUnsavedChanges1(true);
                      dispatch(contactstatuss(false));
                    }}
                    placeholder={person.placeholder}
                    type={person.type}
                    fullWidth={true}
                    autoComplete="text"
                    helperText={
                      <ErrorMessage name={person.name}>
                        {(error) => <ErrorProps>{error}</ErrorProps>}
                      </ErrorMessage>
                    }
                  />
                </Grid>
              ))}

              
              <Grid item xs={12} sm={4}>
                <Typography className={classes.h6Org}>
                  Upload Profile Photo
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Box
                    position="relative"
                    height={50}
                    sx={{ flexGrow: 1, width: "90%" }}
                   
                  >
                    <Box
                      position="absolute"
                      top={0}
                      bottom={0}
                      left={0}
                      right={0}
                    >
                      <Field
                        className={classes.signuptext}
                        as={TextField}
                        variant="outlined"
                        fullWidth
                        placeholder="Choose file"
                        type="text"
                        name="profilefile"
                        value={contactimgInput}
                        onChange={(e: any) => {
                          handleChange(e);
                          setUnsavedChanges1(true);
                          dispatch(contactstatuss(false));
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <InsertLinkIcon />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <IconButton
                              sx={{
                                visibility: currentFile1 ? "visible" : "hidden",
                              }}
                              onClick={handleCloseFile}
                            >
                              <ClearIcon />
                            </IconButton>
                          ),
                        }}
                      />
                    </Box>
                    <ButtonBase
                      sx={{
                        width: "85%",
                        height: "100%",
                      }}
                      component="label"
                    >
                      <input
                        style={{ display: "none" }}
                        id="upload-photo"
                        name="profilefile"
                        type="file"
                        hidden
                        accept=".png,.jpg,.jpeg"
                        value={undefined}
                        ref={fileInput}
                        onChange={SingleFileChange}
                      />
                    </ButtonBase>
                  </Box>
                </Box>
                {errorMessage ? (
                  errorMessage && (
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                        fontSize: "0.9rem",
                        marginTop: "0.6rem",
                      }}
                    >
                      {errorMessage}
                    </div>
                  )
                ) : (
                  <Box className={classes.errorOrg} component="span">
                    {text}
                  </Box>
                )}
              </Grid>

              <Grid item xs={12} md={4}>
                <Typography className={classes.h6Org}>
                  Contact Number{" "}
                  <Typography className={classes.inlineOrg} component="span"display="inline">
                    *
                  </Typography>
                </Typography>

                <Field name="contactno" component={CustomPhoneInput} />
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography className={classes.h6Org}>
                  Email{" "}
                  <Typography className={classes.inlineOrg} component="span"display="inline">
                    (* readonly)
                  </Typography>
                </Typography>
                <Field
                  className={classes.signuptext}
                  as={TextField}
                  onChange={(e: any) => {
                    handleChange(e);
                    setUnsavedChanges1(true);
                    dispatch(contactstatuss(false));
                  }}
                  helperText={
                    <ErrorMessage name="contactPersonInformation.email">
                      {(error) => <ErrorProps>{error}</ErrorProps>}
                    </ErrorMessage>
                  }
                  name="email"
                  placeholder="Email"
                  type="email"
                  fullWidth={true}
                  autoComplete="text"
                  inputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  mb={"0.5rem"}
                  sx={{
                    fontFamily: "Inder, sans-serif",
                    color: "#000000",
                    fontSize: "16px",
                    letterSpacing: "0.2px",
                    lineHeight: "28px",
                  }}
                >
                  Alternate Contact Person
                </Typography>
              </Grid>
              {delegateData.map((person, i) => (
                <Grid item xs={person.xs} md={person.md} key={i}>
                  <Typography className={classes.h6Org}>
                    {person.label}{" "}
                    <Typography
                    component="span"
                      display="inline"
                      className={classes.inlineOrg}
                      
                    >
                      *
                    </Typography>
                  </Typography>
                  <Field
                    className={classes.signuptext}
                    as={TextField}
                    onChange={(e: any) => {
                      handleChange(e);
                      setUnsavedChanges1(true);
                      dispatch(contactstatuss(false));
                    }}
                    name={person.name}
                    placeholder={person.placeholder}
                    type={person.type}
                    fullWidth={true}
                    autoComplete="text"
                    helperText={
                      <ErrorMessage name={person.name}>
                        {(error) => <ErrorProps>{error}</ErrorProps>}
                      </ErrorMessage>
                    }
                  />
                </Grid>
              ))}
           <Grid item xs={12} md={3}>
                <Typography className={classes.h6Org}>
                  Role{" "}
                  <Typography component="span"display="inline" className={classes.inlineOrg}>
                    *
                  </Typography>
                </Typography>
          
             
        
              <FormControl
                    sx={{ width: "100%", backgroundColor: "#F9F9F9" }}
                  >
                    <Field
                      as={Select}
                      name="delegaterole"
                      onChange={(e:any) => {
                        setFieldValue("delegaterole", e.target.value); 
                        
                       
                      }}
                      MenuProps={{
                        sx: {
                          fontSize: 14,
                          fontFamily: "Montserrat",
                          color: "blue",
                        },
                      }}
                      renderValue={(selected: any) => (
                        <div
                          style={{
                            fontFamily: "Montserrat",
                            fontSize: 14,
                            color: "#252B42",
                          }}
                        >
                          {
                            (
                              roleOptions.find(
                                (opt: any) => opt.item === selected
                              ) || ({} as any)
                            ).item
                          }
                        </div>
                      )}
                    >
                      <MenuItem value="" disabled>
                        Select Role
                      </MenuItem>
                     {(roleOptions || []).map(
                        (select: any, index: any) => (
                          <MenuItem
                            key={index + 1}
                            value={select.item}
                            style={{
                              fontFamily: "Montserrat",
                              fontSize: 14,
                              color: "#737373",
                            }}
                          >
                            {select.item}
                          </MenuItem>
                        )
                      )} 
                    </Field>

                    <ErrorMessage name="delegaterole">
                      {(error) => <ErrorProps>{error}</ErrorProps>}
                    </ErrorMessage>
                  </FormControl>
                  </Grid> 

              <Grid item xs={12} sm={3}>
                <Typography className={classes.h6Org}>
                  Upload Profile Photo
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Box
                    position="relative"
                    height={50}
                    sx={{
                      flexGrow: 1,
                      width: "90%",
                    }}
                  >
                    <Box
                      position="absolute"
                      top={0}
                      bottom={0}
                      left={0}
                      right={0}
                    >
                      <Field
                        className={classes.signuptext}
                        as={TextField}
                        onChange={(e: any) => {
                          handleChange(e);
                          setUnsavedChanges1(true);
                          dispatch(contactstatuss(false));
                        }}
                        variant="outlined"
                        fullWidth
                        placeholder="Choose the file"
                        type="text"
                        name="delegatefile"
                        value={delegateimgInput}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <InsertLinkIcon />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <IconButton
                              sx={{
                                visibility: attachment ? "visible" : "hidden",
                              }}
                              onClick={handleCloseFile1}
                            >
                              <ClearIcon />
                            </IconButton>
                          ),
                        }}
                      />
                    </Box>
                    <ButtonBase
                      sx={{
                        width: "85%",

                        height: "100%",
                      }}
                      component="label"
                    >
                      <input
                        style={{ display: "none" }}
                        id="upload-photo"
                        name="delegatefile"
                        type="file"
                        hidden
                        accept=".png,.jpg,.jpeg"
                        value={undefined}
                        ref={fileInput1}
                        onChange={delegateFileChange}
                      />
                    </ButtonBase>
                  </Box>
                </Box>

                {errorMessage1 ? (
                  errorMessage1 && (
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                        fontSize: "0.9rem",
                        marginTop: "0.6rem",
                      }}
                    >
                      {errorMessage1}
                    </div>
                  )
                ) : (
                  <Box className={classes.errorOrg} component="span">
                    {text1}
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography className={classes.h6Org}>
                  Contact Number{" "}
                  <Typography className={classes.inlineOrg} component="span"display="inline">
                    *
                  </Typography>
                </Typography>
                <Field name="delegatecontactno" component={CustomPhoneInput} />
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography className={classes.h6Org}>
                  Email{" "}
                  <Typography component="span"display="inline" className={classes.inlineOrg}>
                    *
                  </Typography>
                </Typography>
                <Field
                  className={classes.signuptext}
                  as={TextField}
                  onChange={(e: any) => {
                    handleChange(e);
                    setUnsavedChanges1(true);
                    dispatch(contactstatuss(false));
                  }}
                  helperText={
                    <ErrorMessage name="delegateemail">
                      {(error) => <ErrorProps>{error}</ErrorProps>}
                    </ErrorMessage>
                  }
                  name="delegateemail"
                  placeholder="Email"
                  type="email"
                  fullWidth={true}
                  autoComplete="text"
                />
              </Grid>

              <Grid container item justifyContent="left" xs={12} gap={"5px"}>
                <Grid item xs={5.9} sm={1.43}>
                  <Buttoncomponent
                    className={classes.orgButton}
                    type="submit"
                    fullWidth
                    size="large"
                    variant="contained"
                    disable={isLoading}
                    onClick={Previous}
                    sx={{
                      textTransform: "capitalize",}}
                  >
                    Save
                  </Buttoncomponent>
                </Grid>
                <Grid item xs={5.9} sm={1.43}>
                  <Buttoncomponent
                    className={classes.orgButton}
                    type="submit"
                    size="large"
                    fullWidth
                    variant="contained"
                    disable={isLoading}
                    onClick={Save}
                    sx={{
                    textTransform: "capitalize",}}
                  >
                    Submit
                  </Buttoncomponent>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default ContactPersonInformation;
