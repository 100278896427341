import React, { useState } from "react";
import {  useNavigate } from "react-router-dom";
import {
  Tabs,
  Tab,
  Box,
  useMediaQuery,
} from "@mui/material";

import {
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,

} from "@mui/material";
import BulkPricelistUpload from "./BulkPricelistUploadTab1";
import PricelistTableView from "./BulkPricelistViewTable";

import useStyles from "../../styles";

import { useAppSelector } from "../../Redux/Hook";
import PublishComp from "./Bulkpublishcomponent";

interface props {
  textalign: string;
  children: JSX.Element;
}

type CloseReason = "backdropClick" | "escapeKeyDown" | "closeButtonClick";
interface DialogProps extends MuiDialogProps {
  onClose: (reason: CloseReason) => void;
}



function Pricelist({ handleClosee, getData,facilityStateRefresh,handleClosethrfacilitypage,serviceLandFlag }: any) {


  const classes=useStyles()
  const data = useAppSelector(
    (state: { providerAuth: { login: any } }) => state.providerAuth.login
  );



  const [tabValue, setTabValue] = useState<number>(0);

  const [csvData, setCsvData] = useState<any[]>([]);
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const handleFileUpload = (data: any[]) => {
    setCsvData(data);
    setTabValue(1); 
  };

  const handleNext = (csvData: any[]) => {
    if (tabValue < 2) {
      setCsvData(csvData);
      setTabValue(tabValue + 1);
    }
  };

  const handlePrevious = () => {
    if (tabValue > 0) {
      setTabValue(tabValue - 1);
    }
  };

  const handleSubmit = () => {
    // Handle form submission logic here
   
  };
  const handlecancell = () => {
    handleClosee();
  };
  const ServiceStateRefresh = () => {
    getData();
  }
  const Facilitystaterefresh =()=>{
    facilityStateRefresh()
  }
  const isMdUp = useMediaQuery('(min-width:500px)');
  return (
    <Box >
      <Tabs
        value={tabValue}
        variant={isMdUp ? 'fullWidth' : 'fullWidth'}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        sx={{
          width: '100%',

        '& .MuiTabs-flexContainer': {
          flexDirection: isMdUp ? 'row' : 'column',
        
        },
        }}
      >
        <Tab 
        className={classes.uploadTab}
          sx={{
          
            textTransform: "capitalize",
            
          }}
          
          label="Upload & Validate"
          disabled={tabValue !== 0}
        />
        <Tab 
        className={classes.uploadTab}
          sx={{
            
            textTransform: "capitalize",
            
          }}
          label="Preview"
          disabled={tabValue !== 1}
        />
        <Tab 
        className={classes.uploadTab}
          sx={{
           
            textTransform: "capitalize",
            
            
          }}
          label="Publish"
          disabled={tabValue !== 2}
        />
      </Tabs>
      <Box mt={2}>
        {tabValue === 0 && (
          <div>
            <BulkPricelistUpload
              onFileUpload={handleFileUpload}
              handleClosee={handlecancell}
              getData = {ServiceStateRefresh}
              facilityStateRefresh = {Facilitystaterefresh}
              handleClosethrfacilitypage ={handleClosethrfacilitypage}
              serviceLandFlag={serviceLandFlag}
            />
          </div>
        )}
        {tabValue === 1 && (
          <div>
            <PricelistTableView data={csvData} handleNext={handleNext} handlePrevious= {handlePrevious}/>
          </div>
        )}
        {tabValue === 2 && (
          <div>
            <PublishComp data={csvData} handleClosee={handlecancell}facilityStateRefresh = {Facilitystaterefresh} handleClosethrfacilitypage ={handleClosethrfacilitypage} serviceLandRefreshState = {getData} serviceLandFlag={serviceLandFlag} handlePrevious= {handlePrevious}/>
          </div>
        )}
      </Box>
     
    </Box>
  );
}

export default Pricelist;
