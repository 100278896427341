import React from "react";
import { useState, useEffect, useRef } from "react";
import { Formik, Form, ErrorMessage, Field, FormikProps } from "formik";
import * as Yup from "yup";
import {
  Box,
  Grid,
  Typography,
  Paper,
  TextField,
  Select,
  Autocomplete,
  AutocompleteRenderInputParams,
  createFilterOptions,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";
import { toast } from "react-toastify";

//redux store
import { useAppDispatch, useAppSelector } from "../../Redux/Hook";
//components
import { Buttoncomponent } from "../../Components/Buttoncomp";
import ErrorProps from "../../Components/Errorprops";
import { axiosPrivate, baseURL } from "../../axios/axios";
import { tabValueNav } from "../../Redux/ProviderRedux/LoginSlice";
import useStyles from "../../styles";

interface forminitialValues {
  DBA:string;
  providerID: string;
  organizationID: string;
  facilityNPI?: string | number;
  facilityName: string;
  MainfacilityType: string;
  OthersfacilityType: string;
  addressLine1: string;
  addressLine2?: string;
  country: string;
  city: string;
  state: string;
  zipCode: string;
  contact: string;
  email: string;
  latitude: string;
  longitude: string;
}

export default function CreateFacility({ handleClose1 }: any) {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const [checkInfo, setCheckInfo] = useState<any>([]);
  const [checkInfo1, setCheckInfo1] = useState<any>([]);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [facilityTypeOptions, setFacilityTypeOptions] = useState([]);
  const [facilityTypeSelected, setFacilityTypeSelected] = useState(false);
  const data = useAppSelector((state) => state.providerAuth.login);
  const [zipCodedata, setZipCodedata] = useState<any>([]);
  const [autoCompleteData, setAutoCompleteData] = useState<any>([]);
  const [errorMessage3, setErrorMessage3] = useState("");
  const [isTrue,setIsTrue] = useState<boolean>(false);
  const [errorMessage2, setErrorMessage2] = useState("");
  const [isTrue1,setIsTrue1] = useState<boolean>(false);

  const orgID = useAppSelector(
    (state) => state.providerOrganization.orgEditData
  );

  const initialValues: forminitialValues = {
    DBA: "",
    providerID: data.userID,
    organizationID: orgID[0].organizationID,
    facilityNPI: "",
    facilityName: "",
    MainfacilityType: "",
    OthersfacilityType: "",
    addressLine1: "",
    addressLine2: "",
    country: "USA",
    city: "",
    state: "",
    zipCode: "",
    contact: "",
    email: "",
    latitude: "",
    longitude: "",
  };

  useEffect(() => {
    const fetchFacilityNPI = async () => {
      await axiosPrivate
        .get(`/facility/findfacilityNPI`)
        .then((res) => {
          setCheckInfo(res.data.data);
        })
        .catch((error) => {
          throw new Error("An error occurred while processing the data.");
        });
    };
    fetchFacilityNPI();
  }, [checkInfo]);
  useEffect(() => {
    const fetchFacilityName = async () => {
            await axiosPrivate
        .get(`/facility/findfacilityName`)
        .then((res) => {
          setCheckInfo1(res.data.data);
        })
        .catch((error) => {
          throw new Error("An error occurred while processing the data.");
        });
    };
    fetchFacilityName();
  }, [checkInfo1]);



  useEffect(() => {
    const getFacilityType = async () => {
      if (!facilityTypeSelected) {
        try {
          const res = await axiosPrivate.get(`/facility/findfacilityType`);
          setFacilityTypeOptions(res.data.data);
        } catch (error) {
          throw new Error("An error occurred while processing the data.");
        }
      }
    };
  
    getFacilityType();
  
    
  }, [facilityTypeSelected]);

  useEffect(() => {
    axiosPrivate.get("/organization/cityStateList").then((res) => {
      setAutoCompleteData(res.data.data)
   
    })
    .catch((err)=>{
      console.log(err)
    })
  }, []);
  useEffect(() => {
    axiosPrivate
      .get("/search/cityStateList")
      .then((res) => {
        setZipCodedata(res.data.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  const orgid = useAppSelector(
    (state) => state.providerOrganization.orgEditData
  );
  const getData = async () => {
    const facilityDetails = await axiosPrivate.get(
      `/facility/getFacilityByOrganization?organizationID=${orgid[0].organizationID}`
    );
  };

  const OPTIONS_LIMIT = 10;
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options: any, state: any) => {
    return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  };

  const validationSchema = Yup.object().shape({
   // facilityNPI: Yup.string()
      // .required("FacilityNPI is required")
      // .matches(
      //   /^[1-9]\d{9}$/,
      //   "FacilityNPI must be a 10-digit number"
      // )
      // .test("len", " Invalid NPI", (val: any) => val && val.length === 10),
    //facilityName: Yup.string().required("Facility Name is required"),

    MainfacilityType: Yup.string().required("Facility Type is Required"),
    addressLine1: Yup.string().required("Street Address1 is required"),

    city: Yup.string()
      .nullable()
      .required("City is required")
      .matches(
        /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/,
        "City name should be alpha-characters"
      ),
    zipCode: Yup.string()
    .required("Zipcode is required")
    .matches(/^\d{5}$/, "Zipcode should be exactly 5 numeric digits")
    .test({
      name: "check-zipcode-exists",
      message: "Please give valid US zipcode",
      test: function (value) {
        return zipCodedata.some((item: any) => item.ZIP_CODE === value);
      },
    }),
    state: Yup.string()
      .nullable()
      .required("State is required")
      .matches(/^[A-Za-z -]+$/, "State name should be alpha-characters")
      .min(2, "State must be at least 2 characters.")
      .max(100, "State has a maximum limit of 100 characters."),
    country: Yup.string()
      .required("Country is required")
      .oneOf(["US", "USA", "UnitedStates"]),

    contact: Yup.string()
      .required("Phone number is required")

      .matches(/^\((?!0\d{2}\))\d{3}\) \d{3}-\d{4}$/, "Invalid Phone Number"),
    email: Yup.string().email().required("Email is required"),
  });

  const onSubmit = (values: forminitialValues, actions: any) => {
    setIsLoading(true);
    const facilitydata = {
      providerID: values.providerID,
      organizationID: values.organizationID,
      facilityNPI: values.facilityNPI,
      facilityName: values.facilityName,

      facilityType: {
        MainfacilityType: values.MainfacilityType,
        OthersfacilityType: values.OthersfacilityType,
      },
      address: {
        addressLine1: values.addressLine1,
        addressLine2: values.addressLine2,
        country: values.country,
        city: values.city,
        state_code: values.state,
        zipCode: values.zipCode,
      },
      email: values.email,
      contact: values.contact,
      GPSCoordinate: {
        latitude: values.latitude,
        longitude: values.longitude,
      },
    };

    actions.resetForm({
      values: initialValues,
    });
    axiosPrivate
      .post(`/facility/createFacility`, facilitydata)
      .then((res) => {
        toast.success(res.data.message);
        getData();

        setIsLoading(false);
        dispatch(tabValueNav(1));
        handleClose1();
      })
      .catch((err) => {
        setIsLoading(false);
        toast.warning(err.message);
      });
  };
  const formatPhoneNumber = (input: any) => {
    const cleaned = input.replace(/\D/g, "");
    const formatted = cleaned.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    return formatted;
  };

  const CustomPhoneInput = ({ field, form, ...props }: any) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    const handleChange = (e: any) => {
      const formattedNumber = formatPhoneNumber(e.target.value);
      setFieldValue(name, formattedNumber);
    };

    return (
      <TextField
        className={classes.signuptext}
        {...props}
        {...field}
        onChange={handleChange}
        value={value}
        placeholder="(XXX) XXX-XXXX"
        fullWidth
        helperText={
          <ErrorMessage name="contact">
            {(error) => <ErrorProps>{error}</ErrorProps>}
          </ErrorMessage>
        }
        autoComplete="new-country-area"
      />
    );
  };

  const CustomPaper = (props: any) => {
    return (
      <Paper
        elevation={8}
        sx={{
          backgroundColor: "white",
          color: "black",
        }}
        {...props}
      />
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
    {({ handleChange, setFieldValue,    setFieldError,     values,
        touched,
        setFieldTouched,  }) => {
        const handleButtonClick = () => {
          setFieldTouched("facilityNPI", true); // Mark ServiceCode field as touched
          setFieldTouched("facilityName", true); // Mark DiagnosisTestorServiceName field as touched
          // setFieldTouched("FacilityNPI", true); // Mark ServiceCode field as touched
          // setFieldTouched("FacilityName", true); // Mark DiagnosisTestorServiceName field as touched
          if (!values.facilityNPI || !values.facilityName ) {
            return; // Exit if any required field is empty
          }
      
        
          // If all fields are valid, continue with the form submission
          // setOpen(true);
        };
        return(
        <Form>
          <Grid container spacing={2} justifyContent={"center"}>
            <Grid item xs={12}>
              <Typography
                className={classes.formHead}
                mb={"0.3rem"}
                sx={{
                  fontWeight: 600,
                }}
              >
                Add Facility Information
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography
                className={classes.h6Org}
                sx={{
                  mb: "0.3rem",
                }}
              >
                Search Facility NPI{" "}
                <Typography
                  component="span"
                  display="inline"
                  sx={{ color: "red" }}
                >
                  *
                </Typography>
              </Typography>
              <Box sx={{ position: "relative" }}>
              <Field
                name="facilityNPI"
                placeholder="Search Facility NPI"
                component={Autocomplete}
                filterOptions={filterOptions}
                loading={checkInfo.length === 0}
                options={checkInfo}
               type="number"
                PaperComponent={CustomPaper}
                // getOptionLabel={(option: any) =>
                //   option.facilityNPI || option
                // }
                getOptionLabel={(option: any) => {
                  if (typeof option === 'object' && option.facilityNPI) {
                    return option.facilityNPI.toString();
                  } else {
                    return String(option);
                  }
                }}
                // getOptionLabel={(option:any) => option.facilityNPI}
                isOptionEqualToValue={(option: any, value: any) => 
                  option.value === value.value}
          
                onChange={(e: any, value: any) => {
                  setIsTrue(false)
                  setIsTrue1(false)
                  setErrorMessage2("");
                  setErrorMessage3("");
                  // setFieldValue("facilityNPI", value ? value.facilityNPI : "");
                  setFieldValue("facilityName", value ? value.DBA : "");
                  setFieldValue("facilityNPI", value !== null ? Number(value.facilityNPI) : "");
                  setFieldValue(
                    "addressLine1",
                    value !== null ? value.addressLine1 : ""
                  );
                  setFieldValue(
                    "addressLine2",
                    value === null || value === "null"
                      ? ""
                      : value?.addressLine2 || ""
                  );

                  setFieldValue("city", value !== null ? value.city : "");
                  setFieldValue(
                    "state",
                    value !== null ? value?.state_code : ""
                  );
                  setFieldValue("zipCode", value !== null ? value.zipCode : "");
                  setFieldValue(
                    "latitude",
                    value !== null ? value.latitude : ""
                  );
                  setFieldValue(
                    "longitude",
                    value !== null ? value.longitude : ""
                  );
                  setTimeout(() => {
                    setFieldError("facilityName", "");
                  }, 0);
                  setTimeout(() => {
                    setFieldError("addressLine1", "");
                  }, 0);
                  setTimeout(() => {
                    setFieldError("addressLine2", "");
                  }, 0);
                  setTimeout(() => {
                    setFieldError("city", "");
                  }, 0);
                  setTimeout(() => {
                    setFieldError("state", "");
                  }, 0);
                  setTimeout(() => {
                    setFieldError("zipCode", "");
                  }, 0);
                  setTimeout(() => {
                    setFieldError("latitude", "");
                  }, 0);
                  setTimeout(() => {
                    setFieldError("longitude", "");
                  }, 0);
                  if (!value || (value.facilityNPI === null && value.DBA === null)) {
                    setFieldValue("FacilityName", "");}
                }}
               
                value={values.facilityNPI}
               // value={values.facilityNPI ? checkInfo.find((option:forminitialValues) => option.facilityNPI === values.facilityNPI) : null}

                renderInput={(params: AutocompleteRenderInputParams) => (
                  <TextField
                    {...params}
                    name="facilityNPI"
                    placeholder="Search Facility NPI"
                    onChange={handleChange}
                    type="number"
                    variant="outlined"
                    helperText={
                      (touched.facilityNPI && !values.facilityNPI) ? (
                        <ErrorMessage name="facilityNPI">
                          {(error) => <ErrorProps>{error}</ErrorProps>}
                        </ErrorMessage>
                      ) : (
                        <ErrorMessage name="facilityNPI">
                          {(error) => (
                            <ErrorProps>
                              {values.facilityNPI ? (
                                "Please select a facility NPI from the suggestions"
                              ) : (
                                "Facility NPI is required"
                              )}
                            </ErrorProps>
                          )}
                        </ErrorMessage>
                      )
                    }
                    // helperText={
                    //   <ErrorMessage name="facilityNPI">
                    //     {(error) => <ErrorProps>{error}</ErrorProps>}
                    //   </ErrorMessage>
                    // }
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {checkInfo.length === 0 ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                    sx={{
                      ".MuiFormLabel-root ": {
                        letterSpacing: "0.2rem",
                        fontSize: "0.8rem",
                      },
                      ".MuiInputLabel-shrink": {
                        letterSpacing: 0,
                      },
                      "& .MuiAutocomplete-popupIndicator": {
                        transform: "none",
                      },
                      "& input::placeholder": {
                        fontSize: 14,
                        fontFamily: "Montserrat",
                      },
                      "& input": {
                        fontSize: 14,
                        fontFamily: "Montserrat",
                        color: "#252B42",
                      },
                      ".MuiInputBase-root": {
                        backgroundColor: "#F9F9F9",
                        color: "#252B42",
                      },
                    }}
                  />
                )}
              />

{values.facilityNPI && !checkInfo.some((option: forminitialValues) => option.facilityNPI === values.facilityNPI) ? (
                    <Box
                      sx={{
                        color: "red",
                        position: "absolute",
                        top: { xs: "45px", md: "calc(100% + 5px)" },
                        left: 10,
                        
                        fontSize: 14,
                        fontFamily: "Inder",
                        fontWeight: 400,
                      }}
                    >
                      { "Please select a Facility NPI from the suggestions" }  
                    </Box>
                  ) : null}
                  {touched.facilityNPI && !values.facilityNPI ? (
                    <Box
                      sx={{
                        color: "red",
                        position: "absolute",
                        top: { xs: "45px", md: "calc(100% + 5px)" },
                        left: 10,
                        fontSize: 14,
                        fontFamily: "Inder",
                        fontWeight: 400,
                      }}
                    >
                      Facility NPI is required
                    </Box>
                  ) : null}
                  </Box>

            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography className={classes.h6Org}>
                Facility Name{" "}
                <Typography
                  component="span"
                  display="inline"
                  sx={{ color: "red" }}
                >
                  *
                </Typography>
              </Typography>
              <Box sx={{ position: "relative" }}>
              <Field
                name="facilityName"
                placeholder="Search Facility Name"
                component={Autocomplete}
                filterOptions={filterOptions}
                loading={checkInfo1.length === 0}
                options={checkInfo1}
               type="text"
                PaperComponent={CustomPaper}
                getOptionLabel={(option:forminitialValues) => {
                  if (typeof option === 'object' && option.DBA) {
                    return option.DBA.toString();
                  } else {
                    return String(option);
                  }
                }}
                // getOptionLabel={(option: any) => {
                //   if (typeof option === 'object' && option.DBA) {
                //     return option.DBA.toString();
                //   } else {
                //     return String(option);
                //   }
                // }}
                
                isOptionEqualToValue={(option: any, value: any) => 
                  option.value === value.value}
          
                onChange={(e: any, value: any) => {
                  setIsTrue(false)
                  setIsTrue1(false)
                  setErrorMessage2("");
                  setErrorMessage3("");
                  setFieldValue("facilityNPI", value ? value.facilityNPI : "");
                  setFieldValue("facilityName", value ? value.DBA : "");
                  setFieldValue(
                    "addressLine1",
                    value !== null ? value.addressLine1 : ""
                  );
                  setFieldValue(
                    "addressLine2",
                    value === null || value === "null"
                      ? ""
                      : value?.addressLine2 || ""
                  );

                  setFieldValue("city", value !== null ? value.city : "");
                  setFieldValue(
                    "state",
                    value !== null ? value?.state_code : ""
                  );
                  setFieldValue("zipCode", value !== null ? value.zipCode : "");
                  setFieldValue(
                    "latitude",
                    value !== null ? value.latitude : ""
                  );
                  setFieldValue(
                    "longitude",
                    value !== null ? value.longitude : ""
                  );
                  setTimeout(() => {
                    setFieldError("facilityName", "");
                  }, 0);
                  setTimeout(() => {
                    setFieldError("addressLine1", "");
                  }, 0);
                  setTimeout(() => {
                    setFieldError("addressLine2", "");
                  }, 0);
                  setTimeout(() => {
                    setFieldError("city", "");
                  }, 0);
                  setTimeout(() => {
                    setFieldError("state", "");
                  }, 0);
                  setTimeout(() => {
                    setFieldError("zipCode", "");
                  }, 0);
                  setTimeout(() => {
                    setFieldError("latitude", "");
                  }, 0);
                  setTimeout(() => {
                    setFieldError("longitude", "");
                  }, 0);
                  if (!value || (value.facilityNPI === null && value.DBA === null)) {
                    setFieldValue("FacilityNPI", "");
                  }
                }}
                value={values.facilityName}
                //value={values.facilityName ? checkInfo1.find((option :forminitialValues) => option.DBA === values.facilityName) : null}
                renderInput={(params: AutocompleteRenderInputParams) => (
                  <TextField
                    {...params}
                    name="facilityName"
                    placeholder="Search Facility Name"
                    onChange={handleChange}
                    type="text"
                    variant="outlined"
                    helperText={
                      (touched.facilityName&& !values.facilityName) ? (
                        <ErrorMessage name="facilityName">
                          {(error) => <ErrorProps>{error}</ErrorProps>}
                        </ErrorMessage>
                      ) : (
                        <ErrorMessage name="facilityName">
                          {(error) => (
                            <ErrorProps>
                              {values.facilityName ? (
                                "Please select a facility Name from the suggestions"
                              ) : (
                                "Facility Name is required"
                              )}
                            </ErrorProps>
                          )}
                        </ErrorMessage>
                      )
                    }
                    // helperText={
                    //   <ErrorMessage name="facilityNPI">
                    //     {(error) => <ErrorProps>{error}</ErrorProps>}
                    //   </ErrorMessage>
                    // }
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {checkInfo1.length === 0 ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                    sx={{
                      ".MuiFormLabel-root ": {
                        letterSpacing: "0.2rem",
                        fontSize: "0.8rem",
                      },
                      ".MuiInputLabel-shrink": {
                        letterSpacing: 0,
                      },
                      "& .MuiAutocomplete-popupIndicator": {
                        transform: "none",
                      },
                      "& input::placeholder": {
                        fontSize: 14,
                        fontFamily: "Montserrat",
                      },
                      "& input": {
                        fontSize: 14,
                        fontFamily: "Montserrat",
                        color: "#252B42",
                      },
                      ".MuiInputBase-root": {
                        backgroundColor: "#F9F9F9",
                        color: "#252B42",
                      },
                    }}
                  />
                )}
              />

{values.facilityName && !checkInfo1.some((option: forminitialValues) => option.DBA === values.facilityName) ? (
                    <Box
                      sx={{
                        color: "red",
                        position: "absolute",
                        top: { xs: "45px", md: "calc(100% + 5px)" },
                        left: 10,
                        
                        fontSize: 14,
                        fontFamily: "Inder",
                        fontWeight: 400,
                      }}
                    >
                      { "Please select a Facility Name from the suggestions" }  
                    </Box>
                  ) : null}
                  {touched.facilityName && !values.facilityName ? (
                    <Box
                      sx={{
                        color: "red",
                        position: "absolute",
                        top: { xs: "45px", md: "calc(100% + 5px)" },
                        left: 10,
                        fontSize: 14,
                        fontFamily: "Inder",
                        fontWeight: 400,
                      }}
                    >
                      Facility Name is required
                    </Box>
                  ) : null}
                  </Box>
            {/* <Field
                as={TextField}
                name="facilityName"
                 placeholder="Facility Name"
                type="text"
                fullWidth={true}
                autoComplete="new-country-area"
                helperText={
                  <ErrorMessage name="facilityName">
                    {(error) => <ErrorProps>{error}</ErrorProps>}
                  </ErrorMessage>
                }
                inputProps={{ readOnly: true}}
                sx={{
                  ".MuiFormLabel-root ": {
                    fontSize: 14,
                    fontFamily: "Montserrat",
                    color: "#737373",
                  },

                  ".MuiInputLabel-shrink": {
                    display: "none",
                  },
                  "& input::placeholder": {
                    fontSize: 14,
                    fontFamily: "Montserrat",
                    color: "#737373",
                  },
                  "& input": {
                    fontSize: 14,
                    fontFamily: "Montserrat",
                    color: "#252B42",
                  },
                  ".MuiInputBase-root": {
                    backgroundColor: "#F9F9F9",
                    color: "#252B42",
                  },
                }}
              />  */}
               </Grid> 

           
            <Grid item xs={12} sm={6}>
              <Typography className={classes.h6Org}>
                Facility Type{" "}
                <Typography
                  component="span"
                  display="inline"
                  sx={{ color: "red" }}
                >
                  *
                </Typography>
              </Typography>
              <FormControl sx={{ width: "100%", backgroundColor: "#F9F9F9" }}>
                <InputLabel
                  htmlFor="facility-type"
                  sx={{
                    color: "#aeaeae",
                    fontSize: 14,
                    fontFamily: "Montserrat",
                    "&.MuiInputLabel-shrink": {
                      display: "none",
                    },
                  }}
                >
                  Facility Type
                </InputLabel>

                <Field
                  as={Select}
                  name="MainfacilityType"
                  onChange={(e:any) => {
                    setFieldValue("MainfacilityType", e.target.value); 
                    setFacilityTypeSelected(true); 
                  }}
                  MenuProps={{
                    sx: {
                      fontSize: 14,
                      fontFamily: "Montserrat",
                      color: "blue",
                    },
                  }}
                  renderValue={(selected: any) => (
                    <div
                      style={{
                        fontFamily: "Montserrat",
                        fontSize: 14,
                        color: "#252B42",
                      }}
                    >
                      {
                        (
                          facilityTypeOptions.find(
                            (opt: any) => opt.facilityTypeId === selected
                          ) || ({} as any)
                        ).item
                      }
                    </div>
                  )}
                >
                  <MenuItem value="" disabled>
                    Select Facility Type
                  </MenuItem>
                  {(facilityTypeOptions || []).map(
                    (select: any, index: any) => (
                      <MenuItem
                        key={index + 1}
                        value={select.facilityTypeId}
                        style={{
                          fontFamily: "Montserrat",
                          fontSize: 14,
                          // color: "#737373",
                          color: "#252B42",
                        }}
                      >
                        {select.item}
                      </MenuItem>
                    )
                  )}
                </Field>
              </FormControl>
              <ErrorMessage name="MainfacilityType">
                {(error) => <ErrorProps>{error}</ErrorProps>}
              </ErrorMessage>

              {values.MainfacilityType === "FACT-6" ? (
                <Field
                  as={TextField}
                  name="OthersfacilityType"
                  placeholder="Enter options for facility Type"
                  type="text"
                  fullWidth
                  autoComplete="new-country-area"
                  value={values.OthersfacilityType}
                  onChange={(e:any) => {
                    setFieldValue("OthersfacilityType", e.target.value); 
                    setFacilityTypeSelected(true); 
                  }}
                  sx={{
                    mt: "0.7rem",
                    ".MuiFormLabel-root ": {
                      fontSize: "0.8rem",
                    },
                    ".MuiInputLabel-shrink": {
                      letterSpacing: 0,
                    },
                    "& input": {
                      fontSize: 14,
                      fontFamily: "Montserrat",
                      color: "#252B42",
                    },
                    ".MuiInputBase-root": {
                      backgroundColor: "#F9F9F9",
                      color: "#252B42",
                    },
                  }}
                />
              ) : null}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                className={classes.h6Org}
                sx={{
                  mb: "0.3rem",
                }}
              >
                Street Address 1{" "}
                <Typography
                  component="span"
                  display="inline"
                  sx={{ color: "red" }}
                >
                  *
                </Typography>
              </Typography>
              <Field
                as={TextField}
                name="addressLine1"
                placeholder="Street Address 1"
                type="text"
                fullWidth={true}
                autoComplete="new-country-area"
                helperText={
                  <ErrorMessage name="addressLine1">
                    {(error) => <ErrorProps>{error}</ErrorProps>}
                  </ErrorMessage>
                }
                inputProps={{ readOnly: disabled }}
                sx={{
                  ".MuiFormLabel-root ": {
                    letterSpacing: "0.2rem",
                    fontSize: "0.8rem",
                  },
                  ".MuiInputLabel-shrink": {
                    letterSpacing: 0,
                  },
                  "& input::placeholder": {
                    fontSize: 14,
                    fontFamily: "Montserrat",
                    color: "#737373",
                  },
                  "& input": {
                    fontSize: 14,
                    fontFamily: "Montserrat",
                    color: "#252B42",
                  },
                  ".MuiInputBase-root": {
                    backgroundColor: "#F9F9F9",
                    color: "#252B42",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                className={classes.h6Org}
                sx={{
                  mb: "0.3rem",
                }}
              >
                Street Address 2
              </Typography>
              <Field
                as={TextField}
                name="addressLine2"
                placeholder="Street Address 2"
                type="text"
                fullWidth={true}
                autoComplete="new-country-area"
                helperText={
                  <ErrorMessage name="addressLine2">
                    {(error) => <ErrorProps>{error}</ErrorProps>}
                  </ErrorMessage>
                }
                inputProps={{ readOnly: disabled }}
                sx={{
                  ".MuiFormLabel-root ": {
                    letterSpacing: "0.2rem",
                    fontSize: "0.8rem",
                  },
                  ".MuiInputLabel-shrink": {
                    letterSpacing: 0,
                  },
                  "& input::placeholder": {
                    fontSize: 14,
                    fontFamily: "Montserrat",
                    color: "#737373",
                  },
                  "& input": {
                    fontSize: 14,
                    fontFamily: "Montserrat",
                    color: "#252B42",
                  },
                  ".MuiInputBase-root": {
                    backgroundColor: "#F9F9F9",
                    color: "#252B42",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <Typography className={classes.h6Org}>
                City{" "}
                <Typography
                  component="span"
                  display="inline"
                  sx={{ color: "red" }}
                >
                  *
                </Typography>
              </Typography>
              <Field
                as={TextField}
                name="city"
                placeholder="City"
                type="text"
                fullWidth={true}
                autoComplete="new-country-area"
                // helperText={
                //   <ErrorMessage name="city">
                //     {(error) => <ErrorProps>{error}</ErrorProps>}
                //   </ErrorMessage>
                // }
                onChange={(e: any) => {
                
                  const typedState1 = e.target.value;
                    
                   setFieldValue("city", typedState1); 
                      if (!typedState1) {
                        setErrorMessage2("");
                        return; 
                      }
                      const cityExists = autoCompleteData.some(
                        (item:any) => item.city.toLowerCase() === typedState1.toLowerCase()
                      );
                      
                      if (cityExists) {
                       
                        setIsTrue1(false)
                        setErrorMessage2("")
                        setFieldValue("city", typedState1);
                      } 
                      else {
                        setIsTrue1(true)
                        setErrorMessage2("Invalid City")
                      
                      }
                    }}
                inputProps={{ readOnly: disabled }}
                sx={{
                  ".MuiFormLabel-root ": {
                    letterSpacing: "0.2rem",
                    fontSize: "0.8rem",
                  },
                  ".MuiInputLabel-shrink": {
                    letterSpacing: 0,
                  },
                  "& input::placeholder": {
                    fontSize: 14,
                    fontFamily: "Montserrat",
                    color: "#737373",
                  },
                  "& input": {
                    fontSize: 14,
                    fontFamily: "Montserrat",
                    color: "#252B42",
                  },
                  ".MuiInputBase-root": {
                    backgroundColor: "#F9F9F9",
                    color: "#252B42",
                  },
                }}
              />
                                       {(errorMessage2 || touched.city) && ( 
    <div style={{
      textAlign: "left",
      color: "red",
      fontSize: "0.9rem",
      marginTop: "0.6rem",
    }}>
      {errorMessage2 || (
        <ErrorMessage name="city">
          {(error) => <ErrorProps>{error}</ErrorProps>}
        </ErrorMessage>
      )}
    </div>)}
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography className={classes.h6Org}>
                State{" "}
                <Typography
                  component="span"
                  display="inline"
                  sx={{ color: "red" }}
                >
                  *
                </Typography>
              </Typography>
              <Field
                as={TextField}
                name="state"
                placeholder="State"
                type="text"
                fullWidth={true}
                autoComplete="new-country-area"
                // helperText={
                //   <ErrorMessage name="state">
                //     {(error) => <ErrorProps>{error}</ErrorProps>}
                //   </ErrorMessage>
                // }
                onChange={(e: any) => {
                
                  const typedState = e.target.value;
                    
                   setFieldValue("state", typedState); 
                      if (!typedState) {
                        setErrorMessage3("");
                        return; 
                      }
                      const stateExists = autoCompleteData.some(
                        (item:any) => item.state_code.toLowerCase() === typedState.toLowerCase()
                      );
                      
                      if (stateExists) {
                       
                        setIsTrue(false)
                        setErrorMessage3("")
                        setFieldValue("state", typedState);
                      } 
                      else {
                        setIsTrue(true)
                        setErrorMessage3("Invalid State code")
                      
                      }
                    }}
                

                inputProps={{ readOnly: disabled }}
                sx={{
                  ".MuiFormLabel-root ": {
                    letterSpacing: "0.2rem",
                    fontSize: "0.8rem",
                  },
                  ".MuiInputLabel-shrink": {
                    letterSpacing: 0,
                  },
                  "& input::placeholder": {
                    fontSize: 14,
                    fontFamily: "Montserrat",
                    color: "#737373",
                  },
                  "& input": {
                    fontSize: 14,
                    fontFamily: "Montserrat",
                    color: "#252B42",
                  },
                  ".MuiInputBase-root": {
                    backgroundColor: "#F9F9F9",
                    color: "#252B42",
                  },
                }}
              />
                          {(errorMessage3 || touched.state) && ( 
    <div style={{
      textAlign: "left",
      color: "red",
      fontSize: "0.9rem",
      marginTop: "0.6rem",
    }}>
      {errorMessage3 || (
        <ErrorMessage name="state">
          {(error) => <ErrorProps>{error}</ErrorProps>}
        </ErrorMessage>
      )}
    </div>)}
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography className={classes.h6Org}>
                Zipcode{" "}
                <Typography
                  component="span"
                  display="inline"
                  sx={{ color: "red" }}
                >
                  *
                </Typography>
              </Typography>
              <Field
                as={TextField}
                name="zipCode"
                placeholder="Zipcode"
                type="text"
                fullWidth={true}
                autoComplete="new-country-area"
                helperText={
                  <ErrorMessage name="zipCode">
                    {(error) => <ErrorProps>{error}</ErrorProps>}
                  </ErrorMessage>
                }
                inputProps={{ readOnly: disabled }}
                sx={{
                  ".MuiFormLabel-root ": {
                    letterSpacing: "0.2rem",
                    fontSize: "0.8rem",
                  },
                  ".MuiInputLabel-shrink": {
                    letterSpacing: 0,
                  },
                  "& input::placeholder": {
                    fontSize: 14,
                    fontFamily: "Montserrat",
                    color: "#737373",
                  },
                  "& input": {
                    fontSize: 14,
                    fontFamily: "Montserrat",
                    color: "#252B42",
                  },
                  ".MuiInputBase-root": {
                    backgroundColor: "#F9F9F9",
                    color: "#252B42",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography className={classes.h6Org}>
                Country{" "}
                <Typography
                  component="span"
                  display="inline"
                  sx={{ color: "red" }}
                >
                  *
                </Typography>
              </Typography>
              <Field
                className={classes.signuptext}
                as={TextField}
          
                name="country"
                placeholder="Country"
                type="text"
                fullWidth={true}
                autoComplete="new-country-area"
                helperText={
                  <ErrorMessage name="country">
                    {(error) => <ErrorProps>{error}</ErrorProps>}
                  </ErrorMessage>
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className={classes.h6Org}>
                Phone{" "}
                <Typography
                  component="span"
                  display="inline"
                  sx={{ color: "red" }}
                >
                  *
                </Typography>
              </Typography>
              <Field name="contact" component={CustomPhoneInput} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                className={classes.h6Org}
                sx={{
                  mb: "0.3rem",
                }}
              >
                Email
                <Typography
                  component="span"
                  display="inline"
                  sx={{ color: "red" }}
                >
                  *
                </Typography>
              </Typography>
              <Field
                as={TextField}
                name="email"
                placeholder="Email"
                type="email"
                fullWidth={true}
                autoComplete="new-country-area"
                helperText={
                  <ErrorMessage name="email">
                    {(error) => <ErrorProps>{error}</ErrorProps>}
                  </ErrorMessage>
                }
                sx={{
                  "& input:-webkit-autofill": {
                    backgroundColor: "#F9F9F9",
                  },
                  ".MuiFormLabel-root ": {
                    letterSpacing: "0.2rem",
                    fontSize: "0.8rem",
                  },
                  ".MuiInputLabel-shrink": {
                    letterSpacing: 0,
                  },
                  "& input::placeholder": {
                    fontSize: 14,
                    fontFamily: "Montserrat",
                    color: "#737373",
                  },
                  "& input": {
                    fontSize: 14,
                    fontFamily: "Montserrat",
                    color: "#252B42",
                  },
                  ".MuiInputBase-root": {
                    backgroundColor: "#F9F9F9",
                    color: "#252B42",
                  },
                }}
              />
            </Grid>
            <Grid
              container
              item
              xs={12}
              justifyContent="flex-start"
              gap={"5px"}
            >
              <Grid item>
                <Buttoncomponent
                  className={classes.cancelButton}
                  type="button"
                  onClick={handleClose1}
                  size="large"
                  fullWidth
                  variant="contained"
                  sx={{
                    mr: "1px",
                    width: "150px",
                    // width: {
                    //   xs: '100%',
                    //   md: '15vw' },

                    fontWeight: 400,
                    textTransform: "capitalize",
                  }}
                >
                  Cancel
                </Buttoncomponent>
              </Grid>
              <Grid item>
                <Buttoncomponent
                  className={classes.orgButton}
                  type="submit"
                  size="large"
                  fullWidth
                  variant="contained"
                  onClick={handleButtonClick}
                   disable={
                    (
                      touched.facilityNPI &&
                      touched.facilityName &&
                      touched.MainfacilityType &&
                      touched.addressLine1 &&
                      touched.addressLine2 &&
                      touched.city &&
                      touched.state && // If facilityNPI field has been touched
                      touched.country &&
                      touched.contact &&
                      (
                        !(
                          values.facilityNPI &&
                      values.facilityName &&
                 
                   
                      checkInfo.some(
                        (option: forminitialValues) =>
                          option.facilityNPI === values.facilityNPI
                      ) &&
                      checkInfo1.some((option: forminitialValues) => option.DBA === values.facilityName)
              
                    )) ||
                    (
                      values.facilityNPI &&
                      !checkInfo.some(
                        (option: forminitialValues) =>
                          option.facilityNPI === values.facilityNPI
                      )
                    ) ||
                    (
                      values.facilityName && 
                      !checkInfo1.some((option: forminitialValues) => option.facilityName === values.facilityName)
                    )
             
                    ) || // Or if there's an ongoing loading process
                   isTrue || isTrue1
                  }


                 // disable={!values.facilityNPI || !checkInfo.some((option:forminitialValues) => option.facilityNPI === values.facilityNPI)}
                
                   // disable={isLoading}
                  sx={{
                    ml: "1px",
                    fontWeight: 400,
                    textTransform: "capitalize",
                    width: "150px",

                    borderRadius: 0,
                  }}
                >
                  Add Facility
                </Buttoncomponent>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}}
    </Formik>
  );
}
