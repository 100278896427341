import React from "react";
import { Grid, Link, Typography, Box } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import useStyles from "../styles";

const Footer = () => {
  const classes = useStyles();
  return (
    <>
    <Box  sx={{width:"100%",   backgroundColor: "custom.customColor5", 
        mt: "10px",
        fontFamily: "Poppins",
       }}>
          <Grid container  justifyContent="space-between" alignItems="center">
    <Grid
        item
    sx={{
      backgroundColor: "custom.customColor5",
      padding: "25px 30px",
      display: "flex",
      flexDirection: { xs: "column", md: "row" },
      justifyContent: { md: "space-between" },
    }}
  >
    <Typography
    component="a"
      href="https://carecadet.com/"
      target="_blank" //  Open in a new tab/window
      rel="noopener noreferrer" 
      sx={{
        color: " #A7A7A7",
        textEdge: "cap",
        fontFamily: "Inter",
        fontSize: "26px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "100%",
        textTransform: "capitalize",
        ml: { xs: "0px", md: "30px" },
        cursor: "pointer",
        textDecoration: "none", 
      }}
    >
      CareCadet
    </Typography>
    </Grid>
    <Box
     
      sx={{
        backgroundColor: "custom.customColor5",
        paddingRight: " 10px",
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        justifyContent: { md: "space-between" },
      }}
    >
      <Grid item >
      <Typography
      component="a"
        href="https://carecadet.com/contact"
        target="_blank" //  Open in a new tab/window
        rel="noopener noreferrer" 
        sx={{
          color: " #A7A7A7",
          textEdge: "cap",
          fontFamily: "Inter",
          fontSize: "20px",
          fontStyle: "normal",
          fontWeight: 600,
          lineHeight: "100%",
          textTransform: "capitalize",
          ml: { xs: "0px", md: "30px" },
          cursor: "pointer",
          textDecoration: "none", 
          mt: "20px",
        }}
      >
        Contact us
      </Typography>
      </Grid>
      <Grid item >
      <Typography
      component="a"
        href="https://carecadet.com/terms"
        target="_blank" //  Open in a new tab/window
        rel="noopener noreferrer" 
        sx={{
          color: " #A7A7A7",
          textEdge: "cap",
          fontFamily: "Inter",
          fontSize: "20px",
          fontStyle: "normal",
          fontWeight: 600,
          lineHeight: "100%",
          textTransform: "capitalize",
          ml: { xs: "0px", md: "30px" },
          cursor: "pointer",
          textDecoration: "none", 
          mt: "20px",
        }}
      >
        Terms of Use
      </Typography>
       </Grid>
       <Grid item >
      <Typography
      component="a"
        href="https://carecadet.com/privacy"
        target="_blank" //  Open in a new tab/window
        rel="noopener noreferrer" 
        sx={{
          color: " #A7A7A7",
          textEdge: "cap",
          fontFamily: "Inter",
          fontSize: "20px",
          fontStyle: "normal",
          fontWeight: 600,
          lineHeight: "100%",
          textTransform: "capitalize",
          ml: { xs: "0px", md: "30px" },
          cursor: "pointer",
          textDecoration: "none", 
          mt: "20px",
        }}
      >
       Privacy Policy
      </Typography>
      </Grid>
      </Box>
  
    </Grid>
    
    </Box>
    </>
  );
};

export default Footer;
//commented temporavarily contains full details
// import React, { FC, ReactNode } from "react";
// import {
//   Box,
//   CssBaseline,
//   Grid,
//   Typography,
//   Card,
//   Divider,
//   List,
//   ListItem,
//   ListItemText,
// } from "@mui/material";
// import YouTubeIcon from "@mui/icons-material/YouTube";
// import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
// import LinkedInIcon from "@mui/icons-material/LinkedIn";
// import EmailIcon from "@mui/icons-material/Email";
// const Footer = () => {
//   return (
//     // <Box
//     //   sx={{
//     //     backgroundColor: "#00334F",
//     //     height: "5em",
//     //     mt: "50px",
//     //   }}
//     // >
//       <Grid item sx={{ backgroundColor: "#00334F" }}>
//         <Grid
//           container
//           direction="column"
//           // justifyContent="center"
//           // alignItems="center"
//           item
//           // sx={{ mb: "20px" }}
//           sx={{ backgroundColor: "#00334F", padding: {xs:"20px",md:"40px"} }}
//         >
//           <Grid container spacing={{ xs: 2, md: 0 }} padding={{xs:0,md:4}}>
//             <Grid item xs ={12} md={10} >
//               <Typography
//                 // variant="h3"
//                 sx={{
//                   color: " #A7A7A7",
//                   textEdge: "cap",
//                   fontFamily: "Inter",
//                   fontSize: "26px",
//                   fontStyle: "normal",
//                   fontWeight: 700,
//                   lineHeight: "110%",
//                   textTransform: "capitalize",
//                   ml: { xs:"0px",md: "40px" },

//                 }}
//               >
//                 CareCadet
//               </Typography>
//             </Grid>
//             <Grid
//               item
//               xs={12}
//               md={2}
//               sx={{
//                 display: "flex",
//                 alignItems: "flexStart",
//                 gap: "20px",
               
//               }}
//             >
//               <YouTubeIcon sx={{ color: "#A7A7A7", fontSize: "40px" }} />
//               <FacebookRoundedIcon
//                 sx={{ color: "#A7A7A7", fontSize: "40px" }}
//               />
//               <LinkedInIcon sx={{ color: "#A7A7A7", fontSize: "40px" }} />
//             </Grid>
//           </Grid>

//           <Divider
//             sx={{
//               height: "1px",
//               width: "100%",
//               backgroundColor: "#979797",
//               color: "#979797",
//             }}
//           />
//         </Grid>

//         <Grid
//           container
//           display="flex"
//           justifyContent="center"
//           // alignItems="center"
//           spacing={{ xs: 1, md: 1 }}
//           // direction={{ md: "row" }}
//           // // flexWrap={"wrap"}
//           // // justifyContent="center"
//           // // alignItems="center"
//           // spacing={3}
//           // paddingLeft={"15vh"}
//           // paddingRight={"15vh"}
//           sx={{ backgroundColor: "#00334F" }}
//         >
//           <Grid
//             item
//             xs={12}
//             md={2.3}
//             display={"flex"}
//             justifyContent={{xs:"flex-start",md:"center"}}
//             marginLeft={{ xs: "20px", md: "0" }}
//             padding={{ xs: "20px", md: "0" }}
//           >
//             <Card
//               sx={{
//                 display: "flex",
//                 flexDirection: "column",
//                 justifyContent: "space-between",
//                 alignItems: "center",
//                 backgroundColor: "#00334F",
//                 boxShadow: "none",
//                 // ,width:"18em"
//               }}
//             >
//               <Typography
//                 // variant="h6"
//                 color="textSecondary"
//                 sx={{
//                   color: "#FFF",
//                   textEdge: "cap",
//                   fontFamily: "Inter",
//                   fontSize: "18px",
//                   fontStyle: "normal",
//                   fontWeight: 600,
//                   lineHeight: "170%",
//                   textTransform: "capitalize",

//                 }}
//               >
//                 Treatment Cost
//               </Typography>
//               <List>

//                 <ListItemText
//                   primary="ACL Surgery"

//                   sx={{
//                     color: "#B5B5B5",
//                     textEdge: "cap",
//                     fontFamily: "Inter",
//                     fontSize: "18px",
//                     fontStyle: "normal",
//                     fontWeight: 400,
//                     marginLeft: '5px',
//                     padding: 0,
//                     textTransform: "capitalize",
//                   }}
//                 />


//                 <ListItemText
//                   primary="Angioplasty"

//                   sx={{
//                     color: "#B5B5B5",
//                     textEdge: "cap",
//                     fontFamily: "Inter",
//                     fontSize: "18px",
//                     fontStyle: "normal",
//                     fontWeight: 400,
//                     marginLeft: '5px',
//                     p: 0,
//                     textTransform: "capitalize",
//                   }}
//                 />


//                 <ListItemText
//                   primary="Bariatric Surgery"

//                   sx={{
//                     color: "#B5B5B5",
//                     textEdge: "cap",
//                     fontFamily: "Inter",
//                     fontSize: "18px",
//                     fontStyle: "normal",
//                     fontWeight: 400,

//                     marginLeft: '5px', p: 0,
//                     textTransform: "capitalize",
//                   }}
//                 />


//                 <ListItemText
//                   primary="Brain Tumor Surgery"
//                   sx={{
//                     color: "#B5B5B5",
//                     textEdge: "cap",
//                     fontFamily: "Inter",
//                     fontSize: "18px",
//                     fontStyle: "normal",
//                     fontWeight: 400,
//                     marginLeft: '5px', p: 0,
//                     textTransform: "capitalize",
//                   }}
//                 />

//                 <ListItemText
//                   primary="Bypass Surgery"
//                   sx={{
//                     color: "#B5B5B5",
//                     textEdge: "cap",
//                     fontFamily: "Inter",
//                     fontSize: "18px",
//                     fontStyle: "normal",
//                     fontWeight: 400,
//                     marginLeft: '5px', p: 0,
//                     textTransform: "capitalize",
//                   }}
//                 />

//                 <ListItemText
//                   primary="Cataract Surgery"
//                   sx={{
//                     color: "#B5B5B5",
//                     textEdge: "cap",
//                     fontFamily: "Inter",
//                     fontSize: "18px",
//                     fontStyle: "normal",
//                     fontWeight: 400, marginLeft: '5px', p: 0,

//                     textTransform: "capitalize",
//                   }}
//                 />

//               </List>
//             </Card>
//           </Grid>
//           <Grid
//             item
//             xs={12}
//             md={2.3}
//             display={"flex"}
//             justifyContent={{xs:"flex-start",md:"center"}}
//             marginLeft={{ xs: "20px", md: "0" }}
//             padding={{ xs: "20px", md: "0" }}
//           >
//             <Card
//               sx={{
//                 display: "flex",
//                 flexDirection: "column",
//                 justifyContent: "space-between",
//                 alignItems: "center",
//                 backgroundColor: "#00334F",
//                 boxShadow: "none",
//                 // ,width:"18em"
//               }}
//             >
//               <Typography
//                 // variant="h6"
//                 color="textSecondary"
//                 sx={{
//                   color: "#FFF",
//                   textEdge: "cap",
//                   fontFamily: "Inter",
//                   fontSize: "18px",
//                   fontStyle: "normal",
//                   fontWeight: 600,
//                   lineHeight: "170%",
//                   textTransform: "capitalize",
//                 }}
//               >
//                 Hospitals
//               </Typography>
//               <List>

//                 <ListItemText
//                   primary="xxxxxxxx xxxxxxxxx"

//                   sx={{
//                     color: "#B5B5B5",
//                     textEdge: "cap",
//                     fontFamily: "Inter",
//                     fontSize: "18px",
//                     fontStyle: "normal",
//                     fontWeight: 400,
//                     marginLeft: '5px',
//                     padding: 0,
//                     textTransform: "capitalize",
//                   }}
//                 />

//                 <ListItemText
//                   primary="xxxxxxxx xxxxxxxxx"

//                   sx={{
//                     color: "#B5B5B5",
//                     textEdge: "cap",
//                     fontFamily: "Inter",
//                     fontSize: "18px",
//                     fontStyle: "normal",
//                     fontWeight: 400,
//                     marginLeft: '5px',
//                     p: 0,
//                     textTransform: "capitalize",
//                   }}
//                 />

//                 <ListItemText
//                   primary="xxxxxxxx xxxxxxxxx"

//                   sx={{
//                     color: "#B5B5B5",
//                     textEdge: "cap",
//                     fontFamily: "Inter",
//                     fontSize: "18px",
//                     fontStyle: "normal",
//                     fontWeight: 400,

//                     marginLeft: '5px', p: 0,
//                     textTransform: "capitalize",
//                   }}
//                 />

//                 <ListItemText
//                   primary="xxxxxxxx xxxxxxxxx"
//                   sx={{
//                     color: "#B5B5B5",
//                     textEdge: "cap",
//                     fontFamily: "Inter",
//                     fontSize: "18px",
//                     fontStyle: "normal",
//                     fontWeight: 400,
//                     marginLeft: '5px', p: 0,
//                     textTransform: "capitalize",
//                   }}
//                 />

//                 <ListItemText
//                   primary="xxxxxxxx xxxxxxxxx"
//                   sx={{
//                     color: "#B5B5B5",
//                     textEdge: "cap",
//                     fontFamily: "Inter",
//                     fontSize: "18px",
//                     fontStyle: "normal",
//                     fontWeight: 400,
//                     marginLeft: '5px', p: 0,
//                     textTransform: "capitalize",
//                   }}
//                 />

//                 <ListItemText
//                   primary="xxxxxxxx xxxxxxxxx"
//                   sx={{
//                     color: "#B5B5B5",
//                     textEdge: "cap",
//                     fontFamily: "Inter",
//                     fontSize: "18px",
//                     fontStyle: "normal",
//                     fontWeight: 400, marginLeft: '5px', p: 0,

//                     textTransform: "capitalize",
//                   }}
//                 />

//               </List>
//             </Card>
//           </Grid>
//           <Grid
//             item
//             xs={12}
//             md={2.3}
//             display={"flex"}
//             justifyContent={{xs:"flex-start",md:"center"}}
//             marginLeft={{ xs: "20px", md: "0" }}
//             padding={{ xs: "20px", md: "0" }}
//           >
//             <Card
//               sx={{
//                 display: "flex",
//                 flexDirection: "column",
//                 justifyContent: "space-between",
//                 alignItems: "center",
//                 backgroundColor: "#00334F",
//                 boxShadow: "none",
//                 // ,width:"18em"
//               }}
//             >
//               <Typography
//                 // variant="h6"
//                 color="textSecondary"
//                 sx={{
//                   color: "#FFF",
//                   textEdge: "cap",
//                   fontFamily: "Inter",
//                   fontSize: "18px",
//                   fontStyle: "normal",
//                   fontWeight: 600,
//                   lineHeight: "170%",
//                   textTransform: "capitalize",
//                 }}
//               >
//                 Payers
//               </Typography>
//               <List>

//                 <ListItemText
//                   primary="xxxxxxxx xxxxxxxxx"

//                   sx={{
//                     color: "#B5B5B5",
//                     textEdge: "cap",
//                     fontFamily: "Inter",
//                     fontSize: "18px",
//                     fontStyle: "normal",
//                     fontWeight: 400,
//                     marginLeft: '5px',
//                     padding: 0,
//                     textTransform: "capitalize",
//                   }}
//                 />

//                 <ListItemText
//                   primary="xxxxxxxx xxxxxxxxx"

//                   sx={{
//                     color: "#B5B5B5",
//                     textEdge: "cap",
//                     fontFamily: "Inter",
//                     fontSize: "18px",
//                     fontStyle: "normal",
//                     fontWeight: 400,
//                     marginLeft: '5px',
//                     p: 0,
//                     textTransform: "capitalize",
//                   }}
//                 />

//                 <ListItemText
//                   primary="xxxxxxxx xxxxxxxxx"

//                   sx={{
//                     color: "#B5B5B5",
//                     textEdge: "cap",
//                     fontFamily: "Inter",
//                     fontSize: "18px",
//                     fontStyle: "normal",
//                     fontWeight: 400,

//                     marginLeft: '5px', p: 0,
//                     textTransform: "capitalize",
//                   }}
//                 />

//                 <ListItemText
//                   primary="xxxxxxxx xxxxxxxxx"
//                   sx={{
//                     color: "#B5B5B5",
//                     textEdge: "cap",
//                     fontFamily: "Inter",
//                     fontSize: "18px",
//                     fontStyle: "normal",
//                     fontWeight: 400,
//                     marginLeft: '5px', p: 0,
//                     textTransform: "capitalize",
//                   }}
//                 />

//                 <ListItemText
//                   primary="xxxxxxxx xxxxxxxxx"
//                   sx={{
//                     color: "#B5B5B5",
//                     textEdge: "cap",
//                     fontFamily: "Inter",
//                     fontSize: "18px",
//                     fontStyle: "normal",
//                     fontWeight: 400,
//                     marginLeft: '5px', p: 0,
//                     textTransform: "capitalize",
//                   }}
//                 />

//                 <ListItemText
//                   primary="xxxxxxxx xxxxxxxxx"
//                   sx={{
//                     color: "#B5B5B5",
//                     textEdge: "cap",
//                     fontFamily: "Inter",
//                     fontSize: "18px",
//                     fontStyle: "normal",
//                     fontWeight: 400, marginLeft: '5px', p: 0,

//                     textTransform: "capitalize",
//                   }}
//                 />

//               </List>
//             </Card>
//           </Grid>
//           <Grid
//             item
//             xs={12}
//             md={2.3}
//             display={"flex"}
//             justifyContent={{xs:"flex-start",md:"center"}}
//             marginLeft={{ xs: "20px", md: "0" }}
//             padding={{ xs: "20px", md: "0" }}
//           >
//             <Card
//               sx={{
//                 display: "flex",
//                 flexDirection: "column",
//                 justifyContent: "space-between",
//                 alignItems: "center",
//                 backgroundColor: "#00334F",
//                 boxShadow: "none",
//                 // ,width:"18em"
//               }}
//             >
//               <Typography
//                 // variant="h6"
//                 color="textSecondary"
//                 sx={{
//                   color: "#FFF",
//                   textEdge: "cap",
//                   fontFamily: "Inter",
//                   fontSize: "18px",
//                   fontStyle: "normal",
//                   fontWeight: 600,
//                   lineHeight: "170%",
//                   textTransform: "capitalize",
//                 }}
//               >
//                 About
//               </Typography>
//               <List>

//                 <ListItemText
//                   primary="xxxxxxxx xxxxxxxxx"

//                   sx={{
//                     color: "#B5B5B5",
//                     textEdge: "cap",
//                     fontFamily: "Inter",
//                     fontSize: "18px",
//                     fontStyle: "normal",
//                     fontWeight: 400,
//                     marginLeft: '5px',
//                     padding: 0,
//                     textTransform: "capitalize",
//                   }}
//                 />

//                 <ListItemText
//                   primary="xxxxxxxx xxxxxxxxx"

//                   sx={{
//                     color: "#B5B5B5",
//                     textEdge: "cap",
//                     fontFamily: "Inter",
//                     fontSize: "18px",
//                     fontStyle: "normal",
//                     fontWeight: 400,
//                     marginLeft: '5px',
//                     p: 0,
//                     textTransform: "capitalize",
//                   }}
//                 />

//                 <ListItemText
//                   primary="xxxxxxxx xxxxxxxxx"

//                   sx={{
//                     color: "#B5B5B5",
//                     textEdge: "cap",
//                     fontFamily: "Inter",
//                     fontSize: "18px",
//                     fontStyle: "normal",
//                     fontWeight: 400,

//                     marginLeft: '5px', p: 0,
//                     textTransform: "capitalize",
//                   }}
//                 />

//                 <ListItemText
//                   primary="xxxxxxxx xxxxxxxxx"
//                   sx={{
//                     color: "#B5B5B5",
//                     textEdge: "cap",
//                     fontFamily: "Inter",
//                     fontSize: "18px",
//                     fontStyle: "normal",
//                     fontWeight: 400,
//                     marginLeft: '5px', p: 0,
//                     textTransform: "capitalize",
//                   }}
//                 />


//                 <ListItemText
//                   primary="xxxxxxxx xxxxxxxxx"
//                   sx={{
//                     color: "#B5B5B5",
//                     textEdge: "cap",
//                     fontFamily: "Inter",
//                     fontSize: "18px",
//                     fontStyle: "normal",
//                     fontWeight: 400,
//                     marginLeft: '5px', p: '0 !important',
//                     textTransform: "capitalize",
//                   }}
//                 />


//                 <ListItemText
//                   primary="xxxxxxxx xxxxxxxxx"
//                   sx={{
//                     color: "#B5B5B5",
//                     textEdge: "cap",
//                     fontFamily: "Inter",
//                     fontSize: "18px",
//                     fontStyle: "normal",
//                     fontWeight: 400, marginLeft: '5px', p: '0 !important',

//                     textTransform: "capitalize",
//                   }}
//                 />

//               </List>
//             </Card>
//           </Grid>
//         </Grid>

//         {/* <Grid
//           container
//           // direction="column"
//           // justifyContent="center"
//           // alignItems="center"
//           item
//           // sx={{ mb: "20px" }}
          
//         > */}
//         <Grid container spacing={{ xs: 1.5, md: 0 }} padding={"30px 30px 30px 50px"} style={{ backgroundColor: "#00334F", }}>
//           <Grid item md={7}>
//             <Typography
//               // variant="h4"
//               sx={{
//                 color: "#FFF",
//                 fontFamily: "Inter",
//                 fontSize: "16px",
//                 fontStyle: "normal",
//                 fontWeight: 400,
//                 lineHeight: "170%",
//                 textTransform: "capitalize",
//               }}
//             >
//               <EmailIcon
//                 sx={{
//                   color: "#979797",
//                   fontSize: "16px",
//                   marginRight: "5px",
//                   ml: {xs:"60px", md: "60px" }
//                 }}
//               />{" "}
//               info@carecadet.com
//             </Typography>
//           </Grid>
//           <Grid
//             item
//             md={5}
//             sx={{
//               display: "flex",
//               alignItems: "space-around",
//               justifyContent: "space-around",
//             }}
//           >
//             <Typography
//               // variant="h4"
//               sx={{
//                 color: "#FFF",
//                 fontFamily: "Inter",
//                 fontSize: "16px",
//                 fontStyle: "normal",
//                 fontWeight: 400,
//                 lineHeight: "170%",
//                 textTransform: "capitalize",
//                 ml: {xs:"60px", md: "250px" }
//               }}
//             >
//               Terms & Conditions
//             </Typography>
//           </Grid>
//         </Grid>
//       </Grid>
     
//     // {/* </Box> */}
//   );
// };

// export default Footer;
