import React, { useState } from "react";
import {useNavigate } from "react-router-dom";
import {
  Tabs,
  Tab,
  Button,
  Box,
  useMediaQuery,
} from "@mui/material";

import {
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import PricelistUploadthroFacility from "./PricelistUploadthrofacilitytab1";
import PricelistTableView from "./PricelistViewTable";

import useStyles from "../../styles";
import { useAppSelector } from "../../Redux/Hook";
import PublishComp from "./publishcomponent";

interface props {
  textalign: string;
  children: JSX.Element;
}

type CloseReason = "backdropClick" | "escapeKeyDown" | "closeButtonClick";
interface DialogProps extends MuiDialogProps {
  onClose: (reason: CloseReason) => void;
}

const Dialog = ({ title, open, onClose, children, ...props }: DialogProps) => {
  return (
    <MuiDialog
      onClose={(_, reason) => onClose(reason)}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={() => onClose("closeButtonClick")} color="primary">
          Close
        </Button>
      </DialogActions>
    </MuiDialog>
  );
};

function Pricelist({ handleCancel,handleCloseService,dataCheck,addRow,handlePricelistUpdate }: any) {
  const [open, setOpen] = React.useState(false);

  const classes=useStyles()

  const navigate = useNavigate();
  const data = useAppSelector(
    (state: { providerAuth: { login: any } }) => state.providerAuth.login
  );



  const [tabValue, setTabValue] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [csvData, setCsvData] = useState<any[]>([]);
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const handleFileUpload = (data: any[]) => {
    setCsvData(data);
    setIsLoading(true);
    setTabValue(1);
  };

  const handleNext = (csvData: any[]) => {
    if (tabValue < 2) {
      setCsvData(csvData);
      setTabValue(tabValue + 1);
    }
  };

  const handlePrevious = () => {
    if (tabValue > 0) {
      setTabValue(tabValue - 1);
    }
  };


  const handleSave = () => {
    handleCancel();
  };
  const handleCloseServices = ()=>{
    handleCloseService()
  }
  
  const isMdUp = useMediaQuery('(min-width:500px)');
  return (
    <Box>
      <Tabs
        value={tabValue}
        variant={isMdUp ? 'fullWidth' : 'standard'}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        sx={{
          width: '100%',
        '& .MuiTabs-flexContainer': {
          flexDirection: isMdUp ? 'row' : 'column',
        },
        }}
      >
        <Tab className={classes.uploadTab}
          sx={{
        
            textTransform:"capitalize",
         
          }}
          label="Upload & Validate"
          disabled={tabValue !== 0}
        />
        <Tab className={classes.uploadTab}
          sx={{
           
            textTransform:"capitalize",
           
          }}
          label="Preview"
          disabled={tabValue !== 1}
        />
        <Tab className={classes.uploadTab}
           sx={{
           
            textTransform:"capitalize",
            
          }}
          label="Publish"
          disabled={tabValue !== 2}
        />
      </Tabs>
      <Box mt={2}>
        {tabValue === 0 && (
          <div>
            <PricelistUploadthroFacility
              onFileUpload={handleFileUpload}
              handleCancel={handleSave}
              handleCloseServicee = {handleCloseServices}
              dataCheeck = {dataCheck}
              addRow={addRow}
              handlePricelistUpdate={handlePricelistUpdate}
            />
          </div>
        )}
        {tabValue === 1 && (
          <div>
            <PricelistTableView data={csvData} handleNext={handleNext} handlePrevious= {handlePrevious} />
          </div>
        )}
        {tabValue === 2 && (
          <div>
            <PublishComp data={csvData} handlePrevious= {handlePrevious}/>
          </div>
        )}
      </Box>
    
    </Box>
    
  );
}

export default Pricelist;
