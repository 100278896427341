import React from "react";
import { TextField, Box, Typography, Grid, Paper, Link } from "@mui/material";
import { Formik, Form } from "formik";
import * as yup from "yup";

import { toast } from "react-toastify";
import { NavLink, useNavigate } from "react-router-dom";

import { useState } from "react";
import FormTextField from "../../Components/Textfield";
import { Buttoncomponent } from "../../Components/Buttoncomp";

import { axiosPrivate } from "../../axios/axios";

import { useTheme } from "@mui/material/styles";

import { useLocation } from "react-router-dom";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";

import useStyles from "../../styles";

//AutoPlaySwipeableViews for future use

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    name: "John Doe",
    quote: "This is a great product! I would highly recommend it to anyone.",
  },
  {
    name: "Jane Doe",
    quote:
      "I've been using this product for a few months now and I'm really happy with it.",
  },
  {
    name: "Bob Smith",
    quote: "This product is amazing! It's changed my life for the better.",
  },
];
const schema = yup.object().shape({
  email: yup
    .string()
    .required("Email is a required field")
    .email("Invalid email"),
});
export default function Forgotpass() {
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const classes = useStyles();

  const data = location.state?.data?.Link;
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        mt: "2rem",
      }}
    >
      <Paper elevation={3} sx={{  padding: "3rem",width:{md:"50%"}}}>
        <Grid container justifyContent={"center"}>
          {/* <Grid
            item
            md={4}
            xs={12}
            sx={{
              backgroundColor: "#f4f4f4",

              mt: 2,
              padding: 3,
            }}
          >
            <Typography sx={{ fontSize: "2.5rem", fontWeight: "bold" }}>
              Welcome to CareCadet Family
            </Typography>
            <Typography sx={{ fontSize: "1.5rem" }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.{" "}
            </Typography>

            <Box
              sx={{
                mt: "15vh",
                flexGrow: 1,
                borderRadius: 10,
                padding: 3,
                backgroundColor: "white",
              }}
            >
              <AutoPlaySwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
              >
           
                {images.map((step, index) => (
                  <div key={step.name}>
                    {Math.abs(activeStep - index) <= 2 ? (
                      <Box
                        component="div"
                        sx={{
                          display: "block",
                          overflow: "hidden",
                        }}
                      >
                        {" "}
                        <Typography
                        
                          fontSize={16}
                        >
                      
                        </Typography>
                        <Box
                          marginTop={4}
                      
                          sx={{ display: "flex", flexDirection: "row" }}
                        >
                    
                          <Typography fontSize={23} marginLeft={2}>
                          
                          </Typography>
                        </Box>
                      </Box>
                    ) : // src={step.quote}
                    // alt={step.name}

                    null}
                  </div>
                ))}
              </AutoPlaySwipeableViews>
            </Box>
            <Box sx={{ backgroundColor: "#f4f4f4" }}>
              <MobileStepper
                sx={{ backgroundColor: "#f4f4f4", fontSize: "2vh" }}
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                  <Button
                    size="large"
                    onClick={handleNext}
                    disabled={activeStep === maxSteps - 1}
                  >
                    Next
                    {theme.direction === "rtl" ? (
                      <KeyboardArrowLeft />
                    ) : (
                      <KeyboardArrowRight />
                    )}
                  </Button>
                }
                backButton={
                  <Button
                    size="large"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                  >
                    {theme.direction === "rtl" ? (
                      <KeyboardArrowRight />
                    ) : (
                      <KeyboardArrowLeft />
                    )}
                    Back
                  </Button>
                }
              />
            </Box>
          </Grid> */}

          <Grid
            item
            md={8}
            sx={{ display: "flex", justifyContent: "center", mt: "2rem" }}
          >
            <Formik
              validationSchema={schema}
              initialValues={{
                email: "",
                userType: "PROVIDER",
              }}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                setIsLoading(true);
                const Resetpass = {
                  email: values.email,
                };

                axiosPrivate
                  .put("/provider/forgotpassword", Resetpass)
                  .then((res) => {
                    setIsLoading(false);

                    toast.success(res.data.message);
                  })
                  .catch((err) => {
                    toast.warning(err.message);

                    setIsLoading(false);
                  });

                resetForm();
              }}
            >
              {({ values, isSubmitting, resetForm }) => (
                <Form>
                  <>
                    <Typography className={classes.h1}>
                      Account Details
                    </Typography>

                    <Grid>
                      <Typography className={classes.h2}>
                        Please enter your email address used for sign up
                      </Typography>
                    </Grid>

                    <Grid>
                      <Typography
                        className={`${classes.h6} ${classes.h6WithLargeMargin}`}
                      >
                        Email{" "}
                        <Typography
                          component="span"
                          display="inline"
                          sx={{ color: "red" }}
                        >
                          *
                        </Typography>
                      </Typography>

                      <FormTextField
                        className={classes.signuptext}
                        container={TextField}
                        name="email"
                        placeholder="Email"
                        type="email"
                        autoComplete="text"
                      />
                    </Grid>

                    <Grid item>
                      <Buttoncomponent
                        className={`${classes.signupbutton} ${classes.h6WithLargeMargin}`}
                        type="submit"
                        disable={isLoading}
                        size="large"
                        fullWidth={true}
                        variant="contained"
                        sx={{ textTransform: "capitalize" }}
                      >
                        {isLoading && (
                          <i
                            className="fa fa-refresh fa-spin"
                            style={{ marginRight: "5px" }}
                          />
                        )}
                        {isLoading && <span>Sending Email</span>}
                        {!isLoading && <span>Recovery email</span>}
                      </Buttoncomponent>
                    </Grid>

                    <Divider
                      component="div"
                      variant="fullWidth"
                      sx={{ marginTop: "2vh" }}
                    />
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <Typography
                        className={`${classes.h3} ${classes.h6WithLargeMargin}`}
                      >
                        Need an account ?{" "}
                        <Link
                          sx={{ textDecoration: "none" }}
                          to="/provider/signup"
                          component={NavLink}
                          state={{ signup: data }}
                        >
                          <Typography
                            component="span"
                            sx={{ color: "custom.customColor6" }}
                          >
                            Sign Up
                          </Typography>
                        </Link>
                      </Typography>
                    </Box>
                  </>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
