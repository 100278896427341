import React from "react";
import { Button, Typography, Grid, Box } from "@mui/material";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";


import { axiosPrivate, } from "../../axios/axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";

import useStyles from "../../styles"

interface TableViewProps {
  data: any;
  handleCancel: any;
  onPostSuccess: any;
  getData: () => void;
  handleClosethrfacilityPage: any;
  serviceLandFlag: any;
  facilityStateRefresh:()=>void;
}

const TableViewCreateManually: React.FC<TableViewProps> = ({
  data,
  handleCancel,
  onPostSuccess,
  getData,
  handleClosethrfacilityPage,
  serviceLandFlag,
  facilityStateRefresh
}) => {
  const {
    _id,
    ServiceCode,
    DiagnosisTestorServiceName,
    FacilityName,
    OrganizationPrices,
    FacilityNPI,
    FacilityPrices,
  } = data;

  const navigate = useNavigate();



  const onSubmit = (row: any) => {


    axiosPrivate
      .post("/service/createservice", data)
      .then((res) => {
        toast.success(res.data.message);
     
        if (serviceLandFlag) {
          getData();
          onPostSuccess();
        } else {
          facilityStateRefresh()
          handleClosethrfacilityPage();
        }
      })
      .catch((err) => {
        toast.warning(err.message);
      });
  };
  
  const classes=useStyles()

  const handleClose = () => {
    navigate("/provider/facility/viewfacility");
  };

  return (
    <Box>
      <Typography className={classes.manualCell}
        sx={{
        
          m: "50px 0 20px 0",
        }}
      >
       All set to publish
      </Typography>
      <TableContainer component={Paper}>
        <Table className={classes.table}
        
        >
          <TableHead>
            <TableRow>
              <TableCell className={classes.manualCell}
           
              >
                Service Code
              </TableCell>
              <TableCell className={classes.manualCell}
              
              >
                Diagnosis Test/Service Name
              </TableCell>
              <TableCell className={classes.manualCell}
               
              >
                Facility Name
              </TableCell>
    
              <TableCell className={classes.manualCell}
                
              >
                Facility NPI
              </TableCell>
              <TableCell className={classes.manualCell}
                
              >
                Facility Prices
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow sx={{ backgroundColor: "custom.customColor3" }}>
              <TableCell className={classes.manualCelldetails}
                
              >
                {ServiceCode}
              </TableCell>
              <TableCell className={classes.manualCelldetails}
                sx={{
                  
                  textTransform: "capitalize",
                }}
              >
                {DiagnosisTestorServiceName.toLocaleLowerCase()}
              </TableCell>
              <TableCell className={classes.manualCelldetails}
                sx={{
                  
                  textTransform: "capitalize",
                }}
              >
                {FacilityName.toLocaleLowerCase()}
              </TableCell>

              <TableCell className={classes.manualCelldetails}
          
              >
                {FacilityNPI}
              </TableCell>
              <TableCell className={classes.manualCelldetails}
                sx={{
                
                  textAlign: "right",
                }}
              >
                <Typography display={"inline"}>${" "}</Typography>
                {FacilityPrices}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container item xs={10} columnGap={1} justifyContent="left">
        <Button  className={classes.noButton}
          type="button"
          onClick={handleCancel}
          fullWidth={false}
          variant="contained"
          sx={{
            mt: "3vh",
          
            width: "15vw",
           
            textTransform: "capitalize",
            borderRadius: "0",
           
          }}
        >
          Cancel
        </Button>
        <Button className={classes.yesButton}
          type="submit"
          onClick={onSubmit}
          fullWidth={false}
          variant="contained"
          sx={{
            mt: "3vh",
           
            textTransform: "capitalize",
            width: "15vw",
            color: "#FFFFFF",
            borderRadius: "0",
         
          }}
        >
          Publish
        </Button>
      </Grid>
    </Box>
  );
};

export default TableViewCreateManually;
