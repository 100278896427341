import React, { useState } from "react";

import {
  DataGrid,
  
  GridColTypeDef,
  GridValueFormatterParams,
  GridColumns,
  GridRow,
  GridPreProcessEditCellProps,
} from "@mui/x-data-grid";
import {  useAppSelector } from "../../Redux/Hook";
import { axiosPrivate,  } from "../../axios/axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import useStyles from "../../styles";
import {
 
  Box,

} from "@mui/material";
import { Buttoncomponent } from "../../Components/Buttoncomp";
import clsx from "clsx";
import {CircularProgress, Typography} from "@mui/material"


interface TableViewProps {
  data: any[];
  facilityStateRefresh: any;
  handleClosethrfacilitypage: any;
  serviceLandRefreshState: any;
  serviceLandFlag: any;
  handleClosee: any;
  handlePrevious: any;
}

const Publishcomp: React.FC<TableViewProps> = ({
  data,
  facilityStateRefresh,
  handleClosethrfacilitypage,
  serviceLandFlag,
  serviceLandRefreshState,
  handleClosee,
  handlePrevious,
}) => {

  const [pageSize, setPagesize] = useState(5);
  const [filename, setFilename] = useState("");

  const emailData = useAppSelector((state) => state.providerAuth.login);
  const [loading, setLoading] = useState(false);
  const [page1, setPage1] = useState(1);

  const navigate = useNavigate();
  const orgid = useAppSelector(
    (state) => state.providerOrganization.orgEditData
  );

  
  const classes=useStyles()

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage1(value);
  };

  const usdPrice: GridColTypeDef = {
    type: "number",
    width: 130,
    // valueFormatter: ({ value }) => currencyFormatter.format(value),
    valueFormatter: (params: GridValueFormatterParams<number>) => {
      if (params.value == null) {
        return "";
      }

      const valueFormatted = Number(params.value).toLocaleString();
      return `$ ${valueFormatted} `;
    },
    cellClassName: "font-tabular-nums",
  };
  const columns: GridColumns = [
    {
      field: "SNo",
      headerName: "S.No",
      editable: false,
      headerClassName: "super-app-theme--header",
      // width: 100,
      headerAlign: "center",
      align:"center"
    },
    {
      field: "ServiceCode",
      headerName: "Service Code",
      editable: false,
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "DiagnosisTestorServiceName",
      headerName: "Diagnosis Test/Service Name",
      editable: false,
      headerClassName: "super-app-theme--header",
      flex: 2,
      minWidth: 350,
    },
    {
      field: "FacilityName",
      headerName: "Facility Name",
      headerClassName: "super-app-theme--header",
      editable: false,
      flex: 2,
      headerAlign: "center",

      minWidth: 100,
    },
    {
      field: "OrganizationPrices",
      headerName: "Organization Prices",
      editable: false,
      headerClassName: "super-app-theme--header",
      flex: 1,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const invalid = !Number(params.props.value);
        return { ...params.props, error: invalid };
      },
      headerAlign: "center",

      minWidth: 100,
      align: "right",
      ...usdPrice,
    },
    {
      field: "FacilityNPI",
      headerName: "Facility NPI",
      editable: false,
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "FacilityPrices",
      headerName: "Facility Prices",
      headerClassName: "super-app-theme--header",
      type: "number",
      editable: false,
      flex: 1,
      minWidth: 100,
      align: "right",
      headerAlign: "center",

      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const invalid = !Number(params.props.value);
        return { ...params.props, error: invalid };
      },

      ...usdPrice,
    },
  ];

  const handleCancel = () => {
    // navigate("/provider/facility/viewFacility")
    handlePrevious();
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    setLoading(true); 
    let datacheck = {
      name: filename,
      csv: data, // This should be the CSV data
      type: "Multiple facility upload",
      emailData: emailData, // Assuming this is the correct property
      organizationID: orgid[0].organizationID,
    };

    axiosPrivate
      .post(`/service/publishPricelistCorrectformat`, datacheck)
      .then((res) => {
        setLoading(false);
        toast.success(res.data.message);

        if (serviceLandFlag) {
          serviceLandRefreshState();
          handleClosee();
          navigate("/provider/service/listService");
        } else {
          facilityStateRefresh();
          handleClosethrfacilitypage();
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.warning(err.message);
      });
  };
  
  function CustomRow(props: any) {
    const { className, index, ...other } = props;
    return (
      <GridRow
        index={index}
        className={clsx(className, index % 2 === 0 ? "odd" : "even")}
        {...other}
      />
    );
  }

  return (
    <Box className={classes.dataBox}
      sx={{
     
        height: 400,
        width: 1,
        "& .odd": {
          bgcolor: "white",
        },
        "& .even": {
          bgcolor: "#EDEDED",
        },
        ".MuiDataGrid-columnHeader":{
          backgroundColor: "#EDEDED",
        },
        ".MuiDataGrid-columnHeaderTitle": {
          
          fontWeight: 600,
        },
        ".MuiDataGrid-cellContent": {
          fontFamily: "Montserrat",
          fontSize: "14px",
          fontWeight: 400,
          textTransform: "capitalize",
        },
      }}
    >
         {loading && ( // Conditionally render Box containing CircularProgress and Typography
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <CircularProgress color="inherit" size={50} />
          <Typography>Loading</Typography>
        </Box>
      )}
      <DataGrid className={classes.dataGrid1}
        autoHeight
        rows={data.map((row, index) => ({ ...row, id: index + 1 }))}
        getRowId={(row: any) => row.id}
        columns={columns}
       
        pagination={true}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPagesize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20]}
        
        components={{ Row: CustomRow }}
      />
      <Box sx={{ display: "flex", gap: "1.5rem" }}>
        <Buttoncomponent className={classes.noButton}
          type="button"
          variant="contained"
          size="large"
         
          onClick={handleCancel}
          sx={{
            mt: "2vh",
          
            width: "15vw",
          
            textTransform: "capitalize",
           
          }}
        >
          Cancel
        </Buttoncomponent>

        <Buttoncomponent className={classes.yesButton}
          type="submit"
          variant="contained"
          size="large"
          color="primary"
         
          sx={{
            mt: "2vh",
          
            width: "15vw",
            textTransform: "capitalize",
           
          }}
          onClick={onSubmit}
        >
          Publish
        </Buttoncomponent>
      </Box>
    </Box>
  );
};

export default Publishcomp;
