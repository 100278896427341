import React from "react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import RadioSelect from "../../ProtectedRoutes/radio";
import { Formik, Form, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import healthcare from "../../Images/healthcare.jpg";
import SearchIcon from "@mui/icons-material/Search";
import {
  Grid,
  Typography,
  Paper,
  TextField,
  Select,
  IconButton,
  Button,
  Box,
  Card,
  CardContent,
  CardMedia,
  MenuItem,
  Menu,
  Autocomplete,
  Avatar,
  Divider,
  AutocompleteRenderInputParams,
  createFilterOptions,
  useMediaQuery,
} from "@mui/material";

import { useNavigate } from "react-router-dom";

import { useAppDispatch } from "../../Redux/Hook";
import { pageUser } from "../../Redux/ProviderRedux/LoginSlice";

import { axiosPrivate } from "../../axios/axios";
import { Buttoncomponent } from "../../Components/Buttoncomp";
import SelectField from "../../Components/Select";
import FormTextField from "../../Components/Textfield";
import {
  dataProviderSearch,
  dataQueryProvider,
  dataSearch,
} from "../../Redux/ProviderRedux/HomeSlice";
import { toast } from "react-toastify";

import dashboardicon from "../../Images/dashboardicon.png";
import dentallogo from "../../Images/dentallogo.jpg";
import lab from "../../Images/lab.png";
import emergency from "../../Images/emergency.jpg";
import care from "../../Images/care.jpg";

// import LocationOnIcon from '@material-ui/icons/LocationOn';
import InputAdornment from "@mui/material/InputAdornment";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import MobileStepper, {
  MobileStepperProps as MuiMobileStepperProps,
} from "@mui/material/MobileStepper";
import { useTheme } from "@mui/material/styles";
import Logo from "../../Logo/Logo_CareCadetTrasnparent.png"
import imagenew from "../../Images/Untitled-1.jpg";
import img1 from "../../Images/img1.png";
import img2 from "../../Images/img2.png";
import img3 from "../../Images/img3.png";
import img4 from "../../Images/img4.png";
import img5 from "../../Images/img5.png";
import img6 from "../../Images/img6.png";
import img7 from "../../Images/img7.png";
import img8 from "../../Images/img8.png";
import img9 from "../../Images/img9.png";
import hos1 from "../../Images/hos1.png";
import hos2 from "../../Images/hos2.png";
import hos3 from "../../Images/hos3.png";
import hos4 from "../../Images/hos4.png";
import hos5 from "../../Images/hos5.png";
import hos6 from "../../Images/hos6.png";
import cust1 from "../../Images/cust1.png";
import cust2 from "../../Images/cust2.png";
import cust3 from "../../Images/cust3.png";
import cust4 from "../../Images/cust4.png";
import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";
import StarRateIcon from "@mui/icons-material/StarRate";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import useStyles from "../../styles"
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    label: "Shining a light on Health Care Prices...",
    data: "Find estimates for hospital charges, doctors fee, diagnostic tests prices and other healthcare expenses",
    imgPath: imagenew,
    // 'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60',
  },
  {
    // label: "Bird",
    imgPath: img2,
    //'https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60',
  },
  {
    // label: "Bali, Indonesia",
    imgPath: img3,
    //'https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250',
  },
];

interface forminitialValues {
  Service: string;
  Location: string;
}
const options = [
  { value: "Type1", item: "Type1" },
  { value: "Type2", item: "Type2" },
  { value: "Type3", item: "Type3" },
];

const Providerhomepage = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  // const [state, setState] = React.useState("Provider");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [locaInfo1, setLocaInfo1] = useState<any>();
  const [checkInfo, setCheckInfo] = useState<any>([]);
  const OPTIONS_LIMIT = 10;
  const defaultFilterOptions = createFilterOptions();
  const classes = useStyles()

  function getLocationUpdate() {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition((position) => {
        const Lat = position.coords.latitude;
        const Lon = position.coords.longitude;
        const zipCode1 = { lat: Lat, lon: Lon };
   
        axiosPrivate
          .post(`/search/serviceLocationSearch`, zipCode1)
          .then((res) => {
      
            setLocaInfo1(res.data.data);
            // valRef.current = locaInfo
          })
          .catch((e) => console.log(e));
      });
    } else {
      alert("sorry,browser does not support geolocation");
    }
  }
  useEffect(() => {
    // navigator.geolocation.watchPosition(showLocation,errorHandler)
    getLocationUpdate();
  }, []);

  const filterOptions = (options: any, state: any) => {
    return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  };

  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

    // setActiveStep((prevActiveStep) => (prevActiveStep + 1) % maxSteps);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [data, setData] = React.useState({
    Link: "Urgent Care",
  });
  const [data1, setData1] = React.useState({
    Link: "Imaging",
  });
  const [data2, setData2] = React.useState({
    Link: "Dentist",
  });
  const [data3, setData3] = React.useState({
    Link: "Services",
  });
  const mtValue = {
    xs: "-26vh", 
    md: "-26vh", 
    lg: "-26vh", 
  };
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
    
      <Grid item lg={12} md={12}>
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    //   margin:"auto",
    //  height: "50%", // Maintain 50% height if desired
    }}
  >
    {/* <Box sx={{     display: "flex", xs:"none",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center", width: "100%",   margin:"auto", paddingTop:"6.6%" }}>
      <img src={Logo} alt="Your logo description" // Add an alt attribute for accessibility
        style={{ maxWidth: "500px", maxHeight: "500px" }} // Set max image dimensions
         />
    </Box> */}
    <Box sx={{
     display: { xs: "none", md: "flex" },

    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // width: "100%",
    // margin: "auto",
    // paddingTop: "6.6%"
}}>
    <img
        src={Logo}
        alt="Your logo description" // Add an alt attribute for accessibility
        style={{
            maxWidth: "500px", // Adjust the maximum width as needed
            maxHeight: "500px" // Adjust the maximum height as needed
        }}
    />
</Box>
<Box sx={{
     display: { xs:"flex", md: "none" },

    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
   
    // width: "100%",
    // margin: "auto",
    // paddingTop: "6.6%"
}}>
    <img
        src={Logo}
        alt="Your logo description" 
        style={{
            maxWidth: "300px", 
            maxHeight: "300px",
            marginTop:"-100px"
        }}
             
    />
</Box>
    <Box sx={{    display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center", 
       marginTop:{xs:"3vh",md:"-5vh"},
      // maxWidth: "1200px", 
      // width: "100%", 
   
       marginLeft: { md: "22%" } 
      
      }}>
     <RadioSelect /> 
    </Box>
  </Box>
</Grid>
        {/* <Grid item lg={12} md={12}> */}
          
          {/* <Box sx={{ position: "relative", backgroundColor: "transparent" }}>
            <AutoPlaySwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
              interval={5000}
            >
              {images.map((step, index) => (
                <Box
                  // key={step.label}
                  key={index}
                  component="div"
                  sx={{
                    position: "relative",
                    height: "auto",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  
                  {Math.abs(activeStep - index) <= 2 && (
                    <>
                      <img
                        src={step.imgPath}
                        alt={step.data}
                        style={{
                          width: "100%",
                          height: isMobile ? "70vh" : "60vh",
                        }}
                      />
                      <Paper
                        square
                        elevation={0}
                        sx={{
                          position: "absolute",
                          bottom: { xs: 380, md: 260 },
                          left: { xs: 20, md: 110 },
                          right: 0,
                          background: "transparent",
                          textAlign: "left",
                          padding: 1,
                          color: "common.white",
                          width: { xs: "300px", md: "450px" },
                          height: "100px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Inter",
                            fontSize: { xs: "25px", md: "38px" },
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: { md: "50px" },
                            marginBottom: { md: "20px" },
                          }}
                        >
                          {step.label}
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: "Inter",
                            fontSize: { xs: "12px", md: "18px" },
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                          }}
                        >
                          {step.data}
                        </Typography>
                      </Paper>
                    </>
                  )}
                </Box>
              ))}
            </AutoPlaySwipeableViews>

            <MobileStepper
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              sx={{
                width: "100%",
                position: "absolute",
                bottom: "10px",
                left: 0,
                right: 0,
                margin: "auto",
                backgroundColor: "transparent",
                "& .MuiMobileStepper-dots": {
                  justifyContent: "center",
                  marginLeft: "auto",
                  marginRight: "auto",
                  gap: "10px",
                },
              }}
              variant="dots"
              backButton={<></>}
              nextButton={<></>}
            />

            <MobileStepper
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              sx={{
                width: "100%",
                position: "absolute",
              
                bottom: {xs:"250px",md:"190px"},
                left: 0,
                right: 0,
                margin: "auto",
                background: "transparent",
                "& .MuiMobileStepper-dots": {
                  display: "none",
                },
                "& .MuiMobileStepper-root": {
                  display: "flex",
                  justifyContent: "space-between",
                },
              }}
              nextButton={
                <Button
                  size="large"
                  onClick={handleNext}
                  disabled={activeStep === maxSteps - 1}
                  sx={{
                    fontSize: "20rem",
                    fontWeight: "bolder",
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                >
                  {theme.direction === "rtl" ? (
                    <Avatar sx={{ backgroundColor: "white", color: "black" }}>
                      <KeyboardArrowLeftRoundedIcon />
                    </Avatar>
                  ) : (
                    <Avatar sx={{ backgroundColor: "white", color: "black" }}>
                      <KeyboardArrowRightRoundedIcon />
                    </Avatar>
                  )}
                </Button>
              }
              backButton={
                <Button
                  size="large"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                  sx={{
                    fontSize: "20rem",
                    fontWeight: "bolder",
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                >
                  {theme.direction === "rtl" ? (
                    <Avatar sx={{ backgroundColor: "white", color: "black" }}>
                      <KeyboardArrowRightRoundedIcon />
                    </Avatar>
                  ) : (
                    <Avatar sx={{ backgroundColor: "white", color: "black" }}>
                      <KeyboardArrowLeftRoundedIcon />
                    </Avatar>
                  )}
                </Button>
              }
            />

            <Box
              sx={{
                position: "absolute",
                bottom: { xs: 0, md: 30 },
                width: "100%",
                display: "flex",
                left: { md: "90px" },
                justifyContent: "flex-start",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <RadioSelect />
            </Box>
          </Box> */}
        {/* </Grid> */}
    

      {/* <Grid
        container
        sx={{
          
          backgroundColor: "white",
         
        }}
      >
        
        <Typography
          align="justify"
          // variant={{md:"h3"}}
          sx={{
            ml: { md: "90px" },
            p: { xs: "20px 20px 20px 20px", md: "20px" },
            color: "#414A3E",
            fontFamily: "Inter",
            fontSize: { xs: "20px", md: "24px" },
            fontStyle: "normal",
            fontWeight: 300,
            lineHeight: { xs: "25px", md: "50px" },
          }}
        >
          Built by doctors and scientists for the people. We never sell your
          DATA or use it for advertising!
        </Typography>
       
        <Grid
          container
        
          justifyContent="center"
          alignItems="center"
          spacing={{ xs: 1, md: 3 }}
        >
          <Grid
            item
            xs={12}
            md={3.4}
            display={"flex"}
            justifyContent={"center"}
            marginLeft={{ xs: "20px", md: "0" }}
            padding={{ xs: "20px", md: "0" }}
          >
            <Card
              raised
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
               
                backgroundColor: "custom.customColor10",
              
              }}
            >
              <CardMedia
               
                component="img"
                image={img4}
                title="payer dashboard"
              />
              <CardContent>
                <Typography
                  // variant="h6"
                  color="textSecondary"
                  sx={{
                    color: "#000",
                    fontFamily: "Inter",
                    fontSize: "22px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal",
                    marginBottom: "10px",
                  }}
                >
                  Patients
                </Typography>
                <Typography
                  
                  color="textSecondary"
                  align="justify"
                  sx={{
                 
                    color: "#000",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                  }}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            md={3.4}
            display={"flex"}
            justifyContent={"center"}
            marginLeft={{ xs: "20px", md: "0" }}
            padding={{ xs: "20px", md: "0" }}
          >
            <Card
              raised
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                
                backgroundColor: "custom.customColor10",
               
              }}
            >
              <CardMedia
             
                component="img"
                image={img5}
                title="payer dashboard"
              />
              <CardContent>
                <Typography
                  // variant="h6"
                  color="textSecondary"
                  sx={{
                    color: "#000",
                    fontFamily: "Inter",
                    fontSize: "22px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal",
                    marginBottom: "10px",
                  }}
                >
                  Employers
                </Typography>
                <Typography
                 
                  color="textSecondary"
                  align="justify"
                  sx={{
                   
                    color: "#000",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                  }}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            md={3.4}
            display={"flex"}
            justifyContent={"center"}
            marginLeft={{ xs: "20px", md: "0" }}
            padding={{ xs: "20px", md: "0" }}
          >
            <Card
              raised
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                
                backgroundColor: "custom.customColor10",
                
              }}
            >
              <CardMedia
                
                component="img"
                image={img6}
                title="payer dashboard"
              />
              <CardContent>
                <Typography
                 
                  color="textSecondary"
                  sx={{
                    color: "#000",
                    fontFamily: "Inter",
                    fontSize: "22px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal",
                    marginBottom: "10px",
                  }}
                >
                  Healthcare Providers
                </Typography>
                <Typography
                  
                  color="textSecondary"
                  align="justify"
                  sx={{
                    
                    color: "#000",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                  }}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid> */}

      {/* <Grid
        container
        sx={{
          backgroundColor: "white",
          m: { md: "40px 0 40px 0" },
          textAlign: { xs: "center", md: "left" },
          paddingLeft: { xs: "20px", md: "0px" },
        }}
      >
        <Typography
          
          sx={{
            color: "#000",
            fontFamily: "Inter",
            fontSize: { xs: "25px", md: "36px" },
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
            textTransform: "capitalize",
            m: { md: "20px 0 20px 90px" },
            p: { xs: "10px 10px 20px 0", md: "20px" },
          }}
        >
          Publish your cash prices
        </Typography>

        <Grid
          container
         
          display="flex"
          justifyContent="center"
          alignItems="center"
          spacing={{ xs: 1, md: 1 }}
        >
         
          <Grid
            item
            xs={12}
            md={2.53}
            display={"flex"}
            justifyContent={"center"}
            marginLeft={{ xs: "0", md: "0" }}
            padding={{ xs: "20px", md: "0px" }}
          >
            <Link
              to="/provider/login"
              state={{ data: data }}
              style={{ textDecoration: "none" }}
             
            >
              <Card
                raised
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: "custom.customColor10",
                  p: { md: "20px" },
                }}
              >
                <CardMedia
                  sx={{
                    width: { xs: "100%", md: "200px" },
                    height: { xs: "100%", md: "100px" },
                  }}
                  component="img"
                  image={img7}
                  title="payer dashboard"
                />
                <CardContent>
                  <Typography
                    // variant="h6"
                    color="textSecondary"
                    sx={{
                      color: "#000",
                      fontFamily: "Inter",
                      fontSize: "22px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "normal",
                      marginBottom: "10px",
                    }}
                  >
                    Urgent Care
                  </Typography>
                  <Typography
                 
                    color="textSecondary"
                    align="justify"
                    sx={{
                     
                      color: "#000",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                    }}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                  </Typography>
                </CardContent>
                <Buttoncomponent 
                className={classes.homeReg}
                  type="submit"
                  size="large"
                  variant="contained"
                  sx={{
                    
                    margin: "1vh",
                    marginBottom: { xs: "20px" },
                   
                  }}

               
                >
                  Register Here
                </Buttoncomponent>
              </Card>
            </Link>
          </Grid>
          <Grid
            item
            xs={12}
            md={2.53}
            display={"flex"}
            justifyContent={"center"}
            marginLeft={{ xs: 0, md: "0" }}
            padding={{ xs: "20px", md: "0px" }}
          >
            <Link
              style={{ textDecoration: "none" }}
              to="/provider/login"
              state={{ data: data1 }}
            >
              <Card
                raised
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: "custom.customColor10",
                  p: { md: "20px" },
                }}
              >
                <CardMedia
                  sx={{
                    width: { xs: "100%", md: "200px" },
                    height: { xs: "100%", md: "100px" },
                  }}
                  component="img"
                  image={img8}
                  title="payer dashboard"
                />
                <CardContent>
                  <Typography
              
                    color="textSecondary"
                    sx={{
                      color: "common.black",
                      fontFamily: "Inter",
                      fontSize: "22px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "normal",
                      marginBottom: "10px",
                    }}
                  >
                    Imaging
                  </Typography>
                  <Typography
                    // variant="h6"
                    color="textSecondary"
                    align="justify"
                    sx={{
                    
                      color: "#000",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                    }}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                  </Typography>
                </CardContent>
                <Buttoncomponent 
                className={classes.homeReg}
                  type="submit"
                  size="large"
                  variant="contained"
                  sx={{
                    
                    margin: "1vh",
                    marginBottom: { xs: "20px" },
                    
                  }}

                 
                >
                  Register Here
                </Buttoncomponent>
              </Card>
            </Link>
          </Grid>
          <Grid
            item
            xs={12}
            md={2.53}
            display={"flex"}
            justifyContent={"center"}
            marginLeft={{ xs: 0, md: "0" }}
            padding={{ xs: "20px", md: "0" }}
          >
            <Link
              style={{ textDecoration: "none" }}
              to="/provider/login"
              state={{ data: data2 }}
            >
              <Card
                raised
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: "custom.customColor10",
                  p: { md: "20px" },
                }}
              >
                <CardMedia
                  sx={{
                    width: { xs: "100%", md: "200px" },
                    height: { xs: "100%", md: "100px" },
                  }}
                  component="img"
                  image={img9}
                  title="payer dashboard"
                />
                <CardContent>
                  <Typography
                    // variant="h6"
                    color="textSecondary"
                    sx={{
                      color: "common.black",
                      fontFamily: "Inter",
                      fontSize: "22px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "normal",
                      marginBottom: "10px",
                    }}
                  >
                    Dentist
                  </Typography>
                  <Typography
                    // variant="h6"
                    color="textSecondary"
                    align="justify"
                    sx={{
                      // textAlign: "left",
                      // fontSize: { xs: "1 rem", md: "1.2rem" },
                      color: "common.black",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                    }}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                  </Typography>
                </CardContent>
                <Buttoncomponent 
                className={classes.homeReg}
                  type="submit"
                  size="large"
                  variant="contained"
                  sx={{
                   
                    margin: "1vh",
                    marginBottom: { xs: "20px" },
                    
                  }}

            
                >
                  Register Here
                </Buttoncomponent>
              </Card>
            </Link>
          </Grid>
          <Grid
            item
            xs={12}
            md={2.53}
            display={"flex"}
            justifyContent={"center"}
            marginLeft={{ xs: 0, md: "0" }}
            padding={{ xs: "20px", md: "0" }}
          >
            <Link
              style={{ textDecoration: "none" }}
              to="/provider/login"
              state={{ data: data3 }}
            >
              <Card
                raised
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: "custom.customColor10",
                  p: { md: "20px" },
                }}
              >
                <CardMedia
                  sx={{
                    width: { xs: "100%", md: "200px" },
                    height: { xs: "100%", md: "100px" },
                  }}
                  component="img"
                  image={img7}
                  title="payer dashboard"
                />
                <CardContent>
                  <Typography
    
                    color="textSecondary"
                    sx={{
                      color: "common.black",
                      fontFamily: "Inter",
                      fontSize: "22px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "normal",
                      marginBottom: "10px",
                    }}
                  >
                    Others
                  </Typography>
                  <Typography
               
                    color="textSecondary"
                    align="justify"
                    sx={{
                     
                      color: "common.black",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                    }}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                  </Typography>
                </CardContent>
                <Buttoncomponent className={classes.homeReg}
                  type="submit"
                  size="large"
                  variant="contained"
                  sx={{
                    
                    margin: "1vh",
                    marginBottom: { xs: "20px" },
                    
                  }}

              
                >
                  Register Here
                </Buttoncomponent>
              </Card>
            </Link>
          </Grid>
         
        </Grid>
      </Grid> */}



      {/* <Grid
        container
        sx={{
          backgroundColor: "custom.customColor5",
          m: { md: "40px 0 40px 0" },
          textAlign: { xs: "center", md: "left" },
          paddingLeft: { xs: "20px", md: "0px" },
        }}
      > */}
        {/* <Grid container> */}

        {/* <Typography
          // variant="h2"
          sx={{
            color: "#FFF",
            fontFamily: "Inter",
            fontSize: { xs: "25px", md: "36px" },
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
            textTransform: "capitalize",
            // m: { xs: "25px", md: "20px 0 20px 150px" },
            // p: { xs: "0 20px 0 20px" },
            m: { md: "20px 0 20px 90px" },
            p: { xs: "10px 10px 20px 0", md: "20px" },
          }}
        >
          Treatment by Best Hospitals
        </Typography> */}

        {/* <Grid
          container
          display={"flex"}
          // direction={{ md: "row" }}
          // flexWrap={"wrap"}
          justifyContent="center"
          alignItems="center"
          spacing={{ xs: 1, md: 3 }}
          padding={{ md: "10px" }}

          // spacing={3}
          // paddingLeft={"15vh"}
          // paddingRight={"15vh"}
        >
          <Grid
            item
            xs={12}
            md={3.4}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            marginLeft={{ xs: "0", md: "0" }}
            padding={{ xs: "20px", md: "0" }}
          >
            <Card
              raised
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "custom.customColor5",
              }}
            >
              <CardMedia
                sx={{ width: "100%", height: "100%" }}
                component="img"
                image={hos4}
                title="payer dashboard"
              />
              <CardContent>
                <Typography
                  // variant="h6"

                  sx={{
                    color: "#FFF",
                    fontFamily: "Inter",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal",
                    marginBottom: "10px",
                  }}
                >
                  Bascom Palmer Anne Bates<br></br>
                  <StarRateIcon sx={{ color: "yellow" }} />
                  <StarRateIcon sx={{ color: "yellow" }} />
                  <StarRateIcon sx={{ color: "yellow" }} />
                  <StarRateIcon sx={{ color: "yellow" }} />
                  <StarRateIcon sx={{ color: "yellow" }} />
                </Typography>
                <Typography
                  // variant="h6"
                  align="justify"
                  sx={{
                    // textAlign: "left",
                    // fontSize: { xs: "1 rem", md: "1.2rem" },
                    color: "#FFF",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                  }}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            md={3.4}
            justifyContent={"center"}
            alignItems={"center"}
            marginLeft={{ xs: "0", md: "0" }}
            padding={{ xs: "20px", md: "0" }}
          >
            <Card
              raised
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "custom.customColor5",
              }}
            >
              <CardMedia
                sx={{ width: "100%", height: "100%" }}
                component="img"
                image={hos5}
                title="payer dashboard"
              />
              <CardContent>
                <Typography
                  // variant="h6"

                  sx={{
                    color: "#FFF",
                    fontFamily: "Inter",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal",
                    marginBottom: "10px",
                  }}
                >
                  California Pacific Medical Center<br></br>
                  <StarRateIcon sx={{ color: "yellow" }} />
                  <StarRateIcon sx={{ color: "yellow" }} />
                  <StarRateIcon sx={{ color: "yellow" }} />
                  <StarRateIcon sx={{ color: "yellow" }} />
                  <StarRateIcon sx={{ color: "yellow" }} />
                </Typography>
                <Typography
                  // variant="h6"
                  color="white"
                  align="justify"
                  sx={{
                    // textAlign: "left",
                    // fontSize: { xs: "1 rem", md: "1.2rem" },
                    color: "#FFF",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                  }}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            md={3.4}
            justifyContent={"center"}
            alignItems={"center"}
            marginLeft={{ xs: "0", md: "0" }}
            padding={{ xs: "20px", md: "0" }}
          >
            <Card
              raised
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "custom.customColor5",
              }}
            >
              <CardMedia
                sx={{ width: "100%", height: "100%" }}
                component="img"
                image={hos3}
                title="payer dashboard"
              />
              <CardContent>
                <Typography
                  // variant="h6"

                  sx={{
                    color: "#FFF",
                    fontFamily: "Inter",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal",
                    marginBottom: "10px",
                  }}
                >
                  HealthSouth Doctor's Hospital<br></br>
                  <StarRateIcon sx={{ color: "yellow" }} />
                  <StarRateIcon sx={{ color: "yellow" }} />
                  <StarRateIcon sx={{ color: "yellow" }} />
                  <StarRateIcon sx={{ color: "yellow" }} />
                  <StarRateIcon sx={{ color: "yellow" }} />
                </Typography>
                <Typography
                  // variant="h6"
                  color="white"
                  align="justify"
                  sx={{
                    // textAlign: "left",
                    // fontSize: { xs: "1 rem", md: "1.2rem" },
                    color: "#FFF",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                  }}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid> */}
        {/* <Grid
          container
          display={"flex"}
          // flexWrap={"wrap"}
          // justifyContent="center"
          // alignItems="center"
          justifyContent="center"
          alignItems="center"
          spacing={{ xs: 1, md: 3 }}
          padding={{ md: "10px" }}
          // paddingLeft={"15vh"}
          // paddingRight={"15vh"}
        >
          <Grid
            item
            xs={12}
            md={3.4}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            marginLeft={{ xs: "0", md: "0" }}
            padding={{ xs: "20px", md: "0" }}
          >
            <Card
              raised
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "custom.customColor5",
              }}
            >
              <CardMedia
                sx={{ width: "100%", height: "100%" }}
                component="img"
                image={hos4}
                title="payer dashboard"
              />
              <CardContent>
                <Typography
                  // variant="h6"

                  sx={{
                    color: "#FFF",
                    fontFamily: "Inter",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal",
                    marginBottom: "10px",
                  }}
                >
                  Bascom Palmer Anne Bates<br></br>
                  <StarRateIcon sx={{ color: "yellow" }} />
                  <StarRateIcon sx={{ color: "yellow" }} />
                  <StarRateIcon sx={{ color: "yellow" }} />
                  <StarRateIcon sx={{ color: "yellow" }} />
                  <StarRateIcon sx={{ color: "yellow" }} />
                </Typography>
                <Typography
                  // variant="h6"
                  align="justify"
                  sx={{
                    // textAlign: "left",
                    // fontSize: { xs: "1 rem", md: "1.2rem" },
                    color: "#FFF",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                  }}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            md={3.4}
            justifyContent={"center"}
            alignItems={"center"}
            marginLeft={{ xs: "0", md: "0" }}
            padding={{ xs: "20px", md: "0" }}
          >
            <Card
              raised
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "custom.customColor5",
              }}
            >
              <CardMedia
                sx={{ width: "100%", height: "100%" }}
                component="img"
                image={hos5}
                title="payer dashboard"
              />
              <CardContent>
                <Typography
                  // variant="h6"

                  sx={{
                    color: "#FFF",
                    fontFamily: "Inter",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal",
                    marginBottom: "10px",
                  }}
                >
                  California Pacific Medical Center<br></br>
                  <StarRateIcon sx={{ color: "yellow" }} />
                  <StarRateIcon sx={{ color: "yellow" }} />
                  <StarRateIcon sx={{ color: "yellow" }} />
                  <StarRateIcon sx={{ color: "yellow" }} />
                  <StarRateIcon sx={{ color: "lightgrey" }} />
                </Typography>
                <Typography
                  // variant="h6"
                  color="white"
                  align="justify"
                  sx={{
                    // textAlign: "left",
                    // fontSize: { xs: "1 rem", md: "1.2rem" },
                    color: "#FFF",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                  }}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            md={3.4}
            justifyContent={"center"}
            alignItems={"center"}
            marginLeft={{ xs: "0px", md: "0" }}
            padding={{ xs: "20px", md: "0" }}
          >
            <Card
              raised
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "custom.customColor5",
              }}
            >
              <CardMedia
                sx={{ width: "100%", height: "100%" }}
                component="img"
                image={hos6}
                title="payer dashboard"
              />
              <CardContent>
                <Typography
                  // variant="h6"

                  sx={{
                    color: "#FFF",
                    fontFamily: "Inter",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal",
                    marginBottom: "10px",
                  }}
                >
                  HealthSouth Doctor's Hospital<br></br>
                  <StarRateIcon sx={{ color: "yellow" }} />
                  <StarRateIcon sx={{ color: "yellow" }} />
                  <StarRateIcon sx={{ color: "yellow" }} />
                  <StarRateIcon sx={{ color: "lightgrey" }} />
                  <StarRateIcon sx={{ color: "lightgrey" }} />
                </Typography>
                <Typography
                  // variant="h6"
                  color="white"
                  align="justify"
                  sx={{
                    // textAlign: "left",
                    // fontSize: { xs: "1 rem", md: "1.2rem" },
                    color: "#FFF",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                  }}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid> */}
        {/* </Grid> */}
      {/* </Grid> */}

      {/* <Grid
        container
        sx={{
          backgroundColor: "white",
          m: { md: "40px 0 40px 0" },
          textAlign: { xs: "center", md: "left" },
          paddingLeft: { xs: "20px", md: "0px" },
        }}
      > */}
        {/* <Grid
            container
            direction="column"
            // justifyContent="center"
            // alignItems="center"
            item
            // sx={{ mb: "20px" }}
          > */}
        {/* <Typography
          // variant="h2"
          sx={{
            color: "#000",
            fontFamily: "Inter",
            fontSize: { xs: "25px", md: "36px" },
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
            textTransform: "capitalize",
            // m: { xs: "5px", md: "20px 0 20px 150px" },
            // p: { xs: "20px 20px 20px 20px" },
            m: { md: "20px 0 20px 95px" },
            p: { xs: "10px 10px 20px 0", md: "20px" },
          }}
        >
          Meet Our Happy Customers
        </Typography> */}
        {/* </Grid> */}
        {/* <Grid
          container
          display="flex"
          justifyContent="center"
          alignItems="center"
          spacing={{ xs: 1, md: 1 }}
        >
          <Grid
            item
            xs={12}
            md={2.53}
            display={"flex"}
            justifyContent={"center"}
            marginLeft={{ xs: "0", md: "0" }}
            padding={{ xs: "20px", md: "0" }}
          >
            <Card
              raised
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "custom.customColor10",

                // ,width:"18em"
              }}
            >
              <CardMedia
                sx={{ width: { xs: "100%" }, height: { xs: "100%" } }}
                component="img"
                image={cust4}
                title="payer dashboard"
              />
              <CardContent>
                <Typography
                  // variant="h6"
                  color="textSecondary"
                  sx={{
                    color: "#000",
                    fontFamily: "Inter",
                    fontSize: "22px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal",
                    marginBottom: "10px",
                  }}
                >
                  XXXXXXXXXX XXXXX <br></br> XXXXXXXXXX
                </Typography>
                <Typography
                  // variant="h6"
                  color="textSecondary"
                  align="justify"
                  sx={{
                    // textAlign: "left",
                    // fontSize: { xs: "1 rem", md: "1.2rem" },
                    color: "#000",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                  }}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            md={2.53}
            display={"flex"}
            justifyContent={"center"}
            marginLeft={{ xs: "0", md: "0" }}
            padding={{ xs: "20px", md: "0" }}
          >
            <Card
              raised
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "custom.customColor10",
              }}
            >
              <CardMedia
                sx={{ width: "100%", height: "100%" }}
                component="img"
                image={cust2}
                title="payer dashboard"
              />
              <CardContent>
                <Typography
                  // variant="h6"
                  color="textSecondary"
                  sx={{
                    color: "#000",
                    fontFamily: "Inter",
                    fontSize: "22px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal",
                    marginBottom: "10px",
                  }}
                >
                  XXXXXXXXXX XXXXX <br></br> XXXXXXXXXX
                </Typography>
                <Typography
                  // variant="h6"
                  color="textSecondary"
                  align="justify"
                  sx={{
                    // textAlign: "left",
                    // fontSize: { xs: "1 rem", md: "1.2rem" },
                    color: "#000",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                  }}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            md={2.53}
            display={"flex"}
            justifyContent={"center"}
            marginLeft={{ xs: "0", md: "0" }}
            padding={{ xs: "20px", md: "0" }}
          >
            <Card
              raised
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "custom.customColor10",
              }}
            >
              <CardMedia
                sx={{ width: "100%", height: "100%" }}
                component="img"
                image={cust3}
                title="payer dashboard"
              />
              <CardContent>
                <Typography
                  // variant="h6"
                  color="textSecondary"
                  sx={{
                    color: "#000",
                    fontFamily: "Inter",
                    fontSize: "22px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal",
                    marginBottom: "10px",
                  }}
                >
                  XXXXXXXXXX XXXXX <br></br> XXXXXXXXXX
                </Typography>
                <Typography
                  // variant="h6"
                  align="justify"
                  sx={{
                    // textAlign: "left",
                    // fontSize: { xs: "1 rem", md: "1.2rem" },
                    color: "#000",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                  }}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            md={2.53}
            display={"flex"}
            justifyContent={"center"}
            marginLeft={{ xs: "0", md: "0" }}
            padding={{ xs: "20px", md: "0" }}
          >
            <Card
              raised
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "custom.customColor10",
              }}
            >
              <CardMedia
                sx={{ width: "100%", height: "100%" }}
                component="img"
                image={cust4}
                title="payer dashboard"
              />
              <CardContent>
                <Typography
                  // variant="h6"
                  color="textSecondary"
                  sx={{
                    color: "#000",
                    fontFamily: "Inter",
                    fontSize: "22px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal",
                    marginBottom: "10px",
                  }}
                >
                  XXXXXXXXXX XXXXX <br></br> XXXXXXXXXX
                </Typography>
                <Typography
                  // variant="h6"
                  color="textSecondary"
                  align="justify"
                  sx={{
                    color: "#000",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                  }}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid> */}
      {/* </Grid> */}
      
      {/* </Grid> */}
      {/* <Grid
        container
        display={"flex"}
        // direction={{ md: "row" }}
        // flexWrap={"wrap"}
        justifyContent="center"
        alignItems="center"
        spacing={{ xs: 1, md: 3 }}
      > */}
        {/* <Grid
          item
          xs={12}
          md={3.4}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: { xs: "20px", md: "0" },
            padding: { xs: "20px", md: "0" },
            marginTop: { xs: "20px", md: "30px" },
            marginBottom: { xs: "20px", md: "30px" },
          }}
        >
          <Typography
            sx={{
              color: "#000",
              fontFamily: "Inter",
              fontSize: "42px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
              mb: "10px",
            }}
          >
            2 million+{" "}
          </Typography>
          <Typography
            // variant="h4"
            align="justify"
            sx={{
              color: "#000",
              textAlign: "center",
              fontFamily: "Inter",
              fontSize: "18px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
            }}
          >
            profiles on hospitals, physicians, health systems, long-term care
            facilities, and other providers.
          </Typography>
        </Grid> */}
        {/* <Grid
          item
          xs={12}
          md={3.4}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: { xs: "20px", md: "0" },
            padding: { xs: "20px", md: "0" },
            marginTop: { xs: "20px", md: "30px" },
            marginBottom: { xs: "20px", md: "30px" },
          }}
        >
          <Typography
            sx={{
              color: "#000",
              fontFamily: "Inter",
              fontSize: "42px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
              mb: "10px",
            }}
          >
            23 billion+{" "}
          </Typography>
          <Typography
            // variant="h4"

            sx={{
              color: "#000",
              textAlign: "center",
              fontFamily: "Inter",
              fontSize: "18px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
            }}
          >
            medical and Rx claims from Medicare, Medicaid, and third-party
            payers and other providers.
          </Typography>
        </Grid> */}
        {/* <Grid
          item
          xs={12}
          md={3.4}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: { xs: "20px", md: "0" },
            padding: { xs: "20px", md: "0" },
            marginTop: { xs: "20px", md: "30px" },
            marginBottom: { xs: "20px", md: "30px" },
          }}
        >
          <Typography
            sx={{
              color: "#000",
              fontFamily: "Inter",
              fontSize: "42px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
              textAlign: "center",
              mb: "10px",
            }}
          >
            Daily updates{" "}
          </Typography>
          <Typography
            // variant="h4"
            sx={{
              color: "#000",
              textAlign: "center",
              fontFamily: "Inter",
              fontSize: "18px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
            }}
          >
            on executive moves, mergers and acquisitions, requests for
            proposals, and certificates of need.
          </Typography>
        </Grid> */}
      {/* </Grid> */}


      
      
    </>
  );
};

export default Providerhomepage;
