import React,{useState,useEffect} from 'react'
import { useAppSelector } from '../../Redux/Hook'
import { axiosPrivate,  } from '../../axios/axios'

import { Buttoncomponent } from '../../Components/Buttoncomp'
import {Box} from "@mui/material"
import {
    DataGrid,
    GridColTypeDef,
    GridValueFormatterParams,
    GridColumns,
    GridRow, 
  
    GridPreProcessEditCellProps,
    

  } from "@mui/x-data-grid";
  import clsx from "clsx";
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'


import useStyles from "../../styles";




const PublishService = () => {

    const navigate=useNavigate()
    const orgID=useAppSelector(state=>state.providerOrganization.orgEditData)
    const viewData=useAppSelector(state=>state.providerServiceView.ViewData)
    const [csvData,setCsvData]=useState<any>([])
    const [pageSize, setPagesize] = useState(5);
    const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [csvEdit, setcsvEdit] = useState([] as any);

    useEffect(()=>{
      axiosPrivate.get(`/pathPricelist/check?file=${viewData.filePath.split("/")[2]}`).then(res=>{
       const resData=res.data
    
     
       setCsvData(resData)
    
  
      })
    },[])

   const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

    const usdPrice: GridColTypeDef = {
      type: "number",
      width: 130,
    
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
          return "";
        }
  
        const valueFormatted = Number(params.value).toLocaleString();
        return `$ ${valueFormatted} `;
      },
      cellClassName: "font-tabular-nums",
    };
 
    const columns: GridColumns = [
      {
        field: "ServiceCode",
        headerName: "Service Code",
        editable: false,
        headerClassName: "super-app-theme--header",
        flex:0.8,
        headerAlign: "center",
  
        minWidth: 100,
      },
      {
        field: "DiagnosisTestorServiceName",
        headerName: "Diagnosis Test/Service Name",
        headerClassName: "super-app-theme--header",
        editable: false,
        flex: 2,
        headerAlign: "center",
  
        minWidth: 350,
      },
     
      {
        field: "Organizationid",
        headerName: "Organization ID",
        editable: false,
        flex:1,
        headerClassName: "super-app-theme--header",
        headerAlign: "center",
        minWidth: 100,
      },
      {
        field: "FacilityName",
        headerName: "Facility Name",
        headerClassName: "super-app-theme--header",
        editable: false,
        flex: 2,
        headerAlign: "center",
      
        minWidth: 100,
      },
      {
        field: "OrganizationPrices",
        headerName: "Organization Prices",
        editable: true,
        headerClassName: "super-app-theme--header",
        flex:1,
        
        headerAlign: "center",
      
        minWidth: 100,
        align: "right",
        preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
          const invalid = !Number(params.props.value);
          return { ...params.props, error: invalid };
        },
        ...usdPrice,
      },
      {
        field: "FacilityNPI",
        headerName: "Facility NPI",
        editable: false,
        headerClassName: "super-app-theme--header",
        flex:1,
        minWidth: 100,
      },
      {
        field: "FacilityPrices",
        headerName: "Facility Prices",
        headerClassName: "super-app-theme--header",
          type:"number",
        editable: true,
        flex: 1,
        minWidth: 100,
        align: "right",
        headerAlign: "center",
        preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
          const invalid = !Number(params.props.value);
          return { ...params.props, error: invalid };
        },
  
        ...usdPrice,
      },

    ];
   

    const onCellEditCommit = async (cellData: any) => {
      const { id, field, value } = cellData;
    
  
      let updatedData = [...csvData];
  
      for (let i = 0; i < updatedData.length; i++) {
          if (updatedData[i].SNo === id) {
             
              updatedData[i][field] = value;
              
              break; // Stop the loop after updating the matching record
          }
      }
  
   
  
      setCsvData(updatedData);
  };


  const classes=useStyles()


  
    function CustomRow(props: any) {
      const { className, index, ...other } = props;
  
      return (
        <GridRow
          index={index}
          className={clsx(className, index % 2 === 0 ? "odd" : "even")}
          {...other}
        />
      );
    }
      const onSubmit = (e: any) => {
        e.preventDefault();
       let datacheck = { csv: csvData,emailData:{orgID:orgID[0].organizationID,file:viewData} };
        axiosPrivate.post(`/service/publishPricelist`, datacheck)
          .then((res) => {
            toast.success(res.data.message)
            navigate("/provider/serviceView/serviceView");
          }).catch(err=>{
            toast.warning(err.message)
          })
      };


  return (
    <Box className={classes.dataBox}
    sx={{
   
      width: 1,
      "& .odd": {
        bgcolor: "white",
      },
      "& .even": {
        bgcolor: "#EDEDED",
      },
    
    }}
  >
  
    
   {csvData.length!==0&& columns.length!==0?
   <>
   <DataGrid className={classes.dataGrid1}
      autoHeight
      rows={csvData}
      columns={columns}
      getRowId={(row: any) => row.SNo}
      pagination={true}
      pageSize={pageSize}
      onPageSizeChange={(newPageSize: number) => setPagesize(newPageSize)}
      rowsPerPageOptions={[5, 10, 20]}
     
      onEditCellPropsChange={(params:any) => {
        const { id, field, props } = params;
        const editedValue = props.value;
        onCellEditCommit({ id, field, value: editedValue });
        
      }}

      components={{ Row: CustomRow }}
    />
     
    <Box sx={{ display: "flex", gap: "1.5rem" }}>
    <Buttoncomponent className={classes.yesButton}
    
            type="submit"
            variant="contained"
            size="large"
            color="primary"
         
            sx={{
              mt: "2vh",
             
              width: {xs:"auto",md:"15vw"},
              textTransform: "capitalize",
           
            }}
            onClick={onSubmit}
          >
            Publish
          </Buttoncomponent>
     
    </Box>
   </>:"loading"} 
    
  </Box>
  )
}

export default PublishService