import React from "react";
import { Button } from "@mui/material";
import { Formik, Form, Field} from "formik";
import { useState, useEffect } from "react";
import * as Yup from "yup";
import {
  TextField,
  Box,
  Typography,
  Grid,
} from "@mui/material";

import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import FormTextField from "../../Components/Textfield";

import { useAppDispatch, useAppSelector } from "../../Redux/Hook";


import EditServiceTabTableView from "./EditServiceTabTableView";
import useStyles from "../../styles"
import {
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  DialogContent,
  DialogActions,
 
} from "@mui/material";

type CloseReason = "backdropClick" | "escapeKeyDown" | "closeButtonClick";
interface DialogProps extends MuiDialogProps {
  onClose: (reason: CloseReason) => void;
}
const Dialog = React.memo(
  ({ title, open, onClose, children, ...props }: DialogProps) => {
    return (
      <MuiDialog
        onClose={(_, reason) => onClose(reason)}
        aria-labelledby="simple-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={props.maxWidth}
        slotProps={{
          backdrop: {
            style: { backgroundColor: "transparent", boxShadow: "none" },
          },
        }}
      >
        <DialogActions>
          <Button onClick={() => onClose("closeButtonClick")} color="primary">
            <CloseOutlinedIcon />
          </Button>
        </DialogActions>
        <DialogContent>{children}</DialogContent>
      </MuiDialog>
    );
  }
);
interface EditInitialValues {
  // Organizationid: string;
  ServiceCode: string;
  DiagnosisTestorServiceName: string;
  FacilityName: string;
  OrganizationPrices?: string;
  FacilityNPI?: string;
  FacilityPrices: string;
}
interface EditServiceTabProps {
  handleCancel: any;
  onUpdateData: any;
}

const EditServiceTab = React.memo(
  ({ handleCancel, onUpdateData }: EditServiceTabProps) => {
  

    const Organizationid = useAppSelector(
      (state) => state.providerOrganization.orgEditData
    );
    const facilityinput = useAppSelector(
      (state) => state.providerService.serviceData
    );
    
    const initialValues: EditInitialValues = {
    
      ServiceCode: facilityinput.ServiceCode,
      DiagnosisTestorServiceName: facilityinput.DiagnosisTestorServiceName,
      OrganizationPrices: facilityinput.OrganizationPrices,
      FacilityNPI: facilityinput.FacilityNPI,
      FacilityName: facilityinput.FacilityName,
      FacilityPrices: facilityinput.FacilityPrices,
    };

    const [openDialog, setOpenDialog] = useState<boolean>(false);

    const handleClose = () => {
      setOpenDialog(false); 
    };
    const handleSave = () => {
      handleCancel(); 
    };
  const classes=useStyles()

    const [editedData, setEditedData] = useState<any>({});

    const onSubmit = (values: EditInitialValues) => {
      try {
   
        setOpenDialog(true);
 
        const servicedata = {
          _id: facilityinput._id,
          Organizationid: Organizationid[0].organizationID,
          ServiceCode: facilityinput.ServiceCode,
          DiagnosisTestorServiceName: facilityinput.DiagnosisTestorServiceName,
          OrganizationPrices: values.OrganizationPrices,
          FacilityNPI: values.FacilityNPI,
          FacilityName: values.FacilityName,
          FacilityPrices: values.FacilityPrices,
        };

    
        setEditedData(servicedata);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    const validationSchema = Yup.object().shape({
      // OrganizationPrices: Yup.string().required("Organization Price is required").matches(/^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/, "Only numbers"),
      FacilityPrices: Yup.string()
        .required("Facility price is required")
        .matches(
          /^(?:0|[1-9]\d*)(?:\.\d+)?$/,
          "Only numbers"
        ),
    });

    return (
      <Box sx={{ padding: "1vh" }}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, resetForm }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className={classes.formHead}
                 
                  >
                    Edit Service Price
                  </Typography>
                </Grid>
                <Grid container spacing={2} sx={{ padding: "1rem" }}>
                  <Grid item xs={12} sm={6}>
               <Typography className={classes.formType} 
                     
                      sx={{
                 
                        mb: "0.5rem",
                      }}
                    >
                      Diagnosis Test/Service Name{" "}
                     
                      <Typography className={classes.serviceInline}
                        display="inline"
                        
                      >
                        (* Readonly)
                      </Typography>
                    </Typography>
                    <Field className ={classes.serviceText}
                      as={TextField}
                      // label="Service Name"
                      name="DiagnosisTestorServiceName"
                      placeholder="Service Name"
                      type="text"
                      fullWidth={true}
                      inputProps={{ readOnly: true }}
                     
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography className={classes.formType}
                      // variant="h6"
                      sx={{
                        // fontFamily: "Inder",
                        // fontSize: 14,
                        // fontWeight: 400,
                        // color: "#686868",
                        mb: "0.5rem",
                      }}
                    >
                      Service Code{" "}
                    
                      <Typography className={classes.serviceInline}
                        display="inline"
                       
                      >
                        (* Readonly)
                      </Typography>
                    </Typography>
                    <Field 
                    className={classes.serviceText}
                      as={TextField}
                    
                      name="ServiceCode"
                      placeholder="Service Code"
                      type="text"
                      fullWidth={true}
                      inputProps={{ readOnly: true }}
                     
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography className={classes.formType}
                      sx={{
                        
                        mb: "0.5rem",
                      }}
                    >
                      Facility NPI{" "}
                    
                      <Typography className={classes.serviceInline}
                        display="inline"
                    
                      >
                        (* Readonly)
                      </Typography>
                    </Typography>
                    <Field 
                    className={classes.serviceText}
                      as={TextField}
                   
                      name="FacilityNPI"
                      placeholder="Facility NPI"
                      type="text"
                      fullWidth={true}
                      inputProps={{ readOnly: true }}
                   
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography className={classes.formType}
                      sx={{
                        
                        mb: "0.5rem",
                      }}
                    >
                      Facility Name{" "}
                     
                      <Typography className={classes.serviceInline}
                        display="inline"
                        
                      >
                        (* Readonly)
                      </Typography>
                    </Typography>
                    <Field className ={classes.serviceText}
                      as={TextField}
                     
                      name="FacilityName"
                      placeholder="Facility Name"
                      type="text"
                      fullWidth={true}
                      inputProps={{ readOnly: true }}
                    
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography  className={classes.formType}
                      sx={{
                       
                        mb: "0.5rem",
                      }}
                    >
                      Organization Prices
                      
                      <Typography className={classes.serviceInline}
                        display="inline"
                      
                      >
                        (* Readonly)
                      </Typography>
                    </Typography>
                    <Field className={classes.serviceText}
                      as={TextField}
                      // label="Organization Prices"
                      name="OrganizationPrices"
                      placeholder="Organization Prices"
                      type="text"
                      fullWidth={true}
                      inputProps={{ readOnly: true }}
                     
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography className={classes.formType}
                      sx={{
                      
                        mb: "0.3rem",
                      }}
                    >
                      Facility Prices{" "}
                      <Typography display="inline" 
                      sx={{ color: "red" }}
                      >
                        *
                      </Typography>
                    </Typography>
                    <FormTextField 
                    className={classes.manualText}
                      container={TextField}
                    
                      name="FacilityPrices"
                      placeholder="Facility Prices"
                      autoComplete="text"
                      type="text"
                      fullWidth={true}
                    
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={10}
                  columnGap={1}
                  justifyContent="left"
                >
                  <Button className={classes.noButton}
                    type="button"
                    onClick={handleCancel}
                    fullWidth={false}
                    variant="contained"
                    sx={{
                      mt: "2vh",
                      
                      width: "10vw",
                    
                      textTransform: "capitalize",
                      
                    }}
                  >
                    Cancel

                  </Button>
                  <Button className={classes.yesButton}
                    type="submit"
                    // onClick={handleCancel}
                    fullWidth={false}
                    variant="contained"
                    sx={{
                      mt: "2vh",
                   
                      width: "10vw",
                      
                      textTransform: "capitalize",
                     
                    }}
                  >
                    Next
                  </Button>

                  <Dialog
                    maxWidth={"xl"}
                    open={openDialog}
                    onClose={handleClose}
                  >
                    <DialogContent style={{ height: "600px" }}>
                      <EditServiceTabTableView
                        data={editedData}
                        onClose={handleClose}
                        onPostSuccess={handleSave}
                        onUpdateData={onUpdateData}
                      />
                    </DialogContent>
                  </Dialog>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    );
  }
);
export default EditServiceTab;
