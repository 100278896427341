import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../Store";
import Cookies from "js-cookie";

interface CounterState {
  orgEditData: any;
  orgEditImage: any;
  orgData: any;
  orgImage: any;
  fileNameorg: any;
  contactInfo: any;
  contactimageInfo: any;
  delegateimageInfo: any;
  tabstatus:Boolean;
  contactstatus:Boolean;
}

const initialState: CounterState = {
  orgEditData: {},
  orgEditImage: "",
  orgData: {},
  orgImage: "",
  fileNameorg: "",
  contactInfo: {},
  contactimageInfo: "",
  delegateimageInfo: "",
  tabstatus:false,
  contactstatus:false,

};

export const editSlice = createSlice({
  name: "edit",

  initialState,
  reducers: {
    organizationEdit: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        orgEditData: action.payload,
      };
    },
    organizationEditupdate: (state, action: PayloadAction<any>) => {
      const newState = {
        ...state,
        orgEditData: [
          ...state.orgEditData,
          action.payload,
        ],
     };
    

     const existingObjectIndex = newState.orgEditData.findIndex(obj => obj.id === action.payload.id);
    
     if (existingObjectIndex !== -1) {
     
        newState.orgEditData[existingObjectIndex] = action.payload;
     }
    
     return newState;
    },
    
    organizationImage: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        orgEditImage: action.payload,
      };
    },
    refrestState: (state) => {
      state.orgEditData = {};
    },
    orgid: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        orgid: action.payload,
      };
    },
    orgInfo: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        orgData: action.payload,
      };
    },
    orgcreateImage: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        orgImage: action.payload,
      };
    },
    orgfileName: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        fileNameorg: action.payload,
      };
    },
    contactInfor: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        contactInfo: action.payload,
      };
    },
    contactimageInfor: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        contactimageInfo: action.payload,
      };
    },
    delegateimageInfor: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        delegateimageInfo: action.payload,
      };
    },
    tabstatuss: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        tabstatus: action.payload,
      };
    },
    contactstatuss: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        contactstatus: action.payload,
      };
    },


  },
});

export const {
  organizationEdit,
  organizationImage,
  refrestState,
  orgid,
  orgInfo,
  orgcreateImage,
  orgfileName,
  contactInfor,
  contactimageInfor,
  delegateimageInfor,
  tabstatuss,
  organizationEditupdate,
  contactstatuss

} = editSlice.actions;


export const organizationReducer = editSlice.reducer;
