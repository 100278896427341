import React from "react";
import { Field, ErrorMessage } from "formik";
import {
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import ErrorProps from "./Errorprops";

interface props {
  name: string;
  placeholder: string;
  type: string;
  fullWidth?: boolean;
  container: any;
  sx?: any;
  inputProps?: any;
  className?:any;
  label?: string;
  multirow?: number;
  multiline?: boolean;
  autoComplete?: any;

  InputLabelProps?: any;
  onFocus?: any;
  onBlur?: any;
}
const FormTextField = (props: props) => {
  return (
    <FormControl className={props.className}
    sx={{ width: "100%" }}>
      <Field
        label={props.label}
        as={props.container}
        sx={props.sx}
        inputProps={{
          sx: props.sx,
        }}
        fullWidth={props.fullWidth}
        name={props.name}
        placeholder={props.placeholder}
        InputLabelProps={props.InputLabelProps}
        rows={props.multirow}
        type={props.type}
        multiline={props.multiline}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        autoComplete={props.autoComplete}

        helperText={
          <ErrorMessage name={props.name}>
            {(error) => <ErrorProps>{error}</ErrorProps>}
          </ErrorMessage>
        }
      />
    </FormControl>
  );
};

export default FormTextField;
