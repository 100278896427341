import  { ReactNode } from "react";
import { Box, CssBaseline, Grid } from "@mui/material";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useLocation } from "react-router-dom";
interface LayoutProps {
  children: ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  const location = useLocation();
  const path = location.pathname.slice(location.pathname.lastIndexOf("/"));

  const hideNavBar =
    location.pathname === "/provider/facility/viewFacility" ||
    location.pathname === "/provider/facility/update" ||
    location.pathname === "/provider/facility/pricelistlanding" ||
    location.pathname === "/provider/facility/pricelistedit" ||
    location.pathname === "/provider/facility/pricelistthrofacility" ||
    location.pathname === "/provider/facility/PricelistUploadthrofacility " ||
    location.pathname === "/provider/service/listService" ||
    location.pathname === "/provider/serviceView/serviceView" ||
    location.pathname === "/provider/serviceView/publishservice";
  return (
    <>
      <CssBaseline />

      <Grid container style={{ minHeight: '90vh' }}>
        {!hideNavBar && (
          <Grid
            item
            xs={12}
            sx={{
              // position: "sticky",
              top: "0px",
              zIndex: 1,
            }}
          >
             <Navbar /> 
          </Grid>
        )}

        <Grid item xs={12} 
        // style={{ flexGrow: 1 }}
        >
          <Box >{children}</Box>
        </Grid>
      </Grid>

      <Grid>
        <Footer />
      </Grid>
    </>
  );
};

export default Layout;
