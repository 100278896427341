import React from "react";
import { TextField, Box, Typography, Grid } from "@mui/material";
import { Formik, Form } from "formik";
import * as yup from "yup";
import axios from "axios";
import login from "../../Images/login.jpg";
import { toast } from "react-toastify";
import {  useNavigate } from "react-router-dom";
import Cookie from "js-cookie";
import { Link } from "@mui/material";
import FormTextField from "../../Components/Textfield";
import { Buttoncomponent } from "../../Components/Buttoncomp";

import { useAppDispatch, useAppSelector } from "../../Redux/Hook";
import {
  loginButton,
  logoutButton,
  storeLoginInfo,
} from "../../Redux/ProviderRedux/LoginSlice";
import { adminAxiosPrivate, axiosPrivate } from "../../axios/axios";
import { adminLoginInfo, adminLogoutButton } from "../../Redux/Admin/adminLogin";

import { Paper, } from "@mui/material";

import { NavLink } from "react-router-dom";

import { useState } from "react";
import { useTheme } from "@mui/material/styles";

import { useLocation } from "react-router-dom";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import useStyles from "../../styles";





import { dataSearchTenMiles } from "../../Redux/ProviderRedux/HomeSlice";
import {
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const images = [
  {
    name: "John Doe",
    quote: "This is a great product! I would highly recommend it to anyone.",
  },
  {
    name: "Jane Doe",
    quote:
      "I've been using this product for a few months now and I'm really happy with it.",
  },
  {
    name: "Bob Smith",
    quote: "This product is amazing! It's changed my life for the better.",
  },
];

const schema = yup.object().shape({
  email: yup
    .string()
    .required("Email is a required field")
    .email("Invalid email"),
  password: yup
    .string()
    .required("password is a required field")
    .min(4, "password must be at least 4 characters"),
});
export default function AdminLogin() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();


  const data = location.state?.data?.Link;
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const [isSignUpSuccessful, setIsSignUpSuccessful] = useState("");
  const [alert, setAlert] = useState(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
 

  const handleModalClose = () => setOpenModal(false);
  type CloseReason = "backdropClick" | "escapeKeyDown" | "closeButtonClick";
  interface DialogProps extends MuiDialogProps {
    onClose: (reason: CloseReason) => void;
  }

  
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        mt: "2rem",
      }}
    >
     <Paper elevation={3} sx={{  padding: "3rem",width:{md:"50%"}}}>
        <Grid container justifyContent={"center"}>
        {/* <Grid
            item
            xs={12}
            md={4}
            sx={{
              backgroundColor: "#f4f4f4",
      
              mt: 2,
              padding: 3,
            }}
          >
            <Typography sx={{ fontSize: "2.5rem", fontWeight: "bold" }}>
              Welcome to CareCadet Family
            </Typography>
            <Typography sx={{ fontSize: "1.5rem" }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.{" "}
            </Typography>

            <Box
              sx={{
                mt: "15vh",
                flexGrow: 1,
                borderRadius: 10,
                padding: 3,
                backgroundColor: "white",
              }}
            >
             
              <AutoPlaySwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
              >
                {images.map((step, index) => (
                  <div key={step.name}>
                    {Math.abs(activeStep - index) <= 2 ? (
                      <Box
                        component="div"
                        sx={{
                          display: "block",
                          overflow: "hidden",
                        }}
                      >
                        {" "}
                        <Typography 
                    
                        fontSize={16}>
                       
                        </Typography>
                        <Box
                          marginTop={4}
                          // paddingLeft={5}     sx={{ display: "flex", flexDirection: "row" }}
                        >
           
                          <Typography fontSize={23} 
                          marginLeft={2}
                          >
                        
                          </Typography>
                        </Box>
                      </Box>
                    ) : // src={step.quote}
                    // alt={step.name}

                    null}
                  </div>
                ))}
              </AutoPlaySwipeableViews>
            </Box>
            <Box sx={{ backgroundColor: "#f4f4f4" }}>
              <MobileStepper
                sx={{ backgroundColor: "#f4f4f4", fontSize: "2vh" }}
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                  <Button
                    size="large"
                    onClick={handleNext}
                    disabled={activeStep === maxSteps - 1}
                  >
                    Next
                    {theme.direction === "rtl" ? (
                      <KeyboardArrowLeft />
                    ) : (
                      <KeyboardArrowRight />
                    )}
                  </Button>
                }
                backButton={
                  <Button
                    size="large"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                  >
                    {theme.direction === "rtl" ? (
                      <KeyboardArrowRight />
                    ) : (
                      <KeyboardArrowLeft />
                    )}
                    Back
                  </Button>
                }
              />
            </Box>
          </Grid> */}
 
             <Grid
            item
            md={8}
            sx={{ display: "flex", justifyContent: "center", mt: "2rem" }}
          >
          <Formik
            validationSchema={schema}
            initialValues={{
              email: "",
              password: "",
              userType: "ADMIN",
            }}
            onSubmit={(values:any) => {
              // alert(JSON.stringify(values));
              const Logindata = {
                userName: values.email,
                password: values.password,
                userType: values.userType,
              };
          
              dispatch(logoutButton())
              adminAxiosPrivate
                .post("/admin/adminlogin", Logindata)
                .then((res) => {
                  
                  toast.success(res.data.message);
       
                  dispatch(adminLoginInfo(res.data.data));
         
                  navigate("/admin");
                })
                .catch((err) => {
                  toast.warning(err.message)
                  
                    setIsLoading(false);
              
                });
            }}
          >
            <Form>
       
                 <Typography className={classes.h1}
                
                  >
                Welcome Admin
              </Typography>
              <Grid>
                    <Typography  className={classes.h2}
                
                    >
                      Sign In
                    </Typography>
                  </Grid>
              <Grid>
              <Typography className={classes.h2}
            
                    >
                  Enter your email and password to login
                </Typography>
              </Grid>

           
              <Typography className ={`${classes.h6} ${classes.h6WithLargeMargin}`}
                    
                    > Email
                </Typography>

                <FormTextField
                 className ={classes.signuptext}
                  container={TextField}
                  name="email"
                  placeholder="email"
                  type="email"
                
                />
            
              
                  <Typography className ={classes.h6}
                 
                >
                  Password
                </Typography>
                <FormTextField className ={classes.signuptext}
                  container={TextField}
                  name="password"
                  placeholder="password"
                  type="password"
                 
                />
              
              <Grid item >
                <Buttoncomponent
                 className={`${classes.signupbutton} ${classes.h6WithLargeMargin}`}
                  type="submit"
                  size="large"
                  fullWidth
                  variant="contained"
                
                >
                  Login
                </Buttoncomponent>
              </Grid>
           
              <Typography className={`${classes.h3} ${classes.h6WithLargeMargin}`}
                        sx={{textAlign:"right"}}                         
                 >
                    <Link
                      sx={{ textDecoration: "none", }}
                      to="/provider/forgotpass"
                      component={NavLink}
                    >
                      Forgot password?
                    </Link>
                  </Typography>
                  <Divider
                    component="div"
                    variant="fullWidth"
                    sx={{ marginTop: "2vh" }}
                  />
                
            </Form>
          </Formik>
       
      
      </Grid>
      </Grid>
      </Paper>
    </Box>
  );
}
