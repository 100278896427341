//pages

import Contact from "./Pages/Contact/Contact";

// other
import { FC } from "react";
import CreateOrganization from "./Pages/Organization/CreateOrganization";
import ContactPersonInformation from "./Pages/Organization/ContactPersonInformation";

import CreateFacility from "./Pages/Facility/CreateFacility";
import UpdateFacility from "./Pages/Facility/EditFacility";
import ViewFacility from "./Pages/Facility/ViewFacility";
import Pricelist from "./Pages/Services/pricelist";
import PricelistEditpage from "./Pages/Services/pricelisteditpage";
import Pricelistlandingpage from "./Pages/Services/pricelistlandingpagewithoutactions";
import Servicelandingpage from "./Pages/Services/servicelandpage";

import PricelistthroFacility from "./Pages/Services/pricelistthrofacilitytabview";
import PricelistUploadthroFacility from "./Pages/Services/PricelistUploadthrofacilitytab1";

import Login from "./Pages/LoginSignup/Login";
import Signup from "./Pages/LoginSignup/Signup";
import Forgotpass from "./Pages/LoginSignup/Forgotpass";
import Resetpass from "./Pages/LoginSignup/Resetpass";


import Providerhomepage from "./Pages/Home/providerhomepage";


import LoginPatient from "./Pages/LoginSignup/LoginPatient";
import SignupPatient from "./Pages/LoginSignup/SignupPatient";
import ServiceView from "./Pages/serviceView/ServiceView";
import PublishService from "./Pages/serviceView/PublishService";
import Search from "./Pages/Home/Search";
import Admin from "./Pages/Admin/Admin";
import AdminLogin from "./Pages/Admin/LoginAdmin";
import AdminSignup from "./Pages/Admin/AdminSignup";
import OrganizationLandingView from "./Pages/Organization/OrganizationLandingView";
import Providersearch from "./Pages/Home/Providersearch";
import EditOrganization from "./Pages/Organization/EditOrganization";
interface Route {
  key: string;
  title?: string;
  path: string;
  enabled: boolean;
  component: FC<{}>;
  color?: string;
}

export const navRoutes: Array<Route> = [
 
  {
    key: "services",
    title: "Services",
    path: "/services",
    color: "services",
    enabled: true,
    component: Providerhomepage,
  },
  {
    key: "contact",
    title: "Contact",
    path: "/contact",
    color: "contact",
    enabled: true,
    component: Contact,
  },
 
  
];

//**************************Provider Router***************************************************

export const providerLogin: Array<Route> = [
 
  {
    key: "login",
    title: "login",
    path: "/provider/login",
    enabled: true,
    component: Login,
  },
  {
    key: "signup",
    title: "Signup",
    path: "/provider/signup",
    enabled: true,
    component: Signup,
  },
  
  {
    key: "Forgotpass",
    title: "Forgotpass",
    path: "/provider/forgotpass",
    enabled: true,
    component: Forgotpass,
  },

  {
    key: "Resetpass",
    title: "Resetpass",
    path: "/provider/resetpass",
    enabled: true,
    component: Resetpass,
  }

 
];

export const providerRoutespages: Array<Route> = [
 
  {
    key: "org",
    title: "Org",
    path: "/provider/organizationCreate",
    enabled: true,
    component: CreateOrganization,
  },
  {
    key: "org",
    title: "Org",
    path: "/provider/contactPersonInformation",
    enabled: true,
    component: ContactPersonInformation,
  },
  
  {
    key: "serviceView",
    title: "serviceView",
    path: "/provider/serviceView/serviceView",
    enabled: true,
    component: ServiceView,
  },

  {
    key: "publishservice",
    title: "publishservice",
    path: "/provider/serviceView/publishservice",
    enabled: true,
    component: PublishService,
  },
  {
    key: "viewFacility",
    title: "Facility",
    path: "/provider/facility/viewFacility",
    enabled: true,
    component: ViewFacility,
  },
  {
    key: "createFacility",
    title: "Facility",
    path: "/provider/facility/addFacility",
    enabled: true,
    component: CreateFacility,
  },

  {
    key: "pricelist",
    title: "Pricelist",
    path: "/provider/service/pricelist",
    enabled: true,
    component: Pricelist,
  },

  {
    key: "pricelistedit",
    title: "Pricelistedit",
    path: "/provider/facility/pricelistedit",
    enabled: true,
    component: PricelistEditpage,
  },
  {
    key: "pricelistlanding",
    title: "Pricelistlanding",
    path: "/provider/facility/pricelistlanding",
    enabled: true,
    component: Pricelistlandingpage,
  },
  {
    key: "listSevices",
    title: "listSevices",
    path: "/provider/service/listService",
    enabled: true,
    component: Servicelandingpage,
  },
  {
    key: "servicelanding",
    title: "Servicelanding",
    path: "/provider/service/servicelanding",
    enabled: true,
    component: Servicelandingpage,
  },

  {
    key: "pricelistthrofacility",
    title: "Pricelistthrofacility",
    path: "/provider/facility/pricelistthrofacility",
    enabled: true,
    component: PricelistthroFacility,
  },
 
];

//*******************************************Patient Router ****************************************/
export const patientLogin: Array<Route> = [
  
  {
    key: "patitentLogin",
    path: "/patient/login",
    enabled: true,
    component: LoginPatient,
  },
  {
    key: "patientSignup",
    path: "/patient/signup",
    enabled: true,
    component: SignupPatient,
  },
];


// ******************************************CommonPages *****************************************************/
export const commonHome: Array<Route> = [
  
  {
    key: "contact",
    path: "/contact",
    enabled: true,
    component: Contact,
  },
];

export const homePage: Array<Route> = [
 
  {
    key: "providerHome",
    path: "/",
    enabled: true,
    component: Providerhomepage,
  },
  
  {
    key: "services",
    path: "/services",
    enabled: true,
    component: Providerhomepage,
  },
];

export const admin = [

  {
    key: "adminLogin",
    path: "/admin/adminlogin",
    enabled: true,
    component: AdminLogin,
  },
  {
    key: "adminSignup",
    path: "/admin/signup",
    enabled: true,
    component: AdminSignup,
  },
];

export const adminRoute = [
  {
    key: "admin",
    path: "/admin",
    enabled: true,
    component: Admin,
  },
  
];

export const searchPage=[
  {
    key: "providerSearch",
    path: "/provider/search",
    enabled: true,
    component: Providersearch,
  },
  {
    key: "patientSearch",
    path: "/search",
    enabled: true,
    component: Search,
  },
]
