import React, { useState } from "react";

import {
  Box,
  Grid,
  Typography,

  Button,
  
  Divider,

  Menu,
  MenuItem,
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  DialogActions,
  DialogContent,

} from "@mui/material";

import {  baseURL } from "../../axios/axios";
import { useAppDispatch, useAppSelector } from "../../Redux/Hook";
import { Buttoncomponent } from "../../Components/Buttoncomp";

import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {

  organizationImage,
} from "../../Redux/ProviderRedux/orgSlice";

import {  useNavigate } from "react-router-dom";
import EditOrganization from "./EditOrganization";
import PlaceIcon from "@mui/icons-material/Place";
import PhoneIcon from "@mui/icons-material/Phone";

import icon from "../../Images/icon.jpg";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";

import useMediaQuery from "@mui/material/useMediaQuery";


import useStyles from "../../styles";

const OrganizationLandingView = (props: any) => {
  const [popUp, setPopUp] = React.useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const userID = useAppSelector((state) => state.providerAuth.login.userID);
  
  const data = useAppSelector(
    (state) => state.providerOrganization.orgEditData
  );
 
  const [isChecked, setIsChecked] = React.useState(false);
  const [render, setRender] = React.useState<any>(<KeyboardArrowDown />);
  const [orgClick, setorgClick] = React.useState<any>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const typo = "1.2rem";
 

  const isMobile = useMediaQuery("(max-height:720px)");


  const classes = useStyles();

  const formatPhoneNumber = (phoneNumber: any) => {

    const match = phoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
 
    return phoneNumber;
  };



  const orglanClick = (event: any) => {
    setorgClick(event.currentTarget);
    setRender(<KeyboardArrowUp />);
  };
  const orgClose = (event: any) => {
    setorgClick(null);
    setRender(<KeyboardArrowDown />);
  };

  type CloseReason = "backdropClick" | "escapeKeyDown" | "closeButtonClick";
  interface DialogProps extends MuiDialogProps {
    onClose: (reason: CloseReason) => void;
  }

  const Dialog = ({
    title,
    open,
    onClose,
    children,
    ...props
  }: DialogProps) => {
    return (
      <MuiDialog
        onClose={(_, reason) => onClose(reason)}
        aria-labelledby="simple-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={props.maxWidth}
        slotProps={{
          backdrop: {
            style: { backgroundColor: "transparent", boxShadow: "none" },
          },
        }}
      >
        <DialogActions>
          <Button onClick={() => onClose("closeButtonClick")} color="primary">
            <CloseOutlinedIcon />
          </Button>
        </DialogActions>

        <DialogContent>{children}</DialogContent>
      </MuiDialog>
    );
  };

  const [open1, setOpen1] = useState(false);
  const handleClickOpen1 = () => {
    setOpen1(true);
  };
  const handleClose1 = (value: CloseReason) => {
    setOpen1(false);
   
  };

  return (
    <>
      <div>
        <Button
          sx={{
            display: "flex",
            backgroundColor: "#034C81",
            border: "1px solid",
            color: "white",
            width: "21.5rem",
            borderRadius: "9px",
            justifyContent: "flex-start",

            "&:hover": {
              backgroundColor: "#034C81",
              color: "white",
            },
          }}
          onClick={orglanClick}
          endIcon={render}
        >
          <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
            <img
             
              src={data[0].orgImg ? `${baseURL}/organization/image/${data[0].orgImg}` : icon}
              style={{
                width: "33px",
                height: "33px",
                marginRight: "8px",
              }}
            />{" "}
            <Divider
              sx={{
                width: "3px",
                height: "40px",
                backgroundColor: "#ccc",
                marginRight: "8px",
          
              }}
            />
            <Box 
            className={classes.navMenulists}
              sx={{
                
                color: "#FFFFFF",

                backgroundColor: "#034C81",
              }}
            >
              {data[0].organizationName}
            </Box>
          </Box>
        </Button>

        <Menu
          id="simple-menu"
          anchorEl={orgClick}
          keepMounted
          open={Boolean(orgClick)}
          onClose={orgClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          PaperProps={{
            style: {
              maxHeight: "400px",
              overflowY: "auto",
            },
          }}
        >
          <MenuItem>
            <Box sx={{ backgroundColor: "white", padding: "10px" }}>
              <Grid container item justifyContent="right">
                <Buttoncomponent
                  type="submit"
                  size="large"
                  fullWidth={false}
                  onClick={() => {
                    dispatch(organizationImage(data[0].orgImg));

                    handleClickOpen1();
                  }}
                  sx={{
                    backgroundColor: "transparent",
                  }}
                >
                  <BorderColorOutlinedIcon />
                </Buttoncomponent>
              </Grid>
              <Grid item sx={{ mt: "-30px" }}>
                <img
                 src={data[0].orgImg ? `${baseURL}/organization/image/${data[0].orgImg}` : icon}
                  style={{
                    width: "120px",
                    height: "120px",
                    position: "relative",
                    left: "27%",
                    border: "2px solid white",
                    borderRadius: "50%",
                   
                  }}
                />
              </Grid>
              <Typography sx={{ textAlign: "center", color: "#00BABA" }}>
                <PlaceIcon />
              </Typography>

              <Box
                className={classes.orgDetails}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    textTransform: "capitalize",
                    flexDirection: "column",
                  }}
                >
                  {data[0].address.addressLine1.toLocaleLowerCase()}
                </Box>

                <Box
                  sx={{
                    display: "flex",

                    lineHeight: "20px",
                    letterSpacing: "3%",
                    textTransform: "capitalize",

                    flexDirection: "column",
                  }}
                >
                  {data[0].address.addressLine2 === "undefined" ||
                  data[0].address.addressLine2 === "null" ||
                  data[0].address.addressLine2 === null
                    ? null
                    : data[0].address.addressLine2}
                </Box>

                <Box
                  sx={{
                    display: "flex",

                    textTransform: "capitalize",
                  }}
                >
                  {" "}
                  {data[0].address.city.toLocaleLowerCase()},
                  <span>
                    <span
                      style={{
                        marginLeft: "4px",
                        textTransform: "capitalize",
                      }}
                    >
                      {data[0].address.state_code}
                    </span>

                    <span
                      style={{
                        marginLeft: "4px",
                      }}
                    >
                      {data[0].address.zipCode}
                    </span>
                  </span>
                </Box>
              </Box>

              <Box sx={{ display: "flex", flexDirection: "row", mt: "18px" }}>
                <Box
                  sx={{
                    width: "20px",
                    height: "8.30px",
                    flexShrink: "0",
                    transform: "rotate(45deg)",
                    color: "#646363",
                  }}
                >
                  <PhoneIcon />
                </Box>
                <Typography
                  sx={{
                  
                    fontSize: "16px",
                    fontWeight: "medium",
                    color: "#646363",
                    font: "Inter",
                    lineHeight: "20px",
                  }}
                >
                  {data[0].contact}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  backgroundColor: "#E8ECE8",
                  width: "18rem",
                  mt: "30px",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "Bold",
                    fontSize: "14px",
                    letterSpacing: "4%",
                    color: "#000000",
                    font: "Inter",
                    lineHeight: "20px",
                    padding: "10px",
                  }}
                >
                  Contact Person
                </Typography>
              </Box>
              <Typography
                className={classes.orgDarkdetails}
                sx={{ mt: "1rem" }}
              >
                <Box component="span"sx={{ display: "flex" }}>
                  {data[0].contactPerson.firstName}
                  <Box component="span" sx={{ ml: "4px" }}>{data[0].contactPerson.lastName}</Box>
                </Box>
              </Typography>
              <Typography className={classes.orgDetails}>
                {data[0].contactPerson.role
                  .toLowerCase()
                  .replace(/\b\w/g, (char: any) => char.toUpperCase())}
              </Typography>
              <Typography className={classes.orgDetails}>
                Phone: {formatPhoneNumber(data[0].contactPerson.contact)}
              </Typography>
              <Typography className={classes.orgDetails}>
                Email: {data[0].contactPerson.email}
              </Typography>

              <Divider
                sx={{ mt: "0.5rem", backgroundColor: "grey", width: "250px" }}
              />

              <Box sx={{ mt: "0.5rem" }}>
                <Typography className={classes.orgDarkdetails}>
                  Alternate Contact Person
                </Typography>
                <Typography
                  className={classes.orgDarkdetails}
                  sx={{ mt: "0.5rem" }}
                >
                  <Box component="span"sx={{ display: "flex" }}>
                    {data[0].delegateInfo.delegatefirstName}
                    <Box component="span"sx={{ ml: "4px" }}>
                      {data[0].delegateInfo.delegatelastName}
                    </Box>
                  </Box>
                </Typography>

                <Typography className={classes.orgDetails}>
                  {data[0].delegateInfo.delegaterole}
                </Typography>

                <Typography className={classes.orgDetails}>
                  Phone:{" "}
                  {formatPhoneNumber(data[0].delegateInfo.delegatecontactno)}
                </Typography>
                <Typography className={classes.orgDetails}>
                  Email: {data[0].delegateInfo.delegateemail}
                </Typography>
              </Box>
            </Box>
          </MenuItem>
          <MenuItem onClick={props.onClick}>
            <Box onClick={orgClose} sx={props.sx} color={props.color}>
              {props.children}
            </Box>
          </MenuItem>
        </Menu>
        <Dialog
          fullScreen={true}
          fullWidth={true}
          maxWidth={"xl"}
          sx={{
            width: "100%",
          }}
          open={open1}
          onClose={handleClose1}
        >
          <DialogContent>
            <DialogActions>
              <EditOrganization onClose={handleClose1} />
            </DialogActions>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default OrganizationLandingView;
