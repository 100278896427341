import { configureStore } from "@reduxjs/toolkit";

import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

import {loginReducer} from "./ProviderRedux/LoginSlice";
import { facilityReducer } from "./ProviderRedux/facilitySlice";
import {organizationReducer} from "./ProviderRedux/orgSlice";
import { serviceReducer } from "./ProviderRedux/serviceSlice";
import { patientLoginReducer } from "./PatientRedux/patientAuth";
import { serviceViewReducer } from "./ProviderRedux/serviceViewSlice";
import { homeReducer } from "./ProviderRedux/HomeSlice";
import { adminLoginReducer } from "./Admin/adminLogin";


const reducers = combineReducers({
  adminAuth:adminLoginReducer,
  homeReducer:homeReducer,
  providerAuth: loginReducer,
  providerOrganization: organizationReducer,
  providerFacility: facilityReducer,
  providerService: serviceReducer,
  providerServiceView:serviceViewReducer,
  patientAuth:patientLoginReducer

});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);
// ...

export const store = configureStore({
  reducer: persistedReducer,
 
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
      // {
      //   ignoredActions: [PERSIST, REGISTER,PURGE],
      // },
    }),
});


export const persistor = persistStore(store);


export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
