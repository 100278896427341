import { useEffect, useRef, useState } from "react";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Card,
  Typography,
  Container,
  ListItemButton,
  ListItemIcon,
  Collapse,
  ListItemText,
  Checkbox,
  FormControlLabel,
  Divider,
  InputLabel,
  FormControl,
  Radio,
  RadioGroup,
  FormGroup,
  MenuItem,
  Menu,
  Paper,
  Grid,
  TextField,
  Select,
  Slider,
  Pagination,
  CircularProgress,
  Autocomplete,
  AutocompleteRenderInputParams,
  createFilterOptions,
  useMediaQuery,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import LinearProgress from "@mui/material/LinearProgress";
import { Formik, Form, ErrorMessage, Field } from "formik";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
//comp
import { Buttoncomponent } from "../../Components/Buttoncomp";
//redux store
import { useAppSelector, useAppDispatch } from "../../Redux/Hook";
import MenuIcon from "@mui/icons-material/Menu";
import * as Yup from "yup";
import StarRateIcon from "@mui/icons-material/StarRate";
import { axiosPrivate } from "../../axios/axios";

import FormTextField from "../../Components/Textfield";
import SelectField from "../../Components/Select";
import ErrorProps from "../../Components/Errorprops";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  dataSearch,
  dataSearchTenMiles,
  dataSearchTwentyMiles,
  dataSearchThirtyMiles,
  dataQuery,
} from "../../Redux/ProviderRedux/HomeSlice";
import {
  ArrowDropDown,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Replay,
} from "@mui/icons-material";

import useStyles from "../../styles";

import { values } from "lodash";
import SearchNav from "../../ProtectedRoutes/SearchNav";
import ClearIcon from "@mui/icons-material/Clear";

import Tooltip from "@mui/material/Tooltip";
import React from "react";

export default function Search() {
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [open2, setOpen2] = useState<boolean>(false);
  const [open3, setOpen3] = useState<boolean>(false);
  const [open4, setOpen4] = useState<boolean>(false);
  const [zipCodedata, setZipCodedata] = useState<any>([]);
  const [LoadingState, setLoadingState] = useState<boolean>(true);
  const [LoadingSearch, setLoadingSearch] = useState<boolean>(true);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [distance, setDistance] = useState(30);
  const [facType, setFacType] = useState("");
  const [checkText, setCheckText] = useState<boolean>(false);
  const [checkFacText, setCheckFacText] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [service, setService] = useState(false);
  const itemsPerPage = 5;

  const [search, setSearch] = useState<any>([]);
  const [page1, setPage1] = useState(1);
  const [noOfPages] = useState(Math.ceil(search.length / itemsPerPage));
  const [maxPrice, setMaxPrice] = useState<any>(100);
  const [minPrice, setMinPrice] = useState<any>(1);
  const [loading, setLoading] = useState<any>(false);

  const searchData = useAppSelector((state) => state.homeReducer.searchData);
  const QueryData = useAppSelector((state) => state.homeReducer.queryData);
  const [sort, setSort] = useState("");

  const [facilityType, setFacilityType] = useState<any>([]);
  const [facilityCheck, setFacilityCheck] = useState<any>("");
  const [value, setValue] = useState<number[]>([0, 0]);
  const [scoreValue, setScoreValue] = useState<number[]>([0, 5]);
  const [checkInfo, setCheckInfo] = useState<any>([]);
  const OPTIONS_LIMIT = 10;
  const defaultFilterOptions = createFilterOptions();

  const classes = useStyles();

  const filterOptions = (options: any, state: any) => {
    return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  };

  const [scoreType, setScoreType] = useState<any>("");
  const [qualityScoreCheck, setqualityScoreCheck] = useState<any>("");
  const dispatch = useAppDispatch();

  const [latitude, setLatitude] = useState<number>();
  const [longitude, setLongitude] = useState<any>();

  const q = QueryData.Service.split("-")[0].trim();

  const Servicedes = QueryData.Service.trim();

  const locationQ = QueryData.Location.trim();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = (item: any) => {
    handleClose();
  };

  function ratingHandleChange(event: any, value: any) {
    setScoreType(event.target.value);
  }

  useEffect(() => {
    const postData = { q: q, location: locationQ };

    axiosPrivate
      .post(`/search`, postData)
      .then((res) => {
        setLoadingState(true);
        dispatch(dataSearch(res.data.data));
        setSearch(res.data.data);
        setLoadingState(false);

        const maxFilter = Math.max(
          ...res.data.data.map((fprice: any) => {
            if (fprice.priceType === "facilityPrice") {
              return fprice.FacilityPrices;
            } else {
              return fprice.cashPrice;
            }
          })
        );

        const minFilter = Math.min(
          ...res.data.data.map((fprice: any) => {
            if (fprice.priceType === "facilityPrice") {
              return fprice.FacilityPrices;
            } else {
              return fprice.cashPrice;
            }
          })
        );

        if (res.data.data.length !== 0) {
          setMaxPrice(maxFilter);
          setMinPrice(minFilter);
          setValue([minFilter, maxFilter]);
        } else {
          setMaxPrice(0);
          setMinPrice(0);
          setValue([0, 0]);
        }
      })
      .catch((e) => {
        setSearch([]);
        setLoadingState(false);
      });

    const getFacilityType = async () => {
      await axiosPrivate
        .get(`/FacilityType`)
        .then((res) => {
          setFacilityType(res.data.data);
        })
        .catch((e) => console.log(e));
    };
    getFacilityType();
  }, []);

  const handleSortChange = (event: any) => {
    setPage1(1);

    setSort(event.target.value);
  };

  const handleOpenNavMenu = (event: any) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  interface forminitialValues {
    Service: any;
    Location: any;
  }

  const initialValues: forminitialValues = {
    Service: Servicedes,
    Location: locationQ,
  };

  const validationSchema = Yup.object().shape({
    Service: Yup.string().required("Required"),
    Location: Yup.string()
      .required("Zipcode is required")
      .matches(/^\d{5}$/, "Zipcode should be exactly 5 numeric digits")
      .test({
        name: "check-zipcode-exists",
        message: "Location should be a valid US zipcode",
        test: function (value) {
          return zipCodedata.some((item: any) => item.ZIP_CODE === value);
        },
      }),
  });

  const onReSetFilters = () => {
    const postData = {
      q: Servicedes.split("-")[0].trim(),
      location: locationQ,
    };

    axiosPrivate
      .post(`/search`, postData)

      .then((res) => {
        setCheckText(false);
        setCheckFacText(false);
        setPage1(1);
        setFacilityCheck("");
        setDistance(30);
        setScoreValue([0, 5]);
        setSort("");
        dispatch(dataSearch(res.data.data));

        setSearch(res.data.data);
        setLoading(false);

        const maxFilter = Math.max(
          ...res.data.data.map((fprice: any) => {
            if (fprice.priceType === "facilityPrice") {
              return fprice.FacilityPrices;
            } else {
              return fprice.cashPrice;
            }
          })
        );

        const minFilter = Math.min(
          ...res.data.data.map((fprice: any) => {
            if (fprice.priceType === "facilityPrice") {
              return fprice.FacilityPrices;
            } else {
              return fprice.cashPrice;
            }
          })
        );

        if (res.data.data.length !== 0) {
          setMaxPrice(maxFilter);
          setMinPrice(minFilter);
          setValue([minFilter, maxFilter]);
        } else {
          setMaxPrice(0);
          setMinPrice(0);
          setValue([0, 0]);
        }
      })
      .catch((err) => {
        toast.error(err.message);
        setLoading(false);
      });
  };
  const isMdUp = useMediaQuery("(min-width:821px)");

  const onSubmit = (values: forminitialValues, actions: any) => {
    const postData = {
      q: values.Service.split("-")[0].trim(),

      location: values.Location.trim(),
    };

    setLoading(true);
    axiosPrivate
      .post(`/search`, postData)

      .then((res) => {
        setCheckText(false);
        setCheckFacText(false);
        setPage1(1);
        setFacilityCheck("");
        setDistance(30);
        setScoreValue([0, 5]);
        dispatch(dataSearch(res.data.data));
        setSort("");

        setSearch(res.data.data);
        setLoading(false);
        dispatch(dataQuery(values));
        setSearchParams({
          q: values.Service.split("-")[1].trim(),
          location: values.Location.trim(),
        });
        const maxFilter = Math.max(
          ...res.data.data.map((fprice: any) => {
            if (fprice.priceType === "facilityPrice") {
              return fprice.FacilityPrices;
            } else {
              return fprice.cashPrice;
            }
          })
        );

        const minFilter = Math.min(
          ...res.data.data.map((fprice: any) => {
            if (fprice.priceType === "facilityPrice") {
              return fprice.FacilityPrices;
            } else {
              return fprice.cashPrice;
            }
          })
        );

        if (res.data.data.length !== 0) {
          setMaxPrice(maxFilter);
          setMinPrice(minFilter);
          setValue([minFilter, maxFilter]);
        } else {
          setMaxPrice(0);
          setMinPrice(0);
          setValue([0, 0]);
        }
      })
      .catch((err) => {
        setSearch([]);

        dispatch(dataQuery(values));
        setSearchParams({
          q: values.Service.split("-")[1].trim(),
          location: values.Location.trim(),
        });

        // toast.error(err.message);
        setLoading(false);
      });
  };

  var pagination = {
    total: search.length,
    per_page: itemsPerPage,
    current_page: page1,
    last_page: Math.ceil(search.length / itemsPerPage),
    from: (page1 - 1) * itemsPerPage + 1,
    to:
      page1 * itemsPerPage < search.length
        ? page1 * itemsPerPage
        : search.length,
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage1(value);
  };

  const filterFacilityType = (
    filter: any,
    dis?: any,
    type?: any,
    details?: any,
    range?: any,
    score?: any
  ) => {
    const noFacilityType = {
      q: details.Service.split("-")[0].trim(),
      location: details.Location,
      distance: dis,
      ratingRange: score,
    };
    const withFacilityType = {
      q: details.Service.split("-")[0].trim(),
      location: details.Location,
      distance: dis,
      facilityType: type,
      ratingRange: score,
    };
    const noFacAndDistance = {
      q: details.Service.split("-")[0].trim(),
      location: details.Location,
    };
    const noFacilityTypeAndRangeAndDistanceAndScore = {
      q: details.Service.split("-")[0].trim(),
      location: details.Location,
      distance: dis,
      range: range,
      ratingRange: score,
    };

    const withFacilityTypeAndDistanceAndRangeAndScore = {
      q: details.Service.split("-")[0].trim(),
      location: details.Location,
      distance: dis,
      facilityType: type,
      range: range,
      ratingRange: score,
    };

    switch (filter) {
      case "noFacilityType":
        return axiosPrivate.post(`/search`, noFacilityType);
      case "withFacilityType":
        return axiosPrivate.post(`/search`, withFacilityType);
      case "withFacilityTypeAndDistanceAndRangeAndScore":
        return axiosPrivate.post(
          `/search`,
          withFacilityTypeAndDistanceAndRangeAndScore
        );
      case "noFacilityTypeAndRangeAndDistanceAndScore":
        return axiosPrivate.post(
          `/search`,
          noFacilityTypeAndRangeAndDistanceAndScore
        );

      default:
        return axiosPrivate.post(`/search`, noFacAndDistance);
    }
  };

  function handleTypeInputChange(event: any, searchValue: any) {
    var checkFacility = false;
    setLoading(true);
    setPage1(1);
    if (event.target.value === facilityCheck) {
      setCheckFacText(false);
      setFacilityCheck("");
      checkFacility = false;
    } else {
      setCheckFacText(true);
      setFacilityCheck(event.target.value);
      checkFacility = true;
    }
    if (checkFacility) {
      filterFacilityType(
        "withFacilityType",
        `${distance}mi`,
        event.target.value,
        searchValue,
        value,
        scoreValue
      )
        .then((res) => {
          // dispatch(dataSearch(res.data.data));
          setSearch(res.data.data);
          setLoading(false);

          const maxFilter = Math.max(
            ...res.data.data.map((fprice: any) => {
              if (fprice.priceType === "facilityPrice") {
                return fprice.FacilityPrices;
              } else {
                return fprice.cashPrice;
              }
            })
          );

          const minFilter = Math.min(
            ...res.data.data.map((fprice: any) => {
              if (fprice.priceType === "facilityPrice") {
                return fprice.FacilityPrices;
              } else {
                return fprice.cashPrice;
              }
            })
          );

          if (res.data.data.length === 0) {
            setValue([0, 0]);
            setMinPrice(0);
            setMaxPrice(0);
          } else {
            setValue([minFilter, maxFilter]);
            setMinPrice(minFilter);
            setMaxPrice(maxFilter);
          }
        })
        .catch((e) => console.log(e));
    } else {
      filterFacilityType(
        "noFacilityType",
        `${distance}mi`,
        event.target.value,
        searchValue,
        value,
        scoreValue
      )
        .then((res) => {
          setSearch(res.data.data);
          setLoading(false);
          const maxFilter = Math.max(
            ...res.data.data.map((fprice: any) => {
              if (fprice.priceType === "facilityPrice") {
                return fprice.FacilityPrices;
              } else {
                return fprice.cashPrice;
              }
            })
          );

          const minFilter = Math.min(
            ...res.data.data.map((fprice: any) => {
              if (fprice.priceType === "facilityPrice") {
                return fprice.FacilityPrices;
              } else {
                return fprice.cashPrice;
              }
            })
          );

          if (res.data.data.length === 0) {
            setValue([0, 0]);
            setMinPrice(0);
            setMaxPrice(0);
          } else {
            setValue([minFilter, maxFilter]);
            setMinPrice(minFilter);
            setMaxPrice(maxFilter);
          }
        })
        .catch((e) => console.log(e));
    }
  }
  function sliderChange(event: any, newValue: any, searchValues: any) {
    setService(true);
    setPage1(1);
    setValue(newValue as number[]);

    if (facilityCheck === "") {
      filterFacilityType(
        "noFacilityTypeAndRangeAndDistanceAndScore",
        `${distance}mi`,
        facilityCheck,
        searchValues,
        newValue,
        scoreValue
      )
        .then((res) => {
          setSearch(res.data.data);
          setLoading(false);
        })
        .catch((e) => console.log(e));
    } else {
      filterFacilityType(
        "withFacilityTypeAndDistanceAndRangeAndScore",
        `${distance}mi`,
        facilityCheck,
        searchValues,
        newValue,
        scoreValue
      )
        .then((res) => {
          setSearch(res.data.data);
          setLoading(false);
        })
        .catch((e) => console.log(e));
    }
  }
  function sliderScoreChange(event: any, newValue: any, searchValues: any) {
    setService(true);
    setPage1(1);

    if (facilityCheck === "") {
      filterFacilityType(
        "noFacilityType",
        `${distance}mi`,
        facilityCheck,
        searchValues,
        value,
        newValue
      )
        .then((res) => {
          setSearch(res.data.data);
          setLoading(false);
          const maxFilter = Math.max(
            ...res.data.data.map((fprice: any) => {
              if (fprice.priceType === "facilityPrice") {
                return fprice.FacilityPrices;
              } else {
                return fprice.cashPrice;
              }
            })
          );

          const minFilter = Math.min(
            ...res.data.data.map((fprice: any) => {
              if (fprice.priceType === "facilityPrice") {
                return fprice.FacilityPrices;
              } else {
                return fprice.cashPrice;
              }
            })
          );

          if (res.data.data.length === 0) {
            setValue([0, 0]);
            setMinPrice(0);
            setMaxPrice(1);
          } else {
            setValue([minFilter, maxFilter]);
            setMinPrice(minFilter);
            setMaxPrice(maxFilter);
          }
        })
        .catch((e) => console.log(e));
    } else {
      filterFacilityType(
        "withFacilityType",
        `${distance}mi`,
        facilityCheck,
        searchValues,
        value,
        newValue
      )
        .then((res) => {
          setSearch(res.data.data);
          setLoading(false);
          const maxFilter = Math.max(
            ...res.data.data.map((fprice: any) => {
              if (fprice.priceType === "facilityPrice") {
                return fprice.FacilityPrices;
              } else {
                return fprice.cashPrice;
              }
            })
          );

          const minFilter = Math.min(
            ...res.data.data.map((fprice: any) => {
              if (fprice.priceType === "facilityPrice") {
                return fprice.FacilityPrices;
              } else {
                return fprice.cashPrice;
              }
            })
          );

          if (res.data.data.length === 0) {
            setValue([0, 0]);
            setMinPrice(0);
            setMaxPrice(1);
          } else {
            setValue([minFilter, maxFilter]);
            setMinPrice(minFilter);
            setMaxPrice(maxFilter);
          }
        })
        .catch((e) => console.log(e));
    }
  }

  function valuetext(userValue: number) {
    return `${userValue}$`;
  }

  const followersMarks = [
    {
      value: 10,

      label: "10mi",
    },

    {
      value: 50,

      label: "50mi",
    },

    {
      value: 100,

      label: "100mi",
    },
  ];

  const distanceSliderChange = (v: any, searchValue: any) => {
    setPage1(1);
    setDistance(v);
    if (facilityCheck === "") {
      filterFacilityType(
        "noFacilityType",
        `${v}mi`,
        facilityCheck,
        searchValue,
        value,
        scoreValue
      )
        .then((res) => {
          setSearch(res.data.data);
          setLoading(false);
          const maxFilter = Math.max(
            ...res.data.data.map((fprice: any) => {
              if (fprice.priceType === "facilityPrice") {
                return fprice.FacilityPrices;
              } else {
                return fprice.cashPrice;
              }
            })
          );

          const minFilter = Math.min(
            ...res.data.data.map((fprice: any) => {
              if (fprice.priceType === "facilityPrice") {
                return fprice.FacilityPrices;
              } else {
                return fprice.cashPrice;
              }
            })
          );

          if (res.data.data.length === 0) {
            setValue([0, 0]);
            setMinPrice(0);
            setMaxPrice(0);
          } else {
            setValue([minFilter, maxFilter]);
            setMinPrice(minFilter);
            setMaxPrice(maxFilter);
          }
        })
        .catch((e) => console.log(e));
    } else {
      filterFacilityType(
        "withFacilityType",
        `${v}mi`,
        facilityCheck,
        searchValue,
        value,
        scoreValue
      )
        .then((res) => {
          setSearch(res.data.data);
          setLoading(false);
          const maxFilter = Math.max(
            ...res.data.data.map((fprice: any) => {
              if (fprice.priceType === "facilityPrice") {
                return fprice.FacilityPrices;
              } else {
                return fprice.cashPrice;
              }
            })
          );

          const minFilter = Math.min(
            ...res.data.data.map((fprice: any) => {
              if (fprice.priceType === "facilityPrice") {
                return fprice.FacilityPrices;
              } else {
                return fprice.cashPrice;
              }
            })
          );

          if (res.data.data.length === 0) {
            setValue([0, 0]);
            setMinPrice(0);
            setMaxPrice(1);
          } else {
            setValue([minFilter, maxFilter]);
            setMinPrice(minFilter);
            setMaxPrice(maxFilter);
          }
        })
        .catch((e) => console.log(e));
    }
  };

  
  useEffect(() => {
    axiosPrivate
      .get("/search/cityStateList")
      .then((res) => {
        setZipCodedata(res.data.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  return (
    <Box sx={{ backgroundColor: "primary.light", padding: "1.8rem" }}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          handleChange,
          setFieldValue,
          values,
          touched,
          setFieldTouched,
        }) => (
          <Form>
            {LoadingState ? (
              <Box className={classes.searchState}>
                <Box>
                  <CircularProgress color="inherit" size={50} />
                  <Typography>Loading</Typography>
                </Box>
              </Box>
            ) : (
              <>
                {/* <Box sx={{ display: "flex", justifyContent: "right" }}> */}
                  <Grid
                    container
                    // ref={containerRef}
                    columnSpacing={1}
                    alignItems="flexStart"
                    ref={(container) => {
                      if (container) {
                        const fieldContainer = container.querySelector(
                          ".MuiFormControl-root"
                        ) as HTMLElement;
                        if (fieldContainer) {
                          const fieldHeight = fieldContainer.clientHeight;
                          const button = container.querySelector(
                            ".Buttoncomponent-root"
                          ) as HTMLElement;
                          if (button) {
                            button.style.height = `${fieldHeight}px`; 
                          }
                        }
                      }
                    }}
                    style={{ position: "relative" }}
                    sx={{
                      ml: { xs: "none", md: "30%" },
                      width: "100%",
                    }}
                  >
                    <Grid item md={4} xs={12} sx={{ marginBottom: { xs: "20px", md: 0 } }}>
                      <Box sx={{ position: "relative" }}>
                        <Field
                          label="Service Name"
                          name="Service"
                          component={Autocomplete}
                          options={checkInfo ?? []}
                          loading={checkInfo.length === 0}
                          filterOptions={filterOptions}
                          freeSolo
                          onInputChange={(e: any, value: any) => {
                            const postData = { q: value };

                            setCheckText(false);
                            setCheckFacText(false);
                            setFieldValue("Service", value);
                            setFacilityCheck("");

                            axiosPrivate
                              .post(`/search/serviceNamesearch`, postData)
                              .then((res) => {
                                setCheckInfo(res.data.data);
                              })
                              .catch((e) => console.log(e));
                          }}
                          value={values.Service}
                          renderInput={(
                            params: AutocompleteRenderInputParams
                          ) => (
                            <TextField
                              className={classes.serviceName}
                              {...params}
                              name="Service"
                              variant="outlined"
                              placeholder="Search for..."
                            />
                          )}
                        />
                        {values.Service &&
                        !checkInfo.includes(values.Service) ? (
                          <Box
                            sx={{
                              position: "absolute",
                              top: { xs: "55px", md: "calc(100% + 5px)" },
                              left: {xs:"10px",md:0},
                              textAlign: "left",
                              color: "red",
                              fontSize:   "14px" ,
                              fontFamily: "Inder",
                              fontWeight: 400,
                            }}
                          >
                           {isMdUp ? "Please select a service from the suggestions" : "Please select any one option"}   
                          </Box>
                        ) : null}
                        {touched.Service && !values.Service ? (
                          <Box
                            sx={{
                              color: "red",
                              position: "absolute",
                              top: { xs: "55px", md: "calc(100% + 5px)" },
                              left: {xs:"10px",md:0},
                              fontSize:   "14px" ,
                              fontFamily: "Inder",
                              fontWeight: 400,
                            }}
                          >
                            Servicename is required
                          </Box>
                        ) : null}
                      </Box>
                    </Grid>

                    <Grid item md={3} xs={12} sx={{ marginBottom: { xs: "15px", md: 0 } }}>
                      <Field
                        className={classes.serviceDes}
                        as={TextField}
                        name="Location"
                        value={values.Location}
                        placeholder="Location"
                        type="text"
                        onChange={(e: any) => {
                          setCheckText(false);
                          setCheckFacText(false);
                          setFieldValue("Location", e.target.value);
                          setFacilityCheck("");
                          setDistance(30);
                        }}
                        fullWidth={true}
                        helperText={
                          <ErrorMessage name="Location">
                            {(error) => <ErrorProps>{error}</ErrorProps>}
                          </ErrorMessage>
                        }
                      />
                    </Grid>
                    <Grid item md={2} xs={12}>
                      <Buttoncomponent
                      className="Buttoncomponent-root"
                        // className={classes.searchButton}
                        type="submit"
                        size="large"
                        fullWidth={false}
                        variant="contained"
                        disable={loading}
                        sx={{
                          width: { md: "auto", xs: "100%" },
                          borderRadius: 0,
                          backgroundColor: "custom.customColor8",
                          color: "#404b52",
                          fontSize: { xs: "12px", md: "18px" },
                          fontFamily: "Poppins",
                        }}
                      >
                        {loading && <CircularProgress size={14} />}

                        {!loading &&
                          (isMdUp ? <SearchIcon fontSize="large" /> : "Search")}
                      </Buttoncomponent>
                    </Grid>
                  </Grid>
                {/* </Box> */}
                <Grid container columnGap={3} mt="20px">
                  <Grid
                    item
                    xs={3}
                    sx={{
                      ml: "5vh",
                      display: { xs: "none", md: "flex" },
                      backgroundColor: "custom.customColor3",
                      flexDirection: "column",
                      rowGap: "14rem",
                    }}
                  >
                    <Box display={"flex"} flexDirection={"column"}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          mb: "30px",
                          backgroundColor: "custom.customColor4",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Inter",

                            fontSize: 18,
                            fontWeight: 600,
                            color: "white",
                            padding: "0.8rem",
                          }}
                        >
                          Filters
                        </Typography>

                        <Button
                          className={classes.clearButton}
                          sx={{
                            textTransform: "none",
                          }}
                          onClick={() => {
                            onReSetFilters();
                          }}
                        >
                          Clear All
                        </Button>
                      </Box>

                      <Box>
                        <Box className={classes.filters}>
                          Distance
                          <IconButton
                            aria-label="expand row"
                            size="large"
                            onClick={() => setOpen(!open)}
                          >
                            {open ? (
                              <KeyboardArrowUp
                                className={classes.filtersArrow}
                              />
                            ) : (
                              <KeyboardArrowDown
                                className={classes.filtersArrow}
                              />
                            )}
                          </IconButton>
                        </Box>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                          <Box sx={{ padding: "1rem 2.2rem 0 1.5rem" }}>
                            <Slider
                              value={distance}
                              valueLabelDisplay="on"
                              step={1}
                              marks={followersMarks}
                              min={10}
                              max={100}
                              onChange={(e, sliderValue: any) => {
                                setLoading(true);

                                setDistance(sliderValue);
                              }}
                              onChangeCommitted={(e, sliderValue) => {
                                distanceSliderChange(sliderValue, values);
                              }}
                            />
                          </Box>
                        </Collapse>
                      </Box>
                      <Divider />
                      <Box>
                        <Box className={classes.filters}>
                          Quality Score
                          <IconButton
                            aria-label="expand row"
                            size="large"
                            onClick={() => setOpen2(!open2)}
                          >
                            {open2 ? (
                              <KeyboardArrowUp
                                className={classes.filtersArrow}
                              />
                            ) : (
                              <KeyboardArrowDown
                                className={classes.filtersArrow}
                              />
                            )}
                          </IconButton>
                        </Box>
                        <Collapse in={open2} timeout="auto" unmountOnExit>
                          <Box sx={{ padding: "1rem 2.2rem 0 1.5rem" }}>
                            <Slider
                              className={classes.slider}
                              size="medium"
                              getAriaLabel={() => "Quality Score"}
                              value={scoreValue}
                              marks={[
                                { value: 0, label: "NA" },
                                { value: 1, label: 1 },
                                { value: 2, label: 2 },
                                { value: 3, label: 3 },
                                { value: 4, label: 4 },
                                { value: 5, label: 5 },
                              ]}
                              onChange={(e, sliderArray: any) => {
                                setScoreValue(sliderArray);
                                setLoading(true);
                              }}
                              onChangeCommitted={(event, v) => {
                                sliderScoreChange(event, v, values);
                              }}
                              min={0}
                              max={5}
                              step={1}
                              valueLabelDisplay="auto"
                              getAriaValueText={valuetext}
                            />
                          </Box>
                          <Divider />
                          <Box></Box>
                        </Collapse>
                      </Box>
                      <Divider />
                      <Box>
                        <Box className={classes.filters}>
                          Cash Rates
                          <IconButton
                            aria-label="expand row"
                            size="large"
                            onClick={() => setOpen3(!open3)}
                          >
                            {open3 ? (
                              <KeyboardArrowUp
                                className={classes.filtersArrow}
                              />
                            ) : (
                              <KeyboardArrowDown
                                className={classes.filtersArrow}
                              />
                            )}
                          </IconButton>
                        </Box>
                        <Collapse in={open3} timeout="auto" unmountOnExit>
                          <Box sx={{ padding: "1rem 2.2rem 0 1.5rem" }}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography>Min</Typography>
                              <Typography>Max</Typography>
                            </Box>

                            <Slider
                              className={classes.slider}
                              size="medium"
                              getAriaLabel={() => "Price range"}
                              value={value}
                              marks={[
                                {
                                  value: minPrice,
                                  label: `$ ${minPrice.toFixed()}`,
                                },
                                {
                                  value: maxPrice,
                                  label: `$ ${maxPrice.toFixed()}`,
                                },
                              ]}
                              onChange={(e, sliderArray: any) => {
                                setLoading(true);
                                setValue(sliderArray);
                              }}
                              onChangeCommitted={(event, v) => {
                                sliderChange(event, v, values);
                              }}
                              min={minPrice}
                              max={maxPrice}
                              step={1}
                              valueLabelDisplay="auto"
                              getAriaValueText={valuetext}
                            />
                          </Box>
                        </Collapse>
                      </Box>
                      <Divider />
                      <Box>
                        <Box className={classes.filters}>
                          Facility Type
                          <IconButton
                            aria-label="expand row"
                            size="large"
                            onClick={() => setOpen4(!open4)}
                          >
                            {open4 ? (
                              <KeyboardArrowUp
                                className={classes.filtersArrow}
                              />
                            ) : (
                              <KeyboardArrowDown
                                className={classes.filtersArrow}
                              />
                            )}
                          </IconButton>
                        </Box>
                        <Collapse in={open4} timeout="auto" unmountOnExit>
                          <Grid item xs={12}>
                            <FormGroup
                              sx={{
                                pl: "1rem",
                                pr: "1rem",
                              }}
                            >
                              <RadioGroup name="length" value={facilityCheck}>
                                {facilityType.map((type: any, i: any) => (
                                  <FormControlLabel
                                    key={i}
                                    value={type.facilityTypeId}
                                    control={
                                      <Radio
                                        checked={
                                          facilityCheck ===
                                            type.facilityTypeId && checkFacText
                                        }
                                        onClick={(e: any) => {
                                          setLoading(true);
                                          handleTypeInputChange(e, values);
                                        }}
                                      />
                                    }
                                    label={type.value.split("-")[1]}
                                    labelPlacement="end"
                                  />
                                ))}
                              </RadioGroup>
                            </FormGroup>
                          </Grid>
                        </Collapse>
                      </Box>
                      <Divider />
                    </Box>
                  </Grid>

                  <Grid
                    item
                    xs={8}
                    sx={{
                      display: { xs: "none", md: "block" },
                      backgroundColor: "common.white",
                    }}
                  >
                    {loading && <LinearProgress />}
                    <Box>
                      <Grid
                        container
                        columnSpacing={1}
                        sx={{
                          display: "flex",
                          flexGrow: 1,
                          justifyContent: "right",
                          alignItems: "right",
                          height: "8vh",
                          backgroundColor: "custom.customColor5",
                        }}
                      >
                        <Grid
                          item
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "common.white",
                              fontFamily: "h2.fontFamily",
                              fontSize: "16px",
                              fontWeight: 600,
                            }}
                          >
                            Sort by
                          </Typography>
                        </Grid>
                        <Grid item className={classes.gridButton}>
                          <Button
                            className={classes.ratingTypo}
                            onClick={() => {
                              setLoading(true);
                              setSearch((prevSearch: any) => {
                                const sortedSearch = [...prevSearch].sort(
                                  (a, b) =>
                                    (b.facilityDetails.rating || 0) -
                                    (a.facilityDetails.rating || 0)
                                );
                                setLoading(false);
                                return sortedSearch;
                              });
                            }}
                            sx={{
                              textTransform: "none",
                            }}
                          >
                            Rating high to low
                          </Button>
                        </Grid>
                        <Grid item className={classes.gridButton}>
                          <Button
                            className={classes.ratingTypo}
                            onClick={() => {
                              setLoading(true);
                              setSearch((prevSearch: any) => {
                                const sortedSearch = [...prevSearch].sort(
                                  (a, b) =>
                                    (a.facilityDetails.rating || 0) -
                                    (b.facilityDetails.rating || 0)
                                );
                                setLoading(false);
                                return sortedSearch;
                              });
                            }}
                            sx={{
                              textTransform: "none",
                            }}
                          >
                            Rating low to high
                          </Button>
                        </Grid>
                        <Grid
                          item
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            className={classes.ratingTypo}
                            onClick={() => {
                              setLoading(true);
                              setSearch((prevSearch: any) => {
                                const sortedSearch = [...prevSearch].sort(
                                  (a, b) => b.price - a.price
                                );
                                setLoading(false);
                                return sortedSearch;
                              });
                            }}
                            sx={{
                              textTransform: "none",
                            }}
                          >
                            Price high to low
                          </Button>
                        </Grid>
                        <Grid
                          item
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            className={classes.ratingTypo}
                            onClick={() => {
                              setLoading(true);
                              setSearch((prevSearch: any) => {
                                const sortedSearch = [...prevSearch].sort(
                                  (a, b) => a.price - b.price
                                );

                                setLoading(false);
                                return sortedSearch;
                              });
                            }}
                            sx={{
                              textTransform: "none",
                            }}
                          >
                            Price low to high
                          </Button>
                        </Grid>
                      </Grid>

                      {search.length !== 0 ? (
                        (itemsPerPage > 0
                          ? search.slice(
                              (page1 - 1) * itemsPerPage,
                              page1 * itemsPerPage
                            )
                          : search
                        ).map((dsearch: any, i: any) => (
                          <div key={i}>
                            <Paper elevation={3}>
                              <Card
                                raised
                                sx={{
                                  backgroundColor: "common.white",
                                  padding: "1.5rem",
                                  ml: "-0.5rem",
                                  mb: "2rem",
                                }}
                              >
                                <Grid container direction="row">
                                  <Grid item xs={9}>
                                    <Typography
                                      className={classes.facilitySearch}
                                    >
                                      {dsearch?.facilityDetails?.facilityName}
                                    </Typography>
                                    <Box
                                      sx={{
                                        fontFamily: "h2.fontFamily",
                                        fontSize: "14px",
                                        color: "common.black",
                                        display: "block",
                                      }}
                                    >
                                      {dsearch.priceType === "facilityPrice" ? (
                                        <>
                                          {
                                            dsearch.facilityDetails?.address
                                              ?.addressLine1
                                          }
                                          ,
                                          <Box>
                                            {
                                              dsearch.facilityDetails?.address
                                                ?.city
                                            }
                                            ,
                                            {
                                              dsearch.facilityDetails?.address
                                                ?.state_code
                                            }
                                            -
                                            {
                                              dsearch.facilityDetails?.address
                                                ?.zipCode
                                            }
                                          </Box>
                                        </>
                                      ) : (
                                        <>
                                          {
                                            dsearch.facilityDetails
                                              ?.addressLine1
                                          }
                                          ,
                                          <Box>
                                            {dsearch.facilityDetails?.city},
                                            {
                                              dsearch.facilityDetails
                                                ?.state_code
                                            }
                                            -{dsearch.facilityDetails?.zipCode}
                                          </Box>
                                        </>
                                      )}
                                    </Box>

                                    <Typography
                                      sx={{
                                        fontSize: "16px",
                                        color: "common.black",
                                        fontWeight: "500",
                                      }}
                                    >
                                      Distance:{" "}
                                      <Typography
                                        component="span"
                                        sx={{
                                          fontFamily: "h2.fontFamily",

                                          color: "common.black",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {dsearch.distance} miles
                                      </Typography>
                                      <Typography
                                        component="span"
                                        sx={{
                                          ml: "80px",
                                          font: "Inter",
                                          fontWeight: 600,
                                          fontSize: "16px",
                                          color: "common.black",
                                          textTransform: "Capitalize",
                                        }}
                                      >
                                        {dsearch.serviceDes.CODE_DESC_CONSUMER}
                                      </Typography>
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={3}>
                                    <Grid
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          fontSize: "20px",
                                          font: "Inter",
                                          fontWeight: 600,
                                        }}
                                      >
                                        $&nbsp;
                                        {dsearch.priceType ===
                                        "facilityPrice" ? (
                                          <Box>
                                            {dsearch.FacilityPrices?.toLocaleString(
                                              undefined,
                                              {
                                                minimumFractionDigits: 0,
                                                maximumFractionDigits: 0,
                                              }
                                            )}
                                          </Box>
                                        ) : (
                                          <Box>
                                            {dsearch.cashPrice?.toLocaleString(
                                              undefined,
                                              {
                                                minimumFractionDigits: 0,
                                                maximumFractionDigits: 0,
                                              }
                                            )}
                                          </Box>
                                        )}
                                      </Box>
                                      {dsearch.priceType === "cashPrice" ? (
                                        <Typography
                                          sx={{
                                            fontFamily: "h2.fontFamily",
                                            padding: 0,
                                            fontSize: "10px",
                                            textTransform:"capitalize"
                                          }}
                                        >
                                          {dsearch.inpatient_outpatient_flag ===
                                          "both" 
                                            ? null
                                            : `${dsearch?.inpatient_outpatient_flag}`}
                                        </Typography>
                                      ) : null}

                                      {dsearch.priceType === "cashPrice" ? (
                                        <Typography
                                          sx={{
                                            fontFamily: "h2.fontFamily",
                                            mt: "-5px",
                                            fontSize: "10px",
                                          }}
                                        >
                                          (Min: $ {dsearch.dis_min.toFixed()}-
                                          Max: $ {dsearch.dis_max.toFixed()})
                                          <Tooltip
                                            title={
                                              <Typography
                                                sx={{ fontSize: "1rem" }}
                                              >
                                                The price varies based on the
                                                modifers
                                              </Typography>
                                            }
                                          >
                                            <IconButton>
                                              <InfoOutlinedIcon />
                                            </IconButton>
                                          </Tooltip>
                                        </Typography>
                                      ) : (
                                        ""
                                      )}

                                      <Typography
                                        sx={{
                                          fontFamily: "h2.fontFamily",
                                          padding: 0,
                                          mt:
                                            dsearch.priceType ===
                                            "facilityPrice"
                                              ? "5px"
                                              : "-10px",
                                          fontSize:
                                            dsearch.priceType ===
                                            "facilityPrice"
                                              ? "13px"
                                              : "10px",
                                          color: "common.black",
                                        }}
                                      >
                                        {dsearch.priceType === "facilityPrice"
                                          ? "Average Price"
                                          : "Cash Price"}
                                      </Typography>
                                    </Grid>
                                    <Grid item sx={{ mt: "5px" }}>
                                      <Typography
                                        component="span"
                                        sx={{
                                          fontFamily: "h2.fontFamily",

                                          fontSize: "14px",
                                        }}
                                      >
                                        Rating:
                                      </Typography>
                                      <Typography
                                        component="span"
                                        sx={{
                                          fontFamily: "h2.fontFamily",
                                          marginLeft: 0.5,

                                          fontSize: "16px",
                                        }}
                                      >
                                        {dsearch?.facilityDetails?.rating ==
                                        null
                                          ? "N/A"
                                          : dsearch?.facilityDetails
                                              ?.rating}{" "}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Card>
                            </Paper>
                          </div>
                        ))
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "10vh",
                          }}
                        >
                          <Typography>No results found</Typography>
                        </Box>
                      )}
                      {search.length !== 0 ? (
                        <>
                          <Grid
                            container
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            <Typography sx={{ padding: "10px" }}>
                              Displaying items: {pagination.from}-
                              {pagination.to}
                              <span> </span>of<span> </span>
                              {pagination.total}
                            </Typography>
                            <Pagination
                              count={Math.ceil(search.length / itemsPerPage)}
                              page={page1}
                              siblingCount={0}
                              onChange={handleChangePage}
                              defaultPage={1}
                              color="primary"
                              size="large"
                              showFirstButton
                              showLastButton
                            />
                          </Grid>
                        </>
                      ) : null}
                    </Box>
                  </Grid>
                </Grid>

                {/* mobilecard display */}
                <Grid container>
                  <Grid
                    item
                    xs={6}
                    sx={{ display: { xs: "block", md: "none" } }}
                  >
                    <Box sx={{ width: "100%" }}>
                      <IconButton
                        size="large"
                        onClick={handleOpenNavMenu}
                        color="inherit"
                      >
                        <MenuIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        sx={{
                          display: { xs: "block", md: "none" },
                        }}
                      >
                        {" "}
                        <MenuItem
                          sx={{
                            display: "flex",
                            justifyContent: "right",
                            width: "100%",
                          }}
                        >
                          <Replay
                            onClick={() => {
                              handleCloseNavMenu();
                              onReSetFilters();
                            }}
                          />
                          <ClearIcon onClick={handleCloseNavMenu} />
                        </MenuItem>
                        <MenuItem
                          sx={{
                            display: "flex",
                            justifyContent: "left",
                            width: "100%",
                          }}
                        >
                          <Box sx={{ width: "100%" }}>
                            <Box className={classes.filters}>
                              Distance
                              <IconButton
                                aria-label="expand row"
                                size="large"
                                onClick={() => setOpen(!open)}
                              >
                                {open ? (
                                  <KeyboardArrowUp
                                    className={classes.filtersArrow}
                                  />
                                ) : (
                                  <KeyboardArrowDown
                                    className={classes.filtersArrow}
                                  />
                                )}
                              </IconButton>
                            </Box>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                              <Box sx={{ padding: "1rem 1rem 0 1rem" }}>
                                <Slider
                                  value={distance}
                                  valueLabelDisplay="on"
                                  step={1}
                                  marks={followersMarks}
                                  min={10}
                                  max={100}
                                  onChange={(e, sliderValue: any) => {
                                    setLoading(true);

                                    setDistance(sliderValue);
                                  }}
                                  onChangeCommitted={(e, sliderValue) => {
                                    distanceSliderChange(sliderValue, values);
                                  }}
                                />
                              </Box>
                            </Collapse>
                          </Box>
                        </MenuItem>
                        <MenuItem sx={{ width: "100%" }}>
                          <Box sx={{ width: "100%" }}>
                            <Box className={classes.filters}>
                              Quality Score
                              <IconButton
                                aria-label="expand row"
                                size="large"
                                onClick={() => setOpen2(!open2)}
                              >
                                {open2 ? (
                                  <KeyboardArrowUp
                                    className={classes.filtersArrow}
                                  />
                                ) : (
                                  <KeyboardArrowDown
                                    className={classes.filtersArrow}
                                  />
                                )}
                              </IconButton>
                            </Box>
                            <Collapse in={open2} timeout="auto" unmountOnExit>
                              <Box sx={{ padding: "0 1rem" }}>
                                <Slider
                                  className={classes.slider}
                                  size="medium"
                                  getAriaLabel={() => "Quality Score"}
                                  value={scoreValue}
                                  marks={[
                                    { value: 0, label: "NA" },
                                    { value: 1, label: 1 },
                                    { value: 2, label: 2 },
                                    { value: 3, label: 3 },
                                    { value: 4, label: 4 },
                                    { value: 5, label: 5 },
                                  ]}
                                  onChange={(e, sliderArray: any) => {
                                    setScoreValue(sliderArray);
                                    setLoading(true);
                                  }}
                                  onChangeCommitted={(event, v) => {
                                    sliderScoreChange(event, v, values);
                                  }}
                                  min={0}
                                  max={5}
                                  step={1}
                                  valueLabelDisplay="auto"
                                  getAriaValueText={valuetext}
                                />
                              </Box>

                              <Box></Box>
                            </Collapse>
                          </Box>
                        </MenuItem>
                        <MenuItem sx={{ width: "100%" }}>
                          <Box sx={{ width: "100%" }}>
                            <Box className={classes.filters}>
                              Cash Rates
                              <IconButton
                                aria-label="expand row"
                                size="large"
                                onClick={() => setOpen3(!open3)}
                              >
                                {open3 ? (
                                  <KeyboardArrowUp
                                    className={classes.filtersArrow}
                                  />
                                ) : (
                                  <KeyboardArrowDown
                                    className={classes.filtersArrow}
                                  />
                                )}
                              </IconButton>
                            </Box>
                            <Collapse in={open3} timeout="auto" unmountOnExit>
                              <Box sx={{ padding: "0 1rem" }}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Typography>Min</Typography>
                                  <Typography>Max</Typography>
                                </Box>

                                <Slider
                                  className={classes.slider}
                                  size="medium"
                                  getAriaLabel={() => "Price range"}
                                  value={value}
                                  marks={[
                                    {
                                      value: minPrice,
                                      label: `$${minPrice.toFixed()}`,
                                    },
                                    {
                                      value: maxPrice,
                                      label: `$${maxPrice.toFixed()}`,
                                    },
                                  ]}
                                  onChange={(e, sliderArray: any) => {
                                    setLoading(true);
                                    setValue(sliderArray);
                                  }}
                                  onChangeCommitted={(event, v) => {
                                    sliderChange(event, v, values);
                                  }}
                                  min={minPrice}
                                  max={maxPrice}
                                  step={1}
                                  valueLabelDisplay="auto"
                                  getAriaValueText={valuetext}
                                />
                              </Box>
                            </Collapse>
                          </Box>
                        </MenuItem>
                        <MenuItem sx={{ width: "100%" }}>
                          <Box sx={{ width: "100%" }}>
                            <Box className={classes.filters}>
                              Facility Type
                              <IconButton
                                aria-label="expand row"
                                size="large"
                                onClick={() => setOpen4(!open4)}
                              >
                                {open4 ? (
                                  <KeyboardArrowUp
                                    className={classes.filtersArrow}
                                  />
                                ) : (
                                  <KeyboardArrowDown
                                    className={classes.filtersArrow}
                                  />
                                )}
                              </IconButton>
                            </Box>
                            <Collapse in={open4} timeout="auto" unmountOnExit>
                              <Grid item xs={12}>
                                <FormGroup>
                                  <RadioGroup
                                    name="length"
                                    value={facilityCheck}
                                  >
                                    {facilityType.map((type: any, i: any) => (
                                      <FormControlLabel
                                        key={i}
                                        value={type.facilityTypeId}
                                        control={
                                          <Radio
                                            checked={
                                              facilityCheck ===
                                                type.facilityTypeId &&
                                              checkFacText
                                            }
                                            onClick={(e: any) => {
                                              setLoading(true);
                                              handleTypeInputChange(e, values);
                                            }}
                                          />
                                        }
                                        label={type.value.split("-")[1]}
                                        labelPlacement="end"
                                      />
                                    ))}
                                  </RadioGroup>
                                </FormGroup>
                              </Grid>
                            </Collapse>
                          </Box>
                        </MenuItem>
                      </Menu>
                      {/* <SearchNav/> */}
                    </Box>
                  </Grid>

                  <Grid item xs={6}>
                    <Button
                      sx={{
                        display: { xs: "flex", md: "none" },
                        color: "white",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "auto",
                        width: "80%",
                        backgroundColor: "custom.customColor5",
                        "&:hover": {
                          color: "common.black",
                        },
                      }}
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={handleClick}
                      variant="outlined"
                    >
                      Sort by
                    </Button>

                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem
                        onClick={() => {
                          setLoading(true);
                          setSearch((prevSearch: any) => {
                            const sortedSearch = [...prevSearch].sort(
                              (a, b) =>
                                (b.facilityDetails.rating || 0) -
                                (a.facilityDetails.rating || 0)
                            );
                            setLoading(false);
                            return sortedSearch;
                          });
                        }}
                      >
                        Rating high to low
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setLoading(true);
                          setSearch((prevSearch: any) => {
                            const sortedSearch = [...prevSearch].sort(
                              (a, b) =>
                                (a.facilityDetails.rating || 0) -
                                (b.facilityDetails.rating || 0)
                            );
                            setLoading(false);
                            return sortedSearch;
                          });
                        }}
                      >
                        Rating low to high
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setLoading(true);
                          setSearch((prevSearch: any) => {
                            const sortedSearch = [...prevSearch].sort(
                              (a, b) => a.price - b.price
                            );

                            setLoading(false);
                            return sortedSearch;
                          });
                        }}
                      >
                        Price low to high
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setLoading(true);
                          setSearch((prevSearch: any) => {
                            const sortedSearch = [...prevSearch].sort(
                              (a, b) => b.price - a.price
                            );
                            setLoading(false);
                            return sortedSearch;
                          });
                        }}
                      >
                        Price high to low
                      </MenuItem>
                    </Menu>
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    display: { xs: "flex", md: "none" },
                    flexDirection: "column",
                    border: "1px",
                  }}
                >
                  {loading && <LinearProgress />}
                  {search.length !== 0 ? (
                    (itemsPerPage > 0
                      ? search.slice(
                          (page1 - 1) * itemsPerPage,
                          page1 * itemsPerPage
                        )
                      : search
                    ).map((dsearch: any, i: any) => (
                      <React.Fragment key={i}>
                        <Paper
                          sx={{
                            // padding: "0.5rem",
                            // m: "0.2rem",
                            fontSize: "0.9rem",
                            boxShadow: "0px 0px 1px 1px rgba(0, 0, 0, 0.1)",
                            //  border:"1px solid grey"
                          }}
                        >
                          <IconButton
                            onClick={() => setOpen(open === i ? -1 : i)}
                          >
                            <ArrowDropDown
                              sx={{
                                transform: open === i ? "i" : "rotate(-90deg)",
                              }}
                            />
                          </IconButton>
                          {dsearch?.facilityDetails?.facilityName}
                        </Paper>

                        <Collapse in={open === i} timeout="auto" unmountOnExit>
                          <Paper
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              mt: "0.2rem",
                              padding: "1rem",
                            }}
                          >
                            <Grid item xs={8}>
                              <Typography className={classes.addSearch}>
                                {dsearch.priceType === "facilityPrice" ? (
                                  <>
                                    {
                                      dsearch.facilityDetails?.address
                                        ?.addressLine1
                                    }
                                    ,
                                    <Box>
                                      {dsearch.facilityDetails?.address?.city},
                                      {
                                        dsearch.facilityDetails?.address
                                          ?.state_code
                                      }
                                      -
                                      {
                                        dsearch.facilityDetails?.address
                                          ?.zipCode
                                      }
                                    </Box>
                                  </>
                                ) : (
                                  <>
                                    {dsearch.facilityDetails?.addressLine1},
                                    <Box>
                                      {dsearch.facilityDetails?.city},
                                      {dsearch.facilityDetails?.state_code}-
                                      {dsearch.facilityDetails?.zipCode}
                                    </Box>
                                  </>
                                )}
                              </Typography>
                              <Typography className={classes.addSearch}>
                                {dsearch.serviceDes.CODE_DESC_CONSUMER}
                              </Typography>
                              <Typography>
                                Distance: {dsearch.distance} miles
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Grid
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-start",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    fontSize: "20px",
                                    font: "Inter",
                                    fontWeight: 600,
                                    textAlign: "center",
                                  }}
                                >
                                  $&nbsp;
                                  {dsearch.priceType === "facilityPrice" ? (
                                    dsearch.FacilityPrices?.toLocaleString(
                                      undefined,
                                      {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                      }
                                    )
                                  ) : (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      {dsearch.cashPrice?.toLocaleString(
                                        undefined,
                                        {
                                          minimumFractionDigits: 0,
                                          maximumFractionDigits: 0,
                                        }
                                      )}
                                    </Box>
                                  )}
                                </Box>

                                {dsearch.priceType === "cashPrice" ? (
                                  <Typography
                                    sx={{
                                      fontFamily: "h2.fontFamily",

                                      fontSize: "12px",
                                    }}
                                  >
                                    {dsearch.inpatient_outpatient_flag ===
                                    "both"
                                      ? null
                                      : `${dsearch?.inpatient_outpatient_flag}`}
                                  </Typography>
                                ) : null}

                                {dsearch.priceType === "cashPrice" ? (
                                  <Typography
                                    sx={{
                                      fontFamily: "h2.fontFamily",

                                      fontSize: "12px",
                                    }}
                                  >
                                    (Min: $ {dsearch.dis_min.toFixed()}- Max: ${" "}
                                    {dsearch.dis_max.toFixed()})
                                    <Tooltip
                                      title={
                                        <Typography sx={{ fontSize: "1rem" }}>
                                          The price varies based on the modifers
                                        </Typography>
                                      }
                                    >
                                      <IconButton>
                                        <InfoOutlinedIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </Typography>
                                ) : (
                                  ""
                                )}
                                <Typography
                                  sx={{
                                    fontSize: "10px",

                                    // width: "100px",
                                  }}
                                >
                                  {dsearch.priceType === "facilityPrice"
                                    ? "Average Price"
                                    : "Cash Price"}
                                </Typography>
                              </Grid>
                              <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems={"center"}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "0.8rem",
                                    color: "common.black",
                                    // mr: "60px",
                                    // mt: "30px",
                                  }}
                                >
                                  Rating:
                                </Typography>
                                <span></span>
                                <Typography
                                  sx={{
                                    fontSize: "1rem",
                                    color: "common.black",
                                    // mb: "15px",
                                  }}
                                >
                                  {dsearch?.facilityDetails?.rating == null
                                    ? "N/A"
                                    : dsearch?.facilityDetails?.rating}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Paper>
                        </Collapse>
                      </React.Fragment>
                    ))
                  ) : (
                    <Box className={classes.noSearch}>
                      <Typography>No results found</Typography>
                    </Box>
                  )}

                  <>
                    <Grid
                      container
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <Typography sx={{ padding: "10px" }}>
                        Displaying items: {pagination.from}-{pagination.to}
                        <span> </span>of<span> </span>
                        {pagination.total}
                      </Typography>
                      <Pagination
                        count={Math.ceil(search.length / itemsPerPage)}
                        page={page1}
                        siblingCount={0}
                        onChange={handleChangePage}
                        // defaultPage=2
                        color="primary"
                        // size="large"
                        showFirstButton
                        showLastButton
                      />
                    </Grid>
                  </>
                </Box>
              </>
            )}
          </Form>
        )}
      </Formik>
    </Box>
  );
}
