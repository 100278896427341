import React, { useState } from "react";
import { Button, Box,  } from "@mui/material";

import {
  DataGrid,
  GridColDef,
  GridColTypeDef,
  GridValueFormatterParams,
  GridColumns,
  GridPreProcessEditCellProps,
  GridRowId,
  GridRow,
  
  
} from "@mui/x-data-grid";
import {  useAppSelector } from "../../Redux/Hook";


import clsx from "clsx";
import useStyles from "../../styles"

interface TableViewProps {
  data: any[];
  handleNext: any;
  handlePrevious:any;
}

const TableView: React.FC<TableViewProps> = ({ data, handleNext,handlePrevious }) => {
  const [csvData, setCsvData] = useState<any[]>([]);
  const [pageSize, setPagesize] = useState(5);
 
   const [page1, setPage1] = useState(1);
  const orgid = useAppSelector(
    (state) => state.providerOrganization.orgEditData
  );



 

  const classes=useStyles()
  const usdPrice: GridColTypeDef = {
    type: "number",
    width: 130,
   
    valueFormatter: (params: GridValueFormatterParams<number>) => {
      if (params.value == null) {
        return "";
      }

      const valueFormatted = Number(params.value).toLocaleString();
      return `$ ${valueFormatted} `;
    },
    cellClassName: "font-tabular-nums",
  };

  const columns: GridColumns = [
    {
      field: "SNo",
      headerName: "S.No",
      editable: false,
      headerClassName: "super-app-theme--header",
      width: 70,
      headerAlign: "center",
    },
    {
      field: "ServiceCode",
      headerName: "Service Code",
      editable: false,
      headerClassName: "super-app-theme--header",
      flex:0.8,
      headerAlign: "center",

      minWidth: 100,
    },
    {
      field: "DiagnosisTestorServiceName",
      headerName: "Diagnosis Test/Service Name",
      headerClassName: "super-app-theme--header",
      editable: false,
      flex: 2,
      headerAlign: "center",

      minWidth: 350,
    },
    {
      field: "FacilityName",
      headerName: "Facility Name",
      headerClassName: "super-app-theme--header",
      editable: false,
      flex: 2,
      headerAlign: "center",

      minWidth: 100,
    },
    {
      field: "OrganizationPrices",
      headerName: "Organization Prices",
      headerClassName: "super-app-theme--header",
      editable: true,
      flex: 1.2,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const invalid = !Number(params.props.value);
        return { ...params.props, error: invalid };
      },
      headerAlign: "center",

      minWidth: 100,
      align: "right",
      ...usdPrice,
    },
    {
      field: "FacilityNPI",
      headerName: "Facility NPI",
      headerClassName: "super-app-theme--header",
      editable: false,
      flex: 1,
      headerAlign: "center",

      minWidth: 100,
    },
    {
      field: "FacilityPrices",
      headerName: "Facility Prices",
      headerClassName: "super-app-theme--header",
      type: "number",
      editable: true,
      flex: 1,
      minWidth: 100,
      align: "right",
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => ({
        ...params.props,
        error: !Number(params.props.value) || /[^\d\-+\.]/.test(String(params.props.value)),
      }),
      headerAlign: "center",

      ...usdPrice,
    },
  ];
  function CustomRow(props: any) {
    const { className, index, ...other } = props;

    return (
      <GridRow
        index={index}
        className={clsx(className, index % 2 === 0 ? "odd" : "even")}
        {...other}
      />
    );
  }

  



  const onCellEditCommit = async (cellData: any) => {
    const { id, field, value } = cellData;
    

    let updatedData = [...data];

    for (let i = 0; i < updatedData.length; i++) {
        if (updatedData[i].SNo === id) {
         
            updatedData[i][field] = value;
           
            break; // Stop the loop after updating the matching record
        }
    }



    setCsvData(updatedData);
};


  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage1(value);
  };

  const handleUpload = () => {
  
    if (csvData.length === 0) {
      handleNext(data);
    } else {
      handleNext(csvData);
    }
  };

  const handleCancel = () => {
    handlePrevious();
  };
  return (
   
    <Box 
    className={classes.dataBox}
    sx={{
      
      height: 400,
      width: 1,
      "& .odd": {
        bgcolor: "white",
      },
      "& .even": {
        bgcolor: "#EDEDED",
      },
      ".MuiDataGrid-columnHeader":{
        backgroundColor: "#EDEDED",
      },
      ".MuiDataGrid-columnHeaderTitle": {
              fontFamily: "Inter",
              fontSize: "16px",
              fontWeight: 600,
              
            },
            ".MuiDataGrid-cellContent": {
              fontFamily: "Montserrat",
              fontSize: "14px",
              fontWeight: 400,
              textTransform: "capitalize",
                          },
    }}
  >
      <DataGrid 
      className={classes.dataGrid1}
        autoHeight
        rows={data}
        getRowId={(row: any) => row.ServiceCode}
        columns={columns}
      
        pagination={true}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPagesize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20]}
        onEditCellPropsChange={(params:any) => {
          const { id, field, props } = params;
          const editedValue = props.value;
          onCellEditCommit({ id, field, value: editedValue });
        }}

        components={{ Row: CustomRow }}
      />
      <Box sx={{ display: "flex", gap: "1.5rem" }}>
        <Button className={classes.noButton}
          type="submit"
          onClick={handleCancel}
          fullWidth={false}
          variant="contained"
          sx={{
            mt: "2vh",
          
            width: "15vw",
            
            textTransform: "capitalize",
            
          }}
        >
          Cancel
        </Button>
        <Button className={classes.yesButton}
          type="submit"
        
          fullWidth={false}
          variant="contained"
          sx={{
            mt: "2vh",
           
            width: "15vw",
            textTransform: "capitalize",
            
          }}
          onClick={handleUpload}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default TableView;
