import React from "react";
// import { Route, RouteProps, Navigate, useLocation } from "react-router";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import SideNavBar from "../component/SideNav/SideNavComp";
import { Box, Grid, Paper, Typography, Link, Button, MenuItem, Menu, Divider } from "@mui/material";

import { useAppSelector, useAppDispatch } from "../Redux/Hook";
import { refresh } from "../Redux/ProviderRedux/LoginSlice";

import { Formik, Form, ErrorMessage, Field } from "formik";

import * as Yup from "yup";
import { Select } from "@mui/material";
import SelectField from "../Components/Select";

import { InputLabel } from '@mui/material';
import { FormControl } from '@mui/material';
import { NativeSelect } from '@mui/material';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';

import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';



type Props = {
  children: React.ReactNode;
  getData: string
};
const HomePage = ({ children, getData }: Props) => {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate()
  const location = useLocation().pathname.split("/");
 
  const patientUser = useAppSelector(
    (state) => state.patientAuth.patientLogoutButton
  );

  const providerUser = useAppSelector(state => state.providerAuth.providerLogoutButton)
  const adminUser =useAppSelector(state=>state.adminAuth.adminLogoutButton)
 

  React.useEffect(() => {
    if (patientUser) {
      navigate("/patient/checkPage")
    }
    if (providerUser) {
      navigate("/provider/facility/viewFacility")
    }
    if(adminUser){
      navigate("/admin/adminlogin")
    }
  }, [getData])

 
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (

    <Box>
   
      {children}
    </Box>


  );
}




export default HomePage;
