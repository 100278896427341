
import React, { useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Button, Grid, Typography } from "@mui/material";

import {  useAppSelector } from "../../Redux/Hook";
import { axiosPrivate } from "../../axios/axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useStyles from "../../styles"
interface EditServiceTabTableViewProps {
  data: any;
  onPostSuccess: any;
  onClose: any;
  onUpdateData: any;


}
interface EditInitialValues {
 
  ServiceCode: string;
  DiagnosisTestorServiceName: string;
  FacilityName: string;
  OrganizationPrices?: string;
  FacilityNPI?: string;
  FacilityPrices: string;
}


const EditServiceTabTableView: React.FC<EditServiceTabTableViewProps> = React.memo(({ data, onPostSuccess, onClose, onUpdateData }) => {
  const { _id, ServiceCode, DiagnosisTestorServiceName, FacilityName, OrganizationPrices, FacilityNPI, FacilityPrices } = data;

  const navigate = useNavigate();
  const classes = useStyles()


   

  const onSubmit = async () => {
 

    await axiosPrivate.put("/service/updatePricelist", data)
      .then((res) => {
        toast.success(res.data.message)
       
      
        navigate("/provider/service/listService")
        onPostSuccess()
        onUpdateData();
      }).catch(err => {
        toast.warning(err.message)
      })
  }

  return (
    <Box>
      <Typography sx={{ m: "50px 0 20px 0" ,fontFamily:"Inter",fontSize:16,fontWeight:400}}>All set to publish</Typography>
      <TableContainer component={Paper}>
        <Table  className={classes.table}
     
        >
          <TableHead >
            <TableRow>
              <TableCell className={classes.tableCell}
           
              >
               
                Service Code
                </TableCell>
              <TableCell className={classes.tableCell}
            
              >
                
                Diagnosis Test/Service Name</TableCell>
              <TableCell className={classes.tableCell}
  
              >Facility Name</TableCell>
              <TableCell className={classes.tableCell}
  
              >Organization Prices</TableCell>
              <TableCell className={classes.tableCell}
      
              >Facility NPI</TableCell>
              <TableCell className={classes.tableCell}
           
              >
                Facility Prices</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow 
            className={classes.cellDetails}
            sx={{backgroundColor:"custom.customColor3"}}
            >
              <TableCell 
             
              >{ServiceCode}</TableCell>
              <TableCell 
      
              >{DiagnosisTestorServiceName}</TableCell>
              <TableCell 
       
              >{FacilityName}</TableCell>
              <TableCell 

              ><Typography display={"inline"} >${" "}</Typography>{OrganizationPrices}</TableCell>
              <TableCell 

              >{FacilityNPI}</TableCell>
              <TableCell 
              sx={{ textAlign: "right", fontSize: 14, fontFamily: "Montserrat",fontWeight:400,color: "custom.customColor2" }}><Typography display={"inline"}>${" "}</Typography>{FacilityPrices}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container item xs={10} columnGap={1} justifyContent="left">
        <Button className={classes.noButton}
          type="button"
          onClick={onClose}
          fullWidth={false}
          variant="contained"
          sx={{
            mt: "3vh",
           
            width: "10vw",            
           
            textTransform:"capitalize",
           
          }}
        >
          Cancel
        </Button>
        <Button className={classes.yesButton}
          type="submit"
          onClick={onSubmit}
          fullWidth={false}
          variant="contained"
          sx={{
            mt: "3vh",
           
            width: "10vw",
           
            textTransform:"capitalize",
            
          }}
        >
          Publish
        </Button>


      </Grid>

    </Box>
  );
});

export default EditServiceTabTableView;
