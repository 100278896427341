import * as React from "react";
import { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Button,
  Paper,
  Box,
  Container,
  TextField,
  Autocomplete,
  Collapse,
  createFilterOptions,
  IconButton,
  TablePagination,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import axios from "axios";
import { Buttoncomponent } from "../../Components/Buttoncomp";
import { ChangeEvent } from "react";
import { useNavigate } from "react-router";

import {
  DataGrid,
  GridColTypeDef,
  GridValueFormatterParams,
  GridColumns,
  GridRow
} from "@mui/x-data-grid";
import clsx from "clsx";
import { useAppDispatch, useAppSelector } from "../../Redux/Hook";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { adminAxiosPrivate, axiosPrivate, baseURL } from "../../axios/axios";
// import { parse } from "csv-parse/browser/esm/sync";
import { orgid } from "../../Redux/ProviderRedux/orgSlice";
import { toast } from "react-toastify";
import {
  ContentPasteSearchOutlined,
  Edit,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import useStyles from "../../styles";
import FormTextField from "../../Components/Textfield";
type cvsItem = {
  id: string;
  SNo: string;
  value: string;
  GridAlignment: "left" | "right" | "center";
};



export default function Admin() {
  const [csvData, setCsvData] = useState<cvsItem[]>([]);
  const [filename, setFilename] = useState("");
  const [pageSize, setPagesize] = useState(5);
  const [columns, setColumns] = useState<any>([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [unknownHeader, setUnknownHeader] = useState<boolean>(false);
  const [textValue, setTextValue] = useState<any>({});
  const [option, setOption] = useState<any>([]);
  const navigate = useNavigate();

  useEffect(() => {
    adminAxiosPrivate.get("/pathPricelist/nonStandard").then((res) => {
   
      setOption(res.data.data);
    });
  }, []);

  const knownObj = [
    {
      headerName: "ServiceCode",
      headerType: "string",
      maxLength: 32,
    },
    {
      headerName: "SNo",
      headerType: "string",
      maxLength: 32,
    },
    {
      headerName: "DiagnosisTestorServiceName",
      headerType: "string",
      maxLength: 32,
    },

    {
      headerName: "OrganizationPrices",
      headerType: "string",
      maxLength: 32,
    },
    {
      headerName: "Organizationid",
      headerType: "string",
      maxLength: 32,
    },
    {
      headerName: "FacilityPrices",
      headerType: "string",
      maxLength: 32,
    },
    {
      headerName: "FacilityName",
      headerType: "string",
      maxLength: 32,
    },
    {
      headerName: "FacilityNPI",
      headerType: "string",
      maxLength: 32,
    },
    {
      headerName: "serviceStatus",
      headerType: "string",
      maxLength: 32,
    },
  ];

  const usdPrice: GridColTypeDef = {
    type: "number",

    valueFormatter: (params: GridValueFormatterParams<number>) => {
      if (params.value == null) {
        return "";
      }

      const valueFormatted = Number(params.value).toLocaleString();
      return `$ ${valueFormatted} `;
    },
    cellClassName: "font-tabular-nums",
  };

  const columnsFormat: GridColumns = [
    {
      field: "SNo",
      headerName: "S.No",
      editable: false,
      minWidth: 200,
   
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
    },
    {
      field: "ServiceCode",
      headerName: "Service Code",
      editable: false,
      minWidth: 200,
    
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
    },
    {
      field: "DiagnosisTestorServiceName",
      headerName: "Diagnosis Test/Service Name",
      editable: false,
      minWidth: 550,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
    },
    {
      field: "FacilityName",
      headerName: "Facility Name",
      editable: false,
      minWidth: 200,
  
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
    },
    {
      field: "OrganizationPrices",
      headerName: "Organization Prices",
      editable: true,
      minWidth: 200,
    
      align: "right",
      ...usdPrice,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
    },
    {
      field: "FacilityNPI",
      headerName: "Facility NPI",
      editable: false,
      minWidth: 200,
     
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
    },
    {
      field: "FacilityPrices",
      headerName: "Facility Prices",
      editable: true,
      minWidth: 200,
   
      align: "right",
      ...usdPrice,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
    },
  ];

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onCellEditCommit = (cellData: any) => {
    const { id, field, value } = cellData;
   
    let r = csvData.map((data) => {
      if (data.SNo === id) {
    
        return { ...data, [field]: value };
 
      }

      return data;
    });

    setCsvData(r);
  };

  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  function csvJSON(csv: any) {

    var lines = csv.split("\r\n");

    var headers = lines[0].split(",");

    var result = [];

    for (var i = 1; i < lines.length - 1; i++) {
      var obj: any = {};
      var currentline = lines[i].split(",");

      for (var j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j];
      }

      result.push(obj);
    }
    setCsvData(result);

    var validateHeaders = knownObj.map((d) => d.headerName);

    const knownHeaders = validateHeaders.filter((element) =>
      headers.includes(element)
    );

    const isMatched = knownHeaders.length === validateHeaders.length;
   

    if (validateHeaders.length === headers.length && isMatched) {
      setUnknownHeader(false);
      setColumns(columnsFormat);
      return JSON.stringify(result);
    } else {
      setFilename("");
      toast.warning("Format not match. Provide the correct file");
      return { message: "Error" };
      
    }


  }

  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    const { name } = file;
    setFilename(name);

    const reader = new FileReader();
    let j: any = [];
    reader.onload = () => {
      let text: any = reader.result;
      
      csvJSON(text);
    };
   
    reader.readAsBinaryString(file);
    // reader.readAsText(file);


  };

  const upload = (e: any) => {
    e.preventDefault();
    // alert(JSON.stringify(textValue._id))
    let datacheck = {
      name: filename,
      csv: csvData,
      id: textValue._id,
      email: textValue.email,
    };
 
    if (textValue._id === undefined || "" || null) {
      toast.warning("Select the file name to replace the corrected data");
    } else {
      // alert(JSON.stringify(textValue))
      adminAxiosPrivate
        .post(
          `/service/uploadAdminPricelist`,
          datacheck
   
        )
        .then((res) => {
          setColumns([]);
          setCsvData([]);
          toast.success(res.data.message);
        })
        .catch((err) => {
     
          toast.warning(err.message);
        });
    }
    
  };

  const onSubmit = (e: any) => {
    e.preventDefault();

    let datacheck = { name: filename, csv: csvData };
    axiosPrivate
      .post(
        `/service/publishPricelist`,
        datacheck
      
      )
      .then((res) => {
   
        navigate("/provider/facility/pricelistlanding");
      }); 
  };

  const Download = () => {
    axiosPrivate.get("/download").then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "singleFileFormat.csv");
      document.body.appendChild(link);
      link.click();
    });
  };
  const onText = (value: any) => {
  
    setTextValue({ _id: value._id, email: value.providerName });
  };
  const CustomPaper = (props: any) => {
    return (
      <Paper
        elevation={8}
        sx={{  color: "black" }}
        {...props}
      />
    );
  };

  function CustomRow(props: any) {
    const { className, index, ...other } = props;

    return (
      <GridRow
        index={index}
        className={clsx(className, index % 2 === 0 ? "odd" : "even")}
        {...other}
      />
    );
  }

  const classes=useStyles()
  const OPTIONS_LIMIT = 10;
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options: any, state: any) => {
    return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  };

 

  return (
    <>
      <Paper
        elevation={3}
        sx={{
          backgroundColor: "primary.light",
          padding: "1.5rem",
          borderRadius: "15px",
          height: "89vh",

          "&::-webkit-scrollbar": {
            width: { xs: 0, md: 10 },
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "grey",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "secondary.dark",
            borderRadius: 2,
          },
          overflowX: "hidden",

      
        }}
      >


        <Autocomplete
          freeSolo
          options={option}
          loading={option.length === 0}
          filterOptions={filterOptions}
          onChange={(event: React.ChangeEvent<{}>, value: unknown, reason: string) => {
            if (typeof value === 'string') {
                onText(value);
            }
        }}
        
          PaperComponent={CustomPaper}
          getOptionLabel={(opt: any) =>
            opt.organizationID +
              " / " +
              opt.providerID +
              " / " +
              opt.filePath.split("/")[2] || opt
          }
          renderInput={(params) => (
            <TextField {...params} label="Provide the filename to replace the corrected data" />
          )}
        />
  
        <Typography className={classes.normalFlex}
     
          sx={{
           
            marginTop: "50px",
          }}
        >
          Upload your facility's updated Price list
        </Typography>
        <br></br>
        <Box className={classes.normalFlex}
        
        >
          <Button className={classes.viewButton}
            component="label"
            variant="outlined"
            startIcon={<UploadFileIcon />}
            sx={{
              mt: 2,
              backgroundColor: "secondary.dark",
              
            }}
          >
            Upload CSV
            <input
              type="file"
              accept=".csv"
              hidden
              onChange={handleFileUpload}
            />
          </Button>

      
          <Box textAlign={"center"} mb={"1rem"}>{filename}</Box>
        </Box>
        <Box className={classes.oddEvenbox}
    sx={{
      
      "& .odd": {
        bgcolor: "white",
      },
      "& .even": {
        bgcolor: "#EDEDED",
      },
     
    }}
  >
  </Box>
  <Box
    sx={{
      "& .super-app-theme--header": {
        backgroundColor: "#EDEDED",
      },
      // height: 400,
      width: 1,
      "& .odd": {
        bgcolor: "white",
      },
      "& .even": {
        bgcolor: "#EDEDED",
      },
      ".MuiDataGrid-columnHeaderTitle": {
              fontFamily: "Inter",
              fontSize: "16px",
              fontWeight: 600,
            },
            ".MuiDataGrid-cellContent": {
              fontFamily: "Montserrat",
              fontSize: "14px",
              fontWeight: 400,
              textTransform: "capitalize",
            },
    }}
  >
        {columns.length !== 0 ? (
          <>
            <DataGrid className={classes.dataGrid1}
              autoHeight
              rows={csvData}
              columns={columns}
              getRowId={(row: any) => row.SNo}
              pagination={true}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize: number) =>
                setPagesize(newPageSize)
              }
              rowsPerPageOptions={[5, 10, 20]}
              onCellEditCommit={onCellEditCommit}
             
              components={{ Row: CustomRow }}
            />


            
            <Box sx={{ display: "flex", gap: "1.5rem" }}>
              <Buttoncomponent className={classes.viewButton}
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                onClick={upload}
                sx={{
                  mt: 2,
                  backgroundColor: "secondary.dark",
                  width: "10vw",
              
                }}
              >
                Save
              </Buttoncomponent>
             
            </Box>
          </>
        ) : null}
        </Box>
      </Paper>
    </>
  );
}
