import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";


interface CounterState {
  adminLogin: any;
 
  adminLogoutButton: boolean;
  
}


const initialState: CounterState = {
    adminLogin: {},
  
    adminLogoutButton: false,
  
};

export const adminLoginSlice = createSlice({
  name: "admintLogin",

  initialState,
  reducers: {
   
    adminLoginInfo: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        adminLogoutButton:true,
        adminLogin: action.payload,
      };
    },
    adminLogoutButton: (state) => {
      return {
        ...state,
        adminLogin: {},
        adminLogoutButton: false,

        
      };
    },
    adminAccessTokentest: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        adminLogin: { ...state.adminLogin, token: action.payload },
      };
    },
   
   

    
    
  },
});

export const {
    adminLoginInfo,
  
    adminLogoutButton,
    adminAccessTokentest
  

} = adminLoginSlice.actions;



export const adminLoginReducer = adminLoginSlice.reducer;
