import React, { useState, useEffect } from "react";
import { axiosPrivate } from "../../axios/axios";
import { useAppDispatch, useAppSelector } from "../../Redux/Hook";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Grid,
  Paper,
  
} from "@mui/material";
import { toast } from "react-toastify";
import { ViewInfo } from "../../Redux/ProviderRedux/serviceViewSlice";
import { useNavigate } from "react-router-dom";
import { Buttoncomponent } from "../../Components/Buttoncomp";

import { InsertDriveFile, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import useStyles from "../../styles"
const ServiceView = () => {
  const [pathData, setPathData] = useState<any>([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const classes=useStyles()

  const providerID = useAppSelector((state) => state.providerAuth.login.userID);
  const orgID = useAppSelector(
    (state) => state.providerOrganization.orgEditData
  );


  
  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axiosPrivate
      .get(
        `/pathPricelist/getPathByProvider?providerID=${providerID}&OrganizationID=${orgID[0].organizationID}`
      )
      .then((res) => {
        var resData = res.data.data;
        setPathData(resData);
      });
  };

  const viewOnClick = (path: any) => {
    if (path.status === "Pending") {
      toast.warning("Not verified");
    } else if (path.status === "published") {
      toast.warning("Already published");
    } else {
      dispatch(ViewInfo(path));
      navigate("/provider/serviceView/publishservice");
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} >
        {pathData.length !== 0 ? (
          <TableContainer component={Paper}>
           <Table className={classes.table}
          
            >
              <TableHead sx={{ backgroundColor: "#F9F9F9" }}>
                <TableRow>
                  <TableCell  sx={{
                      fontSize: "1.2rem",
                      fontWeight: "bold",
                      align:"center"
                    }}>File Name</TableCell>
                  <TableCell   sx={{
                      fontSize: "1.2rem",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}   >Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pathData.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
                
        
                .map((dataPath: any, i: any) => (
                  <TableRow key={i}    sx={{
                    backgroundColor: (i + 1) % 2 === 0 ? "#F9F9F9" : "white",
                  }}>
                    <TableCell sx={{ fontSize: "1rem" }} >{dataPath.filePath.split("/")[2]}</TableCell>
                    <TableCell align="center">
                      <Buttoncomponent
                        type="button"
                        disable={
                          dataPath.status === "verified"
                            ? false
                            : dataPath.status === "published"
                            ? false
                            : true
                        }
                        size="large"
                        onClick={() => viewOnClick(dataPath)}
                        sx={{
                          fontSize :{xs:"0.7rem", md:"1rem"},
                          '&.MuiButton-textPrimary': {
                            color: 'white',
                          },
                          backgroundColor:  
                            dataPath.status === "Pending" ? "#FFB700" : "#006400",
                          "&:hover": {
                            backgroundColor:  
                              dataPath.status === "Pending" ? "#FFB700" : "#006400",
                          },
                        }}
                      >
                        {dataPath.status}
                      </Buttoncomponent>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    count={pathData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    sx={{backgroundColor:"#E3ECF6"}}
                    labelDisplayedRows={({ from, to, count }) =>
                      `${from}-${to} of ${count !== -1 ? count : ` ${to}}`}`
                    }
                    backIconButtonProps={{
                      color: "secondary",
                    }}
                    nextIconButtonProps={{ color: "secondary" }}
                    showFirstButton={true}
                    showLastButton={true}
                    labelRowsPerPage={<span>Rows:</span>}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        ) : (
          <Box
            sx={{
              height: "5vh",
              backgroundColor: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <InsertDriveFile fontSize="large"/>
            <Typography>No files</Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default ServiceView;