import React, { FC, ReactElement } from "react";
import {
  Box,
  Link,

  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  
  Divider,
  Button,
  ListItem,
} from "@mui/material";
import { toast } from "react-toastify";

import MenuIcon from "@mui/icons-material/Menu";

import { NavLink, useLocation, useNavigate } from "react-router-dom";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Buttoncomponent } from "../Components/Buttoncomp";
import { useAppDispatch, useAppSelector } from "../Redux/Hook";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { axiosPrivate } from "../axios/axios";

import {
  logoutButton,
  pageUser,
 
} from "../Redux/ProviderRedux/LoginSlice";
import { patientLogoutButton } from "../Redux/PatientRedux/patientAuth";
import { refrestState } from "../Redux/ProviderRedux/orgSlice";
import { adminLogoutButton } from "../Redux/Admin/adminLogin";
import { Home, LogoutTwoTone } from "@mui/icons-material";
import useStyles from "../styles";

const Navbar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const providerLogout = useAppSelector(
    (state) => state.providerAuth.providerLogoutButton
  );

  const patientLogout = useAppSelector(
    (state) => state.patientAuth.patientLogoutButton
  );
  const adminLogout = useAppSelector(
    (state) => state.adminAuth.adminLogoutButton
  );
  const userID = useAppSelector((state) => state.providerAuth.login);
  const adminUserID = useAppSelector(
    (state) =>
      state.adminAuth.adminLogin.firstName +
      " " +
      state.adminAuth.adminLogin.lastName
  );
  const userName = useAppSelector((state) => state.providerAuth.login.userName);
  const adminUserName = useAppSelector(
    (state) => state.adminAuth.adminLogin.userName
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElSign, setAnchorElSign] = React.useState<any>(null);
  const [patientclick, setPatientClick] = React.useState<any>(null);
  const [render, setRender] = React.useState<any>(<KeyboardArrowDownIcon />);
  const [render1, setRender1] = React.useState<any>(<KeyboardArrowDownIcon />);
  const [render2, setRender2] = React.useState<any>(<KeyboardArrowDownIcon />);
  const [empclick, setEmpClick] = React.useState<any>(null);
  const [healthclick, setHealthClick] = React.useState<any>(null);
  const classes = useStyles();

  const [data, setData] = React.useState({
    Link: "Healthcare Provider",
  });



  const open = Boolean(anchorEl);
  const openSign = Boolean(anchorElSign);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const patientClick = (event: any) => {
    setPatientClick(event.currentTarget);
    setRender(<KeyboardArrowUpIcon />);
  };

  const healthcareclick = (event: any) => {
    setHealthClick(event.currentTarget);
    setRender1(<KeyboardArrowUpIcon />);
  };
  const employerClick = (event: any) => {
    setEmpClick(event.currentTarget);
    setRender2(<KeyboardArrowUpIcon />);
  };
  const patientClose = (event: any) => {
    setPatientClick(null);
    setRender(<KeyboardArrowDownIcon />);
  };

  const empClose = (event: any) => {
    setEmpClick(null);
    setRender2(<KeyboardArrowDownIcon />);
  };

  const healthClose = (event: any) => {
    setHealthClick(null);
    setRender1(<KeyboardArrowDownIcon />);
  };

  const handleClickSign = (event: any) => {
    setAnchorElSign(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleSignInClick = (event: any) => {
    setAnchorElSign(event.currentTarget);
  };

  const handleOpenNavMenu = (event: any) => {
    setAnchorElNav(event.currentTarget);
  };

  const onPageUser = (user: any, path: string) => {
    dispatch(pageUser(user));
    navigate(path);
  };

  const onLogout = (type: string) => {
    if (type === "patient") {
      dispatch(patientLogoutButton());
      navigate("/");
    }
    if (type === "provider") {
      axiosPrivate.post("/user/logout", userName).then((res) => {
   
        dispatch(logoutButton());
        dispatch(refrestState());
        toast.success(res.data.message);
        navigate("/");
      });
    }
    if (type === "admin") {
      axiosPrivate.post("/admin/adminlogout", adminUserName).then((res) => {
       
        dispatch(adminLogoutButton());

        toast.success(res.data.message);
        navigate("/admin/adminlogin");
      });
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseSign = () => {
    setAnchorElSign(null);

    handleCloseNavMenu();
  };

  return (
    <Toolbar
      disableGutters
      sx={{
        display: "flex",
        justifyContent: { 
    
        xs: "space-between" },
        mt: "15px",
       
        fontFamily: "Poppins",
        // alignItems: "center",

      }}
    >
      <Link className={classes.linkText}   component="a"
        href="https://carecadet.com/"
        target="_blank" //  Open in a new tab/window
        rel="noopener noreferrer" >
        <Box sx={{ display: "flex", marginLeft:"25px"  }}>
          <Box className={classes.careCadet}>
            Care
            <Box
              className={classes.caDet}
            
            >
              Cadet
            </Box>
          </Box>
        </Box>
      </Link>

      <Box sx={{ display: { xs: "flex", md: "none" } }}>
        <IconButton size="large" onClick={handleOpenNavMenu} color="inherit">
          <MenuIcon />
        </IconButton>

        <Menu
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
          sx={{
            display: { xs: "flex", md: "none" },
          }}
        >
                {providerLogout || patientLogout || adminLogout ? null : (
            <ListItem
       
            >
    <Link
  className={classes.navMenus}
  href="https://carecadet.com/"
  target="_blank"
  sx={{
    textTransform: "capitalize",
    width: "auto",
    textDecoration:"none"
    // borderBottom: location.pathname === "/" ? "3px solid #3AB8FF" : "",
    // "&:hover": {
    //   borderBottom: location.pathname === "/" ? "3px solid #3AB8FF" : "",
    // },
  }}
>
  Home
</Link>
              
            </ListItem>
          )}
          {providerLogout || patientLogout || adminLogout ? null : (
            <ListItem
            sx={{
                  
              textTransform: "capitalize",
             
              textDecoration: location.pathname === "/" ? "underline 3px solid #3AB8FF!important" : "none",
           
            "&:hover": {
             
              textDecoration: location.pathname === "/" ? "underline 3px solid #3AB8FF" : "none",
            },
           
             textUnderlineOffset: location.pathname === "/" ? "10px" : "0px",
              
            }}
              className={classes.listItemnav}
              onClick={() => {
                onPageUser(Home, "/");
                handleCloseNavMenu();
              }}
            >
              Price Search
            </ListItem>
          )}
          {/* {providerLogout || patientLogout || adminLogout ? null : (
            <ListItem className={classes.listItemnav} onClick={patientClick}>
              Patients
            </ListItem>
          )}
          <Menu
            id="simple-menu"
            anchorEl={patientclick}
            keepMounted
            open={Boolean(patientclick)}
            onClose={patientClose}
            sx={{
              display: { xs: "flex", md: "none" },
            }}
          >
            <MenuItem sx={{fontFamily:"Poppins"}}onClick={patientClose}>Find Care</MenuItem>
            <MenuItem sx={{fontFamily:"Poppins"}}onClick={patientClose}>Find Insurance</MenuItem>
          </Menu>
           {providerLogout || patientLogout || adminLogout ? null : (
            <ListItem className={classes.listItemnav} onClick={employerClick}>
              Employers
            </ListItem>
          )}
          <Menu
            id="simple-menu"
            anchorEl={empclick}
            keepMounted
            open={Boolean(empclick)}
            onClose={empClose}
            sx={{
              display: { xs: "flex", md: "none" },
            }}
          >
            <MenuItem sx={{fontFamily:"Poppins"}} onClick={empClose}>
              Find Group Health
            </MenuItem>
            <MenuItem sx={{fontFamily:"Poppins"}}onClick={empClose}>Find Insurance</MenuItem>
          </Menu>
          {providerLogout || patientLogout || adminLogout ? null : (
            <ListItem className={classes.listItemnav} sx={{fontFamily:"Poppins"}} onClick={healthcareclick}>
              Healthcare providers
            </ListItem>
          )}

          <Menu
            id="simple-menu"
            anchorEl={healthclick}
            keepMounted
            open={Boolean(healthclick)}
            onClose={healthClose}
            sx={{
              display: { xs: "flex", md: "none" },
            }}
          >
            <MenuItem sx={{fontFamily:"Poppins"}}onClick={healthClose}>
              Find Analyse Reimbursement
            </MenuItem>
            <MenuItem sx={{fontFamily:"Poppins"}}onClick={healthClose}>Publish Cash Price</MenuItem>
          </Menu> */}
       {/* future */}

          {/* {providerLogout || patientLogout || adminLogout ? null : (
            <ListItem
              className={classes.listItemnav}
              aria-controls="simple-menu"
              aria-haspopup="true"
            >
              Explore
            </ListItem>
          )} */}

          {/* {providerLogout || patientLogout || adminLogout ? null : (
            <ListItem
              className={classes.listItemnav}
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={() => {
                onPageUser(Home, "/contact");
                handleCloseNavMenu();

                // onPageUser(Home,"/");
              }}
            >
              Contact
            </ListItem>
          )} */}

          {providerLogout || patientLogout || adminLogout ? null : (
              <Link
              sx={{ textDecoration: "none" }}
              component={NavLink}
              to="/provider/login"
            >
              <ListItem
                className={classes.listItemsign}
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleSignInClick}
                sx={{width:"auto"}}
              >
                Sign In
              </ListItem>
              
         </Link>
              )}

              {/* <Menu
                id="basic-menu"
                anchorEl={anchorElSign}
                open={openSign}
                onClose={() => {
                  handleCloseSign();
                }}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                PaperProps={{
                  style: {
                    width: "20ch",
                  },
                }}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <MenuItem sx={{fontFamily:"Poppins"}}
                  onClick={(event: any) => {
                    navigate("/provider/login", { state: { data: data } });

                    handleCloseSign();
                    handleCloseNavMenu();
                  }}
                >
                  Provider
                </MenuItem>
                <Divider />
                <MenuItem sx={{fontFamily:"Poppins"}}
                  onClick={() => {
                    navigate("/provider/login", { state: { data: data } });
                  }}
                >
                  Patient
                </MenuItem>
              </Menu> */}
           
          

          {providerLogout || patientLogout || adminLogout ? null : (
            <ListItem
              className={classes.listItemsign}
              aria-controls="simple-menu"
              aria-haspopup="true"
              sx={{width:"auto"}}
              onClick={() => {
                onPageUser(Home, "/provider/signup");
                handleCloseNavMenu();
              }}
            >
              Sign Up
            </ListItem>
          )}

          {providerLogout ? (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Divider />
              <Box 
              // className={classes.profileBox}
                sx={{
                  height: "7vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding:"10px",
                  flexWrap: "nowrap",
                  gap: "0.5rem",
                  cursor: "pointer",
                  color: "tertiary.main",
                  fontFamily: "Poppins!important",
                }}
              >
                <AccountCircleOutlinedIcon fontSize="large" />
                <Typography textAlign={"center"}>
                  {userID.firstName + " " + userID.lastName}
                </Typography>
              </Box>
              <Divider />

              <Box
                className={classes.logout}
                onClick={() => {
                  handleCloseNavMenu();
                  onLogout("provider");
                }}
              >
                <LogoutTwoTone /> Logout
              </Box>
            </Box>
          ) : null}
          {patientLogout ? (
            <Box>
              <Buttoncomponent
                className={classes.logoutButton}
                type="button"
                size="small"
                fullWidth={false}
                variant="contained"
                onClick={() => {
                  onLogout("patient");
                }}
              >
                Logout
              </Buttoncomponent>
            </Box>
          ) : null}

          {adminLogout ? (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Divider />

              <Box
                sx={{
                  height: "7vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexWrap: "nowrap",
                  gap: "0.5rem",

                  cursor: "pointer",
                }}
              >
                <AccountCircleOutlinedIcon fontSize="large" />
                <Typography>{adminUserID}</Typography>
              </Box>
              <Divider />
              <Box
                className={classes.logout}
                onClick={() => {
                  handleCloseNavMenu();
                  onLogout("admin");
                }}
              >
                <LogoutTwoTone /> Logout
              </Box>
            </Box>
          ) : null}
        </Menu>
      </Box>



      <Box
        sx={{
          display: { xs: "none", md: "flex" },
          marginRight: "100px" 
    
        }}
      >
        <Box
          sx={{
            display: "flex",
         
          
          //   alignItems: "center",
          //  width:"100%"
          }}
        >
              {!providerLogout && (
            <Button
 
   className={classes.navMenus}
   href="https://carecadet.com/"
   target="_blank"
   sx={{
     textTransform: "capitalize",

  //    borderBottom:
  //    location.pathname === "/" ? "3px solid #3AB8FF" : "",

  //  "&:hover": {
  //    borderBottom:
  //      location.pathname === "/" ? "3px solid #3AB8FF" : "",
  //  },
   marginRight: '20px',
   }}
 >
   
      Home
    </Button> )}
    {!providerLogout && (
      <Button
        className={classes.navMenus}
        component={NavLink}
        to="/" // Link to the Home page
              
     
                sx={{
                  
                  textTransform: "capitalize",
                    marginRight: '20px',
                
                  textDecoration: location.pathname === "/" ? "underline 3px solid #3AB8FF" : "none",
               
                "&:hover": {
                 
                  textDecoration: location.pathname === "/" ? "underline 3px solid #3AB8FF" : "none",
                },
                //  paddingBottom: "10px",
                 textUnderlineOffset: location.pathname === "/" ? "10px" : "0px",
                  
                }}
      >
        Price Search
      </Button>
    )}
                        {/* {providerLogout || patientLogout || adminLogout ? null : (
            <ListItem
       
            >
                 <Link className={classes.listItemnav}   component={NavLink} to="/"
        href="https://carecadet.com/"
        target="_blank" //  Open in a new tab/window
        rel="noopener noreferrer" >
  
          Home
    
      </Link>
              
            </ListItem>
          )} */}
      
          {/* {providerLogout ? null : (
               <ListItem
       
               >
            <Link component={NavLink} to="/" >
              <Button
                className={classes.navMenus}
                onClick={() => {
                  onPageUser(Home, "/");
                }}
                sx={{
                  
                  textTransform: "capitalize", width:"auto",
                  borderBottom:
                    location.pathname === "/" ? "3px solid #3AB8FF" : "",

                  "&:hover": {
                    borderBottom:
                      location.pathname === "/" ? "3px solid #3AB8FF" : "",
                  },
                }}
              >
                Price Searchs
            </Button>
            </Link>
            </ListItem>
          )} */}
          {/* {providerLogout ? null : (
            <Button
              className={classes.navMenus}
              sx={{ textTransform: "capitalize" }}
              onClick={patientClick}
              endIcon={render}
            >
              Patients
            </Button>
          )}
          <Menu
            className={classes.navMenulists}
            id="simple-menu"
            anchorEl={patientclick}
            keepMounted
            open={Boolean(patientclick)}
            onClose={patientClose}
          >
            <MenuItem sx={{fontFamily:"Poppins"}}   onClick={patientClose}>Find Care</MenuItem>
            <MenuItem sx={{fontFamily:"Poppins"}} onClick={patientClose}>Find Insurance</MenuItem>
          </Menu>
        {providerLogout ? null : (
            <Button
              className={classes.navMenus}
              onClick={employerClick}
              sx={{ textTransform: "capitalize" }}
              endIcon={render2}
            >
              Employers
            </Button>
          )} 
          <Menu
            className={classes.navMenulists}
            id="simple-menu"
            anchorEl={empclick}
            keepMounted
            open={Boolean(empclick)}
            onClose={empClose}
          >
            <MenuItem 
            sx={{fontFamily:"Poppins"}} 
            onClick={empClose}>
              Find Group Health
            </MenuItem>
            <MenuItem 
            sx={{fontFamily:"Poppins"}} 
            onClick={empClose}>Find Insurance</MenuItem>
          </Menu>
          {providerLogout ? null : (
            <Button
              className={classes.navMenus}
              onClick={healthcareclick}
              sx={{ textTransform: "capitalize" }}
              endIcon={render1}
            >
              Healthcare providers
            </Button>
          )}

          <Menu
            className={classes.navMenulists}
            id="simple-menu"
            anchorEl={healthclick}
            keepMounted
            open={Boolean(healthclick)}
            onClose={healthClose}
          >
            <MenuItem sx={{fontFamily:"Poppins"}} onClick={healthClose}>
              Find Analyse Reimbursement
            </MenuItem>
            <MenuItem sx={{fontFamily:"Poppins"}} onClick={healthClose}>Publish Cash Price</MenuItem>
          </Menu> */}
          {/* {providerLogout ? null : (
            <Button
              className={classes.navMenus}
              aria-controls="simple-menu"
              aria-haspopup="true"
              sx={{ textTransform: "capitalize" }}
            >
              Explore
            </Button>
          )} */}
          {/* {providerLogout ? null : (
            <Link
              sx={{ textDecoration: "none" }}
              component={NavLink}
              to="/contact"
            >
              <Button
                className={classes.navMenus}
                aria-controls="simple-menu"
                aria-haspopup="true"
                sx={{
                  textTransform: "capitalize",
                  borderBottom:
                    location.pathname === "/contact" ? "3px solid #3AB8FF" : "",
                }}
              >
                Contact
              </Button>
            </Link>
          )} */}
        </Box>
       
        {providerLogout || patientLogout || adminLogout ? null : (
         <Link
         sx={{ textDecoration: "none" }}
         component={NavLink}
         to="/provider/login"
       >
        <Button
        className={classes.menuMain}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClickSign}
        sx={{ textTransform: "capitalize",
        // marginLeft: "30px",
        // padding:"10px"
                  
        }}
      >
        Sign In
      </Button>
      </Link>
      )

      
      }
        
      {/* <Menu
              id="basic-menu"
              anchorEl={anchorElSign}
              open={openSign}
              onClose={handleCloseSign}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              PaperProps={{
                style: {
                  width: "auto",
                  
                },
              }}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                font: "Poppins",
              }}
            >
              <MenuItem sx={{fontFamily:"Poppins"}}
                onClick={() => {
                  handleCloseSign();
                  navigate("/provider/login", { state: { data: data } });
                }}
              >
                Provider
              </MenuItem>
              <Divider />
              <MenuItem sx={{fontFamily:"Poppins"}}
                onClick={() => {
                  handleCloseSign();
                  navigate("/provider/login", { state: { data: data } });
                }}
              >
                Patient
              </MenuItem>
            </Menu> */}

        
        


 {location.pathname === '/admin/adminlogin' || (providerLogout || adminLogout) ? null : (
    <Link
      sx={{ textDecoration: "none" }}
      component={NavLink}
      to="/provider/signup"
    >
      <Button 
        aria-controls="simple-menu"
        aria-haspopup="true"
        // onClick={handleClick}
        sx={{
          // mt: "5px",
          fontFamily: "Poppins",
          fontSize: "1.2rem",
          width: "8vw",
          color: "white",
          backgroundColor: "quaternary.light",
          // cursor: "pointer",
          ":hover": {
            color: "quaternary.contrastText",
            // backgroundColor:"white"
          },
          textTransform: "capitalize",
          borderRadius: 10,
       marginLeft: "30px",
      
                  
        }}
      >
        Sign up
      </Button>
    </Link>)}
        {providerLogout ? (
          <Box sx={{ display: "flex" }}>
            <Box className={classes.profileBox} onClick={handleClick}>
              <Divider orientation="vertical" flexItem />
              <AccountCircleOutlinedIcon fontSize="large" />
              <Typography textAlign={"center"}>
                {userID.firstName + " " + userID.lastName}
              </Typography>
            </Box>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              PaperProps={{
                style: {
                  font: "Poppins",
                 width: "20ch",
                  background: "white",
                },
              }}
            >
              <MenuItem 
                onClick={() => {
                  handleClose();
                  onLogout("provider");
                }}
              >
                Logout
              </MenuItem>
            </Menu>
          </Box>
        ) : null}

        {adminLogout ? (
          <Box sx={{ display: "flex" }}>
            <Box className={classes.profileBox} onClick={handleClick}>
              <Divider orientation="vertical" flexItem />
              <AccountCircleOutlinedIcon fontSize="large" />
              <Typography>{adminUserID}</Typography> 
            </Box>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              PaperProps={{
                style: {
                  //width: "20ch",
                  background: "white",
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  handleClose();
                  onLogout("admin");
                }}
              >
                Logout
              </MenuItem>
            </Menu>
          </Box>
        ) : null}
      </Box>
    </Toolbar>
  );
};

export default Navbar;
