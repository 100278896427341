import React, { useRef, useEffect ,useState} from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { logoutButton, } from './Redux/ProviderRedux/LoginSlice';
import {refrestState} from "./Redux/ProviderRedux/orgSlice";
import { toast } from 'react-toastify';
import { axiosPrivate } from './axios/axios';
import { useAppSelector } from './Redux/Hook';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {

  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  DialogActions,
  DialogContent,
 
} from "@mui/material";
import Button from '@mui/material/Button';


type CloseReason = "backdropClick" | "escapeKeyDown" | "closeButtonClick";

interface DialogProps extends MuiDialogProps {
  onClose: (reason: CloseReason) => void;
}

const Dialog = ({ onClose, children, ...props }: DialogProps) => {
  return (
    <MuiDialog 
    onClose={(_, reason) => onClose(reason as CloseReason)} {...props}
    slotProps={{
      backdrop: {
        style: { backgroundColor: "transparent", boxShadow: "none" },
      },
    }}
    >
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        {/* Add your dialog actions here */}
      </DialogActions>
    </MuiDialog>
  );
};

const timeout: number = Number(BigInt(120) * BigInt(60) * BigInt(1000));

const promptBeforeIdle = 4_000

export default function IdleTimerContainer() {

  const [remaining, setRemaining] = useState<number>(timeout)
  const [open, setOpen] = useState<boolean>(false)
  const navigate = useNavigate()
  const userName = useAppSelector((state) => state.providerAuth.login.userName);
  const dispatch=useDispatch()

  const onIdle = () => {
  
    setOpen(false)
    console.log('User is Idle');
    axiosPrivate.post("/user/logout", userName)
      .then((res) => {
        dispatch(logoutButton());
        dispatch(refrestState());
        toast.success(res.data.message);
        navigate("/");
      })
      .catch((error) => {
        console.error("Logout error:", error);
        // Handle error if necessary
      })
  }

  const onActive = () => {
    console.log("onActive")
  
    setOpen(false)
  }

  const onPrompt = () => {
    console.log("onPrompt")
  
    setOpen(true)
  }
  
  const handleClose = ()=>{
    setOpen(false)
  }


  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000))
    }, 500)

    return () => {
      clearInterval(interval)
    }
  })

  const handleStillHere = () => {
    activate()
  }

  const timeTillPrompt = Math.max(remaining - promptBeforeIdle / 1000, 0)
  const seconds = timeTillPrompt > 1 ? 'seconds' : 'second'

  return (
    <>
     <Dialog
        
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
      >
        <DialogContent>
    
      {timeTillPrompt > 0 && (
        <p>
          {timeTillPrompt} {seconds} until prompt
        </p>
      )}
        <h3>Are you still here?</h3>
        <p>Logging out in {remaining} seconds</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleStillHere} color="primary">
          Im still here
          </Button>
  
        </DialogActions>
      </Dialog>
    </>

  )
}








