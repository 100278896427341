import React, { useEffect, useState } from "react";
import { Formik, Form, ErrorMessage, validateYupSchema, Field } from "formik";
import * as Yup from "yup";

import {
  Grid,
  Box,
  Typography,
  TextField,
  Select,
  FormControl,
  MenuItem,
  CircularProgress,
} from "@mui/material";



//components
import FormTextField from "../../Components/Textfield";
import { Buttoncomponent } from "../../Components/Buttoncomp";

//redux store
import {  useAppSelector } from "../../Redux/Hook";

import { axiosPrivate } from "../../axios/axios";
import ErrorProps from "../../Components/Errorprops";
import useStyles from "../../styles";

interface EditDialogProps {
  onSubmit: any;
  handleClose: any;
}

const UpdateFacility: React.FC<EditDialogProps> = ({
  onSubmit,
  handleClose,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [facilityTypeOptions, setFacilityTypeOptions] = useState<any[]>([]);
  const getid = useAppSelector((state) => state.providerAuth.login);
  const [autoCompleteData, setAutoCompleteData] = useState<any>([]);
  const [errorMessage3, setErrorMessage3] = useState("");
  const [isTrue,setIsTrue] = useState<boolean>(false);
  const [errorMessage2, setErrorMessage2] = useState("");
  const [isTrue1,setIsTrue1] = useState<boolean>(false);
  const [zipCodedata, setZipCodedata] = useState<any>([]);
  const facilityinput = useAppSelector(
    (state: { providerFacility: { fData: any } }) =>
      state.providerFacility.fData
  );

  const classes = useStyles();

  const initialValues = {
    providerID: getid.userID,
    facilityNPI: facilityinput.facilityNPI,
    facilityName: facilityinput.facilityName,

    MainfacilityType: facilityinput.facilityType?.MainfacilityType || "",
    OthersfacilityType: facilityinput.facilityType?.OthersfacilityType || "",
    addressLine1: facilityinput.address.addressLine1,
    addressLine2: facilityinput.address.addressLine2,
    city: facilityinput.address.city,
    state: facilityinput.address.state_code,
    zipCode: facilityinput.address.zipCode,
    country: facilityinput.address.country,
    latitude: facilityinput.latitude,
    longitude: facilityinput.longitude,
    contact: facilityinput.contact,
    email: facilityinput.email,
  };

  useEffect(() => {
    axiosPrivate.get("/organization/cityStateList").then((res) => {
      setAutoCompleteData(res.data.data)
   
    })
    .catch((err)=>{
      console.log(err)
    })
  }, []);

  useEffect(() => {
    axiosPrivate
      .get("/search/cityStateList")
      .then((res) => {
        setZipCodedata(res.data.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  
  useEffect(() => {
    const getFacilityType = async () => {
      await axiosPrivate
        .get(`/facility/findfacilityType`)
        .then((res) => {
          setFacilityTypeOptions(res.data.data);
        })
        .catch((error) => {
          throw new Error("An error occurred while processing the data.");
        });
    };
    
    getFacilityType();
    document.addEventListener("DOMContentLoaded", function () {
      const emailInput = document.getElementsByName("email")[0];
      if (emailInput) {
        emailInput.setAttribute("autocomplete", "off");
      }
    });
  }, []);
  const validationSchema = Yup.object().shape({

    facilityName: Yup.string().required("Facility Name is required"),

    MainfacilityType: Yup.string().required("Facility Type is Required"),
    addressLine1: Yup.string().required("Street Address1 is required"),

    city: Yup.string()
      .nullable()
      .required("City is required")
      .matches(
        /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/,
        "City name should be alpha-characters"
      ),
      zipCode: Yup.string()
      .required("Zipcode is required")
      .matches(/^\d{5}$/, "Zipcode should be exactly 5 numeric digits")
      .test({
        name: "check-zipcode-exists",
        message: "Please give valid US zipcode",
        test: function (value) {
          return zipCodedata.some((item: any) => item.ZIP_CODE === value);
        },
      }),
    state: Yup.string()
      .nullable()
      .required("State is required")
      .matches(/^[A-Za-z -]+$/, "State name should be alpha-characters")
      .min(2, "State must be at least 2 characters.")
      .max(100, "State has a maximum limit of 100 characters."),
    country: Yup.string()
      .required("Country is required")
      .oneOf(["US", "USA", "UnitedStates"]),

    contact: Yup.string()
      .required("Phone number is required")
      .matches(/^\((?!0\d{2}\))\d{3}\) \d{3}-\d{4}$/, "Invalid Phone Number"),
    email: Yup.string().email().required("Email is required"),
  });

  const formatPhoneNumber = (input: any) => {
    const cleaned = input.replace(/\D/g, "");
    const formatted = cleaned.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    return formatted;
  };
  const CustomPhoneInput = ({ field, form, ...props }: any) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    const handleChange = (e: any) => {
      const formattedNumber = formatPhoneNumber(e.target.value);
      setFieldValue(name, formattedNumber);
    };

    return (
      <TextField
        className={classes.signuptext}
        {...props}
        {...field}
        onChange={handleChange}
        value={value}
        placeholder="000-000-0000"
        fullWidth
        helperText={
          <ErrorMessage name="contact">
            {(error) => <ErrorProps>{error}</ErrorProps>}
          </ErrorMessage>
        }
        autoComplete="new-country-area"
      />
    );
  };

  return (
    
    <Box >
       {facilityTypeOptions && facilityTypeOptions.length > 0 ? (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({  values,setFieldValue,touched }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography className={classes.formHead} mb={"0.3rem"}>
                  Edit Facility Information
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography className={classes.h6Org}>
                  Facility NPI{" "}
                  <Typography
                    component="span"
                    display="inline"
                    sx={{ color: "red" }}
                  >
                    *
                  </Typography>
                  <Typography
                    component="span"
                    display="inline"
                    style={{
                      textAlign: "left",
                      color: "red",
                      fontFamily: "Inder",
                      fontSize: 10,
                      marginLeft: "0.5rem",
                      marginBottom: "1rem",
                    }}
                  >
                    (Readonly)
                  </Typography>
                </Typography>
                <Field
                  className={classes.signuptext}
                  as={TextField}
                  name="facilityNPI"
                  placeholder="Facility NPI"
                  type="text"
                  fullWidth={true}
                  inputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className={classes.h6Org}>
                  Facility Name{" "}
                  <Typography
                    component="span"
                    display="inline"
                    sx={{ color: "red" }}
                  >
                    *
                  </Typography>
                  <Typography
                    component="span"
                    display="inline"
                    style={{
                      textAlign: "left",
                      color: "red",
                      fontFamily: "Inder",
                      fontSize: 10,
                      marginLeft: "0.5rem",
                      marginBottom: "1rem",
                    }}
                  >
                    (Readonly)
                  </Typography>
                </Typography>
                <Field
                  className={classes.signuptext}
                  as={TextField}
                  name="facilityName"
                  placeholder="Facility Name"
                  type="text"
                  fullWidth={true}
                  inputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className={classes.h6Org}>
                  Facility Type{" "}
                  <Typography
                    component="span"
                    display="inline"
                    sx={{ color: "red" }}
                  >
                    *
                  </Typography>
                </Typography>
               
                  <FormControl
                    sx={{ width: "100%", backgroundColor: "#F9F9F9" }}
                  >
                    <Field
                      as={Select}
                      name="MainfacilityType"
                      MenuProps={{
                        sx: {
                          fontSize: 14,
                          fontFamily: "Montserrat",
                          color: "blue",
                        },
                      }}
                      renderValue={(selected: any) => (
                        <div
                          style={{
                            fontFamily: "Montserrat",
                            fontSize: 14,
                            color: "#252B42",
                          }}
                        >
                          {
                            (
                              facilityTypeOptions.find(
                                (opt: any) => opt.facilityTypeId === selected
                              ) || ({} as any)
                            ).item
                          }
                        </div>
                      )}
                    >
                      <MenuItem value="" disabled>
                        Select Facility Type
                      </MenuItem>
                      {(facilityTypeOptions || []).map(
                        (select: any, index: any) => (
                          <MenuItem
                            key={index + 1}
                            value={select.facilityTypeId}
                            style={{
                              fontFamily: "Montserrat",
                              fontSize: 14,
                              color: "#737373",
                            }}
                          >
                            {select.item}
                          </MenuItem>
                        )
                      )}
                    </Field>

                    <ErrorMessage name="MainfacilityType">
                      {(error) => <ErrorProps>{error}</ErrorProps>}
                    </ErrorMessage>
                  </FormControl>
                
                {values.MainfacilityType === "FACT-6" ? (
                  <FormTextField
                    className={classes.signuptext}
                    container={TextField}
                    name="OthersfacilityType"
                    placeholder="Enter options for facility Type"
                    type="text"
                    autoComplete="new-country-area"
                    sx={{
                      mt: "0.7rem",
                    }}
                  />
                ) : null}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className={classes.h6Org}>
                  Street Address 1{" "}
                  <Typography
                    component="span"
                    display="inline"
                    sx={{ color: "red" }}
                  >
                    *
                  </Typography>
                </Typography>
                <FormTextField
                  className={classes.signuptext}
                  container={TextField}
                  // label="Street Address1"
                  name="addressLine1"
                  placeholder="Street Address 1"
                  type="text"
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className={classes.h6Org}>
                  Street Address 2
                </Typography>
                <FormTextField
                  className={classes.signuptext}
                  container={TextField}
                  name="addressLine2"
                  placeholder="Street Address 2"
                  type="text"
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography className={classes.h6Org}>
                  City{" "}
                  <Typography
                    component="span"
                    display="inline"
                    sx={{ color: "red" }}
                  >
                    *
                  </Typography>
                </Typography>
                <Field
                as={TextField}
                  className={classes.signuptext}
                  // container {TextField}
                  // label="City"
                  name="city"
                  placeholder="City"
                  type="text"
                  fullWidth={true}
                  onChange={(e: any) => {
                
                    const typedState1 = e.target.value;
                      
                     setFieldValue("city", typedState1); 
                        if (!typedState1) {
                          setErrorMessage2("");
                          return; 
                        }
                        const cityExists = autoCompleteData.some(
                          (item:any) => item.city.toLowerCase() === typedState1.toLowerCase()
                        );
                        
                        if (cityExists) {
                         
                          setIsTrue1(false)
                          setErrorMessage2("")
                          setFieldValue("city", typedState1);
                        } 
                        else {
                          setIsTrue1(true)
                          setErrorMessage2("Invalid City")
                        
                        }
                      }}
                  
                />
                     {(errorMessage2 || touched.city) && ( 
                        <div style={{
                          textAlign: "left",
                          color: "red",
                          fontSize: "0.9rem",
                          marginTop: "0.6rem",
                        }}>
                          {errorMessage2 || (
                            <ErrorMessage name="city">
                              {(error) => <ErrorProps>{error}</ErrorProps>}
                            </ErrorMessage>
                          )}
                        </div>)}    
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography className={classes.h6Org}>
                  State{" "}
                  <Typography
                    component="span"
                    display="inline"
                    sx={{ color: "red" }}
                  >
                    *
                  </Typography>
                </Typography>
                <Field
                  className={classes.signuptext}
                  as={TextField}
                  id="state" 
                  // label="State"
                  name="state"
                  placeholder="State"
                  type="text"
                  fullWidth={true}
                  autoComplete="text"
                  value={values.state}
            
                  onChange={(e: any) => {
                
                const typedState = e.target.value;
                  
                 setFieldValue("state", typedState); 
                    if (!typedState) {
                      setErrorMessage3("");
                      return; 
                    }
                    const stateExists = autoCompleteData.some(
                      (item:any) => item.state_code.toLowerCase() === typedState.toLowerCase()
                    );
                    
                    if (stateExists) {
                     
                      setIsTrue(false)
                      setErrorMessage3("")
                      setFieldValue("state", typedState);
                    } 
                    else {
                      setIsTrue(true)
                      setErrorMessage3("Invalid State code")
                    
                    }
                  }}
                />
          {(errorMessage3 || touched.state) && ( 
  <div style={{
    textAlign: "left",
    color: "red",
    fontSize: "0.9rem",
    marginTop: "0.6rem",
  }}>
    {errorMessage3 || (
      <ErrorMessage name="state">
        {(error) => <ErrorProps>{error}</ErrorProps>}
      </ErrorMessage>
    )}
  </div>)}
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography className={classes.h6Org}>
                  Zipcode{" "}
                  <Typography
                    component="span"
                    display="inline"
                    sx={{ color: "red" }}
                  >
                    *
                  </Typography>
                </Typography>
                <FormTextField
                  className={classes.signuptext}
                  container={TextField}
                  // label="ZipCode"
                  name="zipCode"
                  placeholder="Zipcode"
                  type="text"
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography className={classes.h6Org}>
                  Country
                  <Typography
                    component="span"
                    display="inline"
                    sx={{ color: "red" }}
                  >
                    *
                  </Typography>
                </Typography>
                <Field
                  className={classes.signuptext}
                  as={TextField}
                  name="country"
                  placeholder="Country"
                  type="text"
                  fullWidth={true}
                  autoComplete="new-country-area"
                  helperText={
                    <ErrorMessage name="country">
                      {(error) => <ErrorProps>{error}</ErrorProps>}
                    </ErrorMessage>
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className={classes.h6Org}>
                  Phone{" "}
                  <Typography
                    component="span"
                    display="inline"
                    sx={{ color: "red" }}
                  >
                    *
                  </Typography>
                </Typography>
                <Field name="contact" component={CustomPhoneInput} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className={classes.h6Org}>
                  Email{" "}
                  <Typography
                    component="span"
                    display="inline"
                    sx={{ color: "red" }}
                  >
                    *
                  </Typography>
                </Typography>
                <FormTextField
                  className={classes.signuptext}
                  container={TextField}
                  name="email"
                  placeholder="Email"
                  type="email"
                  fullWidth={true}
                />
              </Grid>
              <Grid container item xs={12} justifyContent="left" gap={"5px"}>
                <Grid item>
                  <Buttoncomponent
                    className={classes.cancelButton}
                    type="button"
                    onClick={handleClose}
                    size="large"
                    fullWidth
                    variant="contained"
                    disable={isLoading}
                    sx={{
                      width: "150px",
                      textTransform: "capitalize",
                    }}
                  >
                    Cancel
                  </Buttoncomponent>
                </Grid>
                <Buttoncomponent
                  className={classes.orgButton}
                  type="submit"
                  size="large"
                  fullWidth={false}
                  variant="contained"
                  disable={isTrue || isTrue1}
                  sx={{
                    textTransform: "capitalize",
                    width: "150px",
                    borderRadius: 0,
                  }}
                >
                  Save Facility
                </Buttoncomponent>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>  ) : (
      
        <div style={{ textAlign: "center" }}>
        <CircularProgress  size={50} />
        <Typography>Loading</Typography>
        </div>
    
    )}
    </Box>
  );
};

export default UpdateFacility;
