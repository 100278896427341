import { useEffect, useState } from "react";
import {
  Paper,
  TextField,
  Box,
  Typography,
  Pagination,
  CircularProgress,
  Button,
  Grid,
  IconButton,
  Table,
  TableFooter,
  TableRow,
  TablePagination,
  TableBody,
  TableCell,
  TableHead,
  Collapse,
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  DialogContent,
  DialogActions,
   TableContainer,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import { InputAdornment } from "@mui/material";

import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { useAppDispatch, useAppSelector } from "../../Redux/Hook";

import { axiosPrivate } from "../../axios/axios";
import {
  serviceInfo,
 
} from "../../Redux/ProviderRedux/serviceSlice";
import {

  KeyboardArrowUp,
} from "@mui/icons-material";

import { toast } from "react-toastify";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import EditServiceTab from "./EditServiceTab";
import SearchIcon from "@mui/icons-material/Search";
import Pricelist from "./BulkuploadTabview";
import useStyles from "../../styles"
import React from "react";

interface forminitialValues {
  _id: string;
  SNo: string;
  ServiceCode: string;
  DiagnosisTestorServiceName: string;
  Organizationid?: string;
  OrganizationPrices: string;
  FacilityNPI?: string;
  FacilityPrices: string;
  FacilityName?: string;
  GridAlignment: "left" | "right" | "center";
}

export default function Servicelandingpage({ serviceName }: any) {
  const [data, setData] = useState<any>([]);

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredData, setFilteredData] = useState<string[]>(data);

  const [getfacdata, setGetfacDataa] = useState([] as forminitialValues[]);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const [selValue, setSelValue] = useState<any>();
 
  const dispatch = useAppDispatch();
  const itemsPerPage = 5;
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [page1, setPage1] = useState(1);
  const [open, setOpen] = useState<any>(-1);
  const [view, setView] = useState<boolean>(false);
  const [dataCheck, setDataCheck] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
 

  const classes=useStyles()



  const orgid = useAppSelector(
    (state) => state.providerOrganization.orgEditData
  );

  type CloseReason = "backdropClick" | "escapeKeyDown" | "closeButtonClick";
  interface DialogProps extends MuiDialogProps {
    onClose: (reason: CloseReason) => void;
  }

  const Dialog = ({
    title,
    open,
    onClose,
    children,
    ...props
  }: DialogProps) => {
    return (
      <MuiDialog
        onClose={(_, reason) => onClose(reason)}
        aria-labelledby="simple-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={props.maxWidth}
        slotProps={{
          backdrop: {
            style: { backgroundColor: "transparent", boxShadow: "none" },
          },
        }}
      >
        <DialogActions>
          <Button onClick={() => onClose("closeButtonClick")} color="primary">
            <CloseOutlinedIcon />
          </Button>
        </DialogActions>

        <DialogContent>{children}</DialogContent>
      </MuiDialog>
    );
  };

  const [openDialog, setOpenDialog] = useState(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };


  useEffect(() => {
    getData();

    const fetchData = async () => {
      try {
        await fetchFacilityData(serviceName);
      } catch (error) {
        console.error("Error fetching facility data:", error);
      }
    };
    fetchData();
  }, [serviceName]);

  useEffect(() => {
    setFilteredData(data);
    setPage1(1);
  }, [data]);
 

  const fetchFacilityData = async (serviceName: any) => {
    try {
      const encodedServiceName = encodeURIComponent(serviceName);
      const pricelistdetails = await axiosPrivate.get(
        `/service/getPriceListbyService?DiagnosisTestorServiceName=${encodedServiceName}&Organizationid=${orgid[0].organizationID}`
      );

      setGetfacDataa(pricelistdetails.data.data);
    } catch (error) {
      throw error;
    }
  };

  const updateState = () => {
    setOpen(-1);
  };
  const handleServiceClick = (serviceName: any, index: any) => {
    dispatch(serviceInfo(serviceName));
    fetchFacilityData(serviceName);
    setOpen(open === index ? -1 : index);
  };
  
  const serviceLandFlag = true;
  const getData = async () => {
    setDataCheck(true);
    axiosPrivate
      .get(
        `/service/getPricelistbyOrg?Organizationid=${orgid[0].organizationID}`
      )
      .then((resp) => {
        const d = resp.data.data;
        const dd = d.map((c: any) => c.DiagnosisTestorServiceName);
        const set = new Set<string>(dd);
        let ddd: string[] = [];
        set.forEach((s) => {
          ddd.push(s);
        
        });

        if (ddd.length == 0) {
          setDataCheck(false);
          setDataLoaded(true);
        } else {
          setDataCheck(false);
          setData(ddd);
          setGetfacDataa(d);

        }
      });
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchTerm = event.target.value.toLowerCase();
    setSearchTerm(newSearchTerm);

    if (newSearchTerm === "") {
      setFilteredData(data);
    } else {
      const newFilteredData = data.filter((service: string) =>
        service.toLowerCase().includes(newSearchTerm)
      );
      setFilteredData(newFilteredData);
    }

    setPage1(1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    setPage(page);
    
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setOpen(-1);
    setPage1(value);
    
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const handleEditClick = (row: forminitialValues) => {
    setSelectedRow(row);
    setOpenEditDialog(true);
  };

  const handleEditDialogClose = () => {
    setOpenEditDialog(false);
  };

  const Pointer = { cursor: "hand" };

  const handleRowClick = (row: any, event: React.MouseEvent) => {
    const isInsideEditDialog =
      event.target instanceof Element &&
      (event.target.closest(".EditServiceTabDialog") !== null ||
        event.target.closest(".MuiDialog-container") !== null);

    if (isInsideEditDialog) {
      return;
    }
    setSelectedRow(row);
  
  };

  //Bulk upload Button Actions
  const [openpricelistupload, setOpenpricelistupload] = useState(false);
  const handleClickOpenpricelistupload = () => {
    setOpenpricelistupload(true);
  };

  const handleClosepricelistupload = (value: CloseReason) => {
    setOpenpricelistupload(false);
  };

  // Delete Actions

  const handleDeleteConfirm = () => {
    if (selectedRow) {
      axiosPrivate
        .delete(`/service/deletePricelist?_id=${selectedRow}`)
        .then(() => {
          toast.success("Successfully deleted");
          const updatedFacData = getfacdata.filter(
            (dataPath) => dataPath._id !== selectedRow
          );
          setGetfacDataa(updatedFacData);
          const deletedServiceName = getfacdata.find(
            (dataPath) => dataPath._id === selectedRow
          )?.DiagnosisTestorServiceName;

          if (deletedServiceName) {
            const updatedData = data.filter(
              (serviceName: string) => serviceName !== deletedServiceName
            );

            setData(updatedData);
            setFilteredData(updatedData);
          }
          setSelectedRow(null);
          setView(false);
          getData();
          updateState();
        })
        .catch((error) => {
          setView(false);
        });
    }
  };

  return (
    <>
      {data.length !== 0 ? (
        <Box
       
          sx={{
            backgroundColor: "primary.light",
            borderRadius: "15px",
           
          }}
        >
          <Grid container display={"flex"} alignItems="stretch" spacing={0.5}>
            <Grid item md={9}>
              <Typography component="div"
            
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: "custom.customColor4",
                  pl: "0.7rem",
                  fontSize: 18,
                  fontFamily: "Inter",
                  fontWeight: 600,
                  color: "#FFFFFF",
                }}
              >
                List of Services
                <TextField
                  // fullWidth
                  sx={{
                    width: "75%",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: "white",
                    },
                  }}
                  variant="outlined"
                  // label="Search Service"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  InputProps={{
                    style: { color: "white" },
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon style={{ color: "white" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Typography>
            </Grid>
            <Grid item md={3}>
              <Button className={classes.viewButton}
                fullWidth
                variant="contained"
                type="button"
                // size="large"
                sx={{
                  backgroundColor: "custom.customColor6",
                  color: "#FFFFFF",
                  borderRadius: 0,
                  height: "100%",
                  textTransform: "capitalize",
                               }}
                onClick={handleClickOpenpricelistupload}
              >
                ADD CASH PRICE
                <CloudUploadOutlinedIcon
                  sx={{ fontSize: "2.7rem", padding: "1vh" }}
                />
              </Button>
              <Dialog
                open={openpricelistupload}
                onClose={handleClosepricelistupload}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth={"lg"}
                sx={{
                  "& .MuiDialog-container": {
                   
                    fullWidth: "true",
                    width: "100%",
                    maxWidth: "lg",
                  },
                }}
                PaperProps={{
                  elevation: 0,

                  style: {
                    width: "90vh",
               
                  },
                }}
              >
        
                <DialogContent>
                  <Pricelist
                    handleClosee={handleClosepricelistupload}
                    getData={getData}
                    serviceLandFlag={serviceLandFlag}
                  />
                </DialogContent>
                <DialogActions></DialogActions>
              </Dialog>
            </Grid>
          </Grid>

          {!dataCheck ? (
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "0.2rem",
              }}
            >
              {(itemsPerPage > 0
                ? filteredData.slice(
                    (page1 - 1) * itemsPerPage,
                    page1 * itemsPerPage
                  )
                : filteredData
              ).map((serviceName: any, index: any) => {
                return (
                  <React.Fragment key={index}>
                    <Paper
                      key={index}
                      sx={{
                        padding: "0.5rem",
                        m: "0.2rem",
                        fontSize: "0.9rem",
                        display: "flex",
                        // alignItems: "center",
                        justifyContent: "space-between",
                        backgroundColor: "custom.customColor3",
                      }}
                    >
                      <Button
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          color: "#000000",
                        }}
                        disableRipple
                        onClick={() => {
                          handleServiceClick(serviceName, index);
                          setSelValue(data[index]);
                        }}
                      >
                        <Typography className={classes.h1}
                          sx={{
                            "&.MuiTypography-root": {
                              textTransform: "capitalize",
                            },
                       
                            fontWeight: 400,
                        
                            textAlign:"left"
                          }}
                        >

                          {serviceName}
                        </Typography>

                        <KeyboardArrowUp
                          sx={{
                            transform:
                              open === data[index]._id
                                ? "initial"
                                : "rotate(-180deg)",
                          }}
                        />
                      </Button>
                    </Paper>
                    <Collapse in={open === index} timeout="auto" unmountOnExit>
                      <TableContainer component={Box}>
                   
                        <Table className={classes.table}
                        
                        >
                          <TableHead sx={{ backgroundColor: "#EDEDED" }}>
                            <TableRow >
                              <TableCell 
                              className={classes.tableCell}
                               
                              >
                                Service Code
                              </TableCell>
                              <TableCell className={classes.tableCell}
                               
                              >
                                Facility Name
                              </TableCell>
                              <TableCell className={classes.tableCell}
                              
                              >
                                Facility NPI
                              </TableCell>
                              <TableCell className={classes.tableCell}
                               
                              >
                                Organization Prices
                              </TableCell>
                              <TableCell 
                              className={classes.tableCell}
                              
                              >
                                Facility Prices
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontFamily: "Inter",
                                  fontSize: 16,
                                  fontWeight: 600,
                                  color: "#252B42",
                                  textAlign: "center",
                                  width: "10%",
                                
                                }}
                              >
                                Actions
                              </TableCell>
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {(rowsPerPage > 0 && getfacdata.length !== 0
                              ? getfacdata.slice(startIndex, endIndex)
                              : getfacdata
                            ).map((dataPath: any, i: any) => (
                              <TableRow 
                                key={i}
                                sx={{
                                  backgroundColor:
                                    (i + 1) % 2 === 0 ? "#EDEDED" : "white",
                                }}
                                onClick={(event) =>
                                  handleRowClick(dataPath._id, event)
                                }
                              >
                                
                                <TableCell className={classes.cellDetails}
                                  
                                >
                              
                                  {dataPath.ServiceCode}
                                </TableCell>
                                <TableCell className={classes.cellDetails}
                                 
                                >
                                 
                                  {dataPath.FacilityName}
                                </TableCell>
                                <TableCell className={classes.cellDetails}
                                 
                                >
                             
                                  {dataPath.FacilityNPI}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    fontFamily: "Montserrat",
                                    fontSize: 14,
                                    fontWeight: 400,
                                    color: "#252B42",
                                    textAlign: "right",
                                  }}
                                >
                              

                                  {dataPath.OrganizationPrices === null
                                    ? ""
                                    : "$ " +
                                      Number(
                                        dataPath.OrganizationPrices
                                      ).toLocaleString()}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    fontFamily: "Montserrat",
                                    fontSize: 14,
                                    fontWeight: 400,
                                    color: "#252B42",
                                    textAlign: "right",
                                  }}
                                >
                                  {dataPath.FacilityPrices === null
                                    ? ""
                                    : "$ " +
                                      Number(
                                        dataPath.FacilityPrices
                                      ).toLocaleString()}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    fontFamily: "Montserrat",
                                    fontSize: 14,
                                    fontWeight: 400,
                                    color: "#252B42",
                                    textAlign: "center",
                                  }}
                                >
                                  <IconButton
                                    style={Pointer}
                                    onClick={() => {
                                      handleEditClick(dataPath._id);
                                      dispatch(serviceInfo(dataPath));
                                    }}
                                  >
                                    <EditIcon style={Pointer} />
                                  </IconButton>
                                  <Dialog
                                    fullScreen={true}
                                    fullWidth={true}
                                    maxWidth={"xl"}
                                    sx={{
                                      width: "100%",
                                    }}
                                    open={openEditDialog}
                                    onClose={handleEditDialogClose}
                                  >
                                    <DialogContent>
                                      <DialogActions>
                                        <EditServiceTab
                                          handleCancel={handleEditDialogClose}
                                          onUpdateData={updateState}
                                        />
                                      </DialogActions>
                                    </DialogContent>
                                  </Dialog>
                                  <IconButton
                                    style={Pointer}
                                    onClick={() => {
                                      setView(true);
                                    }}
                                  >
                                    <DeleteIcon style={Pointer} />
                                  </IconButton>

                                  <Dialog
                                    open={view}
                                    maxWidth={"sm"}
                                    sx={{
                                      "& .MuiDialog-container": {
                                        justifyContent: "flex-center",
                                        alignItems: "flex-start",
                                      },
                                    }}
                                    PaperProps={{
                                      elevation: 0,
                                      style: {
                                        width: "90vh",
                                     
                                      },
                                      sx: {
                                        border: "solid 1px gray",
                                      
                                        m: 0,
                                        top: 20,
                                        left: 30,
                                      },
                                    }}
                                    // hideBackdrop
                                    onClose={() => setView(false)}
                                  >
                                    <DialogContent sx={{ color: "black" }}>
                                      Do you wish to confirm delete?
                                    </DialogContent>

                                    <DialogActions>
                                      <Button className={classes.yesButton}
                                        
                                        onClick={handleDeleteConfirm}
                                        
                                      >
                                        Yes
                                      </Button>
                                      <Button className={classes.noButton}
                                 
                                     
                                        onClick={() => setView(false)}
                                        autoFocus
                                      >
                                        Cancel
                                      </Button>
                                    </DialogActions>
                                  </Dialog>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                          <TableFooter>
                            <TableRow>
                              <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                count={getfacdata.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                labelDisplayedRows={({ from, to, count }) =>
                                  `${from}-${to} of ${
                                    count !== -1 ? count : ` ${to}}`
                                  }`
                                }
                                backIconButtonProps={{
                                  color: "secondary",
                                }}
                                nextIconButtonProps={{ color: "secondary" }}
                                showFirstButton={true}
                                showLastButton={true}
                                labelRowsPerPage={<span>Rows:</span>}
                                sx={{
                                  ".MuiTablePagination-toolbar": {
                                    backgroundColor: "#E3ECF6",
                                    // "rgba(100,100,100,0.5)"
                                  },
                                  ".MuiTablePagination-selectLabel, .MuiTablePagination-input":
                                    {
                                      fontWeight: "bold",
                                      color: "primary",
                                    },
                                }}
                              />
                            </TableRow>
                          </TableFooter>
                        </Table>
                       
                      </TableContainer>
                    </Collapse>
                    {/* <Divider sx={{backgroundColor:"blue"}}/> */}
                  </React.Fragment>
                );
              })}
              <Box sx={{ mt: "2rem" }} component="span">
                <Pagination
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    textTransform: "capitalize",
                  }}


                  count={Math.ceil(data.length / itemsPerPage)}
                  page={page1}
                  onChange={handlePageChange}
                  color="primary"
                 
                  siblingCount={0}
                  showFirstButton
                  showLastButton
                />
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                backgroundColor: "primary.light",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "84vh",
              }}
            >
              <Box>
                <CircularProgress color="inherit" size={50} />
                <Typography>Loading</Typography>
              </Box>
            </Box>
          )}
        </Box>
      ) : (
        <Box>
          {dataLoaded && (
            <Pricelist
              handleClosee={handleClosepricelistupload}
              getData={getData}
              serviceLandFlag={serviceLandFlag}
            />
          )}
        </Box>
      )}
    </>
  );
}
