import axios, { InternalAxiosRequestConfig } from "axios";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { logoutButton } from "../Redux/ProviderRedux/LoginSlice";

import { store } from "../Redux/Store";
import { accessTokentest } from "../Redux/ProviderRedux/LoginSlice";
import { adminAccessTokentest } from "../Redux/Admin/adminLogin";

// http://122.165.78.82:5003/api // http://localhost:5200/api // https://demo.carecadet.com/api
export const baseURL = "https://demo.carecadet.com/api"

export const axiosPrivate = axios.create({

  baseURL: baseURL
});


axiosPrivate.interceptors.request.use(
  (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    const tokenData = store.getState();

    config.headers = config.headers || {};
    config.headers["authorization"] = tokenData.providerAuth.login.token;

    return config;
  }
);

axiosPrivate.interceptors.response.use(
  (res) => {
    
    return res;
  },
  (error) => {
  
    return new Promise((resolve) => {
      const originalRequest = error.config;

      const refreshToken = store.getState().providerAuth.login.token;
      if (error.response && error.response.status === 401 && refreshToken) {
      
        originalRequest._retry = true;

        // body: formBody
        const response = axios
          .post(`${baseURL}/user/access`, { token: refreshToken })
          .then((res) => {
           
            return res;
          })
          .then((res) => {
            store.dispatch(accessTokentest(res.data.accessToken));
         
            // alert(JSON.stringify(store.getState().auth.login.token))
            originalRequest.headers.authorization = res.data.accessToken;
     
            return axios(originalRequest);
          })
          .catch((e) => {
            return Promise.reject(e);
          });
        resolve(response);
      }

      if (error.response) {
        throw error.response.data;
      }

      return Promise.reject(error);
    });
  }
 
);

export const adminAxiosPrivate = axios.create({
  // baseURL: "http://210.18.155.251:5003",
  baseURL:baseURL
});

adminAxiosPrivate.interceptors.request.use(
  (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    const tokenData = store.getState();

    config.headers = config.headers || {};
    config.headers["authorization"] = tokenData.adminAuth.adminLogin.token;

    return config;
  }
);

adminAxiosPrivate.interceptors.response.use(
  (res) => {
 
    return res;
  },
  (error) => {
   
    return new Promise((resolve) => {
      const originalRequest = error.config;
     
      const refreshToken = store.getState().adminAuth.adminLogin.token;
      if (error.response && error.response.status === 401 && refreshToken) {
 
        originalRequest._retry = true;

        // body: formBody
        const response = axios
          .post(`${baseURL}/user/access`, { token: refreshToken })
          .then((res) => {
       
            return res;
          })
          .then((res) => {
            store.dispatch(adminAccessTokentest(res.data.accessToken));
         
            originalRequest.headers.authorization = res.data.accessToken;
   
            return axios(originalRequest);
          })
          .catch((e) => {
            return Promise.reject(e);
          });
        resolve(response);
      }

      if (error.response) {
        throw error.response.data;
      }

      return Promise.reject(error);
    });
  }
 
);
