import { makeStyles } from "@mui/styles";
import theme from "./App";
import { Theme } from "@mui/material/styles";
import { string } from "yup";

interface MyStyles {
  h1: string;
  h6: string;
  h2: string;
  h3: string;
  h4: string;
  signuptext: string;
  signupbutton: string;
  h6WithLargeMargin: string;
  listItemnav: string;
  listItemsign: string;
  fontText: string;
  linkText: string;
  logout: string;
  logoutButton: string;
  navMenus: string;
  navMenulists: string;
  capitalize: string;
  menuMain: string;
  profileBox: string;
  h6Org: string;
  inlineOrg: string;
  errorOrg: string;
  
  orgButton: string;
  cancelButton: string;
  careCadet: string;
  caDet: string;
  orgDetails: string;
  orgDarkdetails: string;
  formHead: string;
  formType: string;
  searchTypo: string;
  serviceName: string;
  serviceDes: string;
  searchButton: string;
  clearButton: string;
  filters: string;
  filtersArrow: string;
  slider: string;
  ratingTypo: string;
  addSearch: string;
  DisSearch: string;
  noSearch: string;
  searchState: string;
  gridButton: string;
  facilitySearch: string;
  facilityName: string;
  tableCell: string;
  cellDetails: string;
  homeReg: string;
  viewButton: string;
  uploadTab: string;
  yesButton: string;
  noButton: string;
  pagination: string;
  fileTypo: string;
  template: string;
  manualText:string;
  manualCell:string;
  manualCelldetails:string;
  table:string;
  priceList:string;
   oddEvenbox:string; 
   dataGrid:string;
   dataGrid1:string;
   dataBox:string;
   editServicetypo:string;
   editServicetype:string;
   serviceInline:string;
   serviceText:string;
   landingtableCell:string;
   landingtableCelldetails:string;
   normalFlex:string;
   userName:string;
}

const useStyles = makeStyles((theme: Theme) => ({
  careCadet: {
    display: "flex",

    color: theme.palette.tertiary?.main,
  },
  caDet: {
    color: theme.palette.tertiary?.dark,
  },
  linkText: {
    textDecoration: "none!important",
    fontFamily: "Poppins!important",
    fontSize: "32px!important",
    fontWeight: "Medium!important",
  },
  h1: {
    fontSize: "16px!important",
    color: "#000000!important",
    fontFamily: "Inter!important",
  },

  h2: {
    fontSize: "22px!important",
    fontFamily: "Inter!important",
    color: "#000000!important",
    fontWeight: "bold!important",
    marginTop: "8px!important",
  },
  h3: {
    color: "black!important",
    fontFamily: "Inter!important",

    fontSize: "14px!important",
    letterSpacing: "0.2px!important",
    lineHeight: "28px!important",
  },
  h4: {
    color: `${theme.palette.tertiary?.contrastText}!important`,
    fontFamily: "Inter!important",
    textAlign: "right",
    fontSize: "16px!important",
  },
  h6: {
    marginBottom: "8px!important",
    marginTop: "16px!important",
    fontFamily: "Inder!important",
    color: "tertiary.light",
    fontSize: "0.875rem!important",
    lineHeight: "28px!important",
    letterSpacing: "0.2px!important",
  },
  h6Org: {
    marginBottom: "4.8px!important",

    fontFamily: "Inder!important",
    color: `${theme.palette.tertiary?.light}!important`,
    fontSize: "0.875rem!important",
    fontWeight: "400!important",
    letterSpacing: "0.2px!important",
  },
  formHead: {
    textAlign: "left",
    fontSize: "18px!important",
   
    fontFamily: "Inter!important",
  },
  inlineOrg: {
    fontSize: "0.7rem!important",
    color: "red",
    marginBottom: "1rem",
  },
  errorOrg: {
    fontSize: "0.8rem!important",
    fontFamily: "Montserrat!important",
    color: `${theme.palette.custom?.customColor1}!important`,
    marginTop: "0.3rem!important",
  },
  h6WithLargeMargin: {
    marginTop: "16px!important",
    // Include other common styles if necessary
  },

  signuptext: {
    ".MuiFormLabel-root ": {
      letterSpacing: "0.2rem",
      fontSize: "0.8rem",
      color: `${theme.palette.custom?.customColor1}!important`,
      fontFamily: "Montserrat",
    },
    "& .MuiOutlinedInput-root": {
      background: "#f4f4f4",
    },
    ".MuiInputLabel-shrink": {
      letterSpacing: 0,
    },
    "& input::placeholder": {
      fontSize: 14,
      fontFamily: "Montserrat",
      color: `${theme.palette.custom?.customColor1}!important`,
    },
    "& input": {
      fontSize: 14,
      fontFamily: "Montserrat",
      color: theme.palette.custom?.customColor2,
    },
    ".MuiInputBase-root": {
      backgroundColor: "#F9F9F9",
      color: theme.palette.custom?.customColor2,
    },
  },

  signupbutton: {
    fontFamily: "Inter!important",
    height: "100%",
    width: "100%",
    backgroundColor: `${theme.palette.custom?.customColor6}!important`,
    color: `${theme.palette.common?.white}!important`, // !important should be placed after the color value
    fontSize: "0.875rem!important",
    padding: "0.85rem",
    // "& .MuiInputBase-root": {
    //   fontFamily: "Inter!important",
    // },
    "&:hover": {
      color: `${theme.palette.secondary.dark} !important`,
      backgroundColor: "#e4ecf7!important",
      border: "1px solid blue",
      borderRadius: "2px",
      letterSpacing: "0.2rem",
      fontSize: "1rem",
    },
  },
  // signupbutton: {
  //   height: "100%",
  //   width:"100%",
  //   backgroundColor:"#2898FF",

  //   color: "#fff!important",

  //   fontSize: "0.875rem",
  //   padding: "0.85rem",
  //   ".MuiInputBase-input": {
  //     fontFamily: "Inter",
  //   },
  //   "&:hover": {

  //     color: theme.palette?.secondary.dark,
  //     backgroundColor:"#e4ecf7",
  //     border: "1px solid blue",
  //     borderRadius: "2px",
  //     letterSpacing: "0.2rem",
  //     fontSize: "1rem",
  //   },
  // },
  // signupbutton:{
  //  height: "100%!important",
  //   backgroundColor: "#2898FF",
  //   margin: "1vh",

  //   color: "#fff",
  //   "&:hover": {
  //     color: theme.palette.secondary.main,
  //     border: "1px solid blue!important",
  //     // letterSpacing: "0.2rem",
  //     // fontSize: "1rem",
  //   },
  // },
  orgDetails: {
    fontFamily: "Inter!important",
    fontSize: "12px!important",
    color: `${theme.palette.custom?.customColor2}!important`,
    fontWeight: "regular!important",

    lineHeight: "20px!important",
    lineSpacing: "30%!important",
  },
  orgDarkdetails: {
    fontFamily: "Inter!important",
    fontSize: "12px!important",
    fontWeight: "bold!important",
    color: "#000000!important",

    lineHeight: "20px!important",
    lineSpacing: "30%!important",
  },
  formType: {
    fontFamily: "Inder!important",
    fontSize: "14px!important",
    
  },
  orgButton: {
    fontSize: "16px!important",
    // width: "10vw",
    fontFamily: "Inter!important",
   

    letterSpacing: "0.2px!important",
    lineHeight: "28px!important",

    backgroundColor: `${theme.palette.custom?.customColor6}!important`,
    // width: {
    //   // xs: '100%',
    //   sm: '8vw' },

    color: `${theme.palette.common?.white}!important`,
    "&:hover": {
      backgroundColor: "#e4ecf7!important",
      color: `${theme.palette.secondary.dark} !important`,
      border: "1px solid blue!important",
      borderRadius: "2px!important",

      fontSize: "1rem!important",
    },
  },
  cancelButton: {
    fontSize: "16px!important",
    // width: "10vw",
    fontFamily: "Inter!important",
    
    borderRadius: "0px!important",

    lineHeight: "28px!important",

    backgroundColor: `${theme.palette.quaternary?.dark}!important`,
    // width: {
    //   // xs: '100%',
    //   sm: '8vw' },

    color: `${theme.palette.common?.white}!important`,
    "&:hover": {
      backgroundColor: "#e4ecf7!important",
      color: `${theme.palette.secondary.dark} !important`,
      border: "1px solid blue!important",
      borderRadius: "0px!important",
     
      fontSize: "1rem!important",
    },
  },

  listItemnav: {
    fontFamily: "Poppins",
    color: theme.palette.primary.main,
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
    letterSpacing: "0.48px",
    textDecoration: "none",
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.quaternary?.contrastText,
    },
    "&:active": {
      color: theme.palette.quaternary?.contrastText,
    },
  },
  listItemsign: {
    whiteSpace: "nowrap",
    fontSize: "16px",
    color: "black",
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.quaternary?.contrastText,
    },
    "&:active": {
      color: theme.palette.quaternary?.contrastText,
    },
    textTransform: "capitalize",
  },
  logout: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "6vh",

    flexWrap: "nowrap",
    gap: "0.5rem",
    color: "black",

    cursor: "pointer",
  },
  logoutButton: {
    backgroundColor: theme.palette.secondary.dark,
    width: "7vw",
    color: `${theme.palette.common?.white}!important`,
    ml: "15px",
    "&:hover": {
      color: theme.palette.secondary.dark,
      border: "1px solid blue",
    },
  },

  navMenus: {
    fontFamily: "Poppins!important",
    color: theme.palette.primary.main,
    fontSize: "16px!important",
    fontStyle: "normal!important",
    fontWeight: "400!important",
     lineHeight: "20px!important",
    letterSpacing: "0.48px!important",
    // textDecoration: "none!important",
    cursor: "pointer!important",

    borderRadius: "0px!important",

    "&:hover": {
      color:theme.palette.quaternary?.contrastText,
      borderRadius: "0px!important",
    },
    "&:active": {
      color: theme.palette.quaternary?.contrastText,
    },
  },
  navMenulists: {
    fontFamily: "Poppins!important",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
    letterSpacing: "0.48px",
    textDecoration: "none",
    cursor: "pointer",
  },
  menuMain: {
    fontSize: "1.2rem!important",
    color: "black!important",
    cursor: "pointer!important",
    fontFamily: "Poppins!important",
    "&:hover": {
      color:`${theme.palette.quaternary?.contrastText}!important`,
      // color:`${theme.palette.secondary.dark} !important`
    },
    "&:active": {
      color: `${theme.palette.quaternary?.contrastText}!important`,
    },
  },
  profileBox: {
    width: "10vw!important",
    display: "flex!important",
    justifyContent: "center!important",
    alignItems: "center!important",
    flexWrap: "nowrap",
    gap: "0.5rem!important",
    color: theme.palette.tertiary?.main,
    cursor: "pointer!important",
    fontFamily: "Poppins!important",
  },
  serviceName: {
    "& .MuiAutocomplete-endAdornment": {
      display: "flex!important",
      alignItems: "center!important",
      justifyContent: "center!important",
    },
    "& .MuiAutocomplete-clearIndicator": {
      cursor: "pointer",
      height: "auto!important",
    },

    "& .MuiAutocomplete-popupIndicator": {
      transform: "none!important",
    },
    "& .MuiInputBase-input": {
      background: theme.palette.common?.white,
      fontSize: { xs: "12px", md: "14px" },
      fontFamily: "Poppins",
    },
    "& .MuiInputBase-root": {
      background: theme.palette.common?.white,
      fontSize: { xs: "12px", md: "14px" },
      fontFamily: "Poppins",
    },
    borderRadius: 1,
    "&::placeholder": {
      fontFamily: "Poppins!important",
      fontSize: "1.3rem!important",
      color: "black!important",
    },
  },
  serviceDes: {
    borderRadius: 1,
    "&::placeholder": {
      fontFamily: "Poppins!important",
      fontSize: "1.1rem!important",
      color: "black!important",
    },
    "& .MuiInputBase-input": {
      background: `${theme.palette.common?.white}!important`,
      fontSize: { xs: "12px", md: "14px" },
      fontFamily: "Poppins!important",
    },
    "& .MuiFormLabel-root ": {
      letterSpacing: "0.2rem!important",
      fontSize: "1.5rem!important",
      fontFamily: "Poppins!important",
    },
    "& .MuiInputLabel-shrink": {
      letterSpacing: 0,
    },
  },
  searchButton: {
    // height: "100%",
    borderRadius: 0,
    backgroundColor: `${theme.palette.custom?.customColor8}!important`,
    color: "black!important",

    fontFamily: "Poppins!important",
    "&:hover": {
      color: theme.palette.secondary.dark,
      border: "1px solid blue",
    },
  },
  clearButton: {
    color: `${theme.palette.common?.white}!important`,
    display: "flex!important",
    justifyContent: "center!important",
    padding: "0.8rem!important",
    fontFamily: "Inter!important",
    fontSize: 14,
    fontWeight: 400,
  },
  filters: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontFamily: "Inter",
    fontSize: "16px",

    backgroundColor: theme.palette.custom?.customColor3,
    color: "black",

    fontWeight: "600",
    padding: "1rem",
  },
  filtersArrow: {
    display: "flex",
    alignSelf: "flex-end",
    color: "black",
    fontSize: "1.5rem",
  },
  slider: {
    ".MuiSlider-thumb": {
      height: 15,
      width: 15,
      backgroundColor: `${theme.palette.common?.white}!important`,
      border: `2px solid ${theme.palette.primary.main} !important`,
      boxShadow: `0px 0px 5px  ${theme.palette.primary.main} !important`,
      "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
        boxShadow: "0px 0px 5px  theme.palette.primary.main",
      },
      "&:before": {
        display: "none",
      },
    },
    color: theme.palette.primary.main,
  },
  ratingTypo: {
    fontFamily: "Inter!important",
    fontSize: "14px!important",

    color: `${theme.palette.common?.white}!important`,
  },
  addSearch: {
    fontSize: "0.8rem",
    color: "black",
    mb: "10px",
  },
  DisSearch: {
    fontSize: "0.9rem",
    color: "blue",
    mb: "10px",
  },
  noSearch: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "10vh",
  },
  searchState: {
    backgroundColor: "primary.light",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "84vh",
  },
  gridButton: {
    display: "flex",
    fontFamily: "Inter",
    fontSize: "14px",
    justifyContent: "center",
    alignItems: "center",
  },
  facilitySearch: {
    fontFamily: "Inter!important",

    fontSize: "18px!important",
    color: "black!important",
    mb: "20px!important",
    fontWeight: "600!important",
  },
  tableCell: {
    fontSize: "16px!important",
     fontWeight: "600!important",
   
    // width: "9%",
    fontFamily: "Inter!important",
  },
  cellDetails: {
    fontFamily: "Montserrat!important",
    fontSize: "14px!important",
    
    color:`${ theme.palette.custom?.customColor2}!important`,
    
  },
  homeReg: {
    width: "80%",
    backgroundColor: `${theme.palette.custom?.customColor6}!important`,
   

    color: `${theme.palette.common?.white}!important`,
    "&:hover": {
      color: `${theme.palette.secondary.dark} !important`,
      border: "1px solid blue",
      backgroundColor: "#e4ecf7!important",
      // letterSpacing: "0.2rem",
      // fontSize: "1rem",
    },
  },
  viewButton: {
    // backgroundColor: "white!important",
    width: "100%",
    height: "100%",
    // color: "black",
    "&:hover": {
      backgroundColor: "#e4ecf7!important",
      color: `${theme.palette.secondary.dark} !important`,
      border: "1px solid blue",
    },
    fontFamily:"Inter!important",
    border: "1px solid #B7B4B4!important",
   
    fontWeight: "bold!important",
    // ".MuiInputBase-input": {
    //   fontFamily: "Inter",
    // },
  },
  uploadTab: {
    fontFamily: "Inter!important",
    fontSize: "18px!important",
    // fontWeight: "bold!important",
    fontWeight: "400!important",
    // textTransform: "capitalize",
    backgroundColor: `${theme.palette.custom?.customColor3}!important`,
    "&.Mui-selected": {
      color: `${theme.palette.common?.white}!important`,
      backgroundColor: `${theme.palette.quaternary?.dark}!important`,
    },
  },
  yesButton: {
    backgroundColor: `${theme.palette.custom?.customColor6}!important`,
    fontFamily: "Inter!important",
    fontSize: 16,
    borderRadius: "0!important",

    color: `${theme.palette.common?.white}!important`,
    "&:hover": {
      backgroundColor: "#e4ecf7!important",
      color: `${theme.palette.secondary.dark}!important`,
      border: "1px solid blue!important",
    },
  },
  noButton: {
    backgroundColor: `${theme.palette.quaternary?.dark}!important`,
    color: `${theme.palette.common?.white}!important`,
    fontFamily: "Inter!important",
    fontSize: "16px",
    borderRadius: "0!important",
    "&:hover": {
      backgroundColor: "#e4ecf7!important",
      color: `${theme.palette.secondary.dark}!important`,
      border: "1px solid blue",
      // letterSpacing: "0.2rem",
      // fontSize: "1rem",
    },
  },
  fileTypo: {
    fontFamily: "Inter!important",
    fontSize: "18px!important",
    fontWeight: 400,
  },
  template: {
    color: "#000000",
    fontFamily: "Inter!important",
    fontSize: "16px!important",
   
  },
  manualText:{
    "& .MuiAutocomplete-popupIndicator": {
      transform: "none",
    },
    borderRadius: 1,
    "& input::placeholder": {
      fontSize: 14,
      fontFamily: "Montserrat",
      color: theme.palette.custom?.customColor1,
    },
    "& input": {
      fontSize: 14,
      fontFamily: "Montserrat",
      fontWeight: 400,
      backgroundColor: "#F9F9F9",
      color: theme.palette.custom?.customColor2,
    },
    ".MuiInputBase-root": {
      backgroundColor: "#F9F9F9",
      color: theme.palette.custom?.customColor2,
    },
  },
  manualCell:{
    fontSize: "14px!important",
    fontWeight: "600!important",
    fontFamily: "Inter!important",
  },
  manualCelldetails:{
    fontSize: "14px!important",
    fontFamily: "Montserrat!important",
    fontWeight: 400,
    color: theme.palette.custom?.customColor2,
  },
  table:{
    border: "1px solid theme.palette.custom.customColor10",
    "& .MuiTableCell-root": {
      borderLeft: "1px solid rgba(224, 224, 224, 1)",
      border: "1px solid rgba(224, 224, 224, 1)",
    },
  },
  priceList:{
 
    padding: "0.7rem",
    textAlign: "center",
    fontSize: "1.3rem!important",
  },
  oddEvenbox:{
      
    "& .super-app-theme--header": {
      backgroundColor: `${theme.palette.custom?.customColor12}!important`,
      fontWeight:7000
     },
  
     fontFamily: "Montserrat!important",
    //  width: 1,
    //  "& .odd": {
    //    bgcolor: "white!important",
    //  },
    //  "& .even": {
    //    bgcolor: "#EDEDED!important",
    //  },
     maxWidth: "100%",
  },
  dataGrid:{
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: 700,
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: "0.875rem",
    },
    "& .MuiDataGrid-footerContainer": {
      backgroundColor: `${theme.palette.custom?.customColor11}!important`,
    },
  
    "& .MuiDataGrid-row": {
      border: "1px solid #DEDEDE!important", // Adjust color and width as needed
    },

    "& .MuiDataGrid-colHeader": {
      border: "1px solid #DEDEDE!important",
      fontWeight: 7000,
      fontFamily: "Roboto",

    
    },
    "& .MuiDataGrid-colCell": {
      fontWeight: 7000,
      border: "1px solid #DEDEDE!important",
    },
    "& .MuiDataGrid-cell": {
      border: "1px solid #DEDEDE!important", // Adjust color and width as needed
    },

    border: "1px solid #DEDEDE!important",
    fontWeight: 400,
    fontFamily: "Roboto",
    
  },
  dataGrid1:{
 
      "& .MuiDataGrid-row": {
        border: "0.3px solid #DEDEDE", // Adjust color and width as needed
      },
      "& .MuiDataGrid-colHeader": {
        border: "0.3px solid #DEDEDE", // Adjust color and width as needed
      },
      "& .MuiDataGrid-cell": {
        border: "0.3px solid #DEDEDE", // Adjust color and width as needed
      },
      "& .MuiDataGrid-colCell": {
        border: "0.3px solid #DEDEDE", // Adjust color and width as needed
      },
      border: "0.3px solid #DEDEDE",
      maxWidth: "100%",
      overflowX: "auto",
      
      mt: 1,
      fontSize: "1rem",
      backgroundColor: theme.palette.custom?.customColor11,
      "& .MuiDataGrid-root": {
        border:` 1px solid ${theme.palette.custom?.customColor13}!important`, // Adjust color and width as needed
      },

      "& .MuiDataGrid-cell:hover": {
        color: `${theme.palette.common?.white}!important`,
      },
  },
  dataBox:{
    "& .super-app-theme--header": {
     
      backgroundColor: "white",
    },
 
  },
  editServicetypo:{
     color: "#000000",
                      fontFamily: "Inter",
                      fontSize: 18,
                      fontWeight: 400,  
  },
  editServicetype:{
    fontFamily: "Inder",
    fontSize: 14,
      fontWeight: 400,
                      
    color: theme.palette.tertiary?.contrastText,
  },
  serviceInline:{
    textAlign: "left",
    color: "red",
    fontSize: "0.7rem!important",
    marginLeft: "0.5rem",
    marginBottom: "1rem",
  },
  serviceText:{
            ".MuiFormLabel-root ": {
                          letterSpacing: "0.2rem",
                        },
                        ".MuiInputLabel-shrink": {
                          letterSpacing: 0,
                        },
                        borderRadius: 1,
                        "& input": {
                          fontSize: 14,
                          fontFamily: "Montserrat",
                          fontWeight: 400,
                          backgroundColor: "#F9F9F9",
                          color: theme.palette.custom?.customColor2
                        },
                        ".MuiInputBase-root": {
                          backgroundColor: "#F9F9F9",
                          color: theme.palette.custom?.customColor2
                        },
  },
  landingtableCell:{
    fontSize: "1rem!important",
    fontWeight: "bold!important",
       border: `1px solid ${theme.palette.custom?.customColor14} !important`
  },
  landingtableCelldetails:{
    fontSize: "1rem!important",
    border: `1px solid ${theme.palette.custom?.customColor14} !important`                    
           
  },
  normalFlex:{
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center", 
  },
  userName:{
    height: "7vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding:"10px",
    flexWrap: "nowrap",
    gap: "0.5rem",
    cursor: "pointer",
  }


})) as () => MyStyles;

export default useStyles;
