import React from "react";
import { Route, RouteProps, Navigate, useLocation } from "react-router";
import SideNavBar from "../component/SideNav/SideNavComp";
import { Box, Grid, Paper } from "@mui/material";

import { useAppSelector, useAppDispatch } from "../Redux/Hook";
import { refresh } from "../Redux/ProviderRedux/LoginSlice";

type Props = {
  children: React.ReactNode;
};
const ProtectedLogin = ({ children }: Props) => {

  const location = useLocation().pathname.split("/")[1];
  const authUser = useAppSelector(
    (state) => state.providerAuth.providerLogoutButton
  );


  return !authUser && location === "provider" ? (
    <> {children}</>
  ) : (
    <Navigate to="/provider/facility/viewFacility" replace />
  );
};

export default ProtectedLogin;


