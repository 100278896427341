import React from "react";
import { TextField, Box, Paper, Typography, Grid } from "@mui/material";
import { Formik, Form } from "formik";
import * as yup from "yup";

import { toast } from "react-toastify";
import { useNavigate, NavLink } from "react-router-dom";
import { Link } from "@mui/material";

import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import FormTextField from "../../Components/Textfield";
import { Buttoncomponent } from "../../Components/Buttoncomp";
import { useLocation } from "react-router-dom";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import { useAppDispatch, useAppSelector } from "../../Redux/Hook";
import {
  loginButton,
  storeLoginInfo,
} from "../../Redux/ProviderRedux/LoginSlice";
import { axiosPrivate } from "../../axios/axios";

import useStyles from "../../styles";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";

//AutoPlaySwipeableViews commented lines for future use
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    name: "John Doe",
    quote: "This is a great product! I would highly recommend it to anyone.",
  },
  {
    name: "Jane Doe",
    quote:
      "I've been using this product for a few months now and I'm really happy with it.",
  },
  {
    name: "Bob Smith",
    quote: "This product is amazing! It's changed my life for the better.",
  },
];
const schema = yup.object().shape({
  email: yup
    .string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Email is a required field")
    .email("Invalid email"),
  password: yup.string().required("password is a required field"),
});

export default function Login(props: any) {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  const data = location.state?.data?.Link;
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        mt: "2rem",
      }}
    >
      <Paper elevation={3} sx={{  padding: "3rem",width:{md:"50%"}}}>
        <Grid container 
        justifyContent={"center"}
        >
          {/* <Grid
            item
            xs={12}
            md={4}
            sx={{
              backgroundColor: "#f4f4f4",

              mt: 2,
              padding: 3,
            }}
          >
            <Typography sx={{ fontSize: "2.5rem", fontWeight: "bold" }}>
              Welcome to CareCadet Family
            </Typography>
            <Typography sx={{ fontSize: "1.5rem" }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.{" "}
            </Typography>

            <Box
              sx={{
                mt: "15vh",
                flexGrow: 1,
                borderRadius: 10,
                padding: 3,
                backgroundColor: "white",
              }}
            >
              <AutoPlaySwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
              >
                {images.map((step, index) => (
                  <div key={step.name}>
                    {Math.abs(activeStep - index) <= 2 ? (
                      <Box
                        component="div"
                        sx={{
                          display: "block",
                          overflow: "hidden",
                        }}
                      >
                        {" "}
                        <Typography fontSize={16}>
                          {/* {step.quote} */}
                        {/* </Typography>
                        <Box
                          marginTop={4}
                          sx={{ display: "flex", flexDirection: "row" }}
                        >
                          {/* <Avatar sx={{ bgcolor: "lightblue" }}>N</Avatar> */}
                          <Typography fontSize={23} marginLeft={2}>
                            {/* {images[activeStep].name} */}
                          </Typography>
                        {/* </Box>
                      </Box> */}
                    {/* ) : null}
                  </div>
                ))} */}
              {/* </AutoPlaySwipeableViews>
            </Box>
            <Box sx={{ backgroundColor: "#f4f4f4" }}>
              <MobileStepper
                sx={{ backgroundColor: "#f4f4f4", fontSize: "2vh" }}
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                  <Button
                    size="large"
                    onClick={handleNext}
                    disabled={activeStep === maxSteps - 1}
                  >
                    Next
                    {theme.direction === "rtl" ? (
                      <KeyboardArrowLeft />
                    ) : (
                      <KeyboardArrowRight />
                    )}
                  </Button>
                }
                backButton={
                  <Button
                    size="large"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                  >
                    {theme.direction === "rtl" ? (
                      <KeyboardArrowRight />
                    ) : (
                      <KeyboardArrowLeft />
                    )}
                    Back
                  </Button>
                }
              />
            </Box> */}
          {/* </Grid> */} 
          {/* <Grid item md={8} sx={{ justifyContent: "center" }}> */}
            <Formik
              validationSchema={schema}
              initialValues={{
                email: "",
                password: "",
                userType: "PROVIDER",
              }}
              onSubmit={(values) => {
                setIsLoading(true);

                const Logindata = {
                  userName: values.email,
                  password: values.password,
                  userType: values.userType,
                };

                axiosPrivate
                  .post("/user/login", Logindata)
                  .then((res) => {
                    setIsLoading(false);
                    toast.success(res.data.message);

                    dispatch(storeLoginInfo(res.data.data));
                    dispatch(loginButton());

                    navigate("/provider/facility/viewFacility");
                  })
                  .catch((err) => {
                    setIsLoading(false);

                    toast.warning(err.message);
                  });
              }}
            >
              <Form>
                <Grid container direction="row" justifyContent="center">
                  <Box
                    sx={{
                      bgcolor: "white",
                    }}
                  >
                    <Typography
                      className={`${classes.h1} ${classes.h6WithLargeMargin}`}
                      sx={{  whiteSpace: "nowrap"}}
                    >
                      Welcome Healthcare Provider !
                    </Typography>

                    <Grid>
                      <Typography className={classes.h2}>Sign In</Typography>
                    </Grid>
             

                    <Typography
                      className={`${classes.h6} ${classes.h6WithLargeMargin}`}
                    >
                      Email{" "}
                    </Typography>

                    <FormTextField
                      className={classes.signuptext}
                      container={TextField}
                      name="email"
                      placeholder="Enter Email"
                      type="email"
                      autoComplete="new-country-area"
                    />

                    <Typography className={classes.h6}>Password </Typography>
                    <FormTextField
                      className={classes.signuptext}
                      container={TextField}
                      name="password"
                      placeholder="Enter Password"
                      type="password"
                      autoComplete="new-country-area"
                      
                    />

                    <Grid item md={12}>
                      <Buttoncomponent 
                        className={`${classes.signupbutton} ${classes.h6WithLargeMargin}`}
                        type="submit"
                        size="large"
                        fullWidth
                        variant="contained"
                        disable={isLoading}
                        sx={{
                          textTransform: "capitalize",
                           
                        }}
                      >
                        Sign In
                      </Buttoncomponent>
                    </Grid>

                    <Typography
                      className={`${classes.h3} ${classes.h6WithLargeMargin}`}
                      sx={{ textAlign: "right" }}
                    >
                      <Link
                        sx={{ textDecoration: "none" }}
                        to="/provider/forgotpass"
                        component={NavLink}
                      >
                        Forgot password?
                      </Link>
                    </Typography>
                    <Divider
                      component="div"
                      variant="fullWidth"
                      sx={{ marginTop: "2vh" }}
                    />
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <Typography
                        className={`${classes.h3} ${classes.h6WithLargeMargin}`}
                      >
                        Need an account ?{" "}
                        <Link
                          sx={{ textDecoration: "none" }}
                          to="/provider/signup"
                          component={NavLink}
                          state={{ signup: data }}
                        >
                          <Typography
                            component="span"
                            sx={{ color: "custom.customColor6" }}
                          >
                            Sign Up
                          </Typography>
                        </Link>
                      </Typography>
                    </Box>
                  </Box>{" "}
                </Grid>
              </Form>
            </Formik>
          {/* </Grid> */}
        </Grid>
      </Paper>
    </Box>
  );
}
