import * as React from "react";
import { useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Box,
   CircularProgress,

} from "@mui/material";

import { useNavigate } from "react-router";
import * as XLSX from "xlsx";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";

import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import {
 
  GridColTypeDef,
  GridValueFormatterParams,
  GridColumns,
  GridPreProcessEditCellProps,
} from "@mui/x-data-grid";
import { useAppDispatch, useAppSelector } from "../../Redux/Hook";
import { axiosPrivate, baseURL } from "../../axios/axios";
// import { statusMessage } from "../../Redux/ProviderRedux/serviceSlice";
import { toast } from "react-toastify";
import CreateServicethroFacility from "./CreatemanuallythroFacility";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { RadioButton } from "../../Components/RadioButton";
import {
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  DialogContent,
  DialogActions,

} from "@mui/material";
import useStyles from "../../styles"

type cvsItem = {
  id: string;
  SNo: string;
  value: string;
  GridAlignment: "left" | "right" | "center";
};
type CloseReason = "backdropClick" | "escapeKeyDown" | "closeButtonClick";
interface DialogProps extends MuiDialogProps {
  onClose: (reason: CloseReason) => void;
}

const Dialog = ({ title, open, onClose, children, ...props }: DialogProps) => {
  return (
    <MuiDialog
      onClose={(_, reason) => onClose(reason)}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={props.maxWidth}
      slotProps={{
        backdrop: {
          style: { backgroundColor: "transparent", boxShadow: "none" },
        },
      }}
      PaperProps={props.PaperProps}
    >
      <DialogActions>
        <Button onClick={() => onClose("closeButtonClick")} color="primary">
          <CloseOutlinedIcon />
        </Button>
      </DialogActions>

      <DialogContent>{children}</DialogContent>
    </MuiDialog>
  );
};



interface DropzoneProps {
  addRow:any
  onFileUpload: (csvData: any[], filename: string) => void;
  handleCancel: any;
  handleCloseServicee:any
  dataCheeck:any
  handlePricelistUpdate:any
}

export default function PricelistUploadthroFacility({
  addRow,
  onFileUpload,
  handleCloseServicee,
  handleCancel,
  dataCheeck,
  handlePricelistUpdate
}: DropzoneProps) {
  const [csvData, setCsvData] = useState<any[]>([]);
  const [filename, setFilename] = useState("");
   const [columns, setColumns] = useState<any>([]);
    const [unknownHeader, setUnknownHeader] = useState<boolean>(false);
  const [openstatDialog, setOpenstatDialog] = useState<boolean>(false);
  const [nextButton, setNextButton] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingFile, setIsLoadingFile] = useState<boolean>(false);
  const navigate = useNavigate();
  const [page1, setPage1] = useState(1);
  const data = useAppSelector((state) => state.providerAuth.login);
  const dispatch = useAppDispatch();

  const [usertype, setUsertype] = useState("File Upload");

  
  const classes=useStyles()
  const radioChangeHandler = (e: any) => {
    setUsertype(e.target.value);
  };
  const orgid = useAppSelector(
    (state) => state.providerOrganization.orgEditData
  );

  const facilityinput = useAppSelector(
    (state) => state.providerService.serviceData
  );
  const emailData = useAppSelector((state) => state.providerAuth.login);


  const knownObj = [
    {
      headerName: "ServiceCode",
      headerType: "string",
      maxLength: 32,
    },
    {
      headerName: "SNo",
      headerType: "string",
      maxLength: 32,
    },
    {
      headerName: "DiagnosisTestorServiceName",
      headerType: "string",
      maxLength: 32,
    },

    {
      headerName: "OrganizationPrices",
      headerType: "string",
      maxLength: 32,
    },
    {
      headerName: "FacilityPrices",
      headerType: "string",
      maxLength: 32,
    },
  ];

  const usdPrice: GridColTypeDef = {
    type: "number",
    width: 130,
  
    valueFormatter: (params: GridValueFormatterParams<number>) => {
      if (params.value == null) {
        return "";
      }

      const valueFormatted = Number(params.value).toLocaleString();
      return `$ ${valueFormatted} `;
    },
    cellClassName: "font-tabular-nums",
  };

  const columnsFormat: GridColumns = [
    {
      field: "SNo",
      headerName: "S.No",
      editable: false,
      width: 100,
    },
    {
      field: "ServiceCode",
      headerName: "Service Code",
      editable: true,
      flex: 1,
      // width: 100,
    },
    {
      field: "DiagnosisTestorServiceName",
      headerName: "Diagnosis Test/Service Name",
      editable: true,
      flex: 2,
      // width: 350,
    },
    {
      field: "FacilityName",
      headerName: "Facility Name",
      editable: false,
      flex: 2,
      // width: 100,
    },
    {
      field: "OrganizationPrices",
      headerName: "Organization Prices",
      type: "number",
      editable: true,
      flex: 1,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const invalid = !Number(params.props.value);
        return { ...params.props, error: invalid };
      },

      // width: 100,
      align: "right",
      ...usdPrice,
    },
    {
      field: "FacilityNPI",
      headerName: "FacilityNPI",
      editable: false,
      flex: 1,
      // width: 100,
    },
    {
      field: "FacilityPrices",
      headerName: "Facility Prices",
      type: "number",
      editable: true,
      flex: 1,
      // width: 100,
      align: "right",
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const invalid = !Number(params.props.value);
        return { ...params.props, error: invalid };
      },

      ...usdPrice,
    },
  ];

  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
// For mobile view card component

  // const onButtonEdit = (e: any) => {
  //   var editData = csvData.map((d) => {
  //     if (d.SNo === e.SNo) {
  //       return e;
  //     } else {
  //       return d;
  //     }
  //   });

   
  //   setCsvData(editData);
  // };

  // const handlePageChange = (
  //   event: React.ChangeEvent<unknown>,
  //   value: number
  // ) => {
  //   setPage1(value);
  // };

  const Download = () => {
    axiosPrivate.get("/service/download?format=singleFacility").then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "singleFileFormat.csv");
      document.body.appendChild(link);
      link.click();
    });
  };

  //  radio button actions
  const [selectedValue, setSelectedValue] = useState("");
  const [open, setOpen] = useState<boolean>(false);
  // const handleRadioClick = (event: any) => {

  // };
  // const handleRadioClick1 = (event: any) => {
  //   setOpen(true);
   
  // };
  // const handleRadioChange = (event: any) => {
  //   setSelectedValue(event.target.value);
  // };
  // const handleRadioChange1 = (event: any) => {
  //   setOpen(true);
  //   setSelectedValue(event.target.value);
  // };

  const handleClickOpen = () => {
    
    setOpen(true);
  };

  const handleClose = (value: CloseReason) => {
    setOpen(false);
    setUsertype("File Upload");
  };
  const handleClosestat = (value: CloseReason) => {
    setOpenstatDialog(false);
  };
  const handleButtonClick = () => {

    if (dataCheeck){
      handleCloseServicee()//viewfacility
    }else{
      handleCancel()//landing for actions
    }
  
   
  };
  const [parsedheader, setParsedheader] = useState<any>();

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const { name } = file;

      setFilename(name);
      setIsLoadingFile(true);
      const reader = new FileReader();
      reader.onload = (e) => {
        const contents = e.target?.result as string;
        function handleHeaderData(headerData: any) {
      
          setParsedheader(headerData);
        }

        // Parse Excel file
        const workbook = XLSX.read(contents, { type: "binary" });
        setIsLoadingFile(false);
        const sheetName = workbook.SheetNames[0];
        const excelData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
        const columnsArray = XLSX.utils.sheet_to_json(
          workbook.Sheets[sheetName],
          { header: 1 }
        )[0];
     
        csvJSON(excelData, columnsArray, name);

        // }
      };

      reader.readAsBinaryString(file);
    }
  }, []);

  const handleUpload = (filename: any) => {
    if (csvData.length > 0 && filename) {
      
      var result = [];
      var facilityNPI = facilityinput.facilityNPI;
      var facilityName = facilityinput.facilityName;

      for (var i = 0; i < csvData.length; i++) {
        var obj: any = csvData[i];

        obj["Organizationid"] = orgid[0].organizationID;

        obj["FacilityName"] = facilityName;
        obj["FacilityNPI"] = facilityNPI;
        obj["serviceStatus"] = "Available";

        result.push(obj);
      }


      setCsvData(result);

   
      let datacheck = {
        name: filename,
        csv: csvData,
        emailData: emailData,
        organizationID: orgid[0].organizationID,
      };
  
      if (unknownHeader) {
        setUnknownHeader(false);
        axiosPrivate
          .post(`/service/unknownHeaderPricelist`, datacheck)
          .then((res) => {
            setColumns([]);
            setCsvData([]);

            setIsLoading(false);
            toast.success(res.data.message);
            navigate("/provider/facility/viewFacility");
          })
          .catch((err) => {
            setIsLoading(false);
    
            toast.warning(err.message);
          });
      }

      setNextButton(true);

      onFileUpload(csvData, filename);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "text/csv": [".csv"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
    }, // Accept CSV and Excel files
    // accept: {
    //   csv: [".csv"],
    //   xls: [".xlsx"],
    // }, // Accept CSV and Excel files
    maxFiles: 1, // Limit to one file
    maxSize: 2 * 1024 * 1024, // 2MB limit
    onDropRejected: () => {
      // Handle file size exceeded error
      toast.warning("File size exceeds 2MB limit. Please split the file and upload");
    },
  });
  function checkForDuplicates(csvData:any) {
    const servicecodeSet = new Set();
    for (let i = 0; i < csvData.length; i++) {
        const obj = csvData[i];
        if (servicecodeSet.has(obj.ServiceCode)) {
            return true; // Duplicate found
        }
        servicecodeSet.add(obj.ServiceCode);
    }
    return false; // No duplicates found
}
  function csvJSON(csvData: any, header: any, filename: any) {

    var result = [];
    var facilityNPI = facilityinput.facilityNPI;
    var facilityName = facilityinput.facilityName;
    const hasDuplicates = checkForDuplicates(csvData);

    if (hasDuplicates) {
        setNextButton(false);
        toast.warning("Duplicate entries found in the Service Code. Kindly remove duplicates and upload");
        return;
    }
    for (var i = 0; i < csvData.length; i++) {
      var obj: any = csvData[i];

      obj["Organizationid"] = orgid[0].organizationID;

      obj["FacilityName"] = facilityName;
      obj["FacilityNPI"] = facilityNPI;
      obj["serviceStatus"] = "Available";

      result.push(obj);
    }

 
    setCsvData(result);

    var validateHeaders = knownObj.map((d) => d.headerName);
   

    const knownHeaders = validateHeaders.filter((element) =>
      header.includes(element)
    );

    const isMatched = knownHeaders.length === validateHeaders.length;
    

    if (
      knownHeaders.length <= validateHeaders.length - 2 ||
      header.length > validateHeaders.length
    ) {
      setNextButton(false);
      toast.warning(
        "Please check the header name or download the sample csv format"
      );
    } else {
      if (validateHeaders.length === header.length && isMatched) {
        setNextButton(true);
        setUnknownHeader(false);
        setColumns(columnsFormat);

        setCsvData(csvData);
       
      } else {
        
        setUnknownHeader(true);
        setNextButton(false);
        setIsLoading(true);
        header.push("FacilityName", "FacilityNPI");

        const unknownFormat = header.map((da: any) => {

          if (da === "OrganizationPrices" || da === "FacilityPrices") {
            return {
              ...usdPrice,
              field: da,
              headerName: da,
              editable: false,
              flex: 1,
            };
          } else {
            return { field: da, headerName: da, editable: false, flex: 1 };
          }
        });

        setColumns(unknownFormat);

        setIsLoading(true);
        let datacheck = {
          name: filename,
          csv: csvData,
          emailData: emailData,
          organizationID: orgid[0].organizationID,
        };
     
        setUnknownHeader(false);
        setIsLoading(true);
        axiosPrivate
          .post(`/service/unknownHeaderPricelist`, datacheck)
          .then((res) => {
            setColumns([]);
            setCsvData([]);
          

            setIsLoading(false);
            setOpenstatDialog(true);
           
          })
          .catch((err) => {
            setIsLoading(false);
  
            toast.warning(err.message);
          });


        return JSON.stringify(csvData);
      }
    }
  }
  return (
    <Box>
      <div />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "2rem",
      
          padding: "1vh",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "1px",
          }}
        >
          <RadioButton
            changed={radioChangeHandler}
            id="1"
            isSelected={usertype === "File Upload"}
            value="File Upload"
            sx={{ color: "black" }}
          />
          <Typography className={classes.fileTypo}
            sx={{
           
              gap: "1px",
            }}
          >
            File Upload
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <RadioButton
            changed={radioChangeHandler}
            id="2"
            isSelected={usertype === "Manual Entry"}
            onClick={handleClickOpen}
            value="Manual Entry"
            sx={{
              color: "black",
              
            }}
          />
          <Typography className={classes.fileTypo}
         
          >
            Manual Entry
          </Typography>
        </Box>
      </Box>

      <Dialog
        maxWidth={"xl"}
        open={open}
        onClose={handleClose}
        children={
          <div>
            <CreateServicethroFacility
              handleCancel={handleClose}
              onCloseAll={handleCancel}
              addRow={addRow}
              handlePricelistUpdate={handlePricelistUpdate}
            />
          </div>
        }
      />
      <Grid container display = "flex" justifyContent={"center"}>
      <Grid item  justifyContent={"center"} >
       
        <Typography  className={classes.template}
          sx={{
            
            display: {xs:"block",sm: "inline"},
          }}>
          Don't have a template? 
          
        </Typography>{" "}
        <Typography className={classes.template}
          sx={{

            display: {xs:"block",sm: "inline"},
            textAlign:{xs:"center"}
          }}>
         Click here{" "}
          
        </Typography>{" "}
        </Grid>
        <Grid item >
        <Button
            variant="outlined"
            sx={{
              fontFamily: "Inter",
              fontSize: 14,
              fontWeight: 400,
              color: "#FFFFFF",
              textTransform: "capitalize",
              borderRadius: "0",
              marginLeft: { xs: 0, sm: "5px" }, 
              marginTop: { xs: "5px", sm: 0 },
              
              backgroundColor: "custom.customColor6",
              "&:hover": {
                color: "secondary.dark",
                border: "1px solid blue",
              },
            }}
            onClick={Download}
          >
            Download Sample Template
          </Button>{" "}
      </Grid>
      </Grid>
      
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          marginTop: "20px",
        }}
      >
        <Box
          {...getRootProps()}
          sx={{
            textAlign: "center",
            justifyItems: "center",
            border: "2px dashed #cccccc",
           
            width: {
              xs: '100%', 
              md: '900px' },
           
            padding: "20px",
            cursor: "pointer",
          }}
        >
          <input {...getInputProps()} />
          <CloudUploadOutlinedIcon
            sx={{ fontSize: "5rem", fontWeight: "none" }}
          />
          <Typography  sx={{ fontFamily: "Inter", fontWeight: 400, fontSize: 16 }}>Drag and drop CSV file </Typography>
          {isLoadingFile ? (
      <Box>
      <CircularProgress color="inherit" size={50} />
      <Typography>Loading</Typography>
      </Box>
    ) : (
          filename && (
            <Typography  sx={{ fontFamily: "Inter", fontWeight: 400, fontSize: 16 }}>
              Selected File: {filename}
            </Typography>
          )         
        )}
          </Box>
        {isLoading ? (
          <Dialog
            open={isLoading}
            onClose={handleClosestat}
            children={
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress
                  sx={{ color: "black", textAlign: "center" }}
                  size={40}
                />
                <Typography>Loading</Typography>
              </Box>
            }
          />
        ) : (
          <Dialog
            open={openstatDialog}
            onClose={handleClosestat}
            PaperProps={{ style: { display: "flex", textAlign: "center" } }}
            children={
              <Typography sx={{ fontSize: "1.3rem" }}>
                "Your request has been forwarded to the administrator for
                verification. Once verified, you will be notified via email and
                have the options to publish in service details tab."
              </Typography>
            }
          />
        )}

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button className={classes.noButton}
            type="button"
            onClick={handleButtonClick}
            fullWidth={false}
            variant="contained"
            sx={{
              mt: "5px",
          
              width: {
                xs: '100%', 
                md: '450px' },
              height: "7vh",
           
              textTransform: "capitalize",
            
              borderRadius: "0",
              
            }}
          >
            Cancel
          </Button>

          <Button 
            type="submit"
            
            disabled={!nextButton}
            fullWidth={false}
            variant="contained"
            sx={{
              mt: "5px",
              backgroundColor: "custom.customColor6",
              width: {
                xs: '100%', 
                md: '450px' }, 
       
              height: "7vh",
              fontFamily: "Inter",
              fontWeight: 400,
              fontSize: 16,
              color: "#FFFFFF",
              textTransform: "capitalize",
              borderRadius: "0",
              "&:hover": {
                color: "secondary.dark",
                border: "1px solid blue",
               
              },
            }}
            onClick={handleUpload}
          >
            Next
          </Button>
        </Box>
      </div>
    </Box>
  );
}
