import React from "react";
import { Navigate, useLocation } from "react-router";


import { useAppSelector, useAppDispatch } from "../Redux/Hook";


type Props = {
  children: React.ReactNode;
};
const AdminProtectedLogin = ({ children }: Props) => {

  const location = useLocation().pathname.split("/")[1];
 
  const authUser = useAppSelector(
    (state) => state.adminAuth.adminLogoutButton
  );



  
  return !authUser && location==="admin" ? (
    <> {children}</>
  ) : (
    <Navigate to="/admin" replace />
  );
};

export default AdminProtectedLogin;

