import { useEffect, useState } from "react";
import {
  Paper,

  Box,
  Grid,
  Button,
  Typography,
  TablePagination,
  Pagination,
  Collapse,
  IconButton,
  CircularProgress,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  DialogActions,
  DialogContent,

} from "@mui/material";


import {
  
  GridColumns,
 
  GridColTypeDef,
  GridValueFormatterParams,
  GridPreProcessEditCellProps
} from "@mui/x-data-grid";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Redux/Hook";
import { KeyboardArrowDown, KeyboardArrowUp, Edit } from "@mui/icons-material";

import { axiosPrivate } from "../../axios/axios";

import Pricelist from "./pricelistthrofacilitytabview";

import useStyles from "../../styles";

interface forminitialValues {
  _id: string;
  SNo: string;
  ServiceCode: string;
  DiagnosisTestorServiceName: string;
  Organizationid?: string;
  OrganizationPrices: string;
  FacilityNPI?: string;
  FacilityPrices: string;
  GridAlignment: "left" | "right" | "center";
}
interface rowProps {
  fac: any
}
function Row({ fac }: rowProps) {
 
  const [open, setOpen] = useState<boolean>(false)
  return (
    <Box>
      <IconButton
        aria-label="expand row"
        size="small"
        onClick={() => setOpen(!open)}
      >
        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      </IconButton>{fac.DiagnosisTestorServiceName}
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Paper sx={{ display: "flex", flexDirection: "column", mt: "0.2rem", padding: "1rem" }}>
          <Grid container item xs={12}>
            <Grid item xs={6} >


              <Typography sx={{ color: "blue" }}>ServiceCode </Typography>
            </Grid>
            <Grid item xs={2} >
              <Typography sx={{ color: "blue" }}>
                :
              </Typography>
            </Grid>
            <Grid item xs={4} >
              <Typography>
                {fac.ServiceCode}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={6} >


              <Typography sx={{ color: "blue" }}>Facility Name </Typography>
            </Grid>
            <Grid item xs={2} >
              <Typography sx={{ color: "blue" }}>
                :
              </Typography>
            </Grid>
            <Grid item xs={4} >
              <Typography>
                {fac.FacilityName}
              </Typography>
            </Grid>
          </Grid>
          {/* <Typography sx={{display:"flex"}}> <Typography sx={{color:"blue"}}>Facility NPI </Typography>  :  {fac.facilityNPI }</Typography> */}
          <Grid container item xs={12}>
            <Grid item xs={6} >


              <Typography sx={{ color: "blue" }}>Organization Prices </Typography>
            </Grid>
            <Grid item xs={2} >
              <Typography sx={{ color: "blue" }}>
                :
              </Typography>
            </Grid>
            <Grid item xs={4} >
              <Typography>
                ${fac.OrganizationPrices}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={6} >


              <Typography sx={{ color: "blue" }}>Facility Prices </Typography>
            </Grid>
            <Grid item xs={2} >
              <Typography sx={{ color: "blue" }}>
                :
              </Typography>
            </Grid>
            <Grid item xs={4} >
              <Typography>
                ${fac.FacilityPrices}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Collapse>
    </Box>
  )
}


export default function Pricelistlandingpage() {
  const [data, setData] = useState([] as forminitialValues[]);

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [dataCheck, setDataCheck] = useState(false)

  const [page1, setPage1] = useState(1);
  const dispatch = useAppDispatch();
  const classes=useStyles()


  const orgid = useAppSelector(
    (state) => state.providerOrganization.orgEditData
  );
  const facilityinput = useAppSelector(
    (state) => state.providerService.serviceData
  );

  const navigate = useNavigate();
  useEffect(() => {
   
    getData();
  }, []);
  const getData = async () => {
    setDataCheck(true)
    const pricelistdetails = await axiosPrivate.get(
      `/service/getPriceListbyFacility?facilityNPI=${facilityinput.facilityNPI}&Organizationid=${orgid[0].organizationID}`
    );
    const data = pricelistdetails.data.data;
    if (data.length == 0) {
      setDataCheck(false)
     
    } else {
      setDataCheck(false)
      setData(pricelistdetails.data.data);
    }

   
  };

  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const usdPrice: GridColTypeDef = {
    type: "number",
    width: 240,
    
    valueFormatter: (params: GridValueFormatterParams<number>) => {
      if (params.value == null) {
        return "";
      }

      const valueFormatted = Number(params.value).toLocaleString();
      return `$ ${valueFormatted} `;
    },
    cellClassName: "font-tabular-nums",
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const columns: GridColumns = [
    
    {
      field: "ServiceCode",
      headerName: "Service Code",
      headerClassName: "super-app-theme--header",
      minWidth: 200,
    },
    {
      field: "DiagnosisTestorServiceName",
      headerName: "Diagnosis Test/Service Name",
      headerClassName: "super-app-theme--header",
      minWidth: 405,
    
    },
    
    {
      field: "OrganizationPrices",
      headerName: "Organization Prices",
      headerClassName: "super-app-theme--header",
      minWidth: 200,
      
      type: "number",
      align: "right",
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const invalid = !Number(params.props.value);
        return { ...params.props, error: invalid };
      },
      ...usdPrice,
    },
    {
      field: "FacilityNPI",
      headerName: "FacilityNPI",
      headerClassName: "super-app-theme--header",
      minWidth: 200,
  
    },
    {
      field: "FacilityPrices",
      headerName: "Facility Prices",
      headerClassName: "super-app-theme--header",
      minWidth: 200,
   
      type: "number",
      align: "right",
      ...usdPrice,
    },
  ];


  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage1(value);
  };

  type CloseReason = "backdropClick" | "escapeKeyDown" | "closeButtonClick";
  interface DialogProps extends MuiDialogProps {
    onClose: (reason: CloseReason) => void;
  }

  const Dialog = ({ title, open, onClose, children, ...props }: DialogProps) => {
    return (
      <MuiDialog
        onClose={(_, reason) => onClose(reason)}
        aria-labelledby="simple-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={props.maxWidth}
        slotProps={{ backdrop: { style: { backgroundColor: 'transparent', boxShadow: 'none' } } }}
      >
        <DialogActions>
          <Button onClick={() => onClose("closeButtonClick")} color="primary">
            <CloseOutlinedIcon />
          </Button>
        </DialogActions>

        <DialogContent>{children}</DialogContent>

      </MuiDialog>
    );
  };

  const Pointer = { cursor: "hand" };

  return (
    <>{data.length === 0 && !dataCheck ? (<Pricelist />) : <>
      {!dataCheck ? (<>

        <Typography className={classes.priceList}
          mb={"0.5rem"}
          sx={{
            backgroundColor: {xs:"none" ,md:"#E3ECF6"} ,
         
            textTransform: "capitalize",
            whiteSpace:"nowrap",
           
          }}
        >
          {facilityinput.facilityName} PRICELIST
        </Typography>


        <Table
          sx={{ maxWidth: "100%", 

         }}
        >
          <TableHead sx={{ backgroundColor: "#EDEDED", border: "1px solid #CCCCCC" }}>
            <TableRow>

              <TableCell className={classes.landingtableCell}
              
              >
                Service Code
              </TableCell>
              <TableCell className={classes.landingtableCell}
                sx={{
             
                  textAlign: "left",
     
                }}
              >
                Diagnosis Test/Service Name
              </TableCell>
              <TableCell className={classes.landingtableCell}
                sx={{
                  
                  textAlign: "left",
          
                }}
              >
                Facility NPI
              </TableCell>
              <TableCell className={classes.landingtableCell}
                sx={{
                 
                  textAlign: "center",
                 
                }}
              >
                Organization Prices
              </TableCell>
              <TableCell className={classes.landingtableCell}
                sx={{
                  
                  textAlign: "center",
                 
                }}
              >
                Facility Prices
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {(rowsPerPage > 0
              ? data.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
              : data
            ).map((dataPath: any, i: any) => (
              <TableRow
                key={i}
                sx={{
                  backgroundColor: (i + 1) % 2 === 0 ? "#EDEDED" : "white",
                  border: "1px solid #CCCCCC"
                }}
              >

                <TableCell className={classes.landingtableCelldetails}
                sx={{
                 
                  textAlign: "left", 
               
                }}>
         
                  {dataPath.ServiceCode}
                </TableCell>
                <TableCell className={classes.landingtableCelldetails} sx={{
                 
                  textAlign: "left", 
                 
                }}>
                  {/* {dataPath.filePath.split("/")[2]} */}
                  {dataPath.DiagnosisTestorServiceName}
                </TableCell>
                <TableCell className={classes.landingtableCelldetails}sx={{
                  
                  textAlign: "left", 
                
                }}>
          
                  {dataPath.FacilityNPI}
                </TableCell>
                <TableCell className={classes.landingtableCelldetails}
                sx={{
                 
                  textAlign: "right", 
                 
                }}>
                

                  {dataPath.OrganizationPrices === null
                    ? ""
                    : "$ " +
                    Number(
                      dataPath.OrganizationPrices
                    ).toLocaleString()}
                </TableCell>
                <TableCell className={classes.landingtableCelldetails}sx={{
       
                  textAlign: "right", 
                
                }}>
             
                  {dataPath.FacilityPrices === null
                    ? ""
                    : "$ " +
                    Number(
                      dataPath.FacilityPrices
                    ).toLocaleString()}

                </TableCell>


              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} of ${count !== -1 ? count : ` ${to}}`}`
                }
                backIconButtonProps={{
                  color: "secondary",
                }}
                nextIconButtonProps={{ color: "secondary" }}
                showFirstButton={true}
                showLastButton={true}
                labelRowsPerPage={<span>Rows:</span>}
                sx={{
                  ".MuiTablePagination-toolbar": {
                    backgroundColor: "#E3ECF6",
                    // "rgba(100,100,100,0.5)"
                  },
                  ".MuiTablePagination-selectLabel, .MuiTablePagination-input":
                  {
                    fontWeight: "bold",
                    color: "#173A5E",
                  },
                }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      
        </>) :
        <Box
          sx={{
            backgroundColor: "primary.light",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "84vh",
          }}
        >
          <Box>
            <CircularProgress color="inherit" size={50} />
            <Typography>Loading</Typography>
          </Box>
        </Box>
      }

    </>

    }




    </>
  );
}

