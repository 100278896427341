import React from "react";
import { useState, useEffect, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Box,
  Typography,
  Grid,

  Paper,
 
  Autocomplete,
  AutocompleteRenderInputParams,
  createFilterOptions,
  IconButton,
  ButtonBase,
  CircularProgress,
} from "@mui/material";


import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../Redux/Hook";
import { NavLink, useNavigate } from "react-router-dom";


import { Buttoncomponent } from "../../Components/Buttoncomp";
import ErrorProps from "../../Components/Errorprops";

import { axiosPrivate } from "../../axios/axios";

import { InputAdornment } from "@mui/material";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import ClearIcon from "@mui/icons-material/Clear";
import {

  orgInfo,
  orgfileName,
  tabstatuss,
  contactInfor,
  contactimageInfor,
  delegateimageInfor,
  contactstatuss,
} from "../../Redux/ProviderRedux/orgSlice";

import { persistor } from "../../Redux/Store";

import { useTheme } from "@mui/material/styles";

import useStyles from "../../styles";



type CloseReason = "backdropClick" | "escapeKeyDown" | "closeButtonClick";

interface InitialValues {
  file: any;

  providerID: string;
  organizationName: string;
  streetAdd1: string;
  streetAdd2: string;
  country: string;
  city: string;
  state: string;
  zipCode: string;
  phone: string;
  Email: string;
}


export default function CreateOrganization() {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const dataLogin = useAppSelector((state) => state.providerAuth.login);

  const [currentFile, setCurrentFile] = useState<any>();
  const [imageData, setImageData] = useState<any>("");
  const [errorMessage, setErrorMessage] = useState("");
  const [autoCompleteData, setAutoCompleteData] = useState<any>([]);
  const [zipDisable, setZipDisable] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const classes = useStyles();

  
  const [fileName, setFileName] = useState<any>(null);

  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);


  const [errorMessage3, setErrorMessage3] = useState("");
  const [isTrue,setIsTrue] = useState<boolean>(false);

  const [text, setText] = useState<any>(
    "Maximum file size 2 MB. Image size 2:1 ratio. Supported file format JPG,JPEG,PNG"
  );
  const [open, setOpen] = React.useState<boolean>(false);

  const [zipCodedata, setZipCodedata] = useState<any>([]);

  const handleClose = (value: CloseReason) => {
    setOpen(false);
  };

  const fileInput = useRef<any>();

  const orgInput = useAppSelector(
    (state: { providerOrganization: { orgData: any } }) =>
      state.providerOrganization.orgData
  );

  const Fileinput = useAppSelector(
    (state: { providerOrganization: { fileNameorg: any } }) =>
      state.providerOrganization.fileNameorg
  );
  
 
  const CustomPaper = (props: any) => {
    return (
      <Paper
        elevation={8}
        sx={{ backgroundColor: "white", color: "black" }}
        {...props}
      />
    );
  };

  useEffect(() => {
   
  }, [currentFile, fileName, imageData]);

  useEffect(() => {
    axiosPrivate
      .get("/search/cityStateList")
      .then((res) => {
        setZipCodedata(res.data.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const initialValues: InitialValues = {
    file: "",
    providerID: dataLogin?.userID || "",
    organizationName: orgInput?.organizationName || "",
    streetAdd1: orgInput?.address?.addressLine1 || "",
    streetAdd2: orgInput?.address?.addressLine2 || "",
    country: orgInput?.address?.country || "USA",
    city: orgInput?.address?.city || "",
    state: orgInput?.address?.state || "",
    zipCode: orgInput?.address?.zipCode || "",
    phone: orgInput?.contact || "",
    Email: orgInput?.email || "",
  };

  const onSubmit = async (values: InitialValues, actions: any) => {
    const orgdata = {
      providerID: dataLogin.userID,
      organizationName: values.organizationName,
      orgImg: imageData,
      address: {
        addressLine1: values.streetAdd1,
        addressLine2: values.streetAdd2,
        city: values.city,
        state: values.state,
        zipCode: values.zipCode,
        country: values.country,
      },
      email: values.Email,
      contact: values.phone,
    };

    dispatch(orgInfo(orgdata));
    setIsLoading(true);
    setOpen(true);
    dispatch(tabstatuss(true));
    navigate("/provider/contactPersonInformation");
  };


  const SingleFileChange = () => {

 
    const selectedFile = fileInput.current.files[0];
    if (selectedFile.size > 2 * 1024 * 1024) { // 2MB limit
      dispatch(orgfileName(""))
      setErrorMessage("File size exceeds the limit (2MB)");
      return;
  }
    if (/\.(jpe?g|png|gif)$/i.test(selectedFile.name) === false) {
      
      dispatch(orgfileName(""))
      setErrorMessage("Unsupported File Format (Allowed[.png,.jpeg,.jpg,.gif])");
      return; 
    } else {
  
      setErrorMessage("");
      setCurrentFile(selectedFile);
    }
   
    setFileName(selectedFile ? selectedFile.name : "");
    dispatch(orgfileName(selectedFile ? selectedFile.name : ""));
    let formData = new FormData();
    formData.append("file", selectedFile);
    try {
      axiosPrivate
        .post("/organization/image", formData, {
          headers: {
            "Content-type": "multipart/form-data",
          },
        })
        .then((res) => {
          toast.success(res.data.message);
          const image = res.data.data.filename;

          setImageData(image);

      
        })
        .catch((err) => {
          setIsLoading(false);

          toast.error(err.message);
        });
    } catch (err) {
      setIsLoading(false);
      throw err;
    }
   
  };
  useEffect(() => {
    const getStatecityzip = async () => {
    axiosPrivate.get("/organization/cityStateList").then((res) => {
      setAutoCompleteData(res.data.data);
    });
  };
  getStatecityzip();
},[]);

  const handleCloseFile = () => {
    setErrorMessage("");
    setFileName("");
    dispatch(orgfileName(""));
    setText(
      "Maximum file size 2 MB. Image size 2:1 ratio. Supported file format JPG,JPEG, PNG"
    );
  };



  const OPTIONS_LIMIT = 10;
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options: any, state: any) => {
    return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  };

  const formatPhoneNumber = (input: any) => {
    const cleaned = input.replace(/\D/g, "");
    const formatted = cleaned.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    return formatted;
  };

  const CustomPhoneInput = ({ field, form, ...props }: any) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    const handleChange = (e: any) => {
      const formattedNumber = formatPhoneNumber(e.target.value);
      setFieldValue(name, formattedNumber);
      dispatch(tabstatuss(false));
    };

    return (
      <TextField
        className={classes.signuptext}
        {...props}
        {...field}
        onChange={handleChange}
        value={value}
        placeholder="(XXX) XXX-XXXX"
        fullWidth
        helperText={
          <ErrorMessage name="phone">
            {(error) => <ErrorProps>{error}</ErrorProps>}
          </ErrorMessage>
        }
        autoComplete="new-country-area"
      />
    );
  };

  const validationSchema = Yup.object().shape({
    organizationName: Yup.string().required("Organization Name is required")
    .matches(/^[A-Za-z0-9 -]+$/, "Organization Name can only contain alphabets, numbers"),
    streetAdd1: Yup.string().required("Address is required"),
    city: Yup.string()
    .nullable()
    .required("City is required")
    .matches(
      /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/,
      "City name should be alpha-characters"
    ),
    state: Yup.string()
      .required("State is required")
      .matches(/^[A-Za-z -]+$/, "State name should be alpha-characters")
      .min(2, "State must be at least 2 characters.")
      .max(100, "State has a maximum limit of 100 characters."),
 
    zipCode: Yup.string()
    .required("Zipcode is required")
    .matches(/^\d{5}$/, "Zipcode should be exactly 5 numeric digits")
    .test({
      name: "check-zipcode-exists",
      message: "Please give valid US zipcode",
      test: function (value) {
        return zipCodedata.some((item: any) => item.ZIP_CODE === value);
      },
    }),
    Email: Yup.string().required("Email is required")
    .matches(
      /^[a-zA-Z0-9._%+-]{1,64}@[a-zA-Z0-9.-]{1,255}\.[a-zA-Z]{2,}$/, 
      'Invalid email address format.'
    )
    .email("invalid email"),

    phone: Yup.string()
      .required("Phone number is required")

      .matches(/^\((?!0\d{2}\))\d{3}\) \d{3}-\d{4}$/, "Invalid Phone Number"),
  });

  return (
    <Box sx={{ mt: "0.5rem" }}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={onSubmit}
      >
        {({ handleChange, handleBlur, values, setFieldValue,touched,setFieldError }) => (
          <Form>
            <Grid container spacing={2} sx={{ p: "2rem" }}>
              <Grid item xs={12} sm={6}>
                <Typography className={classes.h6Org}>
                  Organization Name{" "}
                  <Typography className={classes.inlineOrg}  component="span"display="inline">
                    *
                  </Typography>
                </Typography>
                <Field
                  className={classes.signuptext}
                  as={TextField}
                  name="organizationName"
                  placeholder="Enter Organization Name"
                  type="text"
                  fullWidth={true}
                  autoComplete="text"
                  onChange={(e: any) => {
                    handleChange(e);
                    setUnsavedChanges(true);
                    dispatch(tabstatuss(false));
                  }}
                  helperText={
                    <ErrorMessage name="organizationName">
                      {(error) => <ErrorProps>{error}</ErrorProps>}
                    </ErrorMessage>
                  }
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography className={classes.h6Org}>
                  Upload Organization Logo
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    color: "black",
                  }}
                >
                  <Box position="relative" height={50} sx={{ width: "100%" }}>
                    <Box
                      position="absolute"
                      top={0}
                      bottom={0}
                      left={0}
                      right={0}
                    >
                      <Field
                        className={classes.signuptext}
                        as={TextField}
                        variant="outlined"
                        fullWidth
                        onChange={(e: any) => {
                          handleChange(e);
                          setUnsavedChanges(true);
                          dispatch(tabstatuss(false));
                        }}
                        placeholder="Choose file"
                        name="file"
                         value={Fileinput}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <InsertLinkIcon />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <IconButton
                              sx={{
                                visibility: currentFile ? "visible" : "hidden",
                              }}
                              onClick={handleCloseFile}
                            >
                              <ClearIcon />
                            </IconButton>
                          ),
                        }}
                      />
                    </Box>
                    <ButtonBase
                      sx={{
                        width: "85%",
                        height: "100%",
                      }}
                      component="label"
                    >
                      <input
                        style={{ display: "none" }}
                        id="upload-photo"
                        name="file"
                        type="file"
                        hidden
                        accept=".png,.jpg,.jpeg"
                        value={values.file}
                        ref={fileInput}
                        onChange={SingleFileChange}
                      />
                    </ButtonBase>
                  </Box>
                </Box>

                {errorMessage ? (
                  errorMessage && (
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                        fontSize: "0.9rem",
                        marginTop: "0.6rem",
                      }}
                    >
                      {errorMessage}
                    </div>
                  )
                ) : (
                  <Box className={classes.errorOrg}>{text}</Box>
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography className={classes.h6Org}>
                  Street Address 1{" "}
                  <Typography className={classes.inlineOrg} component="span" display="inline">
                    *
                  </Typography>
                </Typography>
                <Field
                  className={classes.signuptext}
                  as={TextField}
                  name="streetAdd1"
                  placeholder="Enter Street Address 1"
                  type="text"
                  fullWidth={true}
                  autoComplete="new-country-area"
                  onChange={(e: any) => {
                    handleChange(e);
                    setUnsavedChanges(true);
                    dispatch(tabstatuss(false));
                  }}
                  helperText={
                    <ErrorMessage name="streetAdd1">
                      {(error) => <ErrorProps>{error}</ErrorProps>}
                    </ErrorMessage>
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className={classes.h6Org}>
                  Street Address 2
                </Typography>
                <Field
                  className={classes.signuptext}
                  as={TextField}
                  name="streetAdd2"
                  placeholder="Enter Street Address 2"
                  type="text"
                  fullWidth={true}
                  autoComplete="new-country-area"
                  onChange={(e: any) => {
                    handleChange(e);
                    setUnsavedChanges(true);
                    dispatch(tabstatuss(false));
                  }}
                  helperText={
                    <ErrorMessage name="streetAdd2">
                      {(error) => <ErrorProps>{error}</ErrorProps>}
                    </ErrorMessage>
                  }
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <Typography className={classes.h6Org}>
                  Zipcode{" "}
                  <Typography  component="span"display="inline" className={classes.inlineOrg}>
                    *
                  </Typography>
                </Typography>
                <Field
                  className={classes.signuptext}
                  name="zipCode"
                  component={Autocomplete}
                  options={autoCompleteData}
                  loading={autoCompleteData.length === 0}
                  PaperComponent={CustomPaper}
                  filterOptions={filterOptions}
                  getOptionLabel={(option: any) => option.ZIP_CODE || option}
                  freeSolo
                  type="text"
                  fullWidth={true}
                  validateOnChange={true}
                  validateOnBlur={true}
                  onChange={(e: any, value: any) => {
                   
                    // handleChange(e);
                    setFieldValue(
                      "zipCode",
                      value !== null ? value.ZIP_CODE : ""
                    );
                    setFieldValue("city", value !== null ? value.city : "");
                    setFieldValue(
                      "state",
                      value !== null ? value.state_code : ""
                    );
                    setTimeout(() => {
                      setFieldError("zipCode", "");
                    }, 0);
                    setTimeout(() => {
                      setFieldError("city", "");
                    }, 0);
               

                   

                         if (value === null) {
                      setZipDisable(false);
                    } else {
                      setZipDisable(true);
                    }

                  
                    setUnsavedChanges(true);
                    dispatch(tabstatuss(false));
                  }}
              
                 value={values.zipCode||""}
                  renderInput={(params: AutocompleteRenderInputParams) => (
                    <TextField
                      {...params}
                      helperText={
                        <ErrorMessage name="zipCode">
                          {(error) => <ErrorProps>{error}</ErrorProps>}
                        </ErrorMessage>
                      }
                      name="zipCode"
                      type="text"
                      placeholder="Select Zip Code"
                      onChange={handleChange}
                      variant="outlined"
                      onBlur={handleBlur}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                          
                            {autoCompleteData.length === 0 ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                        
                      }}
                     
                    
                      
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography className={classes.h6Org}>
                  City{" "}
                  <Typography className={classes.inlineOrg} component="span" display="inline">
                    *
                  </Typography>
                </Typography>
                <Field
                  className={classes.signuptext}
                  as={TextField}
                  value={values.city}
                  onChange={(e: any) => {
                    handleChange(e);
                    setUnsavedChanges(true);
                    dispatch(tabstatuss(false));
                  }}
                  helperText={
                    <ErrorMessage name="city">
                      {(error) => <ErrorProps>{error}</ErrorProps>}
                    </ErrorMessage>
                  }
                  name="city"
                  placeholder=" Select City"
                  type="text"
                  fullWidth={true}
                  autoComplete="text"
                  inputProps={{
                    sx: {
                      "&::placeholder": {
                        
                      },
                    },
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography className={classes.h6Org}>
                  State{" "}
                  <Typography className={classes.inlineOrg} component="span" display="inline">
                    *
                  </Typography>
                </Typography>
                <Field
                  className={classes.signuptext}
                  as={TextField}
                  value={values.state}
           
                  name="state"
                  placeholder=" Select State"
                  type="text"
                  fullWidth={true}
                  autoComplete="text"
                  inputProps={{
                    sx: {
                      "&::placeholder": {
                        
                      },
                    },
                    readOnly: true,
                  }}
                  onChange={(e: any) => {
                        handleChange(e);
                    setUnsavedChanges(true);
                    dispatch(tabstatuss(false));
                
                    const typedState = e.target.value;
                      
                     setFieldValue("state", typedState); 
                        if (!typedState) {
                          setErrorMessage3("");
                          return; 
                        }
                        const stateExists = autoCompleteData.some(
                          (item:any) => item.state_code.toLowerCase() === typedState.toLowerCase()
                        );
                        
                        if (stateExists) {
                         
                          setIsTrue(false)
                          setErrorMessage3("")
                          setFieldValue("state", typedState);
                        } 
                        else {
                          setIsTrue(true)
                          setErrorMessage3("Invalid State code")
                        
                        }
                      }}
                    />
              {(errorMessage3 || touched.state) && ( 
      <div style={{
        textAlign: "left",
        color: "red",
        fontSize: "0.9rem",
        marginTop: "0.6rem",
      }}>
        {errorMessage3 || (
          <ErrorMessage name="state">
            {(error) => <ErrorProps>{error}</ErrorProps>}
          </ErrorMessage>
        )}
      </div>)}
              
              </Grid>

              <Grid item xs={12} sm={3}>
                <Typography className={classes.h6Org}>Country</Typography>
                <Field
                  className={classes.signuptext}
                  as={TextField}
                  onChange={(e: any) => {
                    handleChange(e);
                    setUnsavedChanges(true);
                    dispatch(tabstatuss(false));
                  }}
                  name="country"
                  placeholder="Select Country"
                  type="text"
                  fullWidth={true}
                  autoComplete="new-country-area"
                  helperText={
                    <ErrorMessage name="country">
                      {(error) => <ErrorProps>{error}</ErrorProps>}
                    </ErrorMessage>
                  }
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography className={classes.h6Org}>
                  Phone{" "}
                  <Typography
                  component="span"
                    display="inline"
                    className={classes.inlineOrg}
                   
                  >
                    *
                  </Typography>
                </Typography>
                <Field name="phone" component={CustomPhoneInput} />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography className={classes.h6Org}>
                  Email{" "}
                  <Typography component="span"display="inline" className={classes.inlineOrg}>
                    *
                  </Typography>
                </Typography>
                <Field
                  className={classes.signuptext}
                  as={TextField}
                  onChange={(e: any) => {
                    handleChange(e);
                    setUnsavedChanges(true);
                    dispatch(tabstatuss(false));
                  }}
                  helperText={
                    <ErrorMessage name="Email">
                      {(error) => <ErrorProps>{error}</ErrorProps>}
                    </ErrorMessage>
                  }
                  name="Email"
                  placeholder="Enter Email"
                  type="email"
                  fullWidth={true}
                  autoComplete="text"
                />
              </Grid>
              <Grid
                container
                item
                justifyContent="left"
                xs={12}
                gap={"5px"}
             
              >
                <Grid item xs={5.9} sm={1.43}>
                  <Buttoncomponent
                    className={classes.cancelButton}
                    type="button"
                    size="large"
                    fullWidth
                    variant="contained"
                    disable={isLoading}
                    onClick={() => {
                   

                      dispatch(contactInfor(""));
                      dispatch(orgInfo(""));
                      dispatch(orgfileName(""));
                      dispatch(contactimageInfor(""));
                      dispatch(delegateimageInfor(""));
                      dispatch(tabstatuss(false));
                      dispatch(contactstatuss(false));
                      window.location.reload();
                   
                    }}
                    sx={{ textTransform: "capitalize" }}
                  >
                    Cancel
                  </Buttoncomponent>
                </Grid>

                <Grid item xs={5.9} sm={1.43}>
                  <Buttoncomponent
                    className={classes.orgButton}
                    type="submit"
                    size="large"
                    fullWidth
                    variant="contained"
                    disable={isTrue}
                 
                    sx={{ textTransform: "capitalize" }}
                  >
                    Save
                  </Buttoncomponent>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
