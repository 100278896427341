import React from "react";
import { Navigate, useLocation, useNavigate } from "react-router";
import {
  Box,
  Grid,
  Typography,
  Divider,
  Paper,
  CircularProgress,
} from "@mui/material";


import { useAppSelector, useAppDispatch } from "../Redux/Hook";
import { axiosPrivate } from "../axios/axios";
import { organizationEdit } from "../Redux/ProviderRedux/orgSlice";
import OrganizationNav from "./OrganizationNav";
import CreateOrganizationTab from "../Pages/Organization/CreateOrganizationTab";
import OrgNavbar from "../component/Orgnavbar";

interface Props {
  children: JSX.Element;
  getData: string;
}

const ProtectedRoute = ({ children, getData }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const userID = useAppSelector((state) => state.providerAuth.login.userID);
  const authUser = useAppSelector((state) => state.providerAuth);

  const [data, setData] = React.useState<any>([]);
  const [value, setValue] = React.useState(false);
  const [orgNavload, setOrgNavload] = React.useState(false);
  const [rendered, setRendered] = React.useState(false);
  const [rendered1, setRendered1] = React.useState(false);
  const [loadingState, setLoadingState] = React.useState(false);

  const path = location.pathname.split("/")[1];

  const getData1 = () => {
    var previouslocation = location.state !== null ? true : false;
    if (data.length === 0 || previouslocation) {
      setValue(true);
      setLoadingState(false);
      axiosPrivate
        .get(`/organization/getOrganizationByProvider?providerID=${userID}`)
        .then((res) => {
          const resData = res.data.data;

          setLoadingState(true);
          setOrgNavload(true);

          if (resData.length === 0) {
            setRendered1(true);
            navigate("/provider/organizationCreate");
          } else {
            dispatch(organizationEdit(resData));
            setData(res.data.data);
         
            setValue(false);
          }
          setRendered(true);
        })
        .catch((err) => console.log(err));
    }
  };

  React.useEffect(() => {
    getData1();
  }, [getData]);

  let isAuth =
    path === "provider" &&
    authUser.login.userType === "PROVIDER" &&
    (authUser.login.token !== null || undefined || "") &&
    authUser.providerLogoutButton;

  return isAuth ? (
    <Grid container width="100%">
      {value && loadingState && data.length === 0 ? (
        <>
          {getData == "org" ? (
            <Grid>
              <Grid item>{rendered1 && <CreateOrganizationTab />}</Grid>
              {children}{" "}
            </Grid>
          ) : (
            <>{children}</>
          )}
        </>
      ) : (
        <Grid item xs={12}>
          {!loadingState && (
            <Box
              sx={{
                backgroundColor: "primary.light",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "84vh",
              }}
            >
              <Box>
                <CircularProgress color="inherit" size={50} />
                <Typography>Loading</Typography>
              </Box>
            </Box>
          )}

          {data.length > 0 && (
            <>
              {orgNavload && <OrgNavbar />}
              <Box sx={{ ml: { md: "2rem" } }}>{<OrganizationNav />}</Box>
              <Box sx={{ p: { xs: "0 1rem 0 1rem", md: "0 2rem 0 2rem" } }}>
                <Divider />
              </Box>
              <Box
                sx={{
                  width: "100%",
                  backgroundColor: "primary.light",
                  padding: { xs: "0.5rem", md: "1.8rem" },
                }}
              >
                {rendered && children}
              </Box>
            </>
          )}
        </Grid>
      )}
    </Grid>
  ) : (
    <Navigate to="/" replace />
  );
};

export default ProtectedRoute;
