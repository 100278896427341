import React from "react";

interface props {
  children: React.ReactNode;
}
export default function ErrorProps(props: props) {
  return (
    <span
      style={{
        textAlign: "left",
        color: "red",
        fontSize: 14,
        fontFamily: "Inder",
        fontWeight: 400,
      
      }}
    >
      {props.children}
    </span>
  );
}
