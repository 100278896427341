import React, { useState } from "react";
import { Button, Typography, Grid, Box } from "@mui/material";
import {

  GridRow,
} from "@mui/x-data-grid";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";


import { axiosPrivate } from "../../axios/axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";

import clsx from "clsx";
import useStyles from "../../styles";

interface TableViewProps {
  data: any;
  onCloseAll: any;
  handleCancel: any;
  addRow: any;
  handlePricelistUpdate:any
}


const TableViewCreateManually: React.FC<TableViewProps> = ({
  data,
  onCloseAll,
  handleCancel,
  addRow,
  handlePricelistUpdate
}) => {
  const {
    _id,
    ServiceCode,
    DiagnosisTestorServiceName,
    FacilityName,
    OrganizationPrices,
    FacilityNPI,
    FacilityPrices,
  } = data;

  const navigate = useNavigate();

  
  const classes=useStyles()

  const onSubmit = async (row: any) => {
 

    axiosPrivate
      .post("/service/createservice", data)
      .then((res) => {
        toast.success(res.data.message);
        handlePricelistUpdate()
        onCloseAll();
        addRow(data);
      })
      .catch((err) => {
        toast.warning(err.message);
      });
  };
  

  function CustomRow(props: any) {
    const { className, index, ...other } = props;

    return (
      <GridRow
        index={index}
        className={clsx(className, index % 2 === 0 ? "odd" : "even")}
        {...other}
      />
    );
  }
  return (
    <Box>
      <Typography sx={{ m: "50px 0 20px 0" }}>All set to publish</Typography>
      <TableContainer component={Paper}>
        <Table className={classes.table}
        
        >
          <TableHead   sx={{
                    backgroundColor: "#EDEDED",
                    border: "1px solid #CCCCCC",
                  }}>
            <TableRow>
              <TableCell className={classes.manualCell}
              
              >
                Service Code
              </TableCell>
              <TableCell className={classes.manualCell}
             
              >
                Diagnosis Test/Service Name
              </TableCell>
              <TableCell className={classes.manualCell}
             
              >
                Facility Name
              </TableCell>
             
              <TableCell className={classes.manualCell}
             
              >
                Facility NPI
              </TableCell>
              <TableCell className={classes.manualCell}
             
              >
                Facility Prices
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={classes.manualCelldetails}
            
              >
                {ServiceCode}
              </TableCell>
              <TableCell className={classes.manualCelldetails}
                sx={{
                  
                  textTransform: "capitalize",
                }}
              >
                {DiagnosisTestorServiceName}
              </TableCell>
              <TableCell className={classes.manualCelldetails}
              
              >
                {FacilityName}
              </TableCell>

              <TableCell className={classes.manualCelldetails}
               
              >
                {FacilityNPI}
              </TableCell>
              <TableCell className={classes.manualCelldetails}
                sx={{
                
                  textAlign: "right",
                }}
              >
                <Typography display={"inline"}>${" "}</Typography>
                {FacilityPrices}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container item xs={10} columnGap={1} justifyContent="left">
        <Button className={classes.noButton}
          type="button"
          onClick={handleCancel}
          fullWidth={false}
          variant="contained"
          sx={{
            mt: "3vh",
           
            width: "15vw",
            
            textTransform: "capitalize",
            borderRadius: "0",
            
          }}
        >
          Cancel
        </Button>
        <Button className={classes.yesButton}
          type="submit"
          onClick={onSubmit}
          fullWidth={false}
          variant="contained"
          sx={{
            mt: "3vh",
           
            textTransform: "capitalize",
            width: "15vw",
           
            borderRadius: "0",
            
          }}
        >
          Publish
        </Button>
      </Grid>
    </Box>
  );
};

export default TableViewCreateManually;
