import * as React from "react";
import { ReactElement, FC } from "react";
import { Formik, Form,Field, ErrorMessage } from "formik";
import { Box, Typography, Paper, Grid, TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../Redux/Hook";
import { Buttoncomponent } from "../../Components/Buttoncomp";
import FormTextField from "../../Components/Textfield";
import * as Yup from "yup";
import { axiosPrivate } from "../../axios/axios";
import { toast } from "react-toastify";

import {contactstatuss} from "../../Redux/ProviderRedux/orgSlice";
 
import useStyles from "../../styles";
import { textTransform } from "@mui/system";

import ErrorProps from "../../Components/Errorprops";

interface forminitialValues {
  firstName:string;
  lastName:string;
  contact:string;
  email: string;
  Message: string;
}

const Contact = () => {
  const dispatch = useAppDispatch();
  const data = useAppSelector(
    (state: { providerAuth: { login: any } }) => state.providerAuth.login
  );
  const classes = useStyles();
 
  const initialValues: forminitialValues = {
    firstName:"",
  lastName:"",
  contact:"",
    email: "",
    Message: "",
  };

  const formatPhoneNumber = (input: any) => {
    const cleaned = input.replace(/\D/g, "");
    const formatted = cleaned.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    return formatted;
  };
  const CustomPhoneInput = ({ field, form, errorMessage, ...props }: any) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    const handleChange = (e: any) => {
      const formattedNumber = formatPhoneNumber(e.target.value);
      setFieldValue(name, formattedNumber);
      dispatch(contactstatuss(false));
    };

    return (
      <TextField
        className={classes.signuptext}
        {...props}
        {...field}
        onChange={handleChange}
        value={value}
        placeholder="(XXX) XXX-XXXX"
        fullWidth
        helperText={
          <ErrorMessage name={name}>
            {(error) => <ErrorProps>{error}</ErrorProps>}
          </ErrorMessage>
        }
        autoComplete="new-country-area"
        
      />
    );
  };

  const onSubmit = (values: forminitialValues, actions: any) => {
    const contactdata = {
      firstName:values.firstName,
      lastName:values.lastName,
      contact:values.contact,
      email: values.email,
      Message: values.Message,
    };

    axiosPrivate
      .post("/contact/contactEmail", contactdata)
      .then((res) => {
        toast.success(res.data.message);
     
        actions.resetForm({
          values: {
            firstName:"",
            lastName:"",
            contact:"",
            email: "",
            Message: "",
          },
        });
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };
  const validationSchema = Yup.object().shape({
    firstName:Yup.string().required("Required")
    .matches(/^[A-Za-z -]+$/, "First Name can only contain alphabets."),
    lastName:Yup.string().required("Required")
    .matches(/^[A-Za-z -]+$/, "Last Name can only contain alphabets."),
    email: Yup.string().required("Required")

    .matches(
      /^[a-zA-Z0-9._%+-]{1,64}@[a-zA-Z0-9.-]{1,255}\.[a-zA-Z]{2,}$/, 
      'Invalid email address format.'
    )
    .email("invalid email"),
    contact: Yup.string()
    

    .matches(/^\((?!0\d{2}\))\d{3}\) \d{3}-\d{4}$/, "Invalid Phone Number"),
    Message: Yup.string().required("Required"),
  });

  return (
 
     
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          <Form>
            <Grid container spacing={2} sx={{ p: "2rem" }} >
            <Grid item xs={12}>
              <Typography className={classes.formHead}
                mb={"0.5rem"}
                sx={{
                  fontWeight: 600,
                
                }}
              >
               Contact Form
              </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className={classes.h6Org}
              
                >
                  First Name <Typography  className={classes.inlineOrg}  component="span"display="inline">
                    *
                  </Typography>
                </Typography>

                <FormTextField className={classes.signuptext}
                  container={TextField}
                  name="firstName"
                  placeholder="First Name"
                  type="text"
           
             
                />
              </Grid>
              <Grid item xs={12} sm={6}>
              <Typography className={classes.h6Org}
              
              >
                Last Name <Typography  className={classes.inlineOrg}  component="span"display="inline">
                  *
                </Typography>
                </Typography>

                <FormTextField className={classes.signuptext}
                  container={TextField}
                  name="lastName"
                  placeholder="Last Name"
                  type="text"
               
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className={classes.h6Org}
              
                >
                  Email  <Typography className={classes.inlineOrg}  component="span"display="inline">
                    *
                  </Typography>
                </Typography>

                <FormTextField className={classes.signuptext}
                  container={TextField}
                  name="email"
                  placeholder="E-mail"
                  type="email"
            
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className={classes.h6Org}>
                  Contact Number{" "}
                 
                </Typography>

                <Field name="contact" component={CustomPhoneInput} />
              </Grid>
                                 
             
              <Grid item xs={12} sm={6}>
              <Typography className={classes.h6Org}
              
              >
                Message <Typography  className={classes.inlineOrg}  component="span"display="inline">
                  *
                </Typography>
                </Typography>

                <FormTextField className={classes.signuptext}
                  container={TextField}
                  name="Message"
                  placeholder="Message"
                  type="textArea"
              
                  multirow={4}
                  multiline={true}
                  fullWidth={true}
                />
              </Grid>
              <Grid   
              container
                item
                justifyContent="left"
             >
              <Grid item >
                <Buttoncomponent className={classes.yesButton}
         
              
                  type="submit"
                  size="large"
                  fullWidth
                  variant="contained"
                
               
                  sx={{
                  
           
                    textTransform:"capitalize",
                    
                    fontSize: { xs: "0.9rem", md: "1rem" },
                  }}
                >
                  Submit
                </Buttoncomponent>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </Formik>
     
 
  );
};

export default Contact;
