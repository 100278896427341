import React from "react";
import { Route, RouteProps, Navigate, useLocation } from "react-router";
import SideNavBar from "../component/SideNav/SideNavComp";
import { Box, Grid, Paper } from "@mui/material";

import { useAppSelector, useAppDispatch } from "../Redux/Hook";
import { refresh } from "../Redux/ProviderRedux/LoginSlice";

type Props = {
  children: React.ReactNode;
};
const PatientLogin = ({ children }: Props) => {

  const location = useLocation().pathname.split("/")[1];
  const path=location===""?"patient":location
  const authUser = useAppSelector(
    (state) => state.patientAuth.patientLogoutButton
  );



  // let isAuth=true
  return !authUser && path==="patient" ? (
    <> {children}</>
  ) : (
    <Navigate to="/patient/checkPage" replace />
  );
};

export default PatientLogin;

