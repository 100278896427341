import { useEffect, useRef, useState } from "react";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Card,
  Typography,
  Container,
  ListItemButton,
  ListItemIcon,
  Collapse,
  ListItemText,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormGroup,
  MenuItem,
  Menu,
  Paper,
  Grid,
  TextField,
  Slider,
  CircularProgress,
  Pagination,
  Autocomplete,
  AutocompleteRenderInputParams,
  createFilterOptions,
  Divider,
  useMediaQuery,
} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { Formik, Form, ErrorMessage, Field } from "formik";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
//comp
import { Buttoncomponent } from "../../Components/Buttoncomp";
import ErrorProps from "../../Components/Errorprops";
//redux store
import { useAppSelector, useAppDispatch } from "../../Redux/Hook";
import MenuIcon from "@mui/icons-material/Menu";
import * as Yup from "yup";
import StarRateIcon from "@mui/icons-material/StarRate";
import { axiosPrivate } from "../../axios/axios";
// import {editButton} from "../../Redux/LoginSlice";
import FormTextField from "../../Components/Textfield";
import SelectField from "../../Components/Select";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import {
  dataSearch,
  dataSearchTenMiles,
  dataSearchTwentyMiles,
  dataSearchThirtyMiles,
  dataProviderSearch,
  dataQueryProvider,
} from "../../Redux/ProviderRedux/HomeSlice";
import {
  ArrowDropDown,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Replay,
} from "@mui/icons-material";
import { ceil, range, values } from "lodash";
import SearchNav from "../../ProtectedRoutes/SearchNav";
import { type } from "os";
import { ClassNames } from "@emotion/react";
import useStyles from "../../styles";
import React from "react";

export default function Providersearch() {
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [open1, setOpen1] = useState<boolean>(false);
  const [open2, setOpen2] = useState<boolean>(false);
  const [open3, setOpen3] = useState<boolean>(false);
  const [open4, setOpen4] = useState<boolean>(false);
  const [LoadingState, setLoadingState] = useState<boolean>(true);
  const [openScore, setOpenScore] = useState<boolean>(false);
  const [openRate, setOpenRate] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [distance, setDistance] = useState(30);
  const [checkText, setCheckText] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [data, setData] = useState([] as forminitialValues[]);
  const [service, setService] = useState(false);
  const [checkInfo, setCheckInfo] = useState<any>([]);
  const [zipCodedata, setZipCodedata] = useState<any>([]);
  const searchData = useAppSelector(
    (state) => state.homeReducer.providerSearchData
  );
  const [loading, setLoading] = useState<any>(false);
  const providerDataQuery = useAppSelector(
    (state) => state.homeReducer.providerDataQuery
  );
  const itemsPerPage = 5;

  const [page1, setPage1] = useState(1);

  const classes = useStyles();
  const [searchqueryData, setSearchqueryData] = useState(searchData);

  const [search, setSearch] = useState<any>([]);

  const [noOfPages] = useState(Math.ceil(search.length / itemsPerPage));
  const [facilityType, setFacilityType] = useState<any>([]);
  const [checkFacText, setCheckFacText] = useState<any>(false);
  const [facilityCheck, setFacilityCheck] = useState<any>("");

  const [insuranceDetails, setInsuranceDetails] = useState<any>([]);
  const [insuranceCheck, setInsuranceCheck] = useState<any>("UHC");
  const [checkInsText, setCheckInsText] = useState<any>(true);
  const [servicelocationType, setServiceLocationType] = useState<any>([]);
  const [checklocText, setCheckLocText] = useState<any>(true);
  const [locationCheck, setLocationCheck] = useState<any>("21");

  const [maxPrice, setMaxPrice] = useState<any>(100);
  const [minPrice, setMinPrice] = useState<any>(1);
  const [value, setValue] = useState<number[]>([0, 100]);
  const dispatch = useAppDispatch();

  const q = providerDataQuery.Service.split("-")[0].trim();

  const Servicedes = providerDataQuery.Service.trim();

  const locationQ = providerDataQuery.Location;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const isMdUp = useMediaQuery("(min-width:821px)");
  const handleItemClick = (item: any) => {
    // Add your functionality for each item click here
    handleClose(); // Close the dropdown after clicking an item
  };

  var pagination = {
    total: search.length,
    per_page: itemsPerPage,
    current_page: page1,
    last_page: Math.ceil(search.length / itemsPerPage),
    from: (page1 - 1) * itemsPerPage + 1,
    to:
      page1 * itemsPerPage < search.length
        ? page1 * itemsPerPage
        : search.length,
  };

  const OPTIONS_LIMIT = 10;
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options: any, state: any) => {
    return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  };

  useEffect(() => {
    const postData = {
      q: q,
      location: locationQ,
      insuranceProvider: insuranceCheck,
      serviceCode: locationCheck,
    };

    axiosPrivate
      .post(`/search/negotiatedSearch`, postData)
      .then((res) => {
        setLoadingState(true);
        dispatch(dataProviderSearch(res.data.data));

        setSearch(res.data.data);
        setLoadingState(false);
        const maxFilter = Math.max(
          ...res.data.data.map((fprice: any) => {
            return fprice.negotiatedRates.negotiated_rate;
          })
        );

        const minFilter = Math.min(
          ...res.data.data.map((fprice: any) => {
            return fprice.negotiatedRates.negotiated_rate;
          })
        );

        if (res.data.data.length !== 0) {
          setMaxPrice(maxFilter);
          setMinPrice(minFilter);
          setValue([minFilter, maxFilter]);
        }
      })
      .catch((e) => {
        setSearch([]);
        setLoadingState(false);
      });

    const getFacilityType = async () => {
      await axiosPrivate
        .get(`/FacilityType`)
        .then((res) => {
          setFacilityType(res.data.data);
          // dispatch(facilityTypeInfo(res.data))
        })
        .catch((e) => console.log(e));
    };

    const getInsuranceProvider = async () => {
      await axiosPrivate
        .get(`/insuranceProvider/findinsuranceProvider`)
        .then((res) => {
          setInsuranceDetails(res.data.data);

          // dispatch(facilityTypeInfo(res.data))
        })
        .catch((e) => console.log(e));
    };

    const getServiceLocation = async () => {
      await axiosPrivate
        .get(`/servicecode/findservicelocation`)
        .then((res) => {
          setServiceLocationType(res.data.data);
          // dispatch(facilityTypeInfo(res.data))
        })
        .catch((e) => console.log(e));
    };

    getFacilityType();
    getInsuranceProvider();
    getServiceLocation();
  }, []);
  useEffect(() => {
    axiosPrivate
      .get("/search/cityStateList")
      .then((res) => {
        setZipCodedata(res.data.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  const handleOpenNavMenu = (event: any) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage1(value);
  };
  interface forminitialValues {
    Service: any;
    Location: any;
  }

  const initialValues: forminitialValues = {
    Service: Servicedes,
    Location: locationQ,
  };

  const validationSchema = Yup.object().shape({
    // Service: Yup.string().required("Required"),
    Location: Yup.string()
      .required("Zipcode is required")
      .matches(/^\d{5}$/, "Zipcode should be exactly 5 numeric digits")
      .test({
        name: "check-zipcode-exists",
        message: "Location should be a valid US zipcode",
        test: function (value) {
          return zipCodedata.some((item: any) => item.ZIP_CODE === value);
        },
      }),
  });

  const onReSetFilters = () => {
    const postData = {
      q: Servicedes.split("-")[0].trim(),
      location: locationQ,
    };
    setLoading(true);
    axiosPrivate
      .post(`/search/negotiatedSearch`, postData)
      .then((res) => {
        setCheckText(false);
        setCheckFacText(false);

        setFacilityCheck("");
        setDistance(30);
        setLocationCheck("21");
        setInsuranceCheck("UHC");

        dispatch(dataProviderSearch(res.data.data));
        // dispatch(dataQueryProvider(values))

        setSearch(res.data.data);
        setLoading(false);
        // setSearchParams({ q: values.Service.trim(), location: values.Location.trim() });
        const maxFilter = Math.max(
          ...res.data.data.map((fprice: any) => {
            return fprice.negotiatedRates.negotiated_rate;
          })
        );

        const minFilter = Math.min(
          ...res.data.data.map((fprice: any) => {
            return fprice.negotiatedRates.negotiated_rate;
          })
        );

        if (res.data.data.length !== 0) {
          setMaxPrice(maxFilter);
          setMinPrice(minFilter);
          setValue([minFilter, maxFilter]);
        }
      })
      .catch((err) => {
        toast.error(err.message);
        setLoading(false);
      });
  };

  const onSubmit = (values: forminitialValues, actions: any) => {
    const postData = {
      q: values.Service.split("-")[0].trim(),
      location: values.Location.trim(),
      insuranceProvider: insuranceCheck,
      serviceCode: locationCheck,
    };

    setLoading(true);
    axiosPrivate
      .post(`/search/negotiatedSearch`, postData)
      .then((res) => {
        setCheckText(false);
        setCheckFacText(false);

        setFacilityCheck("");
        setDistance(30);
        setLocationCheck("21");
        setInsuranceCheck("UHC");

        dispatch(dataProviderSearch(res.data.data));
        dispatch(dataQueryProvider(values));

        setSearch(res.data.data);
        setLoading(false);
        setSearchParams({
          q: values.Service.split("-")[0].trim(),
          // q = providerDataQuery.Service.split('-')[0].trim();
          location: values.Location.trim(),
        });
        const maxFilter = Math.max(
          ...res.data.data.map((fprice: any) => {
            return fprice.negotiatedRates.negotiated_rate;
          })
        );

        const minFilter = Math.min(
          ...res.data.data.map((fprice: any) => {
            return fprice.negotiatedRates.negotiated_rate;
          })
        );

        if (res.data.data.length !== 0) {
          setMaxPrice(maxFilter);
          setMinPrice(minFilter);
          setValue([minFilter, maxFilter]);
        }
      })
      .catch((err) => {
        setSearch([]);
        dispatch(dataQueryProvider(values));
        setSearchParams({
          q: values.Service.split("-")[0].trim(),
     
          location: values.Location.trim(),
        });
     
        setLoading(false);
      });
  };

  //Table Pagination
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const filterFacilityType = (
    filter: any,
    dis?: any,
    type?: any,
    details?: any,
    insurance?: any,
    serviceLocation?: any,
    negotiatedRates?: any
  ) => {
    const noDistance = {
      q: details.Service.split("-")[0].trim(),
      location: details.Location.trim(),
      facilityType: type,

      serviceCode: serviceLocation,
      insuranceProvider: insurance,
    };
    const noFacilityType = {
      q: details.Service.split("-")[0].trim(),
      location: details.Location.trim(),
      distance: dis,

      serviceCode: serviceLocation,
      insuranceProvider: insurance,
    };
    const withFacilityType = {
      q: details.Service.split("-")[0].trim(),
      location: details.Location.trim(),
      distance: dis,
      facilityType: type,

      serviceCode: serviceLocation,
      insuranceProvider: insurance,
    };

    const noFacilityTypeforNegotiatedrate = {
      q: details.Service.split("-")[0].trim(),
      location: details.Location.trim(),
      distance: dis,

      serviceCode: serviceLocation,
      insuranceProvider: insurance,
      negotiatedRates: negotiatedRates,
    };

    const withFacilityTypeforNegotiatedrate = {
      q: details.Service.split("-")[0].trim(),
      location: details.Location.trim(),
      distance: dis,
      facilityType: type,
      serviceCode: serviceLocation,
      insuranceProvider: insurance,
      negotiatedRates: negotiatedRates,
    };

    switch (filter) {
      case "noDistance":
        return axiosPrivate.post(`/search/negotiatedSearch`, noDistance);
      case "noFacilityType":
        return axiosPrivate.post(`/search/negotiatedSearch`, noFacilityType);
      case "withFacilityType":
        return axiosPrivate.post(`/search/negotiatedSearch`, withFacilityType);
      case "noFacilityTypeforNegotiatedrate":
        return axiosPrivate.post(
          `/search/negotiatedSearch`,
          noFacilityTypeforNegotiatedrate
        );
      case "withFacilityTypeforNegotiatedrate":
        return axiosPrivate.post(
          `/search/negotiatedSearch`,
          withFacilityTypeforNegotiatedrate
        );

      default:
        return axiosPrivate.post(`/search/negotiatedSearch`, noFacilityType);
    }
  };

  function handleTypeInputChange(event: any, searchValue: any) {
    var checkFacility = false;
    setLoading(true);
    if (event.target.value === facilityCheck) {
      setCheckFacText(false);
      setFacilityCheck("");
      checkFacility = false;
    } else {
      setCheckFacText(true);
      setFacilityCheck(event.target.value);
      checkFacility = true;
    }
    if (checkFacility) {
      filterFacilityType(
        "withFacilityType",
        `${distance}mi`,
        event.target.value,
        searchValue,
        insuranceCheck,
        locationCheck,
        value
      )
        .then((res) => {
          // dispatch(dataSearch(res.data.data));
          setSearch(res.data.data);
          setLoading(false);
          const maxFilter = Math.max(
            ...res.data.data.map((fprice: any) => {
              return fprice.negotiatedRates.negotiated_rate;
            })
          );

          const minFilter = Math.min(
            ...res.data.data.map((fprice: any) => {
              return fprice.negotiatedRates.negotiated_rate;
            })
          );

          if (res.data.data.length === 0) {
            setValue([0, 0]);
            setMinPrice(0);
            setMaxPrice(0);
          } else {
            setValue([minFilter, maxFilter]);
            setMinPrice(minFilter);
            setMaxPrice(maxFilter);
          }
        })
        .catch((e) => console.log(e));
    } else {
      filterFacilityType(
        "noFacilityType",
        `${distance}mi`,
        event.target.value,
        searchValue,

        insuranceCheck,
        locationCheck,
        value
      )
        .then((res) => {
          // dispatch(dataSearch(res.data.data));
          setSearch(res.data.data);
          setLoading(false);
          const maxFilter = Math.max(
            ...res.data.data.map((fprice: any) => {
              return fprice.negotiatedRates.negotiated_rate;
            })
          );

          const minFilter = Math.min(
            ...res.data.data.map((fprice: any) => {
              return fprice.negotiatedRates.negotiated_rate;
            })
          );

          if (res.data.data.length === 0) {
            setValue([0, 0]);
            setMinPrice(0);
            setMaxPrice(0);
          } else {
            setValue([minFilter, maxFilter]);
            setMinPrice(minFilter);
            setMaxPrice(maxFilter);
          }
        })
        .catch((e) => console.log(e));
    }
  }
  function sliderChange(event: any, newValue: any, searchValues: any) {
    setService(true);
    setValue(newValue as number[]);

    if (facilityCheck === "") {
      filterFacilityType(
        "noFacilityTypeforNegotiatedrate",
        `${distance}mi`,
        facilityCheck,
        searchValues,
        insuranceCheck,
        locationCheck,
        newValue
      )
        .then((res) => {
          // dispatch(dataSearch(res.data.data));
          setSearch(res.data.data);

          setLoading(false);
        })
        .catch((e) => console.log(e));
    } else {
      filterFacilityType(
        "withFacilityTypeforNegotiatedrate",
        `${distance}mi`,
        facilityCheck,
        searchValues,
        insuranceCheck,
        locationCheck,
        newValue
      )
        .then((res) => {
          // dispatch(dataSearch(res.data.data));
          setSearch(res.data.data);

          setLoading(false);
        })
        .catch((e) => console.log(e));
    }
  }
  function valuetext(userValue: number) {
    return `${userValue}$`;
  }
  function handleInsuranceInputChange(event: any, searchValue: any) {
    setLoading(true);
    setInsuranceCheck(event.target.value);
    if (facilityCheck === "") {
      filterFacilityType(
        "noFacilityType",
        `${distance}mi`,
        facilityCheck,
        searchValue,

        event.target.value,
        locationCheck,
        value
      )
        .then((res) => {
        
          setSearch(res.data.data);
          setLoading(false);
        })
        .catch((e) => console.log(e));
    } else {
      filterFacilityType(
        "withFacilityType",
        `${distance}mi`,
        facilityCheck,
        searchValue,

        event.target.value,
        locationCheck,
        value
      )
        .then((res) => {
    
          setSearch(res.data.data);
          setLoading(false);
          const maxFilter = Math.max(
            ...res.data.data.map((fprice: any) => {
              return fprice.negotiatedRates?.negotiated_rate;
            })
          );

          const minFilter = Math.min(
            ...res.data.data.map((fprice: any) => {
              return fprice.negotiatedRates.negotiated_rate;
            })
          );

          if (res.data.data.length === 0) {
            setValue([0, 0]);
            setMinPrice(0);
            setMaxPrice(0);
          } else {
            setValue([minFilter, maxFilter]);
            setMinPrice(minFilter);
            setMaxPrice(maxFilter);
          }
        })
        .catch((e) => console.log(e));
    }
  }
  function handleServicelocationchange(event: any, searchValue: any) {
    setLoading(true);
    setLocationCheck(event.target.value);
    if (facilityCheck === "") {
      filterFacilityType(
        "noFacilityType",
        `${distance}mi`,
        facilityCheck,
        searchValue,

        insuranceCheck,
        event.target.value,
        value
      )
        .then((res) => {
          // dispatch(dataSearch(res.data.data));

          setSearch(res.data.data);
          setLoading(false);
          const maxFilter = Math.max(
            ...res.data.data.map((fprice: any) => {
              return fprice.negotiatedRates?.negotiated_rate;
            })
          );

          const minFilter = Math.min(
            ...res.data.data.map((fprice: any) => {
              return fprice.negotiatedRates.negotiated_rate;
            })
          );

          if (res.data.data.length === 0) {
            setValue([0, 0]);
            setMinPrice(0);
            setMaxPrice(0);
          } else {
            setValue([minFilter, maxFilter]);
            setMinPrice(minFilter);
            setMaxPrice(maxFilter);
          }
        })
        .catch((e) => console.log(e));
    } else {
      filterFacilityType(
        "withFacilityType",
        `${distance}mi`,
        facilityCheck,
        searchValue,

        insuranceCheck,
        event.target.value,
        value
      )
        .then((res) => {
          // dispatch(dataSearch(res.data.data));
          setSearch(res.data.data);
          setLoading(false);
          const maxFilter = Math.max(
            ...res.data.data.map((fprice: any) => {
              return fprice.negotiatedRates?.negotiated_rate;
            })
          );

          const minFilter = Math.min(
            ...res.data.data.map((fprice: any) => {
              return fprice.negotiatedRates.negotiated_rate;
            })
          );

          if (res.data.data.length === 0) {
            setValue([0, 0]);
            setMinPrice(0);
            setMaxPrice(0);
          } else {
            setValue([minFilter, maxFilter]);
            setMinPrice(minFilter);
            setMaxPrice(maxFilter);
          }
        })
        .catch((e) => console.log(e));
    }
  }

  const distanceSliderChange = (v: any, searchValue: any) => {
    setDistance(v);
    if (facilityCheck === "") {
      filterFacilityType(
        "noFacilityType",
        `${v}mi`,
        facilityCheck,
        searchValue,
        insuranceCheck,
        locationCheck,
        value
      )
        .then((res) => {
          setSearch(res.data.data);
          setLoading(false);
          const maxFilter = Math.max(
            ...res.data.data.map((fprice: any) => {
              return fprice.negotiatedRates?.negotiated_rate;
            })
          );

          const minFilter = Math.min(
            ...res.data.data.map((fprice: any) => {
              return fprice.negotiatedRates.negotiated_rate;
            })
          );

          if (res.data.data.length === 0) {
            setValue([0, 0]);
            setMinPrice(0);
            setMaxPrice(0);
          } else {
            setValue([minFilter, maxFilter]);
            setMinPrice(minFilter);
            setMaxPrice(maxFilter);
          }
        })
        .catch((e) => console.log(e));
    } else {
      filterFacilityType(
        "withFacilityType",
        `${v}mi`,
        facilityCheck,
        searchValue,
        insuranceCheck,
        locationCheck,
        value
      )
        .then((res) => {
          // dispatch(dataSearch(res.data.data));
          setSearch(res.data.data);
          setLoading(false);
          const maxFilter = Math.max(
            ...res.data.data.map((fprice: any) => {
              return fprice.negotiatedRates.negotiated_rate;
            })
          );

          const minFilter = Math.min(
            ...res.data.data.map((fprice: any) => {
              return fprice.negotiatedRates.negotiated_rate;
            })
          );

          if (res.data.data.length === 0) {
            setValue([0, 0]);
            setMinPrice(0);
            setMaxPrice(0);
          } else {
            setValue([minFilter, maxFilter]);
            setMinPrice(minFilter);
            setMaxPrice(maxFilter);
          }
        })
        .catch((e) => console.log(e));
    }
  };

  const followersMarks = [
    {
      value: 10,

      label: "10mi",
    },

    {
      value: 50,

      label: "50mi",
    },

    {
      value: 100,

      label: "100mi",
    },
    //   {
    //     value:distance,
    //     label:`${distance}mi`
    //   }
  ];
  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (containerRef.current) {
      const fieldElements = containerRef.current.querySelectorAll(
        ".MuiFormControl-root"
      );
      let maxHeight = 0;
      fieldElements.forEach((element) => {
        const rect = element.getBoundingClientRect();
        maxHeight = Math.max(maxHeight, rect.height);
      });
      const buttonElement = containerRef.current.querySelector("button");
      if (buttonElement) {
        buttonElement.style.height = `${maxHeight}px`;
      }
    }
  }, [checkInfo]);
  return (
    <Box sx={{ backgroundColor: "primary.light", padding: "1.8rem" }}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          handleChange,
          setFieldValue,
          values,
          touched,
          setFieldTouched,
        }) => {
          return (
            <Form>
              {LoadingState ? (
                <Box
                  className={classes.searchState}
               
                >
                  <Box>
                    <CircularProgress color="inherit" size={50} />
                    <Typography>Loading</Typography>
                  </Box>
                </Box>
              ) : (
                <>
            
                    <Grid
                    container
                 
                    columnSpacing={1}
                    alignItems="flexStart"
                    ref={(container) => {
                      if (container) {
                        const fieldContainer = container.querySelector(
                          ".MuiFormControl-root"
                        ) as HTMLElement;
                        if (fieldContainer) {
                          const fieldHeight = fieldContainer.clientHeight;
                          const button = container.querySelector(
                            ".Buttoncomponent-root"
                          ) as HTMLElement;
                          if (button) {
                            button.style.height = `${fieldHeight}px`; 
                          }
                        }
                      }
                    }}
                    style={{ position: "relative" }}
                    sx={{
                      ml: { xs: "none", md: "30%" },
                      width: "100%",
                    }}
                  >
                      <Grid item md={4} xs={12} sx={{ marginBottom: { xs: "20px", md: 0 } }}>
                      <Box sx={{ position: "relative" }}>
                        <Field
                          className={classes.serviceName}
                          label="Service Name"
                          name="Service"
                          component={Autocomplete}
                          options={checkInfo ?? []}
                          loading={checkInfo.length === 0}
                          //  PaperComponent={CustomPaper}
                          filterOptions={filterOptions}
                          freeSolo
                          onInputChange={(e: any, value: any) => {
                            const postData = { q: value };

                            setCheckText(false);
                            setCheckFacText(false);
                            setFieldValue("Service", value);
                            setFacilityCheck("");
                            setLocationCheck("21");
                            setInsuranceCheck("UHC");
                            axiosPrivate
                              .post(`/search/serviceNamesearch`, postData)
                              .then((res) => {
                                setCheckInfo(res.data.data);
                              })
                              .catch((e) => console.log(e));
                          }}
                          value={values.Service}
                          renderInput={(
                            params: AutocompleteRenderInputParams
                          ) => (
                            <TextField
                              {...params}
                              name="Service"
                              // label="Search Service Name"
                              onChange={handleChange}
                              variant="outlined"
                            />
                          )}
                        />
                         {values.Service &&
                        !checkInfo.includes(values.Service) ? (
                          <Box
                            sx={{
                              position: "absolute",
                              top: { xs: "55px", md: "calc(100% + 5px)" },
                              left: {xs:"10px",md:0},
                              textAlign: "left",
                              color: "red",
                              fontSize:   "14px",
                              fontFamily: "Inder",
                              fontWeight: 400,
                            }}
                          >
                             {isMdUp ? "Please select a service from the suggestions" : "Please select any one option"}               
                              </Box>
                        ) : null}
                        {touched.Service && !values.Service ? (
                          <Box
                            sx={{
                              color: "red",
                              position: "absolute",
                              top: { xs: "55px", md: "calc(100% + 5px)" },
                              left: {xs:"10px",md:0},
                              fontSize:  "14px" ,
                              fontFamily: "Inder",
                              fontWeight: 400,
                            }}
                          >
                            Servicename is required
                          </Box>
                        ) : null}
                        </Box>
                      </Grid>
                      <Grid item md={3} xs={12}sx={{ marginBottom: { xs: "15px", md: 0 } }}>
                        <Field
                          className={classes.serviceDes}
                          as={TextField}
                          name="Location"
                          value={values.Location}
                          placeholder="Location"
                          type="text"
                          onChange={(e: any) => {
                            setCheckText(false);
                            setCheckFacText(false);
                            setFieldValue("Location", e.target.value);
                            setFacilityCheck("");
                            setLocationCheck("21");
                            setInsuranceCheck("UHC");
                          }}
                          fullWidth={true}
                          helperText={
                            <ErrorMessage name="Location">
                              {(error) => <ErrorProps>{error}</ErrorProps>}
                            </ErrorMessage>
                          }
                        />
                      </Grid>
                      <Grid item md={2} xs={12}>
                        <Buttoncomponent
                        className="Buttoncomponent-root"
                    
                          type="submit"
                          size="large"
                          fullWidth={false}
                          variant="contained"
                          disable={loading}
                          sx={{
                            width: { md: "auto", xs: "100%" },
                            borderRadius: 0,
                            backgroundColor: "custom.customColor8",
                            color: "#404b52",
                            fontSize: { xs: "12px", md: "18px" },
                            fontFamily: "Poppins",
                          }}
                        >
                          {loading && <CircularProgress size={14} />}

                          {!loading && (isMdUp ? <SearchIcon fontSize="large"/> : "Search")}
                        </Buttoncomponent>
                      </Grid>
                    </Grid>
                  {/* </Box> */}

                  <Grid container columnGap={3} mt="20px">
                    <Grid
                      item
                      xs={3}
                      sx={{
                        ml: "5vh",
                        display: { xs: "none", md: "flex" },
                        // padding: "1rem",
                        backgroundColor: "custom.customColor3",

                        flexDirection: "column",
                        rowGap: "14rem",
                      }}
                    >
                      <Box display={"flex"} flexDirection={"column"}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            mb: "30px",
                            backgroundColor: "custom.customColor4",
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: "Inter",

                              fontSize: 18,
                              fontWeight: 600,
                              color: "common.white",
                              padding: "0.8rem",
                            }}
                          >
                            Filters
                          </Typography>

                          <Button
                            className={classes.clearButton}
                            sx={{
                              textTransform: "none",
                            }}
                            onClick={() => {
                              onReSetFilters();
                            }}
                          >
                            Clear All
                          </Button>
                        </Box>

                        <Box>
                          <Box className={classes.filters}>
                            Distance
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() => setOpen(!open)}
                            >
                              {open ? (
                                <KeyboardArrowUp
                                  className={classes.filtersArrow}
                                />
                              ) : (
                                <KeyboardArrowDown
                                  className={classes.filtersArrow}
                                />
                              )}
                            </IconButton>
                          </Box>
                          <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ padding: "1rem 2.2rem 0 1.5rem" }}>
                              <Slider
                                value={distance}
                                valueLabelDisplay="on"
                                step={1}
                                marks={followersMarks}
                                min={10}
                                max={100}
                                onChange={(e, sliderValue: any) => {
                                  setLoading(true);
                                  setDistance(sliderValue);
                                }}
                                onChangeCommitted={(e, sliderValue) => {
                                  distanceSliderChange(sliderValue, values);
                                  setLoading(false);
                                }}
                              />
                            </Box>
                          </Collapse>
                        </Box>
                        <Divider />
                        <Box>
                          <Box className={classes.filters}>
                            Quality Score
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() => setOpenScore(!openScore)}
                            >
                              {openScore ? (
                                <KeyboardArrowUp
                                  className={classes.filtersArrow}
                                />
                              ) : (
                                <KeyboardArrowDown
                                  className={classes.filtersArrow}
                                />
                              )}
                            </IconButton>
                          </Box>
                        </Box>
                        <Divider />
                        <Box>
                          <Box className={classes.filters}>
                            Negotiated Rate
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() => setOpenRate(!openRate)}
                            >
                              {openRate ? (
                                <KeyboardArrowUp
                                  className={classes.filtersArrow}
                                />
                              ) : (
                                <KeyboardArrowDown
                                  className={classes.filtersArrow}
                                />
                              )}
                            </IconButton>
                          </Box>
                          <Collapse in={openRate} timeout="auto" unmountOnExit>
                            <Box sx={{ padding: "1rem 2.2rem 0 1.5rem" }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography>Min</Typography>
                                <Typography>Max</Typography>
                              </Box>

                              <Slider
                                className={classes.slider}
                                size="medium"
                                getAriaLabel={(index) =>
                                  index === 0 ? "Minprice" : "Maxprice"
                                }
                                track="normal"
                                value={value}
                                marks={[
                                  {
                                    value: minPrice,
                                    label: `$ ${minPrice.toFixed()}`,
                                  },
                                  {
                                    value: maxPrice,
                                    label: `$ ${maxPrice.toFixed()}`,
                                  },
                                ]}
                                onChange={(e, sliderArray: any) => {
                                  setLoading(true);
                                  setValue(sliderArray);
                                }}
                                onChangeCommitted={(event, v) => {
                                  sliderChange(event, v, values);
                                }}
                                min={minPrice}
                                max={maxPrice}
                                step={1}
                                valueLabelDisplay="auto"
                                getAriaValueText={valuetext}
                              />
                            </Box>
                          </Collapse>
                        </Box>
                        <Divider />
                        <Box>
                          <Box className={classes.filters}>
                            Insurance Name
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() => setOpen1(!open1)}
                            >
                              {open1 ? (
                                <KeyboardArrowUp
                                  className={classes.filtersArrow}
                                />
                              ) : (
                                <KeyboardArrowDown
                                  className={classes.filtersArrow}
                                />
                              )}
                            </IconButton>
                          </Box>
                          {/* test */}
                          <Collapse in={open1} timeout="auto" unmountOnExit>
                            <Grid item xs={12}>
                              <FormGroup
                                sx={{
                                  height: 200,
                                  overflow: "auto",
                                  pl: "1rem",
                                  pr: "1rem",
                                }}
                              >
                                <RadioGroup
                                  name="length"
                                  value={insuranceCheck}
                                >
                                  {insuranceDetails.map((ins: any, i: any) => (
                                    <FormControlLabel
                                      key={i}
                                      value={ins.insuranceProviderID}
                                      control={
                                        <Radio
                                          checked={
                                            insuranceCheck ===
                                              ins.insuranceProviderID &&
                                            checkInsText
                                          }
                                          onClick={(e: any) => {
                                            handleInsuranceInputChange(
                                              e,
                                              values
                                            );
                                          }}
                                        />
                                      }
                                      label={ins.insuranceProvider}
                                      labelPlacement="end"
                                    />
                                  ))}
                                </RadioGroup>
                              </FormGroup>
                            </Grid>
                          </Collapse>
                        </Box>

                        <Divider />
                        <Box>
                          <Box className={classes.filters}>
                            Facility Type
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() => setOpen2(!open2)}
                            >
                              {open2 ? (
                                <KeyboardArrowUp
                                  className={classes.filtersArrow}
                                />
                              ) : (
                                <KeyboardArrowDown
                                  className={classes.filtersArrow}
                                />
                              )}
                            </IconButton>
                          </Box>
                          <Collapse in={open2} timeout="auto" unmountOnExit>
                            <Grid item xs={12}>
                              <FormGroup
                                sx={{
                                  pl: "1rem",
                                  pr: "1rem",
                                }}
                              >
                                <RadioGroup name="length" value={facilityCheck}>
                                  {facilityType.map((type: any, i: any) => (
                                    <FormControlLabel
                                      key={i}
                                      value={type.facilityTypeId}
                                      control={
                                        <Radio
                                          checked={
                                            facilityCheck ===
                                              type.facilityTypeId &&
                                            checkFacText
                                          }
                                          onClick={(e: any) => {
                                            handleTypeInputChange(e, values);
                                          }}
                                        />
                                      }
                                      label={type.value.split("-")[1]}
                                      labelPlacement="end"
                                    />
                                  ))}
                                </RadioGroup>
                              </FormGroup>
                            </Grid>
                          </Collapse>
                        </Box>

                        <Divider />
                        <Box>
                          <Box className={classes.filters}>
                            Service Location
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() => setOpen3(!open3)}
                            >
                              {open3 ? (
                                <KeyboardArrowUp
                                  className={classes.filtersArrow}
                                />
                              ) : (
                                <KeyboardArrowDown
                                  className={classes.filtersArrow}
                                />
                              )}
                            </IconButton>
                          </Box>
                          <Collapse in={open3} timeout="auto" unmountOnExit>
                            <Grid item xs={12}>
                              <FormGroup
                                sx={{
                                  pl: "1rem",
                                  pr: "1rem",
                                }}
                              >
                                <RadioGroup name="length" value={locationCheck}>
                                  {servicelocationType.map(
                                    (type: any, i: any) => (
                                      <FormControlLabel
                                        key={i}
                                        value={type.service_code}
                                        control={
                                          <Radio
                                            onClick={(e: any) => {
                                              handleServicelocationchange(
                                                e,
                                                values
                                              );
                                            }}
                                          />
                                        }
                                        label={type.serviceLocationName}
                                        labelPlacement="end"
                                      />
                                    )
                                  )}
                                </RadioGroup>
                              </FormGroup>
                            </Grid>
                          </Collapse>
                        </Box>
                        <Divider />
                      </Box>
                    </Grid>

                    <Grid
                      item
                      xs={8}
                      sx={{
                        display: { xs: "none", md: "block" },
                        backgroundColor: "common.white",
                      }}
                    >
                      {loading && <LinearProgress />}
                      <Box>
                        <Grid
                          container
                          columnSpacing={1}
                          sx={{
                            display: "flex",
                            justifyContent: "right",
                            alignItems: "right",
                            height: "8vh",
                            backgroundColor: "custom.customColor5",
                          }}
                        >
                          <Grid
                            item
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "common.white",
                                fontFamily: "h2.fontFamily",
                                fontSize: "16px",
                                fontWeight: 600,
                              }}
                            >
                              Sort by
                            </Typography>
                          </Grid>

                          <Grid item className={classes.gridButton}>
                            <Button
                              className={classes.ratingTypo}
                              onClick={() => {
                                setLoading(true);
                                setSearch((prevSearch: any) => {
                                  const sortedSearch = [...prevSearch].sort(
                                    (a, b) => b.price - a.price
                                  );
                                  setLoading(false);
                                  return sortedSearch;
                                });
                              }}
                              sx={{
                                textTransform: "none",
                              }}
                            >
                              Price high to low
                            </Button>
                          </Grid>

                          <Grid item className={classes.gridButton}>
                            <Button
                              className={classes.ratingTypo}
                              onClick={() => {
                                setLoading(true);
                                setSearch((prevSearch: any) => {
                                  const sortedSearch = [...prevSearch].sort(
                                    (a, b) => a.price - b.price
                                  );

                                  setLoading(false);
                                  return sortedSearch;
                                });
                              }}
                              sx={{
                                textTransform: "none",
                              }}
                            >
                              Price low to high
                            </Button>
                          </Grid>
                        </Grid>
                        {search.length !== 0 ? (
                          (itemsPerPage > 0
                            ? search.slice(
                                (page1 - 1) * itemsPerPage,
                                page1 * itemsPerPage
                              )
                            : search
                          ).map((dsearch: any, i: any) => (
                            <div key={i}>
                              <Paper elevation={3}>
                                <Card
                                  raised
                                  sx={{
                                    backgroundColor: "white",
                                    padding: "2rem",
                                    ml: "-0.5rem",
                                    mb: "2rem",
                                  }}
                                >
                                  <Grid container direction="row">
                                    <Grid item xs={9.5}>
                                      <Typography
                                        className={classes.facilitySearch}
                                        sx={{
                                          fontFamily: "Inter",

                                          fontSize: "18px",
                                          color: "common.black",
                                          mb: "20px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {dsearch.FacilityName}
                                      </Typography>
                                      <Box
                                        sx={{
                                          fontFamily: "h2.fontFamily",
                                          fontSize: "14px",
                                          color: "common.black",
                                          display: "block", // Ensure the box is a block-level element
                                          // mb: "20px",
                                        }}
                                      >
                                        {
                                          dsearch.facilityDetails?.address
                                            ?.addressLine1
                                        }
                                        ,
                                        <Box>
                                          {
                                            dsearch.facilityDetails?.address
                                              ?.city
                                          }
                                          ,
                                          {
                                            dsearch.facilityDetails?.address
                                              ?.state_code
                                          }
                                          -
                                          {
                                            dsearch.facilityDetails?.address
                                              ?.zipCode
                                          }
                                        </Box>
                                      </Box>

                                      <Typography
                                        sx={{
                                          fontSize: "16px",
                                          color: "common.black",
                                          fontWeight: "500",
                                        }}
                                      >
                                        Distance:{" "}
                                        <Typography
                                          component="span"
                                          sx={{
                                            color: "common.black",
                                            fontWeight: "600",
                                          }}
                                        >
                                          {dsearch.distance}
                                          miles
                                        </Typography>
                                        <Typography
                                          component="span"
                                          sx={{
                                            ml: "80px",
                                            font: "Inter",
                                            fontWeight: 600,
                                            fontSize: "16px",
                                            color: "common.black",
                                            textTransform: "Capitalize",
                                          }}
                                        >
                                          {
                                            dsearch.serviceDes
                                              .CODE_DESC_CONSUMER
                                          }
                                        </Typography>
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={2.5}>
                                      <Grid
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",

                                          padding: "10px",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            fontSize: "28px",
                                            font: "Inter",
                                            fontWeight: 600,
                                          }}
                                        >
                                          ${" "}
                                          {
                                            dsearch?.negotiatedRates
                                              ?.negotiated_rate
                                          }
                                        </Box>
                                        <Typography
                                          sx={{
                                            fontFamily: "Inter",

                                            fontSize: "12px",
                                            color: "common.black",
                                          }}
                                        >
                                          Negotiated price
                                        </Typography>
                                      </Grid>
                                      <Grid container direction="row">
                                        <Typography
                                          component="span"
                                          sx={{
                                            fontSize: "1.2rem",
                                            color: "common.black",
                                            ml: "0.5rem",
                                          }}
                                        >
                                          eCQMscore:
                                        </Typography>
                                        <Typography
                                          sx={{
                                            fontSize: "2rem",
                                            color: "common.black",
                                            mb: "15px",
                                          }}
                                        >
                                          {dsearch.eCQMscore}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Card>
                              </Paper>
                            </div>
                          ))
                        ) : (
                          <Box className={classes.noSearch}>
                            <Typography>No results found</Typography>
                          </Box>
                        )}

                        {search.length !== 0 ? (
                          <>
                            <Grid
                              container
                              sx={{ display: "flex", justifyContent: "center" }}
                            >
                              <Typography sx={{ padding: "10px" }}>
                                Displaying items: {pagination.from}-
                                {pagination.to}
                                <span> </span>of<span> </span>
                                {pagination.total}
                              </Typography>
                              <Pagination
                                count={Math.ceil(search.length / itemsPerPage)}
                                page={page1}
                                siblingCount={0}
                                onChange={handleChangePage}
                                defaultPage={1}
                                color="primary"
                                size="large"
                                showFirstButton
                                showLastButton
                              />
                            </Grid>
                          </>
                        ) : null}
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid
                      item
                      xs={6}
                      sx={{ display: { xs: "block", md: "none" } }}
                    >
                      <Box>
                        <IconButton
                          size="large"
                          onClick={handleOpenNavMenu}
                          color="inherit"
                        >
                          <MenuIcon />
                        </IconButton>
                        <Menu
                          anchorEl={anchorElNav}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          keepMounted
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          open={Boolean(anchorElNav)}
                          onClose={handleCloseNavMenu}
                          sx={{
                            display: { xs: "block", md: "none" },
                          }}
                        >
                          <MenuItem
                            sx={{ display: "flex", justifyContent: "right" }}
                          >
                            <Replay
                              onClick={() => {
                                handleCloseNavMenu();
                                onReSetFilters();
                              }}
                            />
                            <ClearIcon onClick={handleCloseNavMenu} />
                          </MenuItem>
                          <MenuItem sx={{ width: "100%" }}>
                            <Box sx={{ width: "100%" }}>
                              <Box className={classes.filters}>
                                Distance
                                <IconButton
                                  className={classes.filtersArrow}
                                  aria-label="expand row"
                                  size="small"
                                  onClick={() => setOpen(!open)}
                                >
                                  {open ? (
                                    <KeyboardArrowUp
                                      className={classes.filtersArrow}
                                    />
                                  ) : (
                                    <KeyboardArrowDown
                                      className={classes.filtersArrow}
                                    />
                                  )}
                                </IconButton>
                              </Box>
                              <Collapse in={open} timeout="auto" unmountOnExit>
                                <Box sx={{ padding: "1rem 2.2rem 0 1.5rem" }}>
                                  <Slider
                                    value={distance}
                                    valueLabelDisplay="on"
                                    step={1}
                                    marks={followersMarks}
                                    min={10}
                                    max={100}
                                    onChange={(e, sliderValue: any) => {
                                      setLoading(true);
                                      setDistance(sliderValue);
                                    }}
                                    onChangeCommitted={(e, sliderValue) => {
                                      distanceSliderChange(sliderValue, values);
                                      setLoading(false);
                                    }}
                                  />
                                </Box>
                              </Collapse>
                            </Box>
                          </MenuItem>
                          <MenuItem sx={{ width: "100%" }}>
                            <Box sx={{ width: "100%" }}>
                              <Box className={classes.filters}>
                                Quality Score
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                  onClick={() => setOpenScore(!openScore)}
                                >
                                  {openScore ? (
                                    <KeyboardArrowUp
                                      className={classes.filtersArrow}
                                    />
                                  ) : (
                                    <KeyboardArrowDown
                                      className={classes.filtersArrow}
                                    />
                                  )}
                                </IconButton>
                              </Box>
                            </Box>
                          </MenuItem>

                          <MenuItem sx={{ width: "100%" }}>
                            <Box sx={{ width: "100%" }}>
                              <Box className={classes.filters}>
                                Negotiated Rate
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                  onClick={() => setOpenRate(!openRate)}
                                >
                                  {openRate ? (
                                    <KeyboardArrowUp
                                      className={classes.filtersArrow}
                                    />
                                  ) : (
                                    <KeyboardArrowDown
                                      className={classes.filtersArrow}
                                    />
                                  )}
                                </IconButton>
                              </Box>
                              <Collapse
                                in={openRate}
                                timeout="auto"
                                unmountOnExit
                              >
                                <Box sx={{ padding: "0 1rem" }}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Typography>Min</Typography>
                                    <Typography>Max</Typography>
                                  </Box>

                                  <Slider
                                    className={classes.slider}
                                    size="medium"
                                    getAriaLabel={(index) =>
                                      index === 0 ? "Minprice" : "Maxprice"
                                    }
                                    track="normal"
                                    value={value}
                                    marks={[
                                      {
                                        value: minPrice,
                                        label: `$${minPrice.toFixed()}`,
                                      },
                                      {
                                        value: maxPrice,
                                        label: `$${maxPrice.toFixed()}`,
                                      },
                                    ]}
                                    onChange={(e, sliderArray: any) => {
                                      setLoading(true);
                                      setValue(sliderArray);
                                    }}
                                    onChangeCommitted={(event, v) => {
                                      sliderChange(event, v, values);
                                    }}
                                    min={minPrice}
                                    max={maxPrice}
                                    step={1}
                                    valueLabelDisplay="auto"
                                    getAriaValueText={valuetext}
                                  />
                                </Box>
                              </Collapse>
                            </Box>
                          </MenuItem>
                          <MenuItem
                            // onClick={handleCloseNavMenu}
                            sx={{ width: "100%" }}
                          >
                            <Box sx={{ width: "100%" }}>
                              <Box className={classes.filters}>
                                Insurance Name
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                  onClick={() => setOpen1(!open1)}
                                >
                                  {open1 ? (
                                    <KeyboardArrowUp
                                      className={classes.filtersArrow}
                                    />
                                  ) : (
                                    <KeyboardArrowDown
                                      className={classes.filtersArrow}
                                    />
                                  )}
                                </IconButton>
                              </Box>

                              <Collapse in={open1} timeout="auto" unmountOnExit>
                                <Grid item xs={12}>
                                  <FormGroup
                                    sx={{
                                      height: 200,
                                      overflow: "auto",
                                      pl: "1rem",
                                      pr: "1rem",
                                    }}
                                  >
                                    <RadioGroup
                                      name="length"
                                      value={insuranceCheck}
                                    >
                                      {insuranceDetails.map(
                                        (ins: any, i: any) => (
                                          <FormControlLabel
                                            key={i}
                                            value={ins.insuranceProviderID}
                                            control={
                                              <Radio
                                                checked={
                                                  insuranceCheck ===
                                                    ins.insuranceProviderID &&
                                                  checkInsText
                                                }
                                                onClick={(e: any) => {
                                                  handleInsuranceInputChange(
                                                    e,
                                                    values
                                                  );
                                                }}
                                              />
                                            }
                                            label={ins.insuranceProvider}
                                            labelPlacement="end"
                                          />
                                        )
                                      )}
                                    </RadioGroup>
                                  </FormGroup>
                                </Grid>
                              </Collapse>
                            </Box>
                          </MenuItem>

                          <MenuItem sx={{ width: "100%" }}>
                            <Box sx={{ width: "100%" }}>
                              <Box className={classes.filters}>
                                Facility Type
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                  onClick={() => setOpen2(!open2)}
                                >
                                  {open2 ? (
                                    <KeyboardArrowUp
                                      className={classes.filtersArrow}
                                    />
                                  ) : (
                                    <KeyboardArrowDown
                                      className={classes.filtersArrow}
                                    />
                                  )}
                                </IconButton>
                              </Box>
                              <Collapse in={open2} timeout="auto" unmountOnExit>
                                <Grid item xs={12}>
                                  <FormGroup
                                    sx={{
                                      pl: "1rem",
                                      pr: "1rem",
                                    }}
                                  >
                                    <RadioGroup
                                      name="length"
                                      value={facilityCheck}
                                    >
                                      {facilityType.map((type: any, i: any) => (
                                        <FormControlLabel
                                          key={i}
                                          value={type.facilityTypeId}
                                          control={
                                            <Radio
                                              checked={
                                                facilityCheck ===
                                                  type.facilityTypeId &&
                                                checkFacText
                                              }
                                              onClick={(e: any) => {
                                                handleTypeInputChange(
                                                  e,
                                                  values
                                                );
                                              }}
                                            />
                                          }
                                          label={type.value.split("-")[1]}
                                          labelPlacement="end"
                                        />
                                      ))}
                                    </RadioGroup>
                                  </FormGroup>
                                </Grid>
                              </Collapse>
                            </Box>
                          </MenuItem>
                          <MenuItem sx={{ width: "100%" }}>
                            <Box sx={{ width: "100%" }}>
                              <Box className={classes.filters}>
                                Service Location
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                  onClick={() => setOpen3(!open3)}
                                >
                                  {open3 ? (
                                    <KeyboardArrowUp
                                      className={classes.filtersArrow}
                                    />
                                  ) : (
                                    <KeyboardArrowDown
                                      className={classes.filtersArrow}
                                    />
                                  )}
                                </IconButton>
                              </Box>
                              <Collapse in={open3} timeout="auto" unmountOnExit>
                                <Grid item xs={12}>
                                  <FormGroup
                                    sx={{
                                      pl: "1rem",
                                      pr: "1rem",
                                    }}
                                  >
                                    <RadioGroup
                                      name="length"
                                      value={locationCheck}
                                    >
                                      {servicelocationType.map(
                                        (type: any, i: any) => (
                                          <FormControlLabel
                                            key={i}
                                            value={type.service_code}
                                            control={
                                              <Radio
                                                onClick={(e: any) => {
                                                  handleServicelocationchange(
                                                    e,
                                                    values
                                                  );
                                                }}
                                              />
                                            }
                                            label={type.serviceLocationName}
                                            labelPlacement="end"
                                          />
                                        )
                                      )}
                                    </RadioGroup>
                                  </FormGroup>
                                </Grid>
                              </Collapse>
                            </Box>
                          </MenuItem>
                        </Menu>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        sx={{
                          display: { xs: "flex", md: "none" },
                          color: "common.white",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "auto",
                          width: "80%",
                          backgroundColor: "custom.customColor5",
                          "&:hover": {
                            color: "common.black",
                          },
                        }}
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                        variant="outlined"
                      >
                        Sort by
                      </Button>

                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                      >
                        <MenuItem
                          onClick={() => {
                            setLoading(true);
                            setSearch((prevSearch: any) => {
                              const sortedSearch = [...prevSearch].sort(
                                (a, b) => a.price - b.price
                              );

                              setLoading(false);
                              return sortedSearch;
                            });
                          }}
                        >
                          Price low to high
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setLoading(true);
                            setSearch((prevSearch: any) => {
                              const sortedSearch = [...prevSearch].sort(
                                (a, b) => b.price - a.price
                              );
                              setLoading(false);
                              return sortedSearch;
                            });
                          }}
                        >
                          Price high to low
                        </MenuItem>
                      </Menu>
                    </Grid>
                  </Grid>
                  <Box
                    sx={{
                      display: { xs: "flex", md: "none" },
                      flexDirection: "column",
                      border: "1px",
                    }}
                  >
                    {search.length !== 0 ? (
                      (itemsPerPage > 0
                        ? search.slice(
                            (page1 - 1) * itemsPerPage,
                            page1 * itemsPerPage
                          )
                        : search
                      ).map((dsearch: any, i: any) => (
                        <React.Fragment key={i}>
                          <Paper
                            sx={{
                              fontSize: "0.9rem",
                              boxShadow: "0px 0px 1px 1px rgba(0, 0, 0, 0.1)",
                            }}
                          >
                            <IconButton
                              onClick={() => setOpen(open === i ? -1 : i)}
                            >
                              <ArrowDropDown
                                sx={{
                                  transform:
                                    open === i ? "i" : "rotate(-90deg)",
                                }}
                              />
                            </IconButton>
                            {dsearch.FacilityName}
                          </Paper>

                          <Collapse
                            in={open === i}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Paper
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                mt: "0.2rem",
                                padding: "1rem",
                              }}
                            >
                              <Grid container direction="row">
                                <Grid item xs={9}>
                                  <Typography
                                    className={classes.facilitySearch}
                                  >
                                    {dsearch.FacilityName}
                                  </Typography>
                                  <Box
                                    sx={{
                                      fontFamily: "Inter",
                                      fontSize: "14px",
                                      color: "common.black",
                                      display: "block", // Ensure the box is a block-level element
                                    }}
                                  >
                                    {
                                      dsearch.facilityDetails?.address
                                        ?.addressLine1
                                    }
                                    ,
                                    <Box>
                                      {dsearch.facilityDetails?.address?.city},
                                      {
                                        dsearch.facilityDetails?.address
                                          ?.state_code
                                      }
                                      -
                                      {
                                        dsearch.facilityDetails?.address
                                          ?.zipCode
                                      }
                                    </Box>
                                  </Box>

                                  <Typography
                                    sx={{
                                      fontSize: "16px",
                                      color: "common.black",
                                      fontWeight: "500",
                                    }}
                                  >
                                    Distance:{" "}
                                    <Typography
                                      component="span"
                                      sx={{
                                        color: "h2.fontFamily",
                                        fontWeight: "600",
                                      }}
                                    >
                                      {dsearch.distance}
                                      miles
                                    </Typography>
                                    <Typography
                                      sx={{
                                        font: "Inter",
                                        fontWeight: 600,
                                        fontSize: "14px",
                                        color: "black",
                                        textTransform: "Capitalize",
                                      }}
                                    >
                                      {dsearch.serviceDes.CODE_DESC_CONSUMER}
                                    </Typography>
                                  </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                  <Grid
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",

                                      padding: "10px",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        fontSize: "18px",
                                        font: "Inter",
                                        fontWeight: 600,
                                      }}
                                    >
                                      ${" "}
                                      {
                                        dsearch?.negotiatedRates
                                          ?.negotiated_rate
                                      }
                                    </Box>
                                    <Typography
                                      sx={{
                                        fontFamily: "h2.fontFamily",

                                        fontSize: "12px",
                                        color: "common.black",
                                      }}
                                    >
                                      Negotiated price
                                    </Typography>
                                  </Grid>
                                  <Grid container direction="row">
                                    <Typography
                                      component="span"
                                      sx={{
                                        fontSize: "1rem",
                                        color: "common.black",
                                      }}
                                    >
                                      eCQMscore:
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontSize: "1rem",
                                        color: "common.black",
                                        mb: "15px",
                                      }}
                                    >
                                      {dsearch.eCQMscore}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Paper>
                          </Collapse>
                          </React.Fragment>
                      ))
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "10vh",
                        }}
                      >
                        <Typography>No results found</Typography>
                      </Box>
                    )}

                    {search.length !== 0 ? (
                      <>
                        <Grid
                          container
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          <Typography sx={{ padding: "10px" }}>
                            Displaying items: {pagination.from}-{pagination.to}
                            <span> </span>of<span> </span>
                            {pagination.total}
                          </Typography>
                          <Pagination
                            count={Math.ceil(search.length / itemsPerPage)}
                            page={page1}
                            siblingCount={0}
                            onChange={handleChangePage}
                            defaultPage={1}
                            color="primary"
                            showFirstButton
                            showLastButton
                          />
                        </Grid>
                      </>
                    ) : null}
                  </Box>
                </>
              )}
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
}
