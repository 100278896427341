import React, { useState, useEffect } from "react";
import { RadioButton } from "../Components/RadioButton";

import { Formik, Form, ErrorMessage, Field } from "formik";
import * as Yup from "yup";

import {
  Grid,
  Typography,
  Paper,
  TextField,
  Select,
  IconButton,
  Button,
  Box,
  Card,
  CardContent,
  CardMedia,
  MenuItem,
  Menu,
  Autocomplete,
  Avatar,
  Divider,
  AutocompleteRenderInputParams,
  createFilterOptions,
  useMediaQuery,
} from "@mui/material";
import { Buttoncomponent } from "../Components/Buttoncomp";
import ErrorProps from "../Components/Errorprops";
import SearchIcon from "@mui/icons-material/Search";
import { axiosPrivate } from "../axios/axios";

import { useNavigate } from "react-router-dom";

import { useAppDispatch } from "../Redux/Hook";

import {
  dataProviderSearch,
  dataQueryProvider,
  dataQuery,
  dataSearch,
} from "../Redux/ProviderRedux/HomeSlice";

interface forminitialValues {
  Service: string;
  Location: string;
}
interface ErrorComponentProps {
  name: string;
}


export default function RadioSelect() {
  const [usertype, setUsertype] = useState("Patient");


  const radioChangeHandler = (e: any) => {
    setUsertype(e.target.value);
  };
  const [locaInfo1, setLocaInfo1] = useState<any>("");
  const [checkInfo, setCheckInfo] = useState<any>([""]);
  const [zipCodedata, setZipCodedata] = useState<any>([]);
  const OPTIONS_LIMIT = 10;
  const defaultFilterOptions = createFilterOptions();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  function getLocationUpdate() {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition((position) => {
        const Lat = position.coords.latitude;
        const Lon = position.coords.longitude;
        const zipCode1 = { lat: Lat, lon: Lon };
   
        axiosPrivate
          .post(`/search/serviceLocationSearch`, zipCode1)
          .then((res) => {
        
            setLocaInfo1(res.data.data);
           
          })
          .catch((e) => console.log(e));
      });
    } else {
      alert("sorry,browser does not support geolocation");
    }
  }
  useEffect(() => {
    
    getLocationUpdate();
    if (locaInfo1) {
      navigator.geolocation.clearWatch(locaInfo1);
    }
  }, []);
  useEffect(() => {
    axiosPrivate
      .get("/search/cityStateList")
      .then((res) => {
        setZipCodedata(res.data.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  const filterOptions = (options: any, state: any) => {
    return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  };

  const initialValues: forminitialValues = {
    Service: "",
    Location: locaInfo1,
  };
  const validationSchema = Yup.object().shape({
 
    Location: Yup.string()
    .required("Zipcode is required")
    .matches(/^\d{5}$/, "Zipcode should be exactly 5 numeric digits")
    .test({
      name: "check-zipcode-exists",
      message: "Location should be a valid US zipcode",
      test: function (value) {
        return zipCodedata.some((item: any) => item.ZIP_CODE === value);
      },
    }),
  });
  const onSubmit = (values: forminitialValues, actions: any) => {

    if (usertype === "Patient") {
      dispatch(dataQuery(values));
      navigate(
        `/search?q=${values.Service.split(
          "-"
        )[1].trim()}&location=${values.Location.trim()}`
      );
    }
    if (usertype === "Provider") {
      {
        dispatch(dataQueryProvider(values));
        navigate(
          `/provider/search?q=${values.Service.trim()}&location=${values.Location.trim()}`
        );
      }
      actions.resetForm({
        values: initialValues,
      });
    }
  };
  function ErrorComponent({ name }: ErrorComponentProps) {
    return (
      <ErrorMessage name={name}>
        {(error) => <ErrorProps>{error}</ErrorProps>}
      </ErrorMessage>
    );
  }
  const isMdUp = useMediaQuery("(min-width:821px)");
  return (
    <Box sx={{ width: "100%" }}>
    {/* <Box
      sx={{
        display: "flex",
       
        justifyContent: { xs: "flex-start" },
        gap: { md: "2rem" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: { xs: "center", md: "space-between" },
          gap: { md: "1px" },
          marginLeft: "10px",
      
        }}
      >
        <RadioButton
          changed={radioChangeHandler}
          id="1"
          isSelected={usertype === "Patient"}
          value="Patient"
          sx={{ color: "black" }}
        />
        <Typography
          sx={{
            color: "black",
            fontFamily: "Poppins",
            fontSize: { xs: "12px", md: "14px" },
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "20px",
            gap: { md: "1px" },
          }}
        >
          I am a Patient
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: { xs: "center", md: "space-between" },
          gap: "1px",
    
        }}
      >
        <RadioButton
          changed={radioChangeHandler}
          id="2"
          isSelected={usertype === "Provider"}
          value="Provider"
          sx={{ color: "black", marginLeft: 0 }}
        />
        <Typography
          sx={{
            color: "black",
            fontFamily: "Poppins",
            fontSize: { xs: "12px", md: "14px" },
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "20px",
            gap: { md: "1px" },
          }}
        >
          I am an Employer | Healthcare Provider
        </Typography>
      </Box>
    </Box> */}

    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({
        handleChange,
        setFieldValue,
        values,
        touched,
        setFieldTouched,
      }) => {
        const handleButtonClick = () => {
          setFieldTouched("Service", true);
        };

        return (
          <Form>
            <Grid
              container
              spacing={{ xs: 0, md: 1 }}
              p={{ xs: "15px 15px 10px 15px", 
              // md: "5px 15px 5px 15px" 
            }}
  sx={{ marginRight:{md:"650px"}}}
              alignItems="center"
              ref={(container) => {
                if (container) {
                  const fieldContainer = container.querySelector(
                    ".MuiFormControl-root"
                  ) as HTMLElement;
                  if (fieldContainer) {
                    const fieldHeight = fieldContainer.clientHeight;
                    const button = container.querySelector(
                      ".Buttoncomponent-root"
                    ) as HTMLElement;
                    if (button) {
                      button.style.height = fieldHeight + "px";
                    }
                  }
                }
              }}
              // style={{ position: "relative" }}
            >
              <Grid
                item
                xs={12}
                md={4}
                sx={{ marginBottom: { xs: "15px", md: 0 } ,
               
              
              
              }}
              >
                <Box 
                // sx={{ position: "relative" }}
                >
                  <Field
                    label="Service Name"
                    placeholder="Search for... e.g. 'Gynecomastia Surgery Cost'"
                    name="Service"
                    fullWidth
                    sx={{ fontFamily: "Poppins" }}
                    component={Autocomplete}
                    options={checkInfo ?? []}
                    loading={checkInfo.length === 0}
                    filterOptions={filterOptions}
                    freeSolo
                    onInputChange={(e: any, value: any) => {
                      const postData = { q: value };

                      setFieldValue("Service", value);
                      axiosPrivate
                        .post(`/search/serviceNamesearch`, postData)
                        .then((res) => {
                          setCheckInfo(res.data.data);
                        })
                        .catch((e) => console.log(e));
                      if (
                        value &&
                        value.length > 0 &&
                        !checkInfo.includes(value)
                      ) {
                        setFieldValue("Service", "");
                      }
                    }}
                    value={values.Service}
                    renderInput={(params: AutocompleteRenderInputParams) => (
                      <TextField
                        {...params}
                        name="Service"
                        placeholder="Search for... e.g. 'Gynecomastia Surgery Cost'"
                        onChange={handleChange}
                        variant="outlined"
                        sx={{
                          "& .MuiAutocomplete-popupIndicator": {
                            transform: "none",
                          },
                          ".MuiInputBase-input": {
                            background: "white",
                            fontSize: { xs: "12px", md: "14px" },
                            fontFamily: "Poppins",
                          },
                          ".MuiInputBase-root": {
                            background: "white",
                            fontSize: "14px",
                            fontFamily: "Poppins",
                            borderRadius: 0,
                          },
                          "&::placeholder": {
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            color: "#868383",
                          },
                        }}
                      />
                    )}
                  />
                  {values.Service && !checkInfo.includes(values.Service) ? (
                    <Box
                      sx={{
                        color: "red",
                        position: "absolute",
                        top: { xs: "45px", md: "calc(100% + 5px)" },
                        left: 0,
                        fontSize: 14,
                        fontFamily: "Inder",
                        fontWeight: 400,
                      }}
                    >
                      {isMdUp ? "Please select a service from the suggestions" : "Please select any one option"}  
                    </Box>
                  ) : null}
                  {touched.Service && !values.Service ? (
                    <Box
                      sx={{
                        color: "red",
                        position: "absolute",
                        top: { xs: "45px", md: "calc(100% + 5px)" },
                        //left: 0,
                        fontSize: 14,
                        fontFamily: "Inder",
                        fontWeight: 400,
                      }}
                    >
                      Servicename is required
                    </Box>
                  ) : null}
                  {/* {isMdUp ? null : <ErrorComponent name="Service" />} */}
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                md={4}
                sx={{ marginBottom: { xs: "15px", md: 0 } }}
              >
                <Field
                  as={TextField}
                  name="Location"
                  placeholder="Near..."
                  value={values.Location}
                  type="text"
                  fullWidth={true}
                  InputProps={{
                    style: {
                      borderRadius: 0,
                    },
                  }}
                  sx={{
                    "&::placeholder": {
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      color: "black",
                    },
                    ".MuiInputBase-input": {
                      background: "white",
                      fontSize: { xs: "12px", md: "14px" },
                      fontFamily: "Poppins",
                    },
                    ".MuiFormLabel-root ": {
                      letterSpacing: "0.42px",
                      fontSize: "14px",
                      fontFamily: "Poppins",
                    },
                    ".MuiInputLabel-shrink": {
                      letterSpacing: 0,
                    },
                  }}
                />
                {isMdUp ? null : <ErrorComponent name="Location" />}
              </Grid>
              <Grid
                item
                xs={12}
                md={1}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "auto",
                }}
              >
                <Buttoncomponent
                  className="Buttoncomponent-root"
                  type="submit"
                  size="large"
                  variant="contained"
                  sx={{
                    width: { md: "auto", xs: "100%" },
                    borderRadius: 0,
                    backgroundColor: "custom.customColor8",
                    color: "#404b52",
                    fontSize: { xs: "12px", md: "18px" },
                    fontFamily: "Poppins",
                  }}
                  onClick={handleButtonClick}
                >
                  {isMdUp ? <SearchIcon fontSize="large" /> : "Search"}
                </Buttoncomponent>
              </Grid>
            </Grid>
            {isMdUp && (
              <Grid
                container
                spacing={{ xs: 0, md: 1 }}
                p={{ xs: "10px 15px 10px 15px", 
                md: "5px 15px 5px 15px" 
              }}
                alignItems="center"
              >
                
                <Grid item xs={12} md={4}>
                  {" "}
                  <ErrorComponent name="Location" />
                </Grid>
              </Grid>
            )}
          </Form>
        );
      }}
    </Formik>
  </Box>
  );
}
