import React, { FC, ReactElement } from "react";
import { Box, Link, Toolbar, Grid } from "@mui/material";
import { toast } from "react-toastify";

import { NavLink, useLocation, useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../Redux/Hook";

import { axiosPrivate } from "../axios/axios";

import { logoutButton, pageUser } from "../Redux/ProviderRedux/LoginSlice";
import { patientLogoutButton } from "../Redux/PatientRedux/patientAuth";
import { refrestState } from "../Redux/ProviderRedux/orgSlice";
import { adminLogoutButton } from "../Redux/Admin/adminLogin";

import OrganizationLandingView from "../Pages/Organization/OrganizationLandingView";

import useStyles from "../styles";



const OrgNavbar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const classes = useStyles();
  const providerLogout = useAppSelector(
    (state) => state.providerAuth.providerLogoutButton
  );
  const userName = useAppSelector((state) => state.providerAuth.login.userName);
  const adminUserName = useAppSelector(
    (state) => state.adminAuth.adminLogin.userName
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleClose = () => {
    setAnchorElNav(null);
  };

  const onPageUser = (user: any, path: string) => {
    dispatch(pageUser(user));
    navigate(path);
  };

  const onLogout = (type: string) => {
    if (type === "patient") {
      dispatch(patientLogoutButton());
      navigate("/");
    }
    if (type === "provider") {
      axiosPrivate.post("/user/logout", userName).then((res) => {
        dispatch(logoutButton());
        dispatch(refrestState());
        toast.success(res.data.message);
        navigate("/provider/home");
      });
    }
    if (type === "admin") {
      axiosPrivate.post("/admin/adminlogout", adminUserName).then((res) => {
        dispatch(adminLogoutButton());

        toast.success(res.data.message);
        navigate("/admin/adminlogin");
      });
    }
  };

  return (
    <Toolbar
      disableGutters
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },

        justifyContent: { md: "space-between" },
        alignItems: { xs: "flex-start" },
        mt: "10px",
        padding: { xs: "10px" },
      }}
    >
      <Link className={classes.linkText}   component="a"
        href="https://carecadet.com/"
        target="_blank" //  Open in a new tab/window
        rel="noopener noreferrer" >
        <Box sx={{ display: "flex", 
        paddingLeft: "20px" 
        }}>
        <Box className={classes.careCadet}
       
        >
          Care<Box className={classes.caDet}
    
          >Cadet</Box>
       </Box>
        </Box>
      </Link>

      {providerLogout ? (
        <Grid
          item
          sx={{
            marginRight: { md: "30px" },
          }}
        >
          <OrganizationLandingView>
            <Box
              onClick={() => {
                handleClose();
                onLogout("provider");
              }}
              sx={{
                backgroundColor: "#555655",
                font: "Inter",
                fontSize: "16",
                fontWeight: "Regular",
                color: "#FFFFFF",
                width: "18rem",
                cursor: "pointer",
                textAlign: "center",
                padding: "5px",
                marginLeft:"10px"
              }}
            >
              Logout
            </Box>
          </OrganizationLandingView>
        </Grid>
      ) : null}
    </Toolbar>
  );
};

export default OrgNavbar;
