import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../Store";

import Cookies from "js-cookie";

interface CounterState {
  patientLogin: any;
 
  patientLogoutButton: boolean;
  
}


const initialState: CounterState = {
  patientLogin: {},
  
  patientLogoutButton: false,
  
};

export const patientLoginSlice = createSlice({
  name: "patientLogin",

  initialState,
  reducers: {
   
    patientLoginInfo: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        patientLogoutButton:true,
        patientLogin: action.payload,
      };
    },
    patientLogoutButton: (state) => {
      return {
        ...state,
        patientLogin: {},
        patientLogoutButton: false,

        
      };
    },
   
   

    
    
  },
});

export const {
  patientLoginInfo,
  
  patientLogoutButton,
  

} = patientLoginSlice.actions;




export const patientLoginReducer = patientLoginSlice.reducer;
