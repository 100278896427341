import { useEffect, useState } from "react";
import {
 
  Box,
    Typography,
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,

} from "@mui/material";

import {
 
  DataGrid,
  GridColumns,
  GridColTypeDef,
    GridActionsCellItem,
  GridValueFormatterParams,
  GridPreProcessEditCellProps,
  GridRowId,
  GridRow,
} from "@mui/x-data-grid";

import {  useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { Buttoncomponent } from "../../Components/Buttoncomp";

import clsx from "clsx";
import { useAppDispatch, useAppSelector } from "../../Redux/Hook";
import { axiosPrivate,  } from "../../axios/axios";
import { toast } from "react-toastify";
import useStyles from "../../styles";


interface forminitialValues {
  _id: string;
  SNo: string;
  ServiceCode: string;
  DiagnosisTestorServiceName: string;
  Organizationid?: string;
  OrganizationPrices: string;
  FacilityNPI?: string;
  FacilityPrices: string;
  GridAlignment: "left" | "right" | "center";
}


export default function PricelistEditpage({
  onUpdateData,
  handleCloseEditService,
}: any) {
  const [data, setData] = useState([] as any);
  const [pageSize, setPageSize] = useState(5);
  const [csvEdit, setcsvEdit] = useState([] as any);
  const [csvdel, setcsvDel] = useState([] as any);
  const [filename, setFilename] = useState("");


  const [isLoading, setIsLoading] = useState<boolean>(false);
  
   const classes = useStyles();

  type CloseReason = "backdropClick" | "escapeKeyDown" | "closeButtonClick";
  interface DialogProps extends MuiDialogProps {
    onClose: (reason: CloseReason) => void;
  }

 
  const orgid = useAppSelector(
    (state) => state.providerOrganization.orgEditData
  );
  const facilityinput = useAppSelector(
    (state) => state.providerService.serviceData
  );

  

  const getData = async () => {
    setIsLoading(true);
    try {
      const response = await axiosPrivate.get(
        `/service/getPriceListbyFacility?facilityNPI=${facilityinput.facilityNPI}&Organizationid=${orgid[0].organizationID}`
      );

      const updatedData = response.data.data;
      setData(updatedData);
      setIsLoading(false);

      return updatedData; 
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
      throw error; 
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleDeleteClick = (id: GridRowId) => () => {
    setData(data.filter((row: any) => row._id !== id));

    let store = data.filter((row: any) => row._id === id);

    setcsvDel([...csvdel, store[0]._id]);

 
  };

 

  const onCellEditCommit = async (cellData: any) => {
    const { id, field, value } = cellData;
 

    let d = data.filter((data1: any) => data1._id === id);
    let dd = csvEdit.filter((ddd: any) => ddd._id === id);

    if (dd.length !== 0) {
      let r = csvEdit.map((dd: any) => {
        if (dd._id === id) {
          if (dd[field] !== value) {
            return { ...dd, [field]: value };
          }
        }
        return dd;
      });

      if (r.length > 0) {
        setcsvEdit(r);
     
      }
    } else {
      setcsvEdit([...csvEdit, { ...d[0], [field]: value }]);
    
    }
  };

  function csvJSON(csv: any) {

    var lines = csv.split("\r\n");

    var result = [];

    var headers = lines[0].split(",");

    for (var i = 1; i < lines.length; i++) {
      var obj: any = {};
      var currentline = lines[i].split(",");

      for (var j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j];
      }

      result.push(obj);
    }
    setData(result);

    return JSON.stringify(result); 
  }

  const update = async (e: any) => {
    setIsLoading(true);
    e.preventDefault();

    try {
      let datacheck = { name: filename, PriceList: csvEdit };
      const updateResponse = await axiosPrivate.put(
        `/service/bulkupdate`,
        datacheck
      );
 

      let datacheck1 = { data: { name: filename, PriceList: csvdel } };
      const deleteResponse = await axiosPrivate.delete(
        `/service/bulkdelete`,
        datacheck1
      );


      setIsLoading(false);
      toast.success(deleteResponse.data.message);

    
      const updatedData = await getData();
      


      onUpdateData(updatedData);
      handleCloseEditService();
    } catch (error) {
      setIsLoading(false);
      console.error("Error during bulk update and delete:", error);
      toast.warning("Error during bulk update and delete");
    }
  };


  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const usdPrice: GridColTypeDef = {
    type: "number",
    width: 250,
    
    valueFormatter: (params: GridValueFormatterParams<number>) => {
      if (params.value == null) {
        return "";
      }

      const valueFormatted = Number(params.value).toLocaleString();
      return `$ ${valueFormatted} `;
    },
    cellClassName: "font-tabular-nums",
  };
  const onButtonEdit = (e: any) => {
    var editData = data.map((d: any) => {
      if (d.DiagnosisTestorServiceName === e.DiagnosisTestorServiceName) {
        return e;
      } else {
        return d;
      }
    });

   
    var findExistEdit = csvEdit.filter((dataCsv: any) => dataCsv._id === e._id);
  
    if (findExistEdit.length !== 0) {
      const mapEdit = csvEdit.map((dat: any, i: any) => {
        if (dat._id === e._id) {
          return e;
        } else {
          return dat;
        }
      });
     
      setcsvEdit(mapEdit);
    } else {
      setcsvEdit([...csvEdit, e]);
    }
    setData(editData);
  };

  const handleDelete = (e: any) => {
    
    setData(data.filter((row: any) => row._id !== e._id));
    let store = data.filter((row: any) => row._id === e._id);

    setcsvDel([...csvdel, store[0]._id]);
   
  };
  
  const columns: GridColumns = [
    
    {
      field: "ServiceCode",
      headerName: "Service Code",
      headerClassName: "super-app-theme--header",
      // flex:1
      minWidth: 200,
      // editable: true,
    },
    {
      field: "DiagnosisTestorServiceName",
      headerName: "Diagnosis Test/Service Name",
      headerClassName: "super-app-theme--header",
      //flex: 2,
      minWidth: 560
      // editable: true,
    },
    
    {
      field: "OrganizationPrices",
      headerName: "Organization Prices",
      headerClassName: "super-app-theme--header",
      minWidth: 200,
      //flex: 1,
      editable: true,
      align: "right",
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const invalid = !Number(params.props.value);
        return { ...params.props, error: invalid };
      },
      ...usdPrice,
    },
    
    {
      field: "FacilityPrices",
      headerName: "Facility Prices",
      headerClassName: "super-app-theme--header",
      minWidth: 200,
      
      editable: true,
      align: "right",
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const invalid = !Number(params.props.value);
        return { ...params.props, error: invalid };
      },
      ...usdPrice,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      headerClassName: "super-app-theme--header",
      flex: 2,
      minWidth: 150,
      cellClassName: "actions",
      getActions: (data: any) => {
        let id = data.id;

        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  function CustomRow(props: any) {
    const { className, index, ...other } = props;

    return (
      <GridRow
        index={index}
        className={clsx(className, index % 2 === 0 ? "odd" : "even")}
        {...other}
      />
    );
  }

  const navigate = useNavigate();

  const navigateToAdd = () => {
   
    navigate("/provider/facility/PricelistUploadthrofacility");
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "primary.light",
     
          borderRadius: "15px",
          
        }}
      >
        <Typography className={classes.priceList}
          mb={"0.5rem"}
          sx={{
            backgroundColor: "custom.customColor11",
        
          }}
        >
          {facilityinput.facilityName} PRICELIST
        </Typography>
        
        <>
          <Box
            className={classes.oddEvenbox}
            sx={{
              width: '100%',
             
              "& .odd": {
                bgcolor: "white",
              },
              "& .even": {
                bgcolor: "#EDEDED",
              },
            

            }}
          >
            <DataGrid 
            className={classes.dataGrid}
              autoHeight
          
              getRowId={(row) => row._id}
              rows={data}
              columns={columns}
             
              onEditCellPropsChange={(params: any) => {
                const { id, field, props } = params;
                const editedValue = props.value;
                onCellEditCommit({ id, field, value: editedValue });
              }}
              pagination={true}
              pageSize={pageSize}
              loading={isLoading}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[5, 10, 20]}
              components={{ Row: CustomRow }}
            />
          </Box>
         
          {isLoading ? null : (
            <Buttoncomponent className={classes.yesButton}
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disable={isLoading}
              
              onClick={(e) => update(e)}
              sx={{
                mt: 2,
               
                width: "10vw",
                color: "#fff",
                textTransform:"capitalize"
                
              }}
            >
              Save
            </Buttoncomponent>
          )}
         
        </>
      </Box>
    </>
  );
}
