import React from "react";
import {
  TextField,
  Box,
  Typography,
  Grid,
  Button,
  Paper,

  MobileStepper,
} from "@mui/material";
import { Formik, Form } from "formik";
import * as yup from "yup";

import { toast } from "react-toastify";
import {  useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

import { useState } from "react";
import FormTextField from "../../Components/Textfield";
import { autoPlay } from "react-swipeable-views-utils";
import { Buttoncomponent } from "../../Components/Buttoncomp";
import { useTheme } from "@mui/material/styles";
import useStyles from "../../styles";


import { useAppDispatch,  } from "../../Redux/Hook";

import { axiosPrivate } from "../../axios/axios";

import SwipeableViews from "react-swipeable-views";

import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";

// AutoPlaySwipeableViews commented lines for future use
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    name: "John Doe",
    quote: "This is a great product! I would highly recommend it to anyone.",
  },
  {
    name: "Jane Doe",
    quote:
      "I've been using this product for a few months now and I'm really happy with it.",
  },
  {
    name: "Bob Smith",
    quote: "This product is amazing! It's changed my life for the better.",
  },
];
const schema = yup.object().shape({
  password: yup
    .string()
    .required("Password is a required field")
    .min(4, "Password must be at least 4 characters")
    .max(50, "Too Long!")
    .matches(/[a-z]+/, "One lowercase character")
    .matches(/[A-Z]+/, "One uppercase character")
    .matches(/[@$!%*#?&]+/, "One special character")
    .matches(/\d+/, "One number"),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});
export default function Resetpass() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const classes = useStyles();


  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

 


  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        mt: "2rem",
      }}
    >
<Paper elevation={3} sx={{  padding: "3rem",width:{md:"50%"}}}>
        <Grid container justifyContent={"center"}>
          {/* <Grid
            item
            md={4}
            xs={12}
            sx={{
              backgroundColor: "#f4f4f4",
         
              mt: 2,
              padding: 3,
            }}
          >
            <Typography sx={{ fontSize: "2.5rem", fontWeight: "bold" }}>
              Welcome to CareCadet Family
            </Typography>
            <Typography sx={{ fontSize: "1.5rem" }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.{" "}
            </Typography>

            <Box
              sx={{
                mt: "15vh",
                flexGrow: 1,
                borderRadius: 10,
                padding: 3,
                backgroundColor: "white",
              }}
            >
       
              <AutoPlaySwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
              >
                {images.map((step, index) => (
                  <div key={step.name}>
                    {Math.abs(activeStep - index) <= 2 ? (
                      <Box
                        component="div"
                        sx={{
                          display: "block",
                          overflow: "hidden",
                        }}
                      >
                        {" "}
                        <Typography
                     
                          fontSize={16}
                        >
                       
                        </Typography>
                        <Box
                          marginTop={4}
                      
                          sx={{ display: "flex", flexDirection: "row" }}
                        >
               
                          <Typography fontSize={23} marginLeft={2}>
                         
                          </Typography>
                        </Box>
                      </Box>
                    ) : 

                    null}
                  </div>
                ))}
              </AutoPlaySwipeableViews>
            </Box>
            <Box sx={{ backgroundColor: "#f4f4f4" }}>
              <MobileStepper
                sx={{ backgroundColor: "#f4f4f4", fontSize: "2vh" }}
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                  <Button
                    size="large"
                    onClick={handleNext}
                    disabled={activeStep === maxSteps - 1}
                  >
                    Next
                    {theme.direction === "rtl" ? (
                      <KeyboardArrowLeft />
                    ) : (
                      <KeyboardArrowRight />
                    )}
                  </Button>
                }
                backButton={
                  <Button
                    size="large"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                  >
                    {theme.direction === "rtl" ? (
                      <KeyboardArrowRight />
                    ) : (
                      <KeyboardArrowLeft />
                    )}
                    Back
                  </Button>
                }
              />
            </Box>
          </Grid> */}
          <Grid
            item
            md={8}
            sx={{ display: "flex", justifyContent: "center", mt: "2rem" }}
          >
            <Formik
              validationSchema={schema}
              initialValues={{
                password: "",
                passwordConfirmation: "",
                userType: "PROVIDER",
              }}
              onSubmit={(values) => {
                setIsLoading(true);
                let searchquery = searchParams.get("resettoken");

                const Resetdata = {
                  resetLink: searchquery,
                  newPass: values.password,
                  userType: values.userType,
                };

                axiosPrivate
                  .put("/provider/resetpassword", Resetdata)
                  .then((res) => {
                    setIsLoading(false);
                    toast.success(res.data.message);
                  
                    navigate("/provider/login");
                  })
                  .catch((err) => {
                    setIsLoading(false);
             
                    toast.warning(err.message);
                  });
              }}
            >
              <Form>
                <Typography className={classes.h1}
              
                >
                  Reset Password
                </Typography>

                <Grid>
                  <Typography className={classes.h2}
                  
                  >
                    Enter your new password to reset
                  </Typography>
                </Grid>

                <Grid>
                  <Typography className ={`${classes.h6} ${classes.h6WithLargeMargin}`}
                    
                  >
                    New Password{" "}
                    <Typography component="span"display="inline" sx={{ color: "red" }}>
                      *
                    </Typography>
                  </Typography>
                  <FormTextField className={classes.signuptext}
                    container={TextField}
                    name="password"
                    placeholder="Password"
                    type="password"
                    autoComplete="new-country-area"
                    
                  />
                </Grid>
                <Grid>
                  <Typography className={classes.h6}
                    
                  >
                    Confirm Password{" "}
                    <Typography component="span"display="inline" sx={{ color: "red" }}>
                      *
                    </Typography>
                  </Typography>
                  <FormTextField className={classes.signuptext}
                    container={TextField}
                    name="passwordConfirmation"
                    placeholder="Password"
                    type="password"
                    autoComplete="new-country-area"
                   
                  />
                </Grid>
                <Grid item>
                  <Buttoncomponent className={`${classes.signupbutton} ${classes.h6WithLargeMargin}`}
                    type="submit"
                    size="large"
                    fullWidth={true}
                    variant="contained"
                    disable={isLoading}
                 sx={{  textTransform: "capitalize"}}
                  >
                    Reset
                  </Buttoncomponent>
                
                </Grid>
              </Form>
            </Formik>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
