import React from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import {  NavLink, useLocation, useNavigate } from "react-router-dom";
import styles from "./styles.module.css";
import { Grid, Box, Typography, TextField, Paper, useTheme } from "@mui/material";
import Formtext from "../../Components/Textfield";
import { Buttoncomponent } from "../../Components/Buttoncomp";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import Avatar from "@mui/material/Avatar";

import { Link } from "@mui/material";

import Divider from "@mui/material/Divider";
import { adminAxiosPrivate, axiosPrivate } from "../../axios/axios";
import useStyles from "../../styles";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    name: "John Doe",
    quote: "This is a great product! I would highly recommend it to anyone.",
  },
  {
    name: "Jane Doe",
    quote:
      "I've been using this product for a few months now and I'm really happy with it.",
  },
  {
    name: "Bob Smith",
    quote: "This product is amazing! It's changed my life for the better.",
  },
];



const schema = yup.object().shape({
  firstName: yup.string().required("firstname is a required field"),
  lastName: yup.string().required("lastname is a required field"),
  email: yup
    .string()
    .required("Email is a required field")
    .email("Invalid email"),
    password: yup
    .string()
    .required("Password is a required field")

    .max(50, "Too Long!")

    .min(4, "Password must be at least 4 characters")
    .matches(/[a-z]+/, "One lowercase character")
    .matches(/[A-Z]+/, "One uppercase character")
    .matches(/[@$!%*#?&]+/, "One special character")
    .matches(/\d+/, "One number"),
    confirmpassword: yup
    .string()
    .required("Confirm Password is a required field")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

export default function AdminSignup() {
  
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  const data = location.state?.signup;
  const navigate = useNavigate();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const [open, setOpen] = React.useState<boolean>(false);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };
  const [text, setText] = useState("");
  const classes = useStyles();

  return (
    <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      mt: "2rem",
    }}
  >
    <Paper elevation={2} sx={{ width: "90%", padding: "2rem" }}>
     
     <Grid container justifyContent={"center"} alignItems={"center"}>
          <Grid
            item
            md={4}
            xs={12}
            sx={{
              backgroundColor: "#f4f4f4",

              mt: 2,
              padding: 3,
            }}
          >
            <Typography
              sx={{
                fontSize: "3rem",
                fontWeight: "bold",
              }}
            >
              Start your Journey with us
            </Typography>
            <Typography sx={{ fontSize: "1.5rem" }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.{" "}
            </Typography>

            <Box
              sx={{
                mt: "15vh",
                flexGrow: 1,
                borderRadius: 10,
                padding: 3,
                backgroundColor: "white",
              }}
            >
              <AutoPlaySwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
              >
                {images.map((step, index) => (
                  <div key={step.name}>
                    {Math.abs(activeStep - index) <= 2 ? (
                      <Box
                        component="div"
                        sx={{
                          // height: 100,
                          display: "block",
                          overflow: "hidden",
                        }}
                      >
                        {" "}
                        <Typography paddingLeft={5} fontSize={16}>
                          {step.quote}
                        </Typography>
                        <Box
                          marginTop={4}
                          paddingLeft={5}
                          sx={{ display: "flex", flexDirection: "row" }}
                        >
                          <Avatar sx={{ bgcolor: "lightblue" }}>N</Avatar>
                          <Typography fontSize={23} marginLeft={2}>
                            {images[activeStep].name}
                          </Typography>
                        </Box>
                      </Box>
                    ) : null}
                  </div>
                ))}
              </AutoPlaySwipeableViews>
            </Box>
            <Box sx={{ backgroundColor: "#f4f4f4" }}>
              <MobileStepper
                sx={{ backgroundColor: "#f4f4f4", fontSize: "2vh" }}
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                  <Button
                    size="large"
                    onClick={handleNext}
                    disabled={activeStep === maxSteps - 1}
                  >
                    Next
                    {theme.direction === "rtl" ? (
                      <KeyboardArrowLeft />
                    ) : (
                      <KeyboardArrowRight />
                    )}
                  </Button>
                }
                backButton={
                  <Button
                    size="large"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                  >
                    {theme.direction === "rtl" ? (
                      <KeyboardArrowRight />
                    ) : (
                      <KeyboardArrowLeft />
                    )}
                    Back
                  </Button>
                }
              />
            </Box>
          </Grid>
          <Grid item md={8} justifyContent="center">
    
      <Formik
        initialValues={{
          firstName: "",
          lastName:"",
          email: "",
          password: "",
          confirmpassword: "",
        }}
        validationSchema={schema}
        onSubmit={(values) => {

          const Registerdata = {
            firstName: values.firstName,
            password: values.password,
            lastName:values.lastName,
            email: values.email,
            role:"ADMIN"
          };

          adminAxiosPrivate
            .post("/provider/createAdmin", Registerdata)

            .then((res) => {
              toast.success(res.data.message);
              navigate("/admin/adminlogin");
        
            })
            .catch((err) => {
             
              toast.warning(err.message);
            });
        }}
      >
        <Form>
          <Grid
            container
            direction="row"
            justifyContent="center"
       
          >
            <Box
              
              sx={{
          
             
              }}
            >
              <Typography className={classes.h1}>Welcome Admin! </Typography>
              <Typography  className={classes.h2} >
                Sign Up{" "}
              </Typography>
      
              <Typography
             className={`${classes.h6} ${classes.h6WithLargeMargin}`}
              >
                First Name
             
              <Typography display="inline" sx={{ color: "red" }}>
                        *
                      </Typography>
                      </Typography>
              <Formtext
               className={classes.signuptext}
                name="firstName"
                container={TextField}
                placeholder="firstName"
                type="text"
                
              />
              <Typography
                 className={classes.h6}
              
              >
                Last Name
                <Typography component="span"display="inline" sx={{ color: "red" }}>
                        *
                      </Typography>
                      </Typography>
              
              <Formtext  className={classes.signuptext}
                name="lastName"
                container={TextField}
                placeholder="lastName"
                type="text"
               
               
              />
              <Typography
                 className={classes.h6}
             
              >
                Email
              
              <Typography  component="span" display="inline" sx={{ color: "red" }}>
                        *
                      </Typography>
                      <Typography  component="span" display="inline" sx={{ color: "red" }}>
                        *
                      </Typography>
                      </Typography>
              <Formtext  className={classes.signuptext}
                name="email"
                container={TextField}
                placeholder="email"
                type="email"
              
              />
              <Typography
            className={classes.h6}
              >
                Password
           
              <Typography component="span" display="inline" sx={{ color: "red" }}>
                        *
                      </Typography>
                      </Typography>   
              <Formtext
               className={classes.signuptext}
                name="password"
                container={TextField}
                placeholder="password"
                type="password"
     
                
              />
                    <Typography
                      sx={{
                        font: "Inder",
                        fontSize: "12px",
                        fontWeight: "Regular",
                        color: "custom.CustomColor1",
                      }}
                    >
                      Must be 8 or more characters and contains one number and
                      one special character
                    </Typography>
                    <Typography
                      className={classes.h6}
                  
                    >
                      Confirm Password{" "}
                      <Typography component="span" display="inline" sx={{ color: "red" }}>
                        *
                      </Typography>
                    </Typography>
                    <Formtext
                      className={classes.signuptext}
                      name="confirmpassword"
                      container={TextField}
                      placeholder="Enter confirmPassword"
                      type="password"
                      
                     
                    />

              <Grid  item >
                <Buttoncomponent
                 className={`${classes.signupbutton} ${classes.h6WithLargeMargin}`}
                  type="submit"
                  size="large"
                  fullWidth
                  variant="contained"
                  sx={{
                     textTransform: "capitalize"
                  }}
                >
                  Register
                </Buttoncomponent>
              </Grid>
              <Divider
                      component="div"
                      variant="fullWidth"
                      sx={{ marginTop: "1vh" }}
                    />

                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <Typography className={`${classes.h3} ${classes.h6WithLargeMargin}`}
                       
                      >
                        Already a user ?{" "}
                        <Link
                          sx={{ textDecoration: "none" }}
                          component={NavLink}
                          to="/admin/adminlogin"
                        >
                          <Typography
                            component={"span"}
                            sx={{ color: "custom.customColor6" }}
                          >
                            LOGIN
                          </Typography>
                        </Link>
                      </Typography>
                    </Box>
             
            </Box>
          </Grid>
        </Form>
      </Formik>
      </Grid>
      </Grid>
    </Paper>
    </Box>
  );
}
