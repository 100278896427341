import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../Store";

import Cookies from "js-cookie";

interface CounterState {
searchData:any;
searchDataTenMiles:any;
searchDataTwentyMiles:any;
searchDataThirtyMiles:any
providerSearchData:any;
queryData:any;
providerDataQuery:any;
}


const initialState: CounterState = {
searchData:[],
searchDataTenMiles:[],
searchDataTwentyMiles:[],
searchDataThirtyMiles:[],
providerSearchData:[],
queryData:{},
providerDataQuery:{}

};

export const homeSlice = createSlice({
  name: "home",

  initialState,
  reducers: {
    dataSearch: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        searchData: action.payload,
      };
    },
    dataProviderSearch: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        providerSearchData: action.payload,
      };
    },
    dataSearchTenMiles: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        searchData: action.payload
      
      };
    },
    dataSearchTwentyMiles: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        searchData: action.payload
     
      };
    },
    dataSearchThirtyMiles: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        searchData: action.payload

      };
    },
    dataQuery: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        queryData: action.payload
    
      };
    },
    dataQueryProvider: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        providerDataQuery: action.payload
 
      };
    },
    
  },
});

export const {
 dataSearch,dataSearchTenMiles,dataSearchTwentyMiles, dataSearchThirtyMiles,dataProviderSearch,dataQuery,dataQueryProvider

} = homeSlice.actions;




export const homeReducer = homeSlice.reducer;
