import React, { useState, useRef, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Box,
  Typography,
  Grid,
  Paper,
  Button,
  Autocomplete,
  AutocompleteRenderInputParams,
  createFilterOptions,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";

import FormTextField from "../../Components/Textfield";
import { Buttoncomponent } from "../../Components/Buttoncomp";

import { useAppDispatch, useAppSelector } from "../../Redux/Hook";
import { storeLoginInfoupdate } from "../../Redux/ProviderRedux/LoginSlice";
import { organizationEditupdate } from "../../Redux/ProviderRedux/orgSlice";
import { axiosPrivate } from "../../axios/axios";
import { useLocation, useNavigate } from "react-router-dom";

import ErrorProps from "../../Components/Errorprops";
import { toast } from "react-toastify";
import useStyles from "../../styles";

interface InitialValues {
  organizationInformation: {
    providerID: string;
    organizationName: string;
    streetAdd1: string;
    streetAdd2: string;
    country: string;
    city: string;
    state: string;
    zipCode: string;
    phone: string;
    Email: string;
    orgImg: String;
  };
  contactPersonInformation: {
    firstName: string;
    lastName: string;
    role: string;
    contactno: string;
    email: string;
    contactfile: string;
  };
  DelegateInfo: {
    firstName: string;
    lastName: string;
    role: string;
    contactno: string;
    email: string;
    delegatefile: string;
  };
}
interface EditDialogProps {
  onClose: any;
}

const EditOrganization: React.FC<EditDialogProps> = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const [currentFile, setCurrentFile] = useState<any>();
  const [currentFile1, setCurrentFile1] = useState<any>();
  const [currentFile2, setCurrentFile2] = useState<any>();
  const [fileName, setFileName] = useState<any>("");
  const [fileName1, setFileName1] = useState<any>("");
  const [fileName2, setFileName2] = useState<any>("");

  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessage1, setErrorMessage1] = useState("");
  const [errorMessage2, setErrorMessage2] = useState("");
  const [zipDisable, setZipDisable] = useState<Boolean>(true);
  const [autoCompleteData, setAutoCompleteData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const selectOrg = useAppSelector(
    (state) => state.providerOrganization.orgEditData[0]
  );

  const image = useAppSelector(
    (state) => state.providerOrganization.orgEditImage
  );

  const [zipCodedata, setZipCodedata] = useState<any>([]);

  const [image1, setImage1] = useState<any>("");
  const [image2, setImage2] = useState<any>("");
  const [image3, setImage3] = useState<any>("");
  const classes = useStyles();
  const dataLogin = useAppSelector(
    (state: { providerAuth: { login: any } }) => state.providerAuth.login
  );
  const navigate = useNavigate();

  const fileInput = useRef<any>();
  const fileInput1 = useRef<any>();
  const fileInput2 = useRef<any>();

  const formatPhoneNumber = (input: any) => {
    const cleaned = input.replace(/\D/g, "");
    const formatted = cleaned.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    return formatted;
  };

  const CustomPhoneInput = ({ field, form, errorMessage, ...props }: any) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    const handleChange = (e: any) => {
      const formattedNumber = formatPhoneNumber(e.target.value);
      setFieldValue(name, formattedNumber);
    };

    return (
      <TextField
        className={classes.signuptext}
        {...props}
        {...field}
        onChange={handleChange}
        value={value}
        placeholder="(XXX) XXX-XXXX"
        fullWidth
        helperText={
          <ErrorMessage name={name}>
            {(error) => <ErrorProps>{error}</ErrorProps>}
          </ErrorMessage>
        }
        autoComplete="new-country-area"
      />
    );
  };

  useEffect(() => {
    axiosPrivate
      .get("/search/cityStateList")
      .then((res) => {
        setZipCodedata(res.data.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  useEffect(() => {
    axiosPrivate
      .get("/organization/cityStateList")
      .then((res) => {
        setAutoCompleteData(res.data.data);
      })
      .catch((err) => {});
  }, []);

  const CustomPaper = (props: any) => {
    return (
      <Paper
        elevation={8}
        sx={{ backgroundColor: "white", color: "black" }}
        {...props}
      />
    );
  };

  const OPTIONS_LIMIT = 10;
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options: any, state: any) => {
    return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  };

  const initialValues: InitialValues = {
    organizationInformation: {
      providerID: selectOrg.providerID,
      organizationName: selectOrg.organizationName,
      streetAdd1: selectOrg.address.addressLine1,
      streetAdd2: selectOrg.address.addressLine2,
      country: selectOrg.address.country,
      city: selectOrg.address.city,
      state: selectOrg.address.state_code,
      zipCode: selectOrg.address.zipCode,
      phone: selectOrg.contact,
      Email: selectOrg.email,
      orgImg: selectOrg.orgImg,
    },

    contactPersonInformation: {
      firstName: dataLogin.firstName,
      lastName: dataLogin.lastName,
      role: dataLogin.role,
      contactno: selectOrg.contactPerson.contact,
      email: dataLogin.email,
      contactfile: selectOrg.contactPerson.contactImg,
    },
    DelegateInfo: {
      firstName: selectOrg.delegateInfo.delegatefirstName,
      lastName: selectOrg.delegateInfo.delegatelastName,
  
      role: selectOrg.delegateInfo?.delegaterole,
      contactno: selectOrg.delegateInfo.delegatecontactno,
      email: selectOrg.delegateInfo.delegateemail,
      delegatefile: selectOrg.delegateInfo.delegatefile,
    },
  };

  const SingleFileChange = () => {
    const selectedFile = fileInput.current.files[0];
    if (selectedFile.size > 2 * 1024 * 1024) {
      // 2MB limit
      setErrorMessage("File size exceeds the limit (2MB)");
      return;
    }
    if (/\.(jpe?g|png|gif)$/i.test(fileInput.current.files[0].name) === false) {
      setErrorMessage(
        "Unsupported File Format (Allowed[.png,.jpeg,.jpg,.gif])"
      );
      return;
    } else {
      setErrorMessage("");
      setCurrentFile(selectedFile);
    }
    setFileName(selectedFile ? selectedFile.name : "");
    let formData = new FormData();
    formData.append("file", selectedFile);
    try {
      axiosPrivate
        .post("/organization/image", formData, {
          headers: {
            "Content-type": "multipart/form-data",
          },
        })
        .then((res) => {
          toast.success(res.data.message);
          const image1 = res.data.data.filename;
          setImage1(image1);
        })
        .catch((err) => {
          setIsLoading(false);

          toast.error(err.message);
        });
    } catch (err) {
      setIsLoading(false);
      throw err;
    }
  };

  const SingleFileChange1 = () => {
    const selectedFile1 = fileInput1.current.files[0];
    if (selectedFile1.size > 2 * 1024 * 1024) {
      // 2MB limit
      setErrorMessage1("File size exceeds the limit (2MB)");
      return;
    }
    if (
      /\.(jpe?g|png|gif)$/i.test(fileInput1.current.files[0].name) === false
    ) {
      setErrorMessage1(
        "Unsupported File Format (Allowed[.png,.jpeg,.jpg,.gif])"
      );
      return;
    } else {
      setErrorMessage1("");
      setCurrentFile1(selectedFile1);
    }
    setFileName1(selectedFile1 ? selectedFile1.name : "");
    let formData1 = new FormData();
    formData1.append("file", selectedFile1);

    try {
      axiosPrivate
        .post("/organization/image", formData1, {
          headers: {
            "Content-type": "multipart/form-data",
          },
        })
        .then((res) => {
          toast.success(res.data.message);
          const image2 = res.data.data.filename;
          setImage2(image2);
        })
        .catch((err) => {
          setIsLoading(false);

          toast.error(err.message);
        });
    } catch (err) {
      setIsLoading(false);
      throw err;
    }
  };

  const SingleFileChange2 = () => {
    const selectedFile2 = fileInput2.current.files[0];
    if (selectedFile2.size > 2 * 1024 * 1024) {
      // 2MB limit
      setErrorMessage2("File size exceeds the limit (2MB)");
      return;
    }
    if (
      /\.(jpe?g|png|gif)$/i.test(fileInput2.current.files[0].name) === false
    ) {
      setErrorMessage2(
        "Unsupported File Format (Allowed[.png,.jpeg,.jpg,.gif])"
      );
      return;
    } else {
      setErrorMessage2("");
      setCurrentFile2(selectedFile2);
    }

    setFileName2(selectedFile2 ? selectedFile2.name : "");
    let formData2 = new FormData();
    formData2.append("file", selectedFile2);
    try {
      axiosPrivate
        .post("/organization/image", formData2, {
          headers: {
            "Content-type": "multipart/form-data",
          },
        })
        .then((res) => {
          toast.success(res.data.message);
          const image3 = res.data.data.filename;
          setImage3(image3);
        })
        .catch((err) => {
          setIsLoading(false);

          toast.error(err.message);
        });
    } catch (err) {
      setIsLoading(false);
      throw err;
    }
  };

  const onSubmit = async (values: InitialValues, actions: any) => {
    setIsLoading(true);
    const orgprovider = {
      providerID: dataLogin.userID,
      firstName: values.contactPersonInformation.firstName,
      lastName: values.contactPersonInformation.lastName,
      // role: values.contactPersonInformation.role,
      contact: values.contactPersonInformation.contactno,
      // email: values.contactPersonInformation.email,
    };

    try {
      axiosPrivate
        .put("/provider/updateProvider", orgprovider)
        .then((res) => {
          const updatelogininfo = {
            firstName: values.contactPersonInformation.firstName,
            lastName: values.contactPersonInformation.lastName,
          };

          dispatch(storeLoginInfoupdate(updatelogininfo));

          actions.resetForm({
            values: initialValues,
          });
        })
        .catch((err) => {
          setIsLoading(false);

          toast.error(err.message);
        });
    } catch (err) {}

    const orgdata = {
      organizationID: selectOrg.organizationID,
      providerID: values.organizationInformation.providerID,
      organizationName: values.organizationInformation.organizationName,
      orgImg: image1 || image,
      address: {
        addressLine1: values.organizationInformation.streetAdd1,
        addressLine2: values.organizationInformation.streetAdd2,
        country: values.organizationInformation.country,
        city: values.organizationInformation.city,
        state_code: values.organizationInformation.state,
        zipCode: values.organizationInformation.zipCode,
      },
      email: values.organizationInformation.Email,
      contact: values.organizationInformation.phone,
      contactPerson: {
        firstName: values.contactPersonInformation.firstName,
        lastName: values.contactPersonInformation.lastName,
        role: values.contactPersonInformation.role,
        contact: values.contactPersonInformation.contactno,
        email: values.contactPersonInformation.email,
        contactImg: image2 || fileName1,
      },
      delegateInfo: {
        delegatefirstName: values.DelegateInfo.firstName,
        delegatelastName: values.DelegateInfo.lastName,
        delegaterole: values.DelegateInfo.role,
        delegatecontactno: values.DelegateInfo.contactno,
        delegateemail: values.DelegateInfo.email,
        delegatefile: image3 || fileName2,
      },
    };

    axiosPrivate
      .put("/organization/updateOrganization", orgdata)
      .then((res) => {
        // alert("success");
        dispatch(organizationEditupdate(orgdata));

        toast.success(res.data.message);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.message);
      });

    onClose();
  };

  const validationSchema = Yup.object().shape({
    organizationInformation: Yup.object().shape({
      organizationName: Yup.string()
        .required("Organization Name is required")
        .matches(/^[A-Za-z -]+$/, "City can only contain alphabets."),
      streetAdd1: Yup.string().required("Address is required"),
      city: Yup.string()
        .required("City is required")
        .matches(/^[A-Za-z -]+$/, "City can only contain alphabets."),
      state: Yup.string()
        .required("State is required")
        .matches(/^[A-Za-z -]+$/, "State can only contain alphabets."),
      zipCode: Yup.string()
      .required("Zipcode is required")
      .matches(/^\d{5}$/, "Zipcode should be exactly 5 numeric digits")
      .test({
        name: "check-zipcode-exists",
        message: "Please give valid US zipcode",
        test: function (value) {
          return zipCodedata.some((item: any) => item.ZIP_CODE === value);
        },
      }),
      Email: Yup.string().required("Email is required")
      .matches(
        /^[a-zA-Z0-9._%+-]{1,64}@[a-zA-Z0-9.-]{1,255}\.[a-zA-Z]{2,}$/, 
        'Invalid email address format.'
      )
      .email("invalid email"),
      phone: Yup.string()
        .required("Phone number is required")
        .matches(/^\((?!0\d{2}\))\d{3}\) \d{3}-\d{4}$/, "Invalid Phone Number"),
    }),

    contactPersonInformation: Yup.object().shape({
      firstName: Yup.string()
        .required("First Name is a required field")
        .matches(/^[A-Za-z -]+$/, "First Name can only contain alphabets."),
      lastName: Yup.string()
        .required("Last Name is required")
        .matches(/^[A-Za-z -]+$/, "Last Name can only contain alphabets."),
      // role: Yup.string()
      //   .required("Role is a required field")
      //   .matches(/[A-Za-z0-9]+$/, "Role can only contain alphabets and number"),
      contactno: Yup.string()
        .required("Phone number is required")

        .matches(/^\(\d{3}\) \d{3}-\d{4}$/, "Invalid phone number"),
      email: Yup.string()
        .required("Email is a required field")
        .matches(
          /^[a-zA-Z0-9._%+-]{1,64}@[a-zA-Z0-9.-]{1,255}\.[a-zA-Z]{2,}$/, 
          'Invalid email address format.'
        )
        .email("invalid email"),
    }),
    DelegateInfo: Yup.object().shape({
      firstName: Yup.string()
        .required("First Name is a required field")
        .matches(/^[A-Za-z -]+$/, "First Name can only contain alphabets."),
      lastName: Yup.string()
        .required("First Name is a required field")
        .matches(/^[A-Za-z -]+$/, "First Name can only contain alphabets."),
      role: Yup.string()
        .required("Role is a required field")
        .matches(
          /^[A-Za-z0-9\s\W]+$/,
          "Role can only contain alphabets and number"
        ),
        // .matches(
        //   /^[A-Za-z0-9]+$/,
        //   "Role can only contain alphabets and number"
        // ),
      contactno: Yup.string()
        .required("Phone number is required")

        .matches(/^\(\d{3}\) \d{3}-\d{4}$/, "Invalid phone number"),
      email: Yup.string()
        .required("Email is a required field")
        .email("invalid email"),
    }),
  });

  const organizationData = [
    {
      xs: 12,
      md: 12,
      label: "Organization Name ",
      name: "organizationInformation.organizationName",
      placeholder: "Organization Name",
      type: "text",
    },
    {
      xs: 12,
      md: 6,
      label: "Street Address 1 ",
      name: "organizationInformation.streetAdd1",
      placeholder: "Street Address 1",
      type: "text",
    },
  ];
  const contactPersonData = [
    {
      xs: 12,
      md: 6,
      label: "First Name ",
      name: "contactPersonInformation.firstName",
      placeholder: "First Name",
      type: "text",
    },
    {
      xs: 12,
      md: 6,
      label: "Last Name ",
      name: "contactPersonInformation.lastName",
      placeholder: "Last Name",
      type: "text",
    },
  ];

  const delegateData = [
    {
      xs: 12,
      md: 6,
      label: "First Name",
      name: "DelegateInfo.firstName",
      placeholder: "First Name",
      type: "text",
    },
    {
      xs: 12,
      md: 6,
      label: "Last Name",
      name: "DelegateInfo.lastName",
      placeholder: "Last Name",
      type: "text",
    },
  ];

  const roleOptions = [
    { roleId: "owner", item: "Owner" },
    { roleId: "admin", item: "Administrator" },
    { roleId: "director", item: "Medical Director" },
    { roleId: "Physicians", item: "Physicians" },
    {
      roleId: "Assistants",
      item: "Nurse Practitioners and Physician Assistants",
    },
    { roleId: "Nurses", item: "Nurses (RN, LPN)" },
    { roleId: "Medical Assistants", item: "Medical Assistants" },
    { roleId: "Receptionists", item: "Front Desk Staff/Receptionists" },
    { roleId: "Specialist", item: "Billing Specialist" },
  ];

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnChange={true}
      validateOnBlur={true}
    >
      {({ handleChange, handleBlur, setFieldValue, values, touched,   setFieldError}) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                className={classes.formHead}
                mb={"0.5rem"}
                sx={{
                  fontWeight: 600,
                }}
              >
                Organization Information
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <label htmlFor="upload-photo">
                <input
                  style={{ display: "none" }}
                  id="upload-photo"
                  type="file"
                  accept="image/*"
                  ref={fileInput}
                  onChange={SingleFileChange}
                />
                <Button
                  className={classes.orgButton}
                  color="primary"
                  variant="contained"
                  component="span"
                  sx={{
                    ml: "18px",
                    textTransform: "capitalize",
                    fontSize: { xs: "0.9rem", md: "1rem" },
                  }}
                >
                  Upload organization Logo
                </Button>
              </label>
              {errorMessage ? (
                errorMessage && (
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                      fontSize: "0.9rem",
                      marginTop: "0.6rem",
                    }}
                  >
                    {errorMessage}
                  </div>
                )
              ) : (
                <Box component="span" sx={{ marginLeft: "1rem" }}>
                  {fileName || selectOrg.orgImg}
                </Box>
              )}
            </Grid>

            {organizationData.map((org, i) => (
              <Grid item xs={org.xs} md={org.md} key={i}>
                <Typography className={classes.h6Org}>
                  {org.label}
                  <Typography
                    component="span"
                    display="inline"
                    sx={{ color: "red" }}
                  >
                    *
                  </Typography>
                </Typography>
                <FormTextField
                  className={classes.signuptext}
                  container={TextField}
                  name={org.name}
                  placeholder={org.placeholder}
                  type={org.type}
                  fullWidth={true}
                />
              </Grid>
            ))}
            <Grid item xs={12} md={6}>
              <Typography className={classes.h6Org}>
                Street Address 2
              </Typography>
              <Field
                className={classes.signuptext}
                as={TextField}
                name="organizationInformation.streetAdd2"
                placeholder="Street Address 2"
                type="text"
                fullWidth={true}
                autoComplete="text"
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <Typography className={classes.h6Org}>
                Zip Code{" "}
                <Typography
                  component="span"
                  display="inline"
                  className={classes.inlineOrg}
                >
                  *
                </Typography>
              </Typography>

              <Field
                className={classes.signuptext}
                name="organizationInformation.zipCode"
                component={Autocomplete}
                options={autoCompleteData}
                loading={autoCompleteData.length === 0}
                PaperComponent={CustomPaper}
                filterOptions={filterOptions}
                getOptionLabel={(option: any) => option.ZIP_CODE || option}
                freeSolo
                fullWidth={true}
                validateOnChange={true}
                validateOnBlur={true}
                value={values.organizationInformation.zipCode}
                onChange={(e: any, value: any) => {
                  if (value === null) {
                    setZipDisable(false);
                  } else {
                    setZipDisable(true);
                  }

                  setFieldValue(
                    "organizationInformation.zipCode",
                    value !== null ? value.ZIP_CODE : ""
                  );
                  setFieldValue(
                    "organizationInformation.city",
                    value !== null ? value.city : ""
                  );
                  setFieldValue(
                    "organizationInformation.state",
                    value !== null ? value.state_code : ""
                  );
                  setTimeout(() => {
                    setFieldError("organizationInformation.zipCode", "");
                  }, 0);
                  setTimeout(() => {
                    setFieldError("organizationInformation.city", "");
                  }, 0);
             
                }}
                renderInput={(params: AutocompleteRenderInputParams) => (
                  <TextField
                    {...params}
                    helperText={
                      <ErrorMessage name="organizationInformation.zipCode">
                        {(error) => <ErrorProps>{error}</ErrorProps>}
                      </ErrorMessage>
                    }
                    name="organizationInformation.zipCode"
                    placeholder="Zip code"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography className={classes.h6Org}>
                City{" "}
                <Typography
                  component="span"
                  display="inline"
                  className={classes.inlineOrg}
                >
                  *
                </Typography>
              </Typography>
              <Field
                className={classes.signuptext}
                as={TextField}
                value={values.organizationInformation.city}
                helperText={
                  <ErrorMessage name="organizationInformation.city">
                    {(error) => <ErrorProps>{error}</ErrorProps>}
                  </ErrorMessage>
                }
                name="organizationInformation.city"
                placeholder="City"
                type="text"
                fullWidth={true}
                autoComplete="text"
                inputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography className={classes.h6Org}>
                State{" "}
                <Typography
                  component="span"
                  display="inline"
                  className={classes.inlineOrg}
                >
                  *
                </Typography>
              </Typography>
              <Field
                className={classes.signuptext}
                as={TextField}
                value={values.organizationInformation.state}
                helperText={
                  <ErrorMessage name="organizationInformation.state">
                    {(error) => <ErrorProps>{error}</ErrorProps>}
                  </ErrorMessage>
                }
                name="organizationInformation.state"
                placeholder="state"
                type="text"
                fullWidth={true}
                autoComplete="text"
                inputProps={{
                  sx: {
                    "&::placeholder": {
                      letterSpacing: "0.2rem",
                    },
                  },
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography className={classes.h6Org}>Country</Typography>
              <Field
                className={classes.signuptext}
                as={TextField}
                name="organizationInformation.country"
                placeholder="Select Country"
                type="text"
                fullWidth={true}
                autoComplete="new-country-area"
                helperText={
                  <ErrorMessage name="country">
                    {(error) => <ErrorProps>{error}</ErrorProps>}
                  </ErrorMessage>
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography className={classes.h6Org}>
                Phone No{" "}
                <Typography
                  component="span"
                  display="inline"
                  className={classes.inlineOrg}
                >
                  *
                </Typography>
              </Typography>
              <Field
                name="organizationInformation.phone"
                component={CustomPhoneInput}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography className={classes.h6Org}>
                Email{" "}
                <Typography
                  component="span"
                  display="inline"
                  className={classes.inlineOrg}
                >
                  *
                </Typography>
              </Typography>
              <Field
                className={classes.signuptext}
                as={TextField}
                helperText={
                  <ErrorMessage name="organizationInformation.Email">
                    {(error) => <ErrorProps>{error}</ErrorProps>}
                  </ErrorMessage>
                }
                name="organizationInformation.Email"
                placeholder="Email"
                type="email"
                fullWidth={true}
                autoComplete="text"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontFamily: "Inder",

                  fontSize: "16px",
                  letterSpacing: "0.2px",
                  lineHeight: "28px",
                }}
              >
                Contact Person Information
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <label htmlFor="upload-photo1">
                <input
                  style={{ display: "none" }}
                  id="upload-photo1"
                  type="file"
                  accept="image/*"
                  ref={fileInput1}
                  onChange={SingleFileChange1}
                />
                <Button
                  className={classes.orgButton}
                  variant="contained"
                  component="span"
                  sx={{
                    ml: "18px",
                    textTransform: "capitalize",
                    fontSize: { xs: "0.9rem", md: "1rem" },
                  }}
                >
                  Upload profile picture
                </Button>
              </label>
              {errorMessage1 ? (
                errorMessage1 && (
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                      fontSize: "0.9rem",
                      marginTop: "0.6rem",
                    }}
                  >
                    {errorMessage1}
                  </div>
                )
              ) : (
                <Box component="span" sx={{ marginLeft: "1rem" }}>
                  {fileName1 || selectOrg.contactPerson.contactImg}
                </Box>
              )}
            </Grid>

            {contactPersonData.map((person, i) => (
              <Grid item xs={person.xs} md={person.md} key={i}>
                <Typography className={classes.h6Org}>
                  {person.label}
                  <Typography
                    component="span"
                    display="inline"
                    sx={{ color: "red" }}
                  >
                    *
                  </Typography>
                </Typography>
                <FormTextField
                  className={classes.signuptext}
                  container={TextField}
                  name={person.name}
                  placeholder={person.placeholder}
                  type={person.type}
                  fullWidth={true}
                />
              </Grid>
            ))}
            <Grid item xs={12} md={6}>
              <Typography className={classes.h6Org}>
                Role{" "}
                <Typography
                  className={classes.inlineOrg}
                  component="span"
                  display="inline"
                >
                  (* read only)
                </Typography>
              </Typography>
              <Field
                className={classes.signuptext}
                as={TextField}
                name="contactPersonInformation.role"
                placeholder="Role"
                type="text"
                fullWidth={true}
                autoComplete="text"
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography className={classes.h6Org}>
                Contact Number{" "}
                <Typography
                  component="span"
                  display="inline"
                  className={classes.inlineOrg}
                >
                  *
                </Typography>
              </Typography>
              <Field
                name="contactPersonInformation.contactno"
                component={CustomPhoneInput}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography className={classes.h6Org}>
                Email{" "}
                <Typography
                  className={classes.inlineOrg}
                  component="span"
                  display="inline"
                >
                  (* read only)
                </Typography>
              </Typography>
              <Field
                className={classes.signuptext}
                as={TextField}
                name="contactPersonInformation.email"
                placeholder="Email"
                type="email"
                fullWidth={true}
                autoComplete="text"
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                mb={"0.5rem"}
                sx={{
                  fontFamily: "Inder",

                  fontSize: "16px",
                  letterSpacing: "0.2px",
                  lineHeight: "28px",
                }}
              >
                Alternate Contact Person
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <label htmlFor="upload-photo2">
                <input
                  style={{ display: "none" }}
                  id="upload-photo2"
                  type="file"
                  accept="image/*"
                  ref={fileInput2}
                  onChange={SingleFileChange2}
                />
                <Button
                  className={classes.orgButton}
                  color="primary"
                  variant="contained"
                  component="span"
                  sx={{
                    ml: "18px",
                    textTransform: "capitalize",
                    fontSize: { xs: "0.9rem", md: "1rem" },
                  }}
                >
                  Upload Profile picture
                </Button>
              </label>
              {errorMessage2 ? (
                errorMessage2 && (
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                      fontSize: "0.9rem",
                      marginTop: "0.6rem",
                    }}
                  >
                    {errorMessage2}
                  </div>
                )
              ) : (
                <Box component="span" sx={{ marginLeft: "1rem" }}>
                  {fileName2 || selectOrg.delegateInfo.delegatefile}
                </Box>
              )}
            </Grid>
            {delegateData.map((person, i) => (
              <Grid item xs={person.xs} md={person.md} key={i}>
                <Typography className={classes.h6Org}>
                  {person.label}
                  <Typography
                    component="span"
                    display="inline"
                    className={classes.inlineOrg}
                  >
                    *
                  </Typography>
                </Typography>
                <FormTextField
                  className={classes.signuptext}
                  container={TextField}
                  name={person.name}
                  placeholder={person.placeholder}
                  type={person.type}
                  fullWidth={true}
                />
              </Grid>
            ))}

            <Grid item xs={12} md={6}>
              <Typography className={classes.h6Org}>
                Delegate Role
                <Typography
                  component="span"
                  display="inline"
                  className={classes.inlineOrg}
                >
                  *
                </Typography>
              </Typography>

              <FormControl sx={{ width: "100%", backgroundColor: "#F9F9F9" }}>
                <Field
                  as={Select}
                  name="DelegateInfo.role"
                  MenuProps={{
                    sx: {
                      fontSize: 14,
                      fontFamily: "Montserrat",
                      color: "blue",
                    },
                  }}
                  renderValue={(selected: any) => (
                    <div
                      style={{
                        fontFamily: "Montserrat",
                        fontSize: 14,
                        color: "#252B42",
                      }}
                    >
                      {
                        (
                          roleOptions.find(
                            (opt: any) => opt.item === selected
                          ) || ({} as any)
                        ).item
                      }
                    </div>
                  )}
                >
                  <MenuItem value="" disabled>
                    Select Role
                  </MenuItem>
                  {(roleOptions || []).map((select: any, index: any) => (
                    <MenuItem
                      key={index + 1}
                      value={select.item}
                      style={{
                        fontFamily: "Montserrat",
                        fontSize: 14,
                        color: "#737373",
                      }}
                    >
                      {select.item}
                    </MenuItem>
                  ))}
                </Field>

                <ErrorMessage name="delegaterole">
                  {(error) => <ErrorProps>{error}</ErrorProps>}
                </ErrorMessage>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography className={classes.h6Org}>
                Contact Number{" "}
                <Typography
                  component="span"
                  display="inline"
                  className={classes.inlineOrg}
                >
                  *
                </Typography>
              </Typography>

              <Field
                name="DelegateInfo.contactno"
                component={CustomPhoneInput}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography className={classes.h6Org}>
                Email{" "}
                <Typography
                  component="span"
                  display="inline"
                  className={classes.inlineOrg}
                >
                  *
                </Typography>
              </Typography>
              <FormTextField
                className={classes.signuptext}
                container={TextField}
                name="DelegateInfo.email"
                placeholder="Email"
                type="email"
                fullWidth={true}
              />
            </Grid>
            <Grid container item xs={12} md={1.9} justifyContent="right">
              <Buttoncomponent
                className={classes.orgButton}
                type="submit"
                size="large"
                fullWidth
                variant="contained"
                disable={isLoading}
                sx={{
                  textTransform: "capitalize",
                  fontSize: { xs: "0.9rem", md: "1rem" },
                }}
              >
                Update
              </Buttoncomponent>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default EditOrganization;
