

import React, { FC } from "react";
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Radio,
    RadioGroup,
  } from "@mui/material";
export const RadioButton = (props:any) => {
    const { changed, id, isSelected, label, value, onClick } = props;
    return (
<div>
        <Radio
          id={id}
          onChange={changed}
          value={value}
         onClick={onClick}
          checked={isSelected}
       
        />
        <label htmlFor={id}>{label}</label>
      </div>
    );
  };