import {
  Box,
  Typography,
  Link,
  Grid,
  Menu,
  IconButton,
  MenuItem,

} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import React from "react";
import { useLocation, NavLink, useNavigate } from "react-router-dom";

import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

import IdleTimerContainer from "../IdleTimerContainer";
const OrganizationNav = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const location = useLocation().pathname.split("/")[2];
  

  
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleOpenNavMenu = (event: any) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  

  const [orgClick, setorgClick] = React.useState<any>(null);
  const [render, setRender] = React.useState<any>(<KeyboardArrowDown />);


  return (
    <>
    <Box sx={{ padding: "1rem 0 0 0" }}>
      <Box sx={{ display: { xs: "flex", md: "none" } }}>
        <IconButton size="large" onClick={handleOpenNavMenu} color="inherit">
          <MenuIcon />
        </IconButton>

        <Menu
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
          sx={{
            display: { xs: "block", md: "none" },
          }}
        >
          <Link
            to="/provider/facility/viewFacility"
            component={NavLink}
            underline="none"
          >
            <MenuItem onClick={handleCloseNavMenu} sx={{ width: 250 }}>
              <Typography
                sx={{
                  color: location === "facility" ? "secondary.dark" : "default",
                  fontSize: "1.1rem",
                  borderBottom:
                    location === "facility" ? "3px solid #30CDFF" : "none",
                  padding: "0.3rem",

                  cursor: "pointer",
                }}
              >
                Facility
              </Typography>
            </MenuItem>
          </Link>
          <Link
            to="/provider/service/listService"
            component={NavLink}
            underline="none"
          >
            <MenuItem onClick={handleCloseNavMenu} sx={{ width: 250 }}>
              <Typography
                sx={{
                  color: location === "service" ? "secondary.dark" : "default",
                  fontSize: "1.1rem",
                  borderBottom:
                    location === "service" ? "3px solid #30CDFF" : "none",
                  padding: "0.3rem",
                  cursor: "pointer",
                }}
              >
                Service
              </Typography>
            </MenuItem>
          </Link>
          <Link
            to="/provider/serviceView/serviceView"
            component={NavLink}
            underline="none"
          >
            <MenuItem onClick={handleCloseNavMenu} sx={{ width: 250 }}>
              <Typography
                sx={{
                  color: location === "serviceView" ? "secondary.dark" : "default",
                  fontSize: "1.1rem",
                  borderBottom:
                    location === "serviceView" ? "3px solid #30CDFF" : "none",
                  padding: "0.3rem",
                  cursor: "pointer",
                }}
              >
                Service Details
              </Typography>
            </MenuItem>
          </Link>
          {/* </Box> */}
        </Menu>
      </Box>

      <Box
        sx={{
          display: { xs: "none", md: "flex" },
        }}
      >
        <Box display={"flex"} gap="2rem">
          <Link
            to="/provider/facility/viewFacility"
            component={NavLink}
            underline="none"
          >
            <Typography
              sx={{
                color: location === "facility" ? "secondary.dark" : "default",
                fontSize: 18,
                fontWeight: 600,
                fontFamily: "Inter",
                borderBottom:
                  location === "facility" ? "3px solid #30CDFF" : "none",
                padding: "0.3rem",

                cursor: "pointer",
              }}
            >
              Facility
            </Typography>
          </Link>
          <Link
            to="/provider/service/listService"
            component={NavLink}
            underline="none"
          >
            <Typography
              sx={{
                color: location === "service" ? "secondary.dark" : "default",
                fontSize: 18,
                fontWeight: 600,
                fontFamily: "Inter",
                borderBottom:
                  location === "service" ? "3px solid #30CDFF" : "none",
                padding: "0.3rem",
                cursor: "pointer",
              }}
            >
              Service
            </Typography>
          </Link>
          <Link
            to="/provider/serviceView/serviceView"
            component={NavLink}
            underline="none"
          >
            <Typography
              sx={{
                color: location === "serviceView" ? "secondary.dark" : "default",
                fontSize: 18,
                fontWeight: 600,
                fontFamily: "Inter",
                borderBottom:
                  location === "serviceView" ? "3px solid #30CDFF" : "none",
                padding: "0.3rem",
                cursor: "pointer",
              }}
            >
              Service Details
            </Typography>
          </Link>
        </Box>
        <Grid></Grid>
      </Box>
    </Box>
    <IdleTimerContainer  />
    </>
  );
};

export default OrganizationNav;
