import { Button } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useState, useEffect } from "react";
import * as Yup from "yup";
import {
  TextField,
  Box,
  Typography,
  Grid,
  Paper,
  Autocomplete,
  AutocompleteRenderInputParams,
  createFilterOptions,
} from "@mui/material";

import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import FormTextField from "../../Components/Textfield";
import { Buttoncomponent } from "../../Components/Buttoncomp";

import { useAppDispatch, useAppSelector } from "../../Redux/Hook";
import { axiosPrivate} from "../../axios/axios";

import { useNavigate } from "react-router-dom";

import ErrorProps from "../../Components/Errorprops";
import TableViewCreateManually from "./createmanuallytableviewthrofacility";
import {
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@mui/material";

import useStyles from "../../styles";
interface InitialValues {
  Organizationid: string;

  ServiceCode: string;
  DiagnosisTestorServiceName: string;
  OrganizationPrices?: string;
  FacilityName: string;
  FacilityNPI?: string;
  FacilityPrices: string;
}


interface optiontype {
  CODE : string;
  CODE_DESC_CONSUMER : string
}
type CloseReason = "backdropClick" | "escapeKeyDown" | "closeButtonClick";
interface DialogProps extends MuiDialogProps {
  onClose: (reason: CloseReason) => void;
}

const Dialog = ({ title, open, onClose, children, ...props }: DialogProps) => {
  return (
    <MuiDialog
      onClose={(_, reason) => onClose(reason)}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={props.maxWidth}
      slotProps={{
        backdrop: {
          style: { backgroundColor: "transparent", boxShadow: "none" },
        },
      }}
    >
      <DialogActions>
        <Button onClick={() => onClose("closeButtonClick")} color="primary">
          <CloseOutlinedIcon />
        </Button>
      </DialogActions>

      <DialogContent>{children}</DialogContent>
    </MuiDialog>
  );
};

const CreateServicethroFacility = ({
  handleCancel,
  onCloseAll,
  addRow,
  handlePricelistUpdate
}: any) => {
  const [info, setInfo] =  useState<any>([""]);
  const [serviceCodeError, setServiceCodeError] = useState("");
 
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const Organizationid = useAppSelector(
    (state) => state.providerOrganization.orgEditData
  );

  const classes=useStyles()

  const facilityinput = useAppSelector(
    (state) => state.providerService.serviceData
  );

  const initialValues: InitialValues = {
    Organizationid: Organizationid[0].organizationID,

    ServiceCode: "",
    DiagnosisTestorServiceName: "",
    OrganizationPrices: "",
    FacilityNPI: "",
    FacilityName: "",
    FacilityPrices: "",
  };
  const CustomPaper = (props: any) => {
    return (
      <Paper
        elevation={8}
        sx={{  color: "black" }}
        {...props}
      />
    );
  };
  const OPTIONS_LIMIT = 10;
  const defaultFilterOptions = createFilterOptions();
  const [open, setOpen] = useState<boolean>(false);
  const [isFieldFocused, setFieldFocus] = useState(false);
  const filterOptions = (options: any, state: any) => {
    return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  };
  useEffect(() => {
    const fetchUsers = async () => {
      await axiosPrivate.get(`/service/findServiceCode`).then((res) => {
        setInfo(res.data.data);
     
      })
      .catch((err)=>{
        console.log(err)
      })
    };
    fetchUsers();
  }, []);
  const [tableData, setTableData] = useState({});
  const handleClickOpen = (values: InitialValues, actions: any) => {
    const servicedata = {
      Organizationid: Organizationid[0].organizationID,

      ServiceCode: values.ServiceCode,
      DiagnosisTestorServiceName: values.DiagnosisTestorServiceName,
      OrganizationPrices: values.OrganizationPrices,
      FacilityNPI: facilityinput.facilityNPI,
      FacilityName: facilityinput.facilityName,
      FacilityPrices: values.FacilityPrices,
      serviceStatus: "Available",
    };
  
  
  
    setTableData(servicedata);
    setOpen(true);
  };

  const handleClose = (value: CloseReason) => {
    setOpen(false);
  };

  const handleClose1 = () => {
    navigate("/provider/facility/viewfacility");
  };
  

  const validationSchema = Yup.object().shape({
  //  ServiceCode: Yup.string().required("Service Code is required"),
  //   DiagnosisTestorServiceName: Yup.string().required(
  //     "Service Name is required"
  //   ),
    FacilityPrices: Yup.string()
      .required("Facility price is required")
      .matches(
        /^(?:0|[1-9]\d*)(?:\.\d+)?$/,
        "only numbers"
      ),
  });


  return (
    
    <Box sx={{ padding: "1vh" }}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleClickOpen}
        validationSchema={validationSchema}
      >
        {({     handleChange,
        setFieldValue,
        values,
        touched,
        setFieldTouched,  }) => {
        const handleButtonClick = () => {
          setFieldTouched("ServiceCode", true); // Mark ServiceCode field as touched
          setFieldTouched("DiagnosisTestorServiceName", true); // Mark DiagnosisTestorServiceName field as touched
          if (!values.ServiceCode || !values.DiagnosisTestorServiceName ) {
            return; // Exit if any required field is empty
          }
       
        };

        return (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography className={classes.formHead}
                  sx={{
                   
                    fontWeight:600,
                    
                  }}
                >
                  Enter Service Data
                </Typography>
              </Grid>
              <Grid container spacing={2} sx={{ padding: "1rem", mt: "3vh" }}>
                <Grid item xs={12} md={6}>
                  <Typography className={classes.formType}
                  
                    sx={{
                
                      mb: "0.5rem",
                    }}
                  >
                    Diagnosis Test or Service Name{" "}
                    <Typography  component="span"display="inline" sx={{ color: "red" }}>
                      *
                    </Typography>
                  </Typography>
                  <Box sx={{ position: "relative" }}>
                  <Field className={classes.manualText}
                    
                    name="DiagnosisTestorServiceName"
                    component={Autocomplete}
                    options={info}
                    loading={info.length === 0}
                    PaperComponent={CustomPaper}
                    filterOptions={filterOptions}
                    getOptionLabel={(option: any) =>
                      option.CODE_DESC_CONSUMER || option
                    }
                    isOptionEqualToValue={(option: any, value: any) => 
                      option.value === value.value}
                    // freeSolo
                    onChange={(e: any, value: any) => {
                    
                      setFieldValue(
                        "ServiceCode",
                        value !== null ? value.CODE : ""
                      );
                      setFieldValue(
                        "DiagnosisTestorServiceName",
                        value !== null ? value.CODE_DESC_CONSUMER : ""
                      );
                      if (value === null) {
                        setFieldValue("ServiceCode", "");
                      }
                    }}
                    value={values.DiagnosisTestorServiceName}
                    renderInput={(params: AutocompleteRenderInputParams) => (
                      <TextField
                        {...params}
                        name="DiagnosisTestorServiceName"
                        
                        placeholder="Search Service Name"
                        onChange={handleChange}
                        variant="outlined"
                        helperText={
                          (touched.DiagnosisTestorServiceName && !values.DiagnosisTestorServiceName) ? (
                            <ErrorMessage name="DiagnosisTestorServiceName">
                              {(error) => <ErrorProps>{error}</ErrorProps>}
                            </ErrorMessage>
                          ) : (
                            <ErrorMessage name="DiagnosisTestorServiceName">
                              {(error) => (
                                <ErrorProps>
                                  {values.DiagnosisTestorServiceName ? (
                                    "Please select a service Name from the suggestions"
                                  ) : (
                                    "Service Name is required"
                                  )}
                                </ErrorProps>
                              )}
                            </ErrorMessage>
                          )
                        }
                        InputLabelProps={{
                          shrink: Boolean(values.DiagnosisTestorServiceName), // Shrink label if there is a value
                        }}
                        onFocus={() => setFieldFocus(true)}
                        onBlur={() => setFieldFocus(false)}
                       
                      />
                    )}
                  />
                    {values.DiagnosisTestorServiceName && !info.some((option: optiontype) => option.CODE_DESC_CONSUMER === values.DiagnosisTestorServiceName) ? (
                    <Box
                      sx={{
                        color: "red",
                        position: "absolute",
                        top: { xs: "45px", md: "calc(100% + 5px)" },
                        left: 10,
                        
                        fontSize: 14,
                        fontFamily: "Inder",
                        fontWeight: 400,
                      }}
                    >
                      { "Please select a service from the suggestions" }  
                    </Box>
                  ) : null}
                  {touched.DiagnosisTestorServiceName && !values.DiagnosisTestorServiceName ? (
                    <Box
                      sx={{
                        color: "red",
                        position: "absolute",
                        top: { xs: "45px", md: "calc(100% + 5px)" },
                        left: 10,
                        fontSize: 14,
                        fontFamily: "Inder",
                        fontWeight: 400,
                      }}
                    >
                      Servicename is required
                    </Box>
                  ) : null}
                  </Box>
                </Grid>

                <Grid item xs={12} md={5}>
                  <Typography className={classes.formType}
                
                    sx={{
                   
                      mb: "0.5rem",
                    }}
                  >
                    Service Code{" "}
                    <Typography component="span" display="inline" sx={{ color: "red" }}>
                      *
                    </Typography>
                  </Typography>
                  <Box sx={{ position: "relative" }}>
                  <Field className={classes.manualText}
                   // label="Service Code"
                    name="ServiceCode"
                    component={Autocomplete}
                    options={info}
                    loading={info.length === 0}
                    PaperComponent={CustomPaper}
                    filterOptions={filterOptions}
                    getOptionLabel={(option: any) => option.CODE || option}
                    isOptionEqualToValue={(option: any, value: any) => 
                      option.value === value.value}
                    // freeSolo
                    fullWidth={true}
                
                    onChange={(e: any, value: any) => {
               
                      setFieldValue(
                        "ServiceCode",
                        value !== null ? value.CODE : ""
                      );
                      setFieldValue(
                        "DiagnosisTestorServiceName",
                        value !== null ? value.CODE_DESC_CONSUMER : ""
                      );

                      // Explicitly set service name to an empty string when service code is cleared
                      if (value === null) {
                        setFieldValue("DiagnosisTestorServiceName", "");
                      }
                    }}
                    value={values.ServiceCode}
                    renderInput={(params: AutocompleteRenderInputParams) => (
                      <TextField
                        {...params}
                        name="ServiceCode"
                        
                        placeholder="Search Service Code"
                        onChange={handleChange}
                     
                        variant="outlined"
                        // helperText={
                        //   <ErrorMessage name="ServiceCode">
                        //     {(error) => <ErrorProps>{error}</ErrorProps>}
                        //   </ErrorMessage>
                        // }
                        helperText={
                          (touched.ServiceCode && !values.ServiceCode) ? (
                            <ErrorMessage name="ServiceCode">
                              {(error) => <ErrorProps>{error}</ErrorProps>}
                            </ErrorMessage>
                          ) : (
                            <ErrorMessage name="ServiceCode">
                              {(error) => (
                                <ErrorProps>
                                  {values.ServiceCode ? (
                                    "Please select a service Code from the suggestions"
                                  ) : (
                                    "Service Code is required"
                                  )}
                                </ErrorProps>
                              )}
                            </ErrorMessage>
                          )
                        }
                        InputLabelProps={{
                          shrink: Boolean(values.ServiceCode), // Shrink label if there is a value
                        }}
                        onFocus={() => setFieldFocus(true)}
                        onBlur={() => setFieldFocus(false)}
                        
                      />
                    )}
                  />
        
                                {values.ServiceCode && !info.some((option: optiontype) => option.CODE === values.ServiceCode) ? (
                    <Box
                      sx={{
                        color: "red",
                        position: "absolute",
                        top: { xs: "45px", md: "calc(100% + 5px)" },
                        left: 10,
                        fontSize: 14,
                        fontFamily: "Inder",
                        fontWeight: 400,
                      }}
                    >
                      { "Please select a service Code from the suggestions" }  
                    </Box>
                  ) : null}
                                    {values.ServiceCode && values.ServiceCode.length === 3 && (
  <Box
    sx={{
      color: "red",
      position: "absolute",
      top: { xs: "45px", md: "calc(100% + 5px)" },
      left: 10,
      fontSize: 14,
      fontFamily: "Inder",
      fontWeight: 400,
    }}
  >
                { "Please select a service Code from the suggestions" } 
  </Box>
)}
                  {touched.ServiceCode && !values.ServiceCode ? (
                    <Box
                      sx={{
                        color: "red",
                        position: "absolute",
                        top: { xs: "45px", md: "calc(100% + 5px)" },
                        left: 10,
                        fontSize: 14,
                        fontFamily: "Inder",
                        fontWeight: 400,
                      }}
                    >
                      Service Code is required
                    </Box>
                  ) : null}
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography className={classes.formType}
                    sx={{
                     mt: "0.5rem",
                      mb: "0.5rem",
                     
                    }}
                  >
                    Facility Prices{" "}
                    <Typography  component="span"display="inline" sx={{ color: "red" }}>
                      *
                    </Typography>
                  </Typography>
                  <FormTextField className={classes.manualText}
                    container={TextField}
                
                    name="FacilityPrices"
           
                    placeholder="Facility Prices"
                    autoComplete="text"
                    type="text"
                    fullWidth={true}
                    InputLabelProps={{
                      shrink: Boolean(values.FacilityPrices), 
                    }}
                    onFocus={() => setFieldFocus(true)}
                    onBlur={() => setFieldFocus(false)}
                   
                  />
                </Grid>
              </Grid>
              <Grid container item xs={10} columnGap={1} justifyContent="left">
                <Buttoncomponent className={classes.noButton}
                  type="button"
                  onClick={handleCancel}
                  
                  fullWidth={false}
                  variant="contained"
                  sx={{
                    mt: "2vh",
                   
                    width: "15vw",
                 
                    fontWeight:400,
                    textTransform: "capitalize",
                 
                   
                  }}
                >
                  Cancel
                </Buttoncomponent>
                <Buttoncomponent className={classes.yesButton}
                  type="submit"
              
                  fullWidth={false}
                  variant="contained"
                  sx={{
                    mt: "2vh",
                   
                    textTransform: "capitalize",
                    width: "15vw",
                    
                   
                  }}
                  onClick={handleButtonClick}

                  // disable={
                  //   // Check if any required field is touched but not filled
                    
                  //     touched.DiagnosisTestorServiceName &&
                  //     touched.ServiceCode &&
                  //     touched.FacilityPrices &&
                  
                  //     !(
                  //     values.DiagnosisTestorServiceName &&
                  //     values.ServiceCode &&
                  //     values.FacilityPrices &&
                
                  //     // Check if selected values exist in dropdown options
                  //     info.some(
                  //       (option: optiontype) =>
                  //         option.CODE_DESC_CONSUMER === values.DiagnosisTestorServiceName
                  //     ) &&
                  //     info.some((option: optiontype) => option.CODE === values.ServiceCode)                
                  //     )
                  // }
                  disable={
                    values.ServiceCode.length === 3 ||
                    (
                      touched.DiagnosisTestorServiceName &&
                      touched.ServiceCode &&
                      touched.FacilityPrices &&
                      
                      (
                        !(
                          values.DiagnosisTestorServiceName &&
                          values.ServiceCode &&
                          values.FacilityPrices &&
                          info.some(
                            (option: optiontype) =>
                              option.CODE_DESC_CONSUMER === values.DiagnosisTestorServiceName
                          ) && 
                          info.some((option: optiontype) => option.CODE === values.ServiceCode) 
                        ) ||
                        (values.DiagnosisTestorServiceName &&
                          !info.some(
                            (option: optiontype) =>
                              option.CODE_DESC_CONSUMER === values.DiagnosisTestorServiceName
                          )) ||
                        (values.ServiceCode &&
                          !info.some((option: optiontype) => option.CODE === values.ServiceCode))
                      )
                    ) || // Check if typed values are not in the dropdown options
                    (
                      values.DiagnosisTestorServiceName &&
                      !info.some(
                        (option: optiontype) =>
                          option.CODE_DESC_CONSUMER === values.DiagnosisTestorServiceName
                      )
                    ) ||
                    (
                      values.ServiceCode &&
                      !info.some((option: optiontype) => option.CODE === values.ServiceCode)
                    )
                  ? true : false } // Convert the expression to a boolean
                  // disable={
                    
                  //     touched.DiagnosisTestorServiceName &&
                  //     touched.ServiceCode &&
                  //     touched.FacilityPrices&&
                  //     !(values.DiagnosisTestorServiceName &&
                  //     values.ServiceCode &&
                  //     values.FacilityPrices &&
                  //     info.some(
                  //       (option: optiontype) =>
                  //         option.CODE_DESC_CONSUMER === values.DiagnosisTestorServiceName
                  //     ) &&
                  //     info.some((option: optiontype) => option.CODE === values.ServiceCode)
                  //   )
                  // }
                 // disable={ !info.some((option: optiontype) => option.CODE_DESC_CONSUMER === values.DiagnosisTestorServiceName) || !info.some((option: optiontype) => option.CODE === values.ServiceCode)}
                >
                  Next
                </Buttoncomponent>
                <Dialog
                  maxWidth={"xl"}
                  open={open}
                  onClose={handleClose}
                  children={
                    <div>
                      <TableViewCreateManually
                        data={tableData}
                        onCloseAll={onCloseAll}
                        handleCancel={handleClose}
                        addRow={addRow}
                        handlePricelistUpdate={handlePricelistUpdate}
                      />
                    </div>
                  }
                />
              </Grid>
            </Grid>
          </Form>
  )}}
      </Formik>
    </Box>
  );
};

export default CreateServicethroFacility;
